import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ghost from '../../img/ghost.svg'
import Svg from '../svg'

import styles from './styles.css'

const NothingHere = ({ className, visible, children }) =>
  visible ? (
    <div className={classnames(className, styles.container)}>
      <div className={styles.ghost}>
        <Svg width={null} height={null} svg={ghost} alt="ghost" className={styles.ghostSVG} />
      </div>
      {children}
    </div>
  ) : null

NothingHere.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
}

NothingHere.defaultProps = {
  visible: false,
  children: undefined,
  className: undefined,
}

export default NothingHere
