import { Map, fromJS } from 'immutable'
import { normalize, schema } from 'normalizr'

import createReducer from '../../utils/create-reducer'
import { CREATE_WHITELIST_STREAMER, REMOVE_WHITELIST_STREAMER } from '../streamers/action'
import { CREATE_INVITATION_WHITELIST, REMOVE_INVITATION_WHITELIST } from '../invitations/actions'

import { FETCH_WHITELISTS, FETCH_WHITELIST, FETCH_WHITELIST_CAMPAIGNS } from './actions'

export const INITIAL_STATE = new Map()
export const whitelistSchema = new schema.Entity('whitelist')

const normalizeAndMergeDeep = (state, { payload }) => {
  const payloadResults = payload.results ? payload.results : payload
  const results = normalize(payloadResults, [whitelistSchema]).entities.whitelist
  return state.mergeDeep(results)
}

export const whitelists = createReducer(INITIAL_STATE, {
  [FETCH_WHITELISTS.FULFILLED]: normalizeAndMergeDeep,
  [FETCH_WHITELIST_CAMPAIGNS.FULFILLED]: (state, { payload, meta: { whitelistId } }) =>
    state.setIn([String(whitelistId), 'campaigns'], fromJS(payload)),
})

export const whitelist = createReducer(INITIAL_STATE, {
  [FETCH_WHITELIST.FULFILLED]: (state, { payload }) => state.mergeDeep(payload),
  [CREATE_WHITELIST_STREAMER.FULFILLED]: (state, { payload }) =>
    state.update('creator_count', (value) => value + payload.length),
  [REMOVE_WHITELIST_STREAMER.FULFILLED]: (state) =>
    state.update('creator_count', (value) => value - 1),
  [CREATE_INVITATION_WHITELIST.FULFILLED]: (state, { payload }) =>
    state.update('invitation_count', (value) => value + payload.length),
  [REMOVE_INVITATION_WHITELIST.FULFILLED]: (state) =>
    state.update('invitation_count', (value) => value - 1),
  [FETCH_WHITELIST_CAMPAIGNS.FULFILLED]: (state, { payload }) =>
    state.set('campaigns', fromJS(payload)),
})
