import { get, post, del } from './request'

const VIDEO_DEFAULTS = { limit: 10, ordering: 'newest' }

export const getFeatured = (key) => () => get('public-videos', { key })

export const getVideos = (key) => (params) =>
  get(['videos'], { params: { ...VIDEO_DEFAULTS, ...params }, key })

export const getCreatorVideos = (key) => (streamer, params) =>
  get(['videos'], { key, params: { ...VIDEO_DEFAULTS, ...params, streamer } })

export const flag = (key) => (video) => post('flag-videos', { key }, { video })

export const unflag = (key) => (flagId) => del(['flag-videos', flagId], { key })
