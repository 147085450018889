import React from 'react'
import { Route, IndexRedirect } from 'react-router'
import Loadable from 'react-loadable'

import Loading from '../../components/piece-loading-async-route'

const loading = () => <Loading />

const CampaignWizardCodeDistribution = Loadable({
  loader: () =>
    import(/* webpackChunkName: "campaignwizard" */ '../../views/campaign-wizard/code-distribution'),
  loading,
})

const CampaignWizardGameStatus = Loadable({
  loader: () =>
    import(/* webpackChunkName: "campaignwizard" */ '../../views/campaign-wizard/game-status'),
  loading,
})

const CampaignWizardCodes = Loadable({
  loader: () =>
    import(/* webpackChunkName: "campaignwizard" */ '../../views/campaign-wizard/codes'),
  loading,
})

const CampaignWizardContact = Loadable({
  loader: () =>
    import(/* webpackChunkName: "campaignwizard" */ '../../views/campaign-wizard/contact'),
  loading,
})

const CampaignWizardFilters = Loadable({
  loader: () =>
    import(/* webpackChunkName: "campaignwizardfilters" */ '../../views/campaign-wizard/filters'),
  loading,
})

const CampaignWizardWhitelists = Loadable({
  loader: () =>
    import(/* webpackChunkName: "campaignwizardfilters" */ '../../views/campaign-wizard/whitelists'),
  loading,
})

const CampaignWizardConfirmation = Loadable({
  loader: () =>
    import(/* webpackChunkName: "campaignwizard" */ '../../views/campaign-wizard/confirmation'),
  loading,
})

const CampaignWizardSetup = Loadable({
  loader: () =>
    import(/* webpackChunkName: "campaignwizard" */ '../../views/campaign-wizard/setup'),
  loading,
})

const CampaignWizardVisibility = Loadable({
  loader: () =>
    import(/* webpackChunkName: "campaignwizard" */ '../../views/campaign-wizard/campaign-visibility'),
  loading,
})

const CampaignWizardNotifications = Loadable({
  loader: () =>
    import(/* webpackChunkName: "campaignwizard" */ '../../views/campaign-wizard/notifications'),
  loading,
})

const CampaignWizardAdditionalSetup = Loadable({
  loader: () =>
    import(/* webpackChunkName: "campaignwizard" */ '../../views/campaign-wizard/additional-setup'),
  loading,
})

const CampaignWizard = Loadable({
  loader: () => import(/* webpackChunkName: "campaignwizard" */ '../../views/campaign-wizard'),
  loading,
})

const CampaignWizardDone = Loadable({
  loader: () => import(/* webpackChunkName: "campaignwizard" */ '../../views/campaign-wizard/done'),
  loading,
})

const Wizard = (isEdit) => (
  <Route component={CampaignWizard}>
    <IndexRedirect to="code-distribution" />
    <Route path="code-distribution" component={CampaignWizardCodeDistribution} />
    <Route path="game-status" component={CampaignWizardGameStatus} />
    <Route path="setup" component={CampaignWizardSetup} />
    <Route path="codes" component={CampaignWizardCodes} />
    <Route path="contact" component={CampaignWizardContact} />
    <Route path="filters" component={CampaignWizardFilters} />
    <Route path="whitelists" component={CampaignWizardWhitelists} />
    <Route path="campaign-visibility" component={CampaignWizardVisibility} />
    <Route path="notifications" component={CampaignWizardNotifications} />
    <Route path="additional-setup" component={CampaignWizardAdditionalSetup} />
    <Route path="confirmation" component={CampaignWizardConfirmation} />
    {isEdit && <Route path="done" component={CampaignWizardDone} />}
  </Route>
)

export default Wizard
