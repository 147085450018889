import { Map, List } from 'immutable'
import { createSelector } from 'reselect'

export const getHashCodeFromObject = (meta) =>
  String(new Map(meta).filterNot((val) => val === undefined).hashCode())

const getPages = (state) => state.pagination
const getType = (state, type) => type
const getPageMeta = (state, type, meta = {}) => {
  const { offset, ...params } = meta
  return getHashCodeFromObject(params)
}
const getOffset = (state, type, meta) => (meta && meta.offset) || 0
const getPage = (page) => page
const getReducer = (page, reducer) => reducer
const getPagesUnderOffset = (offset, v, k) => {
  if (Number(k) <= offset) {
    return v.get('results')
  }
  return false
}

// Defaul pagination

export const selectPage = createSelector(
  getPages,
  getType,
  getPageMeta,
  getOffset,
  (pages = new Map(), type, page, offset) => pages.getIn([type, page, String(offset)], new Map())
)

export const selectPageResults = createSelector(
  getPage,
  getReducer,
  (page = new Map(), results = new Map()) =>
    page.get('results', new List()).map((resultId) => results.get(resultId))
)

// Inifinite scroll pagination

export const selectPages = createSelector(
  getPages,
  getType,
  getPageMeta,
  (pages = new Map(), type, page) => pages.getIn([type, page], new Map())
)

export const selectPageResultsUnderOffset = createSelector(
  getPage,
  getReducer,
  getOffset,
  (page = new Map(), results = new Map(), offset) =>
    page
      .map(getPagesUnderOffset.bind(null, offset))
      .filter(Boolean)
      .reduce((a, b) => a.concat(b), new List())
      .map((resultId) => results.get(resultId))
)
