import { Map, List, Set, fromJS } from 'immutable'

import createReducer from '../../utils/create-reducer'
import { GET_CAMPAIGN_DETAILS } from '../campaign/actions'
import { getOrder, byOrder, IMAGE } from '../../utils/get-media'

import {
  DELETE_CAMPAIGN_CODES,
  UPDATE_CAMPAIGN,
  UPLOAD_IMAGE,
  CHANGING_CAMPAIGN_FIELD,
  CLEAR_NEW_CAMPAIGN_STORE,
  CREATE_NEW_CAMPAIGN,
  ADD_CODES,
  DELETE_CODES,
  REMOVE_MEDIA,
  REORDER_MEDIA,
  CHECK_SLUG_UNIQUENESS,
} from './actions'

export const INITIAL_STATE = new Map({
  codes: new List(),
  require_steam: true,
  alternative_names: new Set(),
  must_not_own_steam_game: false,
  unlist_from_available: true,
  paid: false,
  payment_low: undefined,
  payment_high: undefined,
  whitelists: new List(),
  twitch_games: new List(),
  email_new_requests: true,
  is_released: true,
})

const ORDER_HALF_STEP = 0.1
const setOrderByOrder = (m, i) => m.set('order', i)
const calcNewIndex = (prevIndex, newIndex) => {
  if (prevIndex > newIndex) {
    return newIndex - ORDER_HALF_STEP
  }
  return newIndex + ORDER_HALF_STEP
}

const mergePayload = (state, { payload }) => {
  if (payload.alternative_names) {
    return state.mergeDeep(payload).set('alternative_names', new Set(payload.alternative_names))
  }
  if (payload.codes) {
    const codes = state.get('codes', new Set()).toSet()
    return state.mergeDeep(payload).set('codes', codes.union(payload.codes))
  }
  return state.merge(payload)
}

export const newCampaign = createReducer(INITIAL_STATE, {
  [CHANGING_CAMPAIGN_FIELD]: mergePayload,

  [ADD_CODES]: (state, { payload }) => {
    const currentCodes = state.get('codes')
    const platform = currentCodes.find((a) => a.get('platform') === payload.platform)
    const platformIndex = currentCodes.indexOf(platform)

    if (platform) {
      return state.updateIn(['codes', platformIndex, 'codes'], (codes = new Set()) =>
        codes.toSet().union(payload.codes)
      )
    }

    return state.updateIn(['codes'], (codes) =>
      codes.push(
        new Map({
          platform: payload.platform,
          codes: new Set(payload.codes),
        })
      )
    )
  },

  [DELETE_CODES]: (state, { payload }) => {
    const codes = state.get('codes')
    const platform = codes.find((a) => a.get('platform') === payload)
    const platformIndex = codes.indexOf(platform)
    return state.removeIn(['codes', platformIndex])
  },

  [CREATE_NEW_CAMPAIGN.FULFILLED]: mergePayload,

  [UPLOAD_IMAGE.FULFILLED]: (state, { payload, meta = {} }) => {
    if (!payload) {
      return state
    }

    if (meta.cover) {
      return state.set('cover_url', payload.original)
    }

    const media = state.get('media', new List())
    const uploadedImage = new Map({
      thumbnail_url: payload.thumbnail,
      url: payload.original,
      type: payload.type || IMAGE,
      order: media.size,
    })
    return state.set('media', media.unshift(uploadedImage))
  },

  [REMOVE_MEDIA]: (state, { payload }) => state.deleteIn(['media', payload]),

  [REORDER_MEDIA]: (state, { payload }) =>
    state
      .setIn(
        ['media', payload.previousIndex, 'order'],
        calcNewIndex(payload.previousIndex, payload.newIndex)
      )
      .update('media', (media = new List()) =>
        media.sortBy(getOrder, byOrder).map(setOrderByOrder)
      ),
  [UPDATE_CAMPAIGN.FULFILLED]: mergePayload,

  [GET_CAMPAIGN_DETAILS.FULFILLED]: (state, { payload }) => fromJS(payload),

  [DELETE_CAMPAIGN_CODES.FULFILLED]: (state) => state.set('codes', INITIAL_STATE.get('codes')),

  [CLEAR_NEW_CAMPAIGN_STORE]: () => INITIAL_STATE,
})

export const slugUniqueness = createReducer(new Map(), {
  [CHECK_SLUG_UNIQUENESS.FULFILLED]: (state, { meta }) => state.set(meta, 0),
  [CHECK_SLUG_UNIQUENESS.REJECTED]: (state, { meta, payload }) => state.set(meta, payload.id),
})
