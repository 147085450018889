import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styles from './styles.css'

class Textarea extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    autoGrow: PropTypes.bool,
  }

  static defaultProps = {
    autoGrow: true,
    onChange: () => {},
  }

  componentDidMount() {
    this.componentDidUpdate()
  }

  componentDidUpdate() {
    const { autoGrow } = this.props
    if (autoGrow) {
      this.ref.style.height = 'auto'
      const height = this.ref.scrollHeight
      if (height > 0) {
        this.ref.style.height = `${height}px`
      }
    }
  }

  handleChange = (e) => {
    const { onChange } = this.props
    onChange(e.target.value, e.target.name, e)
  }

  handleRef = (ref) => {
    this.ref = ref
  }

  render() {
    const { onChange, autoGrow, ...otherProps } = this.props
    return (
      <textarea
        ref={this.handleRef}
        className={styles.textarea}
        onChange={this.handleChange}
        {...otherProps}
      />
    )
  }
}

export default Textarea
