import React from 'react'
import PropTypes from 'prop-types'
import { faXTwitter } from '@fortawesome/free-brands-svg-icons'

import Icon from '../piece-icon'

import styles from './styles.css'

const getTwitterURL = (gameName, shortURL) => {
  const text = encodeURIComponent(`I got a code for ${gameName} at ${shortURL}, video coming soon.`)
  return `https://twitter.com/intent/tweet?text=${text}`
}

const Share = ({ gameName, shortURL, onGetTwitterShareURL }) => (
  <a
    target="_blank"
    className={styles.twitter}
    rel="noopener noreferrer"
    href={onGetTwitterShareURL(gameName, shortURL)}
  >
    <Icon icon={faXTwitter} />
  </a>
)

Share.propTypes = {
  gameName: PropTypes.string,
  shortURL: PropTypes.string,
  onGetTwitterShareURL: PropTypes.func,
}

Share.defaultProps = {
  gameName: undefined,
  shortURL: 'https://woovit.com',
  onGetTwitterShareURL: getTwitterURL,
}

export default Share
