import defineAction from '../../utils/define-action'
import * as whitelistService from '../../services/whitelist'

export const FETCH_WHITELISTS = defineAction('FETCH_WHITELISTS')
export const FETCH_WHITELIST = defineAction('FETCH_WHITELIST')
export const REMOVE_WHITELIST = defineAction('REMOVE_WHITELIST')
export const CREATE_WHITELIST = defineAction('CREATE_WHITELIST')
export const UPDATE_WHITELIST = defineAction('UPDATE_WHITELIST')
export const VALIDATE_WHITELIST_INVITE_EMAILS = defineAction('VALIDATE_WHITELIST_INVITE_EMAILS')
export const FETCH_WHITELIST_CAMPAIGNS = defineAction('FETCH_WHITELIST_CAMPAIGNS')
export const GET_CREATOR_WHITELISTS = defineAction('GET_CREATOR_WHITELISTS')

export const getCreatorWhitelists = (userId, creatorId) => (dispatch, getState) =>
  dispatch({
    type: GET_CREATOR_WHITELISTS.ACTION,
    meta: { creatorId },
    payload: whitelistService
      .fetchParams(getState().user.get('auth_token'))({
        paginated: 0,
        user_id: userId,
      })
      .then((data) => data.filter((r) => r.creator_whitelisted)),
  })

export const fetchWhitelists = ({ offset, paginated, ordering, hasCount }) => (
  dispatch,
  getState
) =>
  dispatch({
    type: FETCH_WHITELISTS.ACTION,
    payload: whitelistService.fetch(getState().user.get('auth_token'))({
      offset,
      paginated,
      ordering,
      hasCount,
    }),
    meta: {
      page: { offset, ordering },
    },
  })

export const fetchUserWhitelists = (userId) => (dispatch, getState) =>
  dispatch({
    type: FETCH_WHITELISTS.ACTION,
    payload: whitelistService.fetchParams(getState().user.get('auth_token'))({
      user_id: userId,
      paginated: 0,
    }),
  })

export const fetchWhitelist = (id, params) => (dispatch, getState) =>
  dispatch({
    type: FETCH_WHITELIST.ACTION,
    payload: whitelistService.fetchOne(getState().user.get('auth_token'))(id, params),
  })

export const removeWhiteList = (id) => (dispatch, getState) =>
  dispatch({
    type: REMOVE_WHITELIST.ACTION,
    payload: whitelistService.remove(getState().user.get('auth_token'))(id),
  })

export const createWhitelist = (payload) => (dispatch, getState) =>
  dispatch({
    type: CREATE_WHITELIST.ACTION,
    payload: whitelistService.create(getState().user.get('auth_token'))(payload),
  })

export const updateWhitelist = (id, payload) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_WHITELIST.ACTION,
    payload: whitelistService.update(getState().user.get('auth_token'))(id, payload),
  })

export const validateInviteEmails = (id, payload) => (dispatch, getState) =>
  dispatch({
    type: VALIDATE_WHITELIST_INVITE_EMAILS.ACTION,
    payload: whitelistService.validateWhitelistInvitationEmails(getState().user.get('auth_token'))(
      id,
      payload
    ),
  })

export const fetchWhitelistCampaigns = (whitelistId) => (dispatch, getState) => {
  return dispatch({
    type: FETCH_WHITELIST_CAMPAIGNS.ACTION,
    payload: whitelistService.getCampaigns(getState().user.get('auth_token'))(whitelistId),
    meta: {
      whitelistId,
    },
  })
}
