import React from 'react'
import { PropTypes } from 'prop-types'

import { formatEmbargo } from '../../utils/datetime'

const EmbargoDate = ({ embargoEnds }) => (
  <span>{embargoEnds ? formatEmbargo(embargoEnds) : 'No embargo'}</span>
)

EmbargoDate.propTypes = {
  embargoEnds: PropTypes.string,
}

EmbargoDate.defaultProps = {
  embargoEnds: undefined,
}

export default EmbargoDate
