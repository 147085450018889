import defineAction from '../../utils/define-action'
import * as gameService from '../../services/games'

export const SEARCH_GAME = defineAction('SEARCH_GAME')
export const SEARCH_GOG_GAME = defineAction('SEARCH_GOG_GAME')
export const SEARCH_TWITCH_GAME = defineAction('SEARCH_TWITCH_GAME')

export const search = (term) => (dispatch, getState) =>
  dispatch({
    type: SEARCH_GAME.ACTION,
    payload:
      getState().game.get(term) || gameService.search(getState().user.get('auth_token'))(term),
    meta: term,
  })

export const searchGOG = (term) => (dispatch, getState) =>
  dispatch({
    type: SEARCH_GOG_GAME,
    payload:
      getState().gogGame.get(term) ||
      gameService.searchGOG(getState().user.get('auth_token'))(term),
    meta: term,
  })

export const searchTwitch = (termOrId) => (dispatch, getState) =>
  dispatch({
    type: SEARCH_TWITCH_GAME.ACTION,
    payload:
      getState().twitchGame.get(termOrId) ||
      gameService.searchTwitch(getState().user.get('auth_token'))(termOrId),
    meta: termOrId,
  })
