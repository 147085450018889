import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import classnames from 'classnames'
import { connect } from 'react-redux'
import ImmutableProptypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'

import { selectPage, selectPageResults } from '../../modules/pagination/selectors'
import { getCreatorBySlug } from '../../modules/creator/selectors'
import { GET_CREATOR_DETAILS } from '../../modules/creator/actions'
import {
  GET_CREATOR_VIDEOS,
  getCreatorVideos as getCreatorVideosAction,
} from '../../modules/video/actions'
import { PLATFORM_YOUTUBE, PLATFORM_TWITCH } from '../../config/settings'
import Loading from '../../components/piece-loading'
import addEllipsis from '../../utils/add-ellipsis'
import Checkbox from '../../components/piece-field-checkbox'
import CreatorVideo from '../../components/creator-video'
import NothingHere from '../../components/piece-nothing-here'
import Actionable from '../../components/actionable'
import Container from '../../components/container'
import Select from '../../components/piece-field-select'
import InputSearch from '../../components/piece-input-search'
import Pagination from '../../components/pagination'
import { Provider } from '../../components/provider-icon'
import { newHandleQuery } from '../../utils/handle-query'
import { format } from '../../utils/datetime'
import lowercaseParams from '../../hocs/lowercase-params'
import videoViewsInfo from '../../utils/video-views-info'
import * as creatorService from '../../services/creator'
import * as videoService from '../../services/videos'
import { creator as creatorReducer } from '../../modules/creator/reducers'
import { pagination as paginationReducer } from '../../modules/pagination/reducers'
import { video as videoReducer } from '../../modules/video/reducers'
import { KIND } from '../../config/user-settings'

import styles from './styles.css'

const DATE_FORMAT = 'MMM DD, YYYY'
const VIDEO_DESCRIPTION_LENGTH = 200
const ORDER_OPTIONS = [
  { value: 'newest', label: 'Recently added' },
  { value: 'oldest', label: 'Older first' },
  { value: 'most_viewers', label: 'Most viewers' },
]
const DEFAULT_ORDERING = 'newest'
const PLATFORM_OPTIONS = [
  { value: 'all', label: 'All' },
  { value: String(PLATFORM_TWITCH), label: 'Twitch' },
  { value: String(PLATFORM_YOUTUBE), label: 'YouTube' },
]

const mapStateToProps = (state, { params: { slug }, location: { query } }) => {
  const creator = getCreatorBySlug(state, slug) || new Map()
  const isPublisher = state.user.getIn(['profile', 'kind']) === KIND.PUBLISHER
  const videos = selectPage(state, GET_CREATOR_VIDEOS.ACTION, {
    streamer: creator.get('creator_id'),
    ...query,
  })
  return {
    isPublisher,
    creator,
    isLoadingVideos: !videos.size && !!state.loading.get(GET_CREATOR_VIDEOS.ACTION),
    videos: selectPageResults(videos, state.video),
    pagination: videos,
  }
}

const mapDispatchToProps = {
  getCreatorVideos: getCreatorVideosAction,
}

const PublicCreatorPageVideos = ({
  creator,
  videos,
  location,
  pagination,
  isPublisher,
  getCreatorVideos,
  isLoadingVideos,
  children,
}) => {
  const creatorId = creator.get('creator_id')

  useEffect(() => {
    if (creatorId) {
      getCreatorVideos(creatorId, location.query)
    }
  }, [creatorId, location.query])

  const handleOrdering = ({ value }) => {
    newHandleQuery({
      ordering: value,
    })
  }

  const handleOnlyDeliveries = (e) => {
    newHandleQuery({
      only_deliveries: e.target.checked ? '1' : undefined,
    })
  }

  const handlePlatform = ({ value }) => {
    newHandleQuery({
      platform: value === 'all' ? undefined : value,
    })
  }

  const handleSearch = (value) => {
    newHandleQuery({
      search: value,
    })
  }

  const handlePagination = ({ value: offset }) => {
    newHandleQuery({
      offset,
    })
  }

  const clearFilters = () => {
    browserHistory.push(location.pathname)
  }

  const renderVideo = (video) => (
    <div key={video.hashCode()} className={styles.video}>
      <div className={styles.videoWrapper}>
        <CreatorVideo canPlay={!!video.get('platform_video_id')} video={video} />
      </div>
      <div className={styles.videoMeta}>
        <h2 className={styles.videoTitle}>
          <a
            className={styles.videoTitleLink}
            href={video.get('video_url')}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Provider small platform={video.get('platform')} />{' '}
            <span className={styles.titleWrapper}>{video.get('title')}</span>
          </a>
        </h2>
        <div className={styles.videoInfo}>
          {videoViewsInfo(video)} • {format(video.get('published_at'), DATE_FORMAT)}
        </div>
        <div className={styles.videoDescription}>
          {addEllipsis(video.get('description'), VIDEO_DESCRIPTION_LENGTH)}
        </div>
      </div>
    </div>
  )

  const hasFilters = () => Object.keys(location.query).length > 0

  const offset = location.query.offset && Number(location.query.offset)

  if (isLoadingVideos && !creator.size) {
    return <Loading isLoading />
  }
  return (
    <>
      <div className={styles.filters}>
        <div className={styles.filterGroup}>
          <div className={styles.selectWrap}>
            <Select
              searchable={false}
              options={ORDER_OPTIONS}
              dark
              name="ordering"
              value={location.query.ordering || DEFAULT_ORDERING}
              onChange={handleOrdering}
            />
          </div>
        </div>
        <div className={styles.filterGroup}>
          <div className={styles.selectWrap}>
            <Select
              searchable={false}
              options={PLATFORM_OPTIONS}
              dark
              name="platform"
              value={location.query.platform || PLATFORM_OPTIONS[0].value}
              onChange={handlePlatform}
            />
          </div>
        </div>
        <div className={styles.filterGroup}>
          <InputSearch
            onSubmit={handleSearch}
            initialValue={location.query.search}
            name="search"
            placeholder={`Search ${creator.get('name')}'s work`}
            className={styles.inputSearch}
          />
        </div>
        {isPublisher ? (
          <div className={styles.filterGroup}>
            <Checkbox
              checked={location.query.only_deliveries === '1'}
              name="onlyDeliveries"
              label="Only deliveries"
              onChange={handleOnlyDeliveries}
            />
          </div>
        ) : null}

        <div className={classnames(styles.filterGroup, styles.clearFilterGroup)}>
          <Actionable className={styles.clearFilters} onClick={clearFilters}>
            clear filters
          </Actionable>
        </div>
      </div>
      <Container className={styles.videos}>
        <Loading isLoading={isLoadingVideos && !videos.size} />
        <NothingHere visible={!isLoadingVideos && !hasFilters() && !videos.size}>
          No videos!
        </NothingHere>
        <NothingHere visible={!isLoadingVideos && hasFilters() && !videos.size}>
          No results!
          <br />
          <button type="button" className={styles.clearFilters} onClick={clearFilters}>
            Reset your search
          </button>{' '}
          to see more videos!
        </NothingHere>

        {videos.size && location.query.search ? (
          <h2 className={styles.resultsFor}>
            Showing Results for <strong>{location.query.search}</strong>
          </h2>
        ) : null}
        {videos.map(renderVideo)}
        {pagination.get('results') ? (
          <div className={styles.paginationWrap}>
            <Pagination
              count={pagination.get('count')}
              prev={pagination.get('prev')}
              next={pagination.get('next')}
              onClick={handlePagination}
              current={offset}
            />
          </div>
        ) : null}
      </Container>
      {children}
    </>
  )
}

PublicCreatorPageVideos.propTypes = {
  isPublisher: PropTypes.bool,
  creator: ImmutableProptypes.map,
  videos: ImmutableProptypes.list,
  pagination: ImmutableProptypes.map.isRequired,
  getCreatorVideos: PropTypes.func.isRequired,
  isLoadingVideos: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({
      ordering: PropTypes.string,
      limit: PropTypes.string,
    }),
    pathname: PropTypes.string,
    state: PropTypes.shape({
      fromSearch: PropTypes.string,
    }),
  }).isRequired,
  children: PropTypes.node,
}

PublicCreatorPageVideos.defaultProps = {
  isPublisher: false,
  videos: new List(),
  creator: new Map(),
  children: null,
}

PublicCreatorPageVideos.getData = ({ slug }, { query }) =>
  creatorService
    .getBySlug()(slug)
    .then((creator) =>
      videoService
        .getCreatorVideos()(creator.creator_id, query)
        .then((videos) => ({
          creator: creatorReducer(undefined, {
            type: GET_CREATOR_DETAILS.FULFILLED,
            payload: creator,
          }),
          video: videoReducer(undefined, {
            type: GET_CREATOR_VIDEOS.FULFILLED,
            payload: videos,
          }),
          pagination: paginationReducer(undefined, {
            payload: videos,
            type: GET_CREATOR_VIDEOS.FULFILLED,
            meta: {
              page: {
                streamer: creator.creator_id,
                ...query,
              },
            },
          }),
        }))
    )

export default lowercaseParams(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PublicCreatorPageVideos)
)
