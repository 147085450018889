import defineAction from '../../utils/define-action'
import * as whitelistServices from '../../services/whitelist'

export const FETCH_INVITATIONS_WHITELISTS = defineAction('FETCH_INVITATIONS_WHITELISTS')
export const REMOVE_INVITATION_WHITELIST = defineAction('REMOVE_INVITATION_WHITELIST')
export const CREATE_INVITATION_WHITELIST = defineAction('CREATE_INVITATION_WHITELIST')
export const RESEND_INVITATION_WHITELIST = defineAction('RESEND_INVITATION_WHITELIST')

export const WHITELIST_INVITATIONS_DEFAULT_PARAMS = { limit: 10 }
export const fetchWhitelistInvitations = (whitelistId, params) => (dispatch, getState) => {
  const newParams = { ...WHITELIST_INVITATIONS_DEFAULT_PARAMS, ...params }

  return dispatch({
    type: FETCH_INVITATIONS_WHITELISTS.ACTION,
    payload: whitelistServices.getWhitelistInvitations(getState().user.get('auth_token'))(
      whitelistId,
      newParams
    ),
    meta: {
      page: newParams,
    },
  })
}

export const removeWhitelistInvitation = (whitelistId, invitationId) => (dispatch, getState) =>
  dispatch({
    type: REMOVE_INVITATION_WHITELIST.ACTION,
    payload: whitelistServices.removeWhitelistInvitation(getState().user.get('auth_token'))(
      whitelistId,
      invitationId
    ),
  })

export const createWhitelistInvitation = (whitelistId, payload) => (dispatch, getState) =>
  dispatch({
    type: CREATE_INVITATION_WHITELIST.ACTION,
    payload: whitelistServices.createWhitelistInvitation(getState().user.get('auth_token'))(
      whitelistId,
      payload
    ),
  })

export const resendWhitelistInvitation = (whitelistId, invitationId) => (dispatch, getState) =>
  dispatch({
    type: RESEND_INVITATION_WHITELIST.ACTION,
    payload: whitelistServices.resendWhitelistInvitation(getState().user.get('auth_token'))(
      whitelistId,
      invitationId
    ),
  })
