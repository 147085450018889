const getFollowerDataFromCreator = (item) => {
  if (item.get('creator')) {
    return {
      twitch: {
        followers: Number(item.getIn(['creator', 'twitch', 'follower_count'])),
        url: item.getIn(['creator', 'twitch', 'account_url']),
      },
      youtube: {
        followers: Number(item.getIn(['creator', 'youtube', 'follower_count'])),
        url: item.getIn(['creator', 'youtube', 'account_url']),
      },
    }
  }

  return {
    twitch: {
      followers: Number(item.getIn(['twitch', 'follower_count'])),
      url: item.getIn(['twitch', 'account_url']),
    },
    youtube: {
      followers: Number(item.getIn(['youtube', 'follower_count'])),
      url: item.getIn(['youtube', 'account_url']),
    },
  }
}

export default getFollowerDataFromCreator

export const getAvgViewersFromCreator = (item) => {
  if (item.get('creator')) {
    return {
      twitch: {
        followers: Number(item.getIn(['creator', 'twitch', 'avg_view_count'])),
        url: item.getIn(['creator', 'twitch', 'account_url']),
      },
      youtube: {
        followers: Number(item.getIn(['creator', 'youtube', 'avg_view_count'])),
        url: item.getIn(['creator', 'youtube', 'account_url']),
      },
    }
  }

  return {
    twitch: {
      followers: Number(item.getIn(['twitch', 'avg_view_count'])),
      url: item.getIn(['twitch', 'account_url']),
    },
    youtube: {
      followers: Number(item.getIn(['youtube', 'avg_view_count'])),
      url: item.getIn(['youtube', 'account_url']),
    },
  }
}
