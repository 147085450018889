import React from 'react'
import ContentLoader from 'react-content-loader'

import styles from './styles.css'

const NUM_SECTIONS = 2
const SECTIONS = [...Array(NUM_SECTIONS).keys()]

const Loader = () => (
  <div className={styles.feed}>
    {SECTIONS.map((i) => (
      <ContentLoader
        speed={2}
        viewBox="0 0 720 525"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        key={i}
      >
        <rect x="0" y="0" rx="0" ry="0" width="75" height="22" />

        <circle cx="18" cy="68" r="18" />
        <rect x="64" y="60" rx="0" ry="0" width="400" height="14" />
        <rect x="680" y="61" rx="0" ry="0" width="35" height="12" />

        <circle cx="18" cy="160" r="18" />
        <rect x="64" y="152" rx="0" ry="0" width="500" height="14" />
        <rect x="680" y="153" rx="0" ry="0" width="35" height="12" />

        <circle cx="18" cy="252" r="18" />
        <rect x="64" y="245" rx="0" ry="0" width="450" height="14" />
        <rect x="680" y="245" rx="0" ry="0" width="35" height="12" />

        <circle cx="18" cy="344" r="18" />
        <rect x="64" y="335" rx="0" ry="0" width="425" height="14" />
        <rect x="680" y="336" rx="0" ry="0" width="35" height="12" />

        <circle cx="18" cy="436" r="18" />
        <rect x="64" y="425" rx="0" ry="0" width="475" height="14" />
        <rect x="680" y="426" rx="0" ry="0" width="35" height="12" />
      </ContentLoader>
    ))}
  </div>
)

export default Loader
