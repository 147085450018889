import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { browserHistory } from 'react-router'
import { routerMiddleware } from 'react-router-redux'
import promise from 'redux-promise-middleware'

import errorMiddleware from '../middlewares/error'
import analytics from '../middlewares/analytics'
import rootReducer from '../modules/reducers'

const router = routerMiddleware(browserHistory)

const configureStore = (preloadedState) =>
  createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(thunk, router, errorMiddleware, promise(), analytics)
  )

export default configureStore
