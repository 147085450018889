import React from 'react'
import ReactIntercom from 'react-intercom'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { useCookies } from 'react-cookie'

import { INTERCOM_APP_ID } from '../../config/environment'
import getUserName from '../../utils/get-user-name'
import sessionStorage from '../../utils/session-storage'
import { REFERER_COOKIE } from '../../utils/server'

const mapDispatchToProps = ({ user }) => ({ user })
// eslint-disable-next-line react/prefer-stateless-function
const Intercom = ({ user }) => {
  const [cookies] = useCookies([REFERER_COOKIE])

  if (!INTERCOM_APP_ID || sessionStorage.getItem('admin_login')) {
    return null
  }

  const userData = {
    user_id: user.get('id'),
    profile_id: user.getIn(['profile', 'id']),
    name: getUserName(
      user.get('first_name'),
      user.get('last_name'),
      user.getIn(['profile', 'creator', 'name']) || user.get('username')
    ),
    kind: user.getIn(['profile', 'kind']),
    email: user.get('email'),
    core_link: `https://core.woovit.com/admin/core/userprofile/${user.getIn(['profile', 'id'])}`,
    first_referrer: cookies[REFERER_COOKIE],
  }
  return <ReactIntercom appID={INTERCOM_APP_ID} {...userData} />
}
Intercom.propTypes = {
  user: ImmutablePropTypes.map.isRequired,
}

export default connect(mapDispatchToProps)(Intercom)
