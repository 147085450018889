import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { KIND } from '../config/user-settings'
import {
  fetchManualReviewByCampaign,
  FETCH_MANUAL_REVIEW_BY_CAMPAIGN,
} from '../modules/manual-review/actions'
import { MANUAL_REVIEW_PENDING } from '../services/manual-review'
import { selectPage } from '../modules/pagination/selectors'

const MANUAL_REVIEW_FETCH_POOLING_DELAY = 60000 // every minute

const mapStateToProps = (state) => ({
  requestsCounter: selectPage(state, FETCH_MANUAL_REVIEW_BY_CAMPAIGN.ACTION, {
    status: MANUAL_REVIEW_PENDING,
  }).get('count', 0),
  isLoading: !!state.loading.get(FETCH_MANUAL_REVIEW_BY_CAMPAIGN.ACTION),
  isPublisher: state.user.getIn(['profile', 'kind']) === KIND.PUBLISHER,
})

const mapDispatchToProps = {
  fetchManualReviewByCampaign,
}

const ManualReviewCount = (WrappedComponent) => {
  class ManualReviewCountComponent extends Component {
    static propTypes = {
      fetchManualReviewByCampaign: PropTypes.func.isRequired,
      requestsCounter: PropTypes.number.isRequired,
      isLoading: PropTypes.bool.isRequired,
      isPublisher: PropTypes.bool.isRequired,
    }

    componentDidMount() {
      // TODO: Should only poll if is a publisher
      this.handleRequestsPooling()
    }

    componentWillUnmount() {
      clearTimeout(this.timeout)
    }

    handleRequestsPooling = () => {
      if (!this.props.isLoading && this.props.isPublisher) {
        this.props.fetchManualReviewByCampaign({ status: MANUAL_REVIEW_PENDING })
      }
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.handleRequestsPooling, MANUAL_REVIEW_FETCH_POOLING_DELAY)
    }

    render() {
      const { requestsCounter } = this.props
      return <WrappedComponent {...this.props} requestsCounter={requestsCounter} />
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(ManualReviewCountComponent)
}

export default ManualReviewCount
