import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import styles from './styles.css'

const CampaignFeedSection = ({ className, date, children }) => {
  const formattedDate = moment(date, 'YYYYMMDD').calendar(null, {
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    lastWeek: 'MMMM DD, YYYY',
    sameElse: () => 'MMMM DD, YYYY',
  })
  return (
    <div className={className}>
      <h2 className={styles.sectionTitle}>
        <span>{formattedDate}</span>
      </h2>
      {children}
    </div>
  )
}

CampaignFeedSection.propTypes = {
  date: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
}

CampaignFeedSection.defaultProps = {
  children: null,
  className: null,
}

export default CampaignFeedSection
