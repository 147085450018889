import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { PLATFORMS_WITHOUT_SPECIFIC_REGIONS } from '../../config/game-code-types'

import styles from './styles.css'

import PlatformIcon from '.'

class Platforms extends Component {
  static propTypes = {
    platforms: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array])),
    dark: PropTypes.bool,
    discloseAllPlatforms: PropTypes.bool,
  }

  static defaultProps = {
    discloseAllPlatforms: false,
    dark: false,
    platforms: [],
  }

  renderPlatform = (moreProps, platform) => {
    if (typeof platform === 'object') {
      return (
        <PlatformIcon
          key={platform.platform}
          {...moreProps}
          platform={platform.platform}
          outOfCodes={!platform.available}
        />
      )
    }
    return <PlatformIcon key={platform} {...moreProps} platform={platform} />
  }

  getMainPlatforms = (platforms) => {
    if (platforms.length && typeof platforms[0] === 'string') {
      const allPlatforms = platforms.join('+')
      return PLATFORMS_WITHOUT_SPECIFIC_REGIONS.filter((mainPlatform) =>
        allPlatforms.includes(mainPlatform)
      )
    }

    return PLATFORMS_WITHOUT_SPECIFIC_REGIONS.reduce((result, mainPlatform) => {
      const filteredPlatforms = platforms.filter((item) => {
        return item.platform.includes(mainPlatform)
      })
      if (filteredPlatforms.length) {
        result.push({
          platform: mainPlatform,
          available: filteredPlatforms.some((platform) => platform.available),
        })
      }
      return result
    }, [])
  }

  getPlatforms = () => {
    const { discloseAllPlatforms, platforms } = this.props
    if (discloseAllPlatforms) {
      return platforms
    }

    return this.getMainPlatforms(platforms)
  }

  render() {
    const { platforms, discloseAllPlatforms, ...moreProps } = this.props
    const customPlatforms = this.getPlatforms()
    return (
      <div className={styles.wrapContainer}>
        {customPlatforms.map(this.renderPlatform.bind(null, moreProps))}
      </div>
    )
  }
}

Platforms.propTypes = {
  platforms: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({ platform: PropTypes.string, available: PropTypes.bool }),
    ])
  ),
  dark: PropTypes.bool,
  discloseAllPlatforms: PropTypes.bool,
}

Platforms.defaultProps = {
  discloseAllPlatforms: false,
  dark: false,
  platforms: [],
}

export default Platforms
