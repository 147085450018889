import { Map, List } from 'immutable'
import { createSelector } from 'reselect'

import { format } from 'utils/datetime'

import { getHashCodeFromObject as getHashCodeFromObjectPage } from '../pagination/selectors'

import { ACTIVITY_FEED_DEFAULT_PARAMS } from './actions'

export const getHashCodeFromObject = ({ offset, ...meta }) => getHashCodeFromObjectPage(meta)

const getPage = (page) => page
const getOffset = (page, campaignOffer, offset) => String(offset)
const getPagesUnderOffset = (offset, v, k) => {
  if (Number(k) <= offset) {
    return v.get('results')
  }
  return false
}

const getPages = (state) => state.activityFeedEventsPagination
const getPageMeta = (state, meta) =>
  getHashCodeFromObject({ ...ACTIVITY_FEED_DEFAULT_PARAMS, ...meta })
const getActivityFeedEvents = (page, events) => events

export const selectPage = createSelector(
  getPages,
  getPageMeta,
  (pages = new Map(), page) => pages.get(page, new Map())
)

export const selectPageResults = createSelector(
  getPage,
  getActivityFeedEvents,
  getOffset,
  (page = new Map(), results = new Map(), offset) =>
    page
      .map(getPagesUnderOffset.bind(null, offset))
      .filter(Boolean)
      .reduce((a, b) => a.concat(b), new List())
      .map((resultId) => results.get(resultId))
      .groupBy((event) => {
        return format(event.get('created_at'), 'YYYYMMDD')
      })
      .toOrderedMap()
      .sortBy((v, k) => k)
      .reverse()
)
