import { Map, OrderedMap, fromJS } from 'immutable'
import { normalize, schema } from 'normalizr'

import createReducer from '../../utils/create-reducer'
import isNumber from '../../utils/is-number'
import { ACCEPT_OFFER, BOOKMARK_OFFER, UNBOOKMARK_OFFER } from '../offer/actions'
import { UPDATE_CAMPAIGN } from '../new-campaign/actions'
import { getHashCodeFromObject } from '../pagination/selectors'
import { CLAIM_OFFER, REJECT_OFFER, GET_PROACTIVE_OFFERS } from '../proactive/actions'

import {
  FETCH_CAMPAIGNS_BY_USER,
  GET_CAMPAIGN_DETAILS,
  DELETE_CAMPAIGN,
  GET_FEATURED_CAMPAIGNS,
  FETCH_AVAILABLE_CAMPAIGNS,
  GET_CAMPAIGN_OFFER_DETAILS,
  FEATURE_CAMPAIGN,
  GET_CAMPAIGN_OFFERS,
  DISCOVER_CAMPAIGN_CREATORS,
  PAUSE_CAMPAIGN,
  UNPAUSE_CAMPAIGN,
  UPDATE_CAMPAIGN_CODE,
  REMOVE_CAMPAIGN_CODE,
  REPLACE_CAMPAIGN_CODE,
  GET_MATCHING_VIDEOS,
  DELIVER_FUZZY_MATCH,
  STAGE_DISCOVER_SELECTIONS,
} from './actions'

export const INITIAL_STATE = new Map()

export const campaignSchema = new schema.Entity('campaign')

const generateId = (obj) => {
  if (obj.id) {
    return String(obj.id)
  }
  return undefined
}

const mergeDeepAndNormalizeList = (state, { payload }) => {
  const payloadResults = payload.results ? payload.results : payload
  const results = normalize(payloadResults, [campaignSchema]).entities.campaign
  return state.mergeDeep(results)
}

const mergeDeepAndNormalizeEntry = (state, { payload }) =>
  state.remove(String(payload.id)).mergeDeep(normalize(payload, campaignSchema).entities.campaign)

// these are the raw campaigns that publishers have access to
export const campaign = createReducer(INITIAL_STATE, {
  [FETCH_CAMPAIGNS_BY_USER.FULFILLED]: mergeDeepAndNormalizeList,

  [FEATURE_CAMPAIGN.FULFILLED]: (state, { payload }) => {
    // we gotta keep the reports state while it's living inside this same reducer:
    const reportsPath = [String(payload.id), 'reports']
    const reports = state.getIn(reportsPath, new Map())
    return state
      .map((c) => c.remove('featured_campaign'))
      .remove(String(payload.id))
      .mergeDeep(normalize(payload, campaignSchema).entities.campaign)
      .setIn(reportsPath, reports)
  },

  [PAUSE_CAMPAIGN.PENDING]: (state, { meta: { campaignId } }) =>
    state.setIn([String(campaignId), 'state'], 'paused'),

  [PAUSE_CAMPAIGN.REJECTED]: (state, { meta: { campaignId } }) =>
    state.setIn([String(campaignId), 'state'], 'active'),

  [UNPAUSE_CAMPAIGN.PENDING]: (state, { meta: { campaignId } }) =>
    state.setIn([String(campaignId), 'state'], 'active'),

  [UNPAUSE_CAMPAIGN.REJECTED]: (state, { meta: { campaignId } }) =>
    state.setIn([String(campaignId), 'state'], 'paused'),

  [UPDATE_CAMPAIGN.FULFILLED]: mergeDeepAndNormalizeEntry,

  [GET_CAMPAIGN_DETAILS.FULFILLED]: mergeDeepAndNormalizeEntry,

  [DELETE_CAMPAIGN.FULFILLED]: (state, { payload }) => state.delete(payload),

  [REMOVE_CAMPAIGN_CODE.FULFILLED]: (state, { meta }) => {
    const platformCodeListIndex = state
      .getIn([String(meta.campaignId), 'codes'])
      .findIndex((c) => c.get('platform') === meta.platform)
    if (platformCodeListIndex === -1) {
      return state
    }
    const platformCodesCodeIndex = state
      .getIn([String(meta.campaignId), 'codes', platformCodeListIndex, 'codes'])
      .findIndex((c) => c === meta.code)

    if (platformCodesCodeIndex === -1) {
      return state
    }

    const currentCodeCount = state.getIn([
      String(meta.campaignId),
      'platform_code_counts',
      meta.platform,
      'unclaimed',
    ])
    const totalCodeCount = state.getIn([
      String(meta.campaignId),
      'platform_code_counts',
      meta.platform,
      'total',
    ])

    return state
      .removeIn([
        String(meta.campaignId),
        'codes',
        platformCodeListIndex,
        'codes',
        platformCodesCodeIndex,
      ])
      .setIn(
        [String(meta.campaignId), 'platform_code_counts', meta.platform, 'total'],
        totalCodeCount - 1
      )
      .setIn(
        [String(meta.campaignId), 'platform_code_counts', meta.platform, 'unclaimed'],
        currentCodeCount - 1
      )
  },
})

// these are Campaigns shown on public pages such as the landing page
export const featuredCampaigns = createReducer(new Map(), {
  [GET_FEATURED_CAMPAIGNS.FULFILLED]: mergeDeepAndNormalizeList,
})

// These are AvailableCampaigns seen by Creators
export const campaignOffer = createReducer(new Map(), {
  [GET_FEATURED_CAMPAIGNS.FULFILLED]: (state, { payload }) =>
    state.mergeDeep(normalize(payload, [campaignSchema]).entities.campaign),
  [FETCH_AVAILABLE_CAMPAIGNS.FULFILLED]: mergeDeepAndNormalizeList,
  [GET_CAMPAIGN_OFFER_DETAILS.FULFILLED]: mergeDeepAndNormalizeEntry,
  [ACCEPT_OFFER.FULFILLED]: (state, { meta: campaignId }) => state.delete(campaignId),
  [REJECT_OFFER.FULFILLED]: (state, { payload }) =>
    state.removeIn([String(payload.campaign), 'offer']),
  [CLAIM_OFFER.FULFILLED]: (state, { payload }) => state.delete(String(payload.campaign)),
  [GET_PROACTIVE_OFFERS.FULFILLED]: (state, { payload }) =>
    state.mergeDeep(normalize(payload, [campaignSchema]).entities.campaign),
  [BOOKMARK_OFFER.PENDING]: (state, { meta }) =>
    state.setIn([meta.campaignId, 'bookmark'], 'loading'),
  [BOOKMARK_OFFER.FULFILLED]: (state, { meta, payload }) =>
    state.setIn([meta.campaignId, 'bookmark'], payload.id),
  [UNBOOKMARK_OFFER.PENDING]: (state, { meta }) => state.setIn([meta.campaignId, 'bookmark'], null),
})

export const campaignOfferCache = createReducer(new Map(), {
  [FETCH_AVAILABLE_CAMPAIGNS.PENDING]: (state, { meta }) => {
    const pageHash = getHashCodeFromObject(meta.page)
    return state.set(pageHash, true)
  },
})

// this is a special pagination reducer for the infinite
// scrolling on the available offers page (for creators)
export const campaignOfferPagination = createReducer(new Map(), {
  [FETCH_AVAILABLE_CAMPAIGNS.FULFILLED]: (state, { payload, meta }) => {
    if (meta.page && payload && isNumber(payload.offset)) {
      const { offset, ...page } = meta.page
      const pageHash = getHashCodeFromObject(page)

      return state
        .set('count', payload.preprocessed_count)
        .updateIn([pageHash], (pageData = new OrderedMap()) =>
          pageData.set(
            String(offset),
            fromJS({
              results: payload.results.map(generateId).filter(Boolean),
              offset: payload.offset,
            })
          )
        )
    }
    return state
  },
})

// these are similar to reports, offers generated for a campaign for publisher view
export const campaignOfferSchema = new schema.Entity('campaignOffer')
export const campaignOffers = createReducer(new Map(), {
  [UPDATE_CAMPAIGN_CODE.FULFILLED]: (state, { payload }) => {
    const index = state
      .getIn([String(payload.offer_id), 'codes'])
      .findIndex((c) => c.get('id') === payload.id)
    return state.setIn([String(payload.offer_id), 'codes', index], fromJS(payload))
  },
  [GET_CAMPAIGN_OFFERS.FULFILLED]: (state, { payload }) => {
    const offersWithoutCreator = payload.results.map(({ profile, ...offer }) => ({
      ...offer,
      creator_id: String(profile?.id),
    }))
    return state.mergeDeep(
      normalize(offersWithoutCreator, [campaignOfferSchema]).entities.campaignOffer
    )
  },
  [REPLACE_CAMPAIGN_CODE.FULFILLED]: (state, { payload }) => {
    const index = state
      .getIn([String(payload.offer_id), 'codes'])
      .findIndex((c) => c.get('platform') === payload.platform)

    return state.setIn([String(payload.offer_id), 'codes', index, 'code'], payload.code)
  },
})

export const discoveryCampaignCreatorsSchema = new schema.Entity('discoveryCampaignCreators')
export const discoverCreators = createReducer(new Map(), {
  [DISCOVER_CAMPAIGN_CREATORS.FULFILLED]: (state, { payload }) =>
    state.mergeDeep(
      normalize(payload.results, [discoveryCampaignCreatorsSchema]).entities
        .discoveryCampaignCreators
    ),
  [STAGE_DISCOVER_SELECTIONS.PENDING]: (state, { meta }) =>
    state.mergeDeep(Object.fromEntries(meta.map((s) => [String(s[0]), { mark: s[1] }]))),
})

export const matchingVideos = new schema.Entity('matchingVideos')
export const campaignMatchingVideos = createReducer(new Map(), {
  [GET_MATCHING_VIDEOS.FULFILLED]: (state, { payload }) => {
    return state.mergeDeep(normalize(payload.results, [matchingVideos]).entities.matchingVideos)
  },
  [DELIVER_FUZZY_MATCH.FULFILLED]: (state, { meta }) => {
    return state.remove(meta.videoId)
  },
})
