import React from 'react'
import { Route, IndexRedirect } from 'react-router'
import Loadable from 'react-loadable'

import Loading from '../../components/piece-loading-async-route'
import AppUser from '../../views/app-user'
import { KIND } from '../../config/user-settings'
import { userLoginRequired } from '../../utils/routes'
import { CAMPAIGN_STATES } from '../../config/settings'
import OrganizationActivityFeed from '../../views/organization-activity-feed'

import wizardRoutes from './wizard'
import campaignDetailRoutes from './campaign-detail'
import campaignListRoutes from './campaign-list'
import settingsRoutes from './settings'
import organizationRoutes from './organization'
import planRoutes from './plan'
import whitelistRoutes from './whitelist'

const loading = () => <Loading />

const CreateCampaign = Loadable({
  loader: () => import(/* webpackChunkName: "publishermain" */ '../../views/create-campaign'),
  loading,
})

const EditCampaign = Loadable({
  loader: () => import(/* webpackChunkName: "publishermain" */ '../../views/edit-campaign'),
  loading,
})

const ManualReview = Loadable({
  loader: () => import(/* webpackChunkName: "publishermain" */ '../../views/manual-review'),
  loading,
})

const Blocklist = Loadable({
  loader: () => import(/* webpackChunkName: "publishermain" */ '../../views/blocklist'),
  loading,
})

const AddNoteModal = Loadable({
  loader: () => import(/* webpackChunkName: "publishermain" */ '../../views/add-notes-modal'),
  loading,
})

const routes = (store) => (
  <Route component={AppUser} onEnter={userLoginRequired(store, KIND.PUBLISHER, 'publisher')}>
    <Route path="/campaigns">
      <IndexRedirect to="/campaigns/active" />
      {campaignListRoutes()}
      {campaignDetailRoutes({ state: CAMPAIGN_STATES.drafting, redirect: 'description' })}
      {campaignDetailRoutes({ state: CAMPAIGN_STATES.scheduled, redirect: 'activity' })}
      {campaignDetailRoutes({ state: CAMPAIGN_STATES.active, redirect: 'activity' })}
      {campaignDetailRoutes({ state: CAMPAIGN_STATES.paused, redirect: 'activity' })}
      <Route path="edit/:slug" component={EditCampaign}>
        {wizardRoutes(true)}
      </Route>
      <Route path="new" component={CreateCampaign}>
        {wizardRoutes(false)}
      </Route>
    </Route>
    {settingsRoutes()}
    {organizationRoutes()}
    {planRoutes()}
    {whitelistRoutes()}
    <Route path="/requests" component={ManualReview} />
    <Route
      path="/activity"
      component={OrganizationActivityFeed}
      stickyFooter
      skipScrollUpOnOffset
    />
    <Route path="/blocklist" component={Blocklist}>
      <Route skipScrollUp path="add-note/:creatorId" component={AddNoteModal} />
    </Route>
  </Route>
)

export default routes
