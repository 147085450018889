import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { browserHistory, Link } from 'react-router'
import classnames from 'classnames'

import Search from '../search-input'

import styles from './styles.css'

class Navigation extends Component {
  static propTypes = {
    className: PropTypes.string,
    requestsCounter: PropTypes.number,
  }

  static defaultProps = {
    className: '',
    requestsCounter: 0,
  }

  onSearch = (searchTerm) => {
    browserHistory.push(`/search/${encodeURIComponent(searchTerm)}`)
  }

  render() {
    const { requestsCounter, className } = this.props
    return (
      <nav className={classnames(className, styles.nav)}>
        <Search placeholder="Discover creators" onSearch={this.onSearch} />

        <Link className={styles.menuItem} activeClassName={styles.menuItemCurrent} to="/activity">
          Activity
        </Link>

        <Link className={styles.menuItem} activeClassName={styles.menuItemCurrent} to="/campaigns">
          Campaigns
        </Link>

        <Link className={styles.menuItem} activeClassName={styles.menuItemCurrent} to="/blocklist">
          Blocklist
        </Link>

        <Link className={styles.menuItem} activeClassName={styles.menuItemCurrent} to="/whitelists">
          Whitelists
        </Link>

        <Link className={styles.menuItem} activeClassName={styles.menuItemCurrent} to="/requests">
          Requests
          <span className={styles.label}>{requestsCounter}</span>
        </Link>
      </nav>
    )
  }
}

export default Navigation
