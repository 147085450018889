import { Map, List } from 'immutable'
import { normalize, schema } from 'normalizr'

import createReducer from '../../utils/create-reducer'

import {
  FETCH_WHITELIST_STREAMERS,
  REMOVE_WHITELIST_STREAMER,
  CREATE_WHITELIST_STREAMER,
  SEARCH_STREAMERS,
  CLEAR_STREAMERS_SEARCH,
} from './action'

export const INITIAL_STATE = new Map()
export const whitelistSchema = new schema.Entity('streamers')

export const streamers = createReducer(INITIAL_STATE, {
  [FETCH_WHITELIST_STREAMERS.FULFILLED]: (state, { payload }) => {
    const results = normalize(payload.results, [whitelistSchema]).entities.streamers
    return state.mergeDeep(results)
  },
  [REMOVE_WHITELIST_STREAMER.FULFILLED]: (state) => state,
  [CREATE_WHITELIST_STREAMER.FULFILLED]: (state) => state,
})

export const streamersSearch = createReducer(new List(), {
  [SEARCH_STREAMERS.FULFILLED]: (state, { payload }) => new List(payload),
  [CLEAR_STREAMERS_SEARCH]: (state) => state.clear(),
})
