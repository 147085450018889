export const ANDROID = 'android'
export const CUSTOM = 'custom'
export const DISCORD = 'discord'
export const EPIC = 'epic'
export const GOOD_OLD_GAMES = 'good_old_games'
export const IOS = 'ios'
export const NINTENDO_SWITCH = 'nintendo_switch'
export const NINTENDO_SWITCH_AMERICAS = 'nintendo_switch_americas'
export const NINTENDO_SWITCH_AUNZ = 'nintendo_switch_aunz'
export const NINTENDO_SWITCH_EUROPE = 'nintendo_switch_europe'
export const NINTENDO_SWITCH_JAPAN = 'nintendo_switch_japan'
export const NINTENDO_SWITCH_US = 'nintendo_switch_us'
export const NINTENDO_SWITCH_LATAM = 'nintendo_switch_latam'
export const OCULUS = 'oculus'
export const RIFT = 'rift'
export const ORIGIN = 'origin'
export const PS4 = 'ps4'
export const PS4_AMERICAS = 'ps4_americas'
export const PS4_NA = 'ps4_na'
export const PS4_EUROPE_AUSTRALIA = 'ps4_europe_australia'
export const PS4_EUROPE = 'ps4_europe'
export const PS4_AUSTRALIA = 'ps4_australia'
export const PS4_JAPAN = 'ps4_japan'
export const PS4_KOREA = 'ps4_korea'
export const PS4_ASIA = 'ps4_asia'
export const STEAM = 'steam'
export const STEAMVR = 'steamvr'
export const XBOX = 'xbox'
export const XBOX_EUROPE = 'xbox_europe'
export const XBOX_NA = 'xbox_na'
export const XBOX_JAPAN = 'xbox_japan'
export const PS5 = 'ps5'
export const PS5_AMERICAS = 'ps5_americas'
export const PS5_EUROPE = 'ps5_europe'
export const PS5_AUSTRALIA = 'ps5_australia'
export const PS5_JAPAN = 'ps5_japan'
export const PS5_KOREA = 'ps5_korea'
export const PS5_ASIA = 'ps5_asia'
export const PS5_US = 'ps5_us'
export const PS5_LATAM = 'ps5_latam'
export const PSVR = 'psvr'
export const PSVR_NA = 'psvr_na'
export const PSVR_EUROPE = 'psvr_europe'
export const XBOX_SERIES_XS_GLOBAL = 'xbox_series_xs_global'
export const XBOX_SERIES_XS_NA = 'xbox_series_xs_na'
export const XBOX_SERIES_XS_EUROPE = 'xbox_series_xs_europe'
export const XBOX_SERIES_XS_JAPAN = 'xbox_series_xs_japan'
export const XBOX_ONE_AND_SERIES_XS = 'xbox_one_series_xs'
export const XBOX_ONE_AND_SERIES_XS_AND_PC = 'xbox_one_series_xs_pc'

export const BATTLENET = 'battlenet'

export const PLATFORMS_WITHOUT_SPECIFIC_REGIONS = [
  ANDROID,
  CUSTOM,
  DISCORD,
  EPIC,
  GOOD_OLD_GAMES,
  IOS,
  NINTENDO_SWITCH,
  OCULUS,
  RIFT,
  ORIGIN,
  PS4,
  PS5,
  PSVR,
  STEAM,
  STEAMVR,
  XBOX,
  BATTLENET,
]

export const CODE_TYPES = {
  [ANDROID]: 'Android',
  [CUSTOM]: 'Custom Offer',
  [DISCORD]: 'Discord',
  [EPIC]: 'Epic Store',
  [GOOD_OLD_GAMES]: 'GOG',
  [IOS]: 'iOS',
  [NINTENDO_SWITCH]: 'Nintendo Switch',
  [NINTENDO_SWITCH_AMERICAS]: 'Nintendo Switch - Americas',
  [NINTENDO_SWITCH_AUNZ]: 'Nintendo Switch - Australia/New Zealand',
  [NINTENDO_SWITCH_EUROPE]: 'Nintendo Switch - Europe',
  [NINTENDO_SWITCH_JAPAN]: 'Nintendo Switch - Japan',
  [NINTENDO_SWITCH_US]: 'Nintendo Switch - US',
  [NINTENDO_SWITCH_LATAM]: 'Nintendo Switch - LATAM',
  [OCULUS]: 'Oculus Quest',
  [RIFT]: 'Oculus Rift',
  [ORIGIN]: 'Origin',
  [PS4]: 'PS4',
  [PS4_AMERICAS]: 'PS4 - Americas',
  [PS4_EUROPE_AUSTRALIA]: 'PS4 - Europe & Australia',
  [PS4_EUROPE]: 'PS4 - Europe',
  [PS4_AUSTRALIA]: 'PS4 - Australia',
  [PS4_JAPAN]: 'PS4 - Japan',
  [PS4_KOREA]: 'PS4 - Korea',
  [PS4_ASIA]: 'PS4 - Asia',
  [PS4_ASIA]: 'PS4 - Asia',
  [PS5]: 'PS5',
  [PS5_AMERICAS]: 'PS5 - Americas',
  [PS5_EUROPE]: 'PS5 - Europe',
  [PS5_AUSTRALIA]: 'PS5 - Australia',
  [PS5_JAPAN]: 'PS5 - Japan',
  [PS5_KOREA]: 'PS5 - Korea',
  [PS5_ASIA]: 'PS5 - Asia',
  [PS5_US]: 'PS5 - US',
  [PS5_LATAM]: 'PS5 - LATAM',
  [PSVR_NA]: 'PSVR - North America',
  [PSVR_EUROPE]: 'PSVR - Europe',
  [STEAM]: 'Steam',
  [STEAMVR]: 'SteamVR',
  [XBOX]: 'Xbox One (global)',
  [XBOX_NA]: 'Xbox One - North America (only)',
  [XBOX_EUROPE]: 'Xbox One - Europe (only)',
  [XBOX_JAPAN]: 'Xbox One - Japan (only)',
  [XBOX_SERIES_XS_GLOBAL]: 'Xbox Series X|S Global',
  [XBOX_SERIES_XS_NA]: 'Xbox Series X|S North America',
  [XBOX_SERIES_XS_EUROPE]: 'Xbox Series X|S Europe',
  [XBOX_SERIES_XS_JAPAN]: 'Xbox Series X|S Japan',
  [XBOX_ONE_AND_SERIES_XS]: 'Xbox One & Xbox Series X|S (Global)',
  [XBOX_ONE_AND_SERIES_XS_AND_PC]: 'Xbox One, Xbox Series X|S, & PC (Global)',
  [BATTLENET]: 'Blizzard Battle.net',
}

export const CODE_TYPES_SELECT = [
  ANDROID,
  BATTLENET,
  CUSTOM,
  DISCORD,
  EPIC,
  GOOD_OLD_GAMES,
  IOS,
  NINTENDO_SWITCH_AMERICAS,
  NINTENDO_SWITCH_AUNZ,
  NINTENDO_SWITCH_EUROPE,
  NINTENDO_SWITCH_JAPAN,
  NINTENDO_SWITCH_US,
  NINTENDO_SWITCH_LATAM,
  OCULUS,
  ORIGIN,
  PS4_AMERICAS,
  PS4_EUROPE_AUSTRALIA,
  PS4_EUROPE,
  PS4_AUSTRALIA,
  PS4_JAPAN,
  PS4_KOREA,
  PS4_ASIA,
  PS5_AMERICAS,
  PS5_EUROPE,
  PS5_AUSTRALIA,
  PS5_JAPAN,
  PS5_KOREA,
  PS5_ASIA,
  PS5_US,
  PS5_LATAM,
  PSVR_NA,
  PSVR_EUROPE,
  RIFT,
  STEAM,
  STEAMVR,
  XBOX,
  XBOX_NA,
  XBOX_EUROPE,
  XBOX_JAPAN,
  XBOX_SERIES_XS_GLOBAL,
  XBOX_SERIES_XS_NA,
  XBOX_SERIES_XS_EUROPE,
  XBOX_SERIES_XS_JAPAN,
  XBOX_ONE_AND_SERIES_XS,
  XBOX_ONE_AND_SERIES_XS_AND_PC,
].map((c) => ({ label: CODE_TYPES[c], value: c }))
