import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import InfiniteScroll from 'react-infinite-scroller'
import { OrderedMap, Map } from 'immutable'
import { connect } from 'react-redux'

import { newHandleQuery } from 'utils/handle-query'
import NothingHere from 'components/piece-nothing-here'
import { selectPage, selectPageResults } from 'modules/activity-feed/selectors'
import {
  getActivityFeed as getActivityFeedAction,
  GET_ACTIVITY_FEED,
} from 'modules/activity-feed/actions'

import Loader from './loader'
import FeedSection from './section'
import FeedEvent from './event'
import styles from './styles.css'

const ACTIVITY_FEED_FETCH_POOLING_DELAY = 10000 // 10 seconds
const ACTIVITY_FEED_LIMIT = 12
const mapStateToProps = (state, { params, query }) => {
  const { loading, activityFeedEvents } = state
  const { offset = '0', ...filters } = query
  const page = selectPage(state, { offset, ...params, ...filters })
  const events = selectPageResults(page, activityFeedEvents, offset)
  return {
    eventsByDay: events,
    pagination: page,
    isLoading: !!loading.get(GET_ACTIVITY_FEED.ACTION),
  }
}

const mapDispatchToProps = {
  getActivityFeed: getActivityFeedAction,
}

const ActivityFeed = ({
  className,
  params,
  query,
  getActivityFeed,
  pagination,
  eventsByDay,
  isLoading,
  onResetFilter,
  expanded,
}) => {
  const [isAutoRefresh, setIsAutoRefresh] = useState(false)
  const { offset = '0', ...queryFilters } = query
  const page = pagination.get(offset)
  let timeout = null

  const handleActivityFeedPooling = (autoRefresh = true) => {
    setIsAutoRefresh(autoRefresh)
    if (params) {
      const newOffset = !autoRefresh ? offset : 0
      getActivityFeed({ ...query, ...params, offset: newOffset })
    }
    clearTimeout(timeout)
    timeout = setTimeout(handleActivityFeedPooling, ACTIVITY_FEED_FETCH_POOLING_DELAY)
  }

  useEffect(() => {
    handleActivityFeedPooling(false)
    return () => {
      clearTimeout(timeout)
    }
  }, [JSON.stringify(params), JSON.stringify(query)])

  const renderSections = () => {
    const sections = eventsByDay.map((events, date) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <FeedSection key={`${date}-${events.length}`} className={styles.section} date={date}>
          {events.map((event) => (
            <FeedEvent
              key={event.get('id')}
              className={styles.event}
              event={event}
              expanded={expanded}
            />
          ))}
        </FeedSection>
      )
    })
    return Array.from(sections.values())
  }

  const loadMore = () => {
    if (!page) {
      return
    }
    const count = page.get('count', 0)
    const nextOffset = Number(offset) + ACTIVITY_FEED_LIMIT
    if (!isLoading && nextOffset <= count) {
      newHandleQuery({
        offset: nextOffset,
      })
    }
  }

  const hasNextPage = () => {
    const count = (page && page.get('count', 0)) || 0
    return Number(offset) + ACTIVITY_FEED_LIMIT < Number(count)
  }

  const hasFilters = () => {
    return queryFilters && Object.entries(queryFilters).length !== 0
  }

  return (
    <InfiniteScroll
      loadMore={loadMore}
      hasMore={hasNextPage()}
      initialLoad={false}
      threshold={400}
      className={className}
    >
      {eventsByDay.size > 0 ? (
        <>
          {hasFilters() && (
            <div className={styles.filtersMessage}>
              You have filters applied to your Activity stream and are viewing partial event
              results.{' '}
              <button type="button" onClick={onResetFilter} className={styles.resetFiltersButton}>
                Reset all filters
              </button>{' '}
              to see all events.
            </div>
          )}
          <div className={styles.feed}>{renderSections()}</div>
        </>
      ) : (
        <>
          <NothingHere visible={!isLoading && !hasFilters()}>No events here!</NothingHere>
          <NothingHere visible={!isLoading && hasFilters()}>
            No results!
            <br />
            <button type="button" className={styles.resetFiltersButton} onClick={onResetFilter}>
              Reset your search
            </button>
          </NothingHere>
        </>
      )}
      {isLoading && !isAutoRefresh && <Loader />}
    </InfiniteScroll>
  )
}

ActivityFeed.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  pagination: ImmutablePropTypes.map,
  onResetFilter: PropTypes.func,
  query: PropTypes.shape({
    offset: PropTypes.string,
  }).isRequired,
  params: PropTypes.instanceOf(Object),
  eventsByDay: ImmutablePropTypes.orderedMap,
  getActivityFeed: PropTypes.func.isRequired,
  expanded: PropTypes.bool,
}

ActivityFeed.defaultProps = {
  className: undefined,
  isLoading: true,
  pagination: new Map(),
  onResetFilter: () => {},
  eventsByDay: new OrderedMap(),
  params: undefined,
  expanded: false,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityFeed)
