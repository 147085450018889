import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { captureException } from '../../utils/exception'

// should be a plain obj if it's not immutable
const toJSONOrRaw = (obj) => (obj.toJSON && obj.toJSON()) || obj
export const toJSONable = (state) => {
  try {
    const newObj = {}
    Object.keys(state).forEach((k) => {
      newObj[k] = toJSONOrRaw(state[k])
    })
    return newObj
  } catch (e) {
    return {
      error: 'Failed to convert obj to JSONable obj',
      exception: e,
    }
  }
}

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    state: PropTypes.shape({}).isRequired,
  }

  state = {
    hasError: false,
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true })
    captureException(error, { ...info, state: toJSONable(this.props.state) })
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>
    }

    return this.props.children
  }
}

export default connect((state) => ({ state }))(ErrorBoundary)
