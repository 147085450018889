import React from 'react'
import { Route, IndexRoute, IndexRedirect, Redirect } from 'react-router'
import Loadable from 'react-loadable'

import NotFound from 'views/not-found'
import App from 'views/app'
import AppPublic from 'views/app-public'
import SearchPage from 'views/search'
import SearchResultsPage from 'views/search/results'
import MailSuccess from 'views/mail/success'
import MailExpiredToken from 'views/mail/expired-token'
import MailInvalidToken from 'views/mail/invalid-token'
import MailError from 'views/mail/error'
import Terms from 'views/terms'
import PrivacyPolicy from 'views/terms/privacy-policy'
import AboutPage from 'views/about'
import CreatorProps from 'views/value-props/creator'
import PublisherProps from 'views/value-props/publisher'
import { redirectIfLogged, redirectIfLoggedWithInvitation, userLoginRequired } from 'utils/routes'
import Loading from 'components/piece-loading-async-route'
import { KIND } from 'config/user-settings'

import PublicOffer from '../views/public-offer'
import PublicOffers from '../views/public-offers'
import Home from '../views/landing'
import PublicCreatorPage from '../views/public-creator-page'
import PublicCreatorPageVideos from '../views/public-creator-page/videos'
import PublicPublisherPage from '../views/public-publisher-page'
import Signout from '../views/signout'

import publisherRoutes from './publisher'
import creatorRoutes from './creator'

const loading = () => <Loading />

const Sign = Loadable({
  loader: () => import(/* webpackChunkName: "sign" */ 'views/sign'),
  loading,
})

const CreatorSignup = Loadable({
  loader: () => import(/* webpackChunkName: "sign" */ 'views/signup/creator-signup'),
  loading,
})

const CreatorSignin = Loadable({
  loader: () => import(/* webpackChunkName: "sign" */ 'views/signin/creator'),
  loading,
})

const CreatorComplete = Loadable({
  loader: () => import(/* webpackChunkName: "sign" */ 'views/signup/creator-complete'),
  loading,
})

const PublisherSignup = Loadable({
  loader: () => import(/* webpackChunkName: "sign" */ 'views/signup/publisher-signup'),
  loading,
})

const PublisherSignupDone = Loadable({
  loader: () => import(/* webpackChunkName: "sign" */ 'views/signup/publisher-signup/done'),
  loading,
})

const PublisherComplete = Loadable({
  loader: () => import(/* webpackChunkName: "sign" */ 'views/signup/publisher-complete'),
  loading,
})

const PublisherSignin = Loadable({
  loader: () => import(/* webpackChunkName: "sign" */ 'views/signin/publisher'),
  loading,
})

const PublicVideos = Loadable({
  loader: () => import(/* webpackChunkName: "publicpage" */ 'views/public-videos'),
  loading,
})

const SendOfferModal = Loadable({
  loader: () =>
    import(/* webpackChunkName: "sendoffer" */ 'views/public-creator-page/send-offer-modal'),
  loading,
})

const SentOfferModal = Loadable({
  loader: () =>
    import(/* webpackChunkName: "sendoffer" */ 'views/public-creator-page/sent-offer-modal'),
  loading,
})

const OffersSentModal = Loadable({
  loader: () => import(/* webpackChunkName: "sendoffer" */ 'views/search/offers-sent-modal'),
  loading,
})

const SendOfferFeatureModal = Loadable({
  loader: () =>
    import(/* webpackChunkName: "sendoffer" */ 'views/public-creator-page/send-offer-feature-modal'),
  loading,
})

const PublicCreatorPageFeed = Loadable({
  loader: () => import(/* webpackChunkName: "publishermain" */ '../views/public-creator-page/feed'),
  loading,
})

const PublicCreatorPageNotes = Loadable({
  loader: () =>
    import(/* webpackChunkName: "publishermain" */ '../views/public-creator-page/notes'),
  loading,
})

const PublicCreatorPageStats = Loadable({
  loader: () =>
    import(/* webpackChunkName: "publishermain" */ '../views/public-creator-page/stats'),
  loading,
})

const PublicCreatorPageSimilarCreators = Loadable({
  loader: () =>
    import(/* webpackChunkName: "publishermain" */ '../views/public-creator-page/similar-creators'),
  loading,
})

const AddToWhitelist = Loadable({
  loader: () =>
    import(/* webpackChunkName: "publisherwhitelist" */ 'views/view-campaign/add-to-whitelist'),
  loading,
})

const AcceptInvitation = Loadable({
  loader: () => import(/* webpackChunkName: "public" */ 'views/organization/accept-invitation'),
  loading,
})

const MissingOrganization = Loadable({
  loader: () => import(/* webpackChunkName: "public" */ 'views/missing-organization'),
  loading,
})

const WoovitStats = Loadable({
  loader: () => import(/* webpackChunkName: "public" */ 'views/woovit-stats'),
  loading,
})

const ChangeEmailFirstConfirmation = Loadable({
  loader: () => import(/* webpackChunkName: "changeinfo" */ 'views/change-email/first-step'),
  loading,
})

const ChangeEmailConfirmation = Loadable({
  loader: () => import(/* webpackChunkName: "changeinfo" */ 'views/change-email/second-step'),
  loading,
})

const ForgotPassword = Loadable({
  loader: () => import(/* webpackChunkName: "changeinfo" */ 'views/forgot-password'),
  loading,
})

const AdminLogin = Loadable({
  loader: () => import(/* webpackChunkName: "public" */ 'views/admin-login'),
  loading,
})

const ResetPassword = Loadable({
  loader: () => import(/* webpackChunkName: "changeinfo" */ 'views/reset-password'),
  loading,
})

const childRoutes = (
  <Route>
    <Route skipScrollUp path="send-offer-premium" component={SendOfferFeatureModal} />
    <Route path="send-offer" component={SendOfferModal} />
    <Route path="offer-sent/:campaignId" component={SentOfferModal} />
    <Route path="add-to-whitelist/:creatorId" component={AddToWhitelist} />
  </Route>
)

const routes = (store) => (
  <Route component={App}>
    <Route path="/" component={Home} onEnter={redirectIfLogged(store)} />
    {creatorRoutes(store)}
    {publisherRoutes(store)}
    <Route component={AppPublic}>
      <Route path="/missing-org" component={MissingOrganization} />
      <Redirect from="/p/:slug" to="/publisher/:slug" />
      <Route path="terms" component={Terms} />
      <Route path="privacy-policy" component={PrivacyPolicy} />
      <Route
        path="/password/reset/confirm/:uid/:token"
        onEnter={redirectIfLogged(store)}
        component={ResetPassword}
      />
      <Route path="/forgot-password" component={ForgotPassword} onEnter={redirectIfLogged(store)} />
      <Route path="/signup/publisher/complete/:uid/:token" component={PublisherComplete} />
      <Route path="videos" component={PublicVideos} />
      <Route path="stats" component={WoovitStats} />
      <Route path="offer">
        <IndexRedirect to="/offer/all" />
      </Route>
      <Route path="offer/all" component={PublicOffers} />
      <Route path="offer/:slug" component={PublicOffer} />
      <Route path="change-email/first-step/:uuid" component={ChangeEmailFirstConfirmation} />
      <Route path="change-email/second-step/:uuid" component={ChangeEmailConfirmation} />
      <Route path="about" component={AboutPage} />
      <Route path="/for-creators" component={CreatorProps} />
      <Route path="/for-publishers" component={PublisherProps} />
      <Redirect from="/c/:slug" to="/creator/:slug" />
      <Route path="/creator/:slug" component={PublicCreatorPage}>
        <IndexRedirect to="videos" />
        <Route onEnter={userLoginRequired(store, KIND.PUBLISHER, 'publisher', 'videos')}>
          <Route path="feed" component={PublicCreatorPageFeed}>
            {childRoutes}
          </Route>
          <Route path="notes" component={PublicCreatorPageNotes}>
            {childRoutes}
          </Route>
          <Route path="stats" component={PublicCreatorPageStats}>
            {childRoutes}
          </Route>
          <Route path="similar-creators" component={PublicCreatorPageSimilarCreators}>
            {childRoutes}
          </Route>
        </Route>
        <Route path="videos" component={PublicCreatorPageVideos}>
          {childRoutes}
        </Route>
      </Route>
      <Route path="publisher/:slug" component={PublicPublisherPage} />
      <Route path="/search" component={SearchPage} />
      <Route path="/search/:search" component={SearchResultsPage}>
        <Route path="offers-sent/:campaignId" component={OffersSentModal} />
      </Route>
    </Route>
    <Route path="signout" component={Signout} />
    <Route path="/o/m/:token" component={AcceptInvitation} />
    <Route component={Sign} onEnter={redirectIfLoggedWithInvitation(store)}>
      <Route path="signup">
        <IndexRedirect to="publisher" />
        <Route path="publisher">
          <IndexRoute component={PublisherSignup} />
          <Route path="done" component={PublisherSignupDone} />
        </Route>
        <Route path="creator" component={CreatorSignup} />
      </Route>
      <Route path="signin">
        <IndexRedirect to="publisher" />
        <Route path="publisher" component={PublisherSignin} />
        <Route path="creator" component={CreatorSignin} />
      </Route>
    </Route>
    <Route path="/auth/:provider/complete" component={AppPublic}>
      <IndexRoute component={CreatorComplete} />
    </Route>
    <Route path="/m/" component={AppPublic}>
      <Route path="success" component={MailSuccess} />
      <Route path="invalid-token" component={MailInvalidToken} />
      <Route path="expired-token" component={MailExpiredToken} />
      <Route path="unknown-error(/:error)" component={MailError} />
    </Route>
    <Route path="/adl_redirect/:authToken" component={AdminLogin} />
    <Route path="*" component={AppPublic}>
      <IndexRoute component={NotFound} />
    </Route>
  </Route>
)

export default routes
