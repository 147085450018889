import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

import styles from './styles.css'

/* eslint-disable jsx-a11y/label-has-for */
const Checkbox = (props) => {
  const { name, label, id, checked, onChange, disabled, type, className } = props

  const inputId = id || name

  const handleOnChange = (event) => {
    if (onChange) onChange(event)
  }

  return (
    <div className={classnames(className, styles.checkbox)}>
      <input
        disabled={disabled}
        className={styles.input}
        type={type}
        name={name}
        id={inputId}
        checked={checked}
        onChange={handleOnChange}
      />
      <label htmlFor={inputId} className={styles.checkboxLabel}>
        {label}
      </label>
    </div>
  )
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  id: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
}

Checkbox.defaultProps = {
  id: undefined,
  onChange: undefined,
  checked: undefined,
  disabled: false,
  type: 'checkbox',
  className: undefined,
}

export default Checkbox
