import { Map, List } from 'immutable'
import { createSelector } from 'reselect'

import { getHashCodeFromObject as getHashCodeFromObjectPage } from '../pagination/selectors'

import { AVAILABLE_CAMPAIGNS_DEFAULT_PARAMS } from './actions'

export const getHashCodeFromObject = ({ offset, ...meta }) => getHashCodeFromObjectPage(meta)

const getCampaigns = (state) => state.campaign

const FILTER_BY_STATUS = ['drafting', 'scheduled', 'active', 'paused', 'expired']

const getId = (c) => c.get('id')
const byId = (a, b) => (a < b ? 1 : -1)
const filterByState = (item, state) => item.get('state') === state
const campaignByState = (campaigns, state) =>
  campaigns
    .filter((item) => filterByState(item, state))
    .toList()
    .sortBy(getId, byId)

export const sortCampaignsByStates = createSelector(
  getCampaigns,
  (campaigns = new Map()) => {
    const allStates = new Map(
      FILTER_BY_STATUS.map((status) => [status, campaignByState(campaigns, status)])
    )
    return new Map({
      drafting: allStates.get('drafting'),
      scheduled: allStates.get('scheduled'),
      active: allStates.get('active'),
      stopped: allStates
        .get('paused')
        .concat(allStates.get('expired'))
        .sortBy(getId, byId),
    })
  }
)

const byVideosCount = (a, b) => {
  const aTotal =
    a.getIn(['videos', 'youtube'], new List()).size + a.getIn(['videos', 'twitch'], new List()).size
  const bTotal =
    b.getIn(['videos', 'youtube'], new List()).size + b.getIn(['videos', 'twitch'], new List()).size
  if (aTotal > bTotal) {
    return -1
  }
  return 1
}

const getCreators = (state) => state.creator
const getCampaignId = (state, id) => parseInt(id, 10) // because creator campaign is integer
export const selectCampaignCreators = createSelector(
  getCreators,
  getCampaignId,
  (creators = new Map(), campaignId) => {
    if (!campaignId) {
      return new List()
    }
    return creators
      .filter((creator) => creator.get('campaign') === campaignId)
      .toList()
      .sort(byVideosCount)
  }
)

const getWhitelistsData = (whitelists) => whitelists
const getWhitelists = (whitelists, campaignWhitelists) => campaignWhitelists
const getWhitelistsNames = (whitelists, campaignWhitelists = []) =>
  whitelists
    .filter((w) => campaignWhitelists.indexOf(w.get('id')) !== -1)
    .toArray()
    .map((w) => w.get('name'))

export const selectWhitelistsNames = createSelector(
  getWhitelistsData,
  getWhitelists,
  getWhitelistsNames
)

const title = (a) => a.title
const asc = (a = '', b = '') => a.localeCompare(b)
const getTitleAndId = (c) => ({
  id: c.get('id'),
  title: c.get('steam_game_name') || c.get('title'),
  slug: c.get('slug'),
})
const getStatus = (state, status) => status
const filterStatus = (status, c) => {
  if (!status) {
    return !!c.get('state')
  }
  return c.get('state') === status
}

export const selectCampaignsForSelect = createSelector(
  getCampaigns,
  getStatus,
  (campaigns = new Map(), status) =>
    campaigns
      .toList()
      .filter(filterStatus.bind(null, status))
      .map(getTitleAndId)
      .sortBy(title, asc)
)

const getPages = (state) => state.campaignOfferPagination
const getPageMeta = (state, meta) =>
  getHashCodeFromObject({ ...AVAILABLE_CAMPAIGNS_DEFAULT_PARAMS, ...meta })
const getPage = (page) => page
const getCampaignOffer = (page, campaignOffer) => campaignOffer
const getOffset = (page, campaignOffer, offset) => String(offset)

export const selectPage = createSelector(
  getPages,
  getPageMeta,
  (pages = new Map(), page) => pages.get(page, new Map())
)

const getPagesUnderOffset = (offset, v, k) => {
  if (Number(k) <= offset) {
    return v.get('results')
  }
  return false
}

export const selectPageResults = createSelector(
  getPage,
  getCampaignOffer,
  getOffset,
  (page = new Map(), results = new Map(), offset) =>
    page
      .map(getPagesUnderOffset.bind(null, offset)) // extract all pages under the desired offset
      .filter(Boolean) // remove skipped pages
      .reduce((a, b) => a.concat(b), new List()) // merge all ids into a list
      .map((resultId) => results.get(resultId)) // populate ids with their actual reducer data
)

const getCampaignOffers = (state, offers) => offers
// Grabs offers and populates with real profile data from creator reducer;
export const selectCampaignOffersWithCreators = createSelector(
  getCreators,
  getCampaignOffers,
  (creators = new Map(), offers = new List()) =>
    offers.map((o) =>
      o.set('creator', creators.get(String(o.get('creator_id')))).remove('creator_id')
    )
)

const getCampaignsData = (campaigns) => campaigns
export const selectCampaignsByTitle = createSelector(
  getCampaignsData,
  (campaigns = new Map()) =>
    campaigns
      .toList()
      .map(getTitleAndId)
      .sortBy(title, asc)
)
