import axios from 'axios'

import sessionStorage from '../utils/session-storage'
import { CLOUDINARY_URL, UPLOAD_PRESET } from '../config/environment'
import { getThumbnailAndOriginal } from '../utils/image-utils'

import instance from './request-v2'
import { post, get, patch, del } from './request'
import * as locationService from './location'

export const create = (payload) => post('publishers', {}, payload)

export const activate = (uid, payload) => post(['publishers', uid, 'activate'], {}, payload)

export const setPassword = (key) => (uid, payload) =>
  post(['publishers', uid, 'new_password'], { key }, payload)

export const resetPassword = (email) => post(['auth', 'users', 'reset_password'], {}, { email })

export const resetPasswordConfirm = (payload) =>
  post(
    ['auth', 'users', 'reset_password_confirm'],
    {},
    {
      uid: payload.uid,
      token: payload.token,
      new_password: payload.new_password,
      re_new_password: payload.re_new_password,
    }
  )

export const completePublisherSignup = (key) => (payload) =>
  patch(['register', 'complete'], { key }, payload)

export const signin = (username, password) =>
  post(['auth', 'token', 'login'], {}, { email: username, password })

export const fetchUserByToken = (key) => get(['auth', 'users', 'me'], { key })

export const getStreamerProfile = (key) => get('streamer_profile', { key })

const updateAcc = (key, payload, data) =>
  patch(
    ['auth', 'users', 'me'],
    { key },
    {
      ...payload,
      existing_location_id: undefined,
      profile: {
        ...payload.profile,
        location_id: data.id,
      },
    }
  )

export const updateAccount = (key) => (payload, newLocation) => {
  if (newLocation && payload.existing_location_id) {
    return locationService
      .update(key)(payload.existing_location_id, newLocation)
      .then(updateAcc.bind(null, key, payload))
  }
  if (newLocation) {
    return locationService
      .create(key)(newLocation)
      .then(updateAcc.bind(null, key, payload))
  }
  if (payload.deleteLocation && payload.existing_location_id) {
    return locationService
      .del(key)(payload.existing_location_id)
      .then(updateAcc.bind(null, key, payload))
  }
  return patch(['auth', 'users', 'me'], { key }, payload)
}

export const updatePassword = (key) => (payload) =>
  post(
    ['auth', 'users', 'set_password'],
    { key },
    {
      current_password: payload.password,
      new_password: payload.newPassword,
      re_new_password: payload.reNewPassword,
    }
  )

export const updateUsername = (key) => (payload) =>
  post(
    ['auth', 'users', 'set_username'],
    { key },
    {
      new_username: payload.username,
      current_password: payload.password,
    }
  )

export const getProviderConnectURL = (provider) =>
  get(['creator', provider, 'connect'], { credentials: 'include' })

export const connectCreator = (key) => (data, provider) =>
  post(['creator', provider, 'connect'], { key, credentials: 'include' }, data)

export const requestProviderCrawl = (key) => (provider) =>
  post(['creator', provider, 'crawl'], { key })

export const acceptTerms = (key) => updateAccount(key)({ profile: { terms: true } })

export const changeEmailFirstConfirmation = (key) => (uuid) =>
  get(['change-email/first-step', uuid], { key })

export const changeEmailConfirmation = (key) => (uuid) =>
  get(['change-email/second-step', uuid], { key })

export const updateUserEmail = (key) => (payload) => post(['change-email'], { key }, payload)

export const deleteProvider = (key) => (provider, providerId) =>
  del(['user-platform', provider, providerId], { key })

export const updateOrganization = (key) => (orgId, data) =>
  patch(['organizations', orgId], { key }, data)

export const uploadLogo = (payload) => {
  const data = new FormData()
  data.append('file', payload)
  data.append('upload_preset', UPLOAD_PRESET)
  return axios.post(CLOUDINARY_URL, data).then(getThumbnailAndOriginal)
}

export const updatePreferences = (key) => (preferences) => {
  if (sessionStorage.getItem('admin_login')) {
    // skip this call to prevent unexpected UX
    return new Promise(Function.prototype) // keep it hanging to prevent side-effects
  }
  return updateAccount(key)({
    profile: {
      preferences_field: JSON.stringify(preferences),
    },
  })
}

export const updateNewPreferences = (key) => (preference) => {
  if (sessionStorage.getItem('admin_login')) {
    // skip this call to prevent unexpected UX
    return new Promise(Function.prototype) // keep it hanging to prevent side-effects
  }
  return patch('preferences', { key }, preference)
}

export const getPreferences = (key) => () => get('preferences', { key })

export const commitPreference = (token) => post(['preferences', 'public', token])

export const deleteAccount = (key) => (reason) => del(['auth', 'users', 'me'], { key }, { reason })

export const getOrganizations = (key) => get(['organizations'], { key })

export const activateOrganization = (key) => (orgId) =>
  post(['organizations', orgId, 'activate'], { key })

export const acceptOrganizationInvitation = (key) => (token) =>
  post(['organization-invitations', token, 'accept_by_token'], { key })

export const getOrganizationInvitations = (key) => get(['organization-invitations'], { key })

export const removeOrganizationInvitation = (key) => (id) =>
  del(['organization-invitations', id], { key })

export const createOrganization = (key) => (data) => post(['organizations'], { key }, data)

export const updateMembership = (key) => (data) =>
  instance.put('organization-member/set-title/', data, { key }).then((r) => r.data)
