export const HTTP_200_OK = 200
export const HTTP_301_MOVED_PERMANENTLY = 301
export const HTTP_400_BAD_REQUEST = 400
export const HTTP_404_NOT_FOUND = 404
export const HTTP_500_INTERNAL_SERVER_ERROR = 500
export const HTTP_503_SERVICE_UNAVAILABLE = 503

const getStatusFromComponent = (props) => {
  const routeComponent = props.components && props.components[props.components.length - 1]
  if (routeComponent && typeof routeComponent.getStatusCode === 'function') {
    return routeComponent.getStatusCode(props)
  }
  return undefined
}

const getStatus = (err, props = {}) => {
  // return 500 if we encounter any error
  if (err) {
    return HTTP_500_INTERNAL_SERVER_ERROR
  }

  // return custom route status code, if available
  // 404 uses this feature
  const customStatus = getStatusFromComponent(props)
  if (customStatus) {
    return customStatus
  }

  // return 200 if it's all good
  return HTTP_200_OK
}

export default getStatus
