import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import ReactDatepicker from 'react-datetime'

import { parseDatetime } from 'utils/datetime'
import Label from 'components/piece-label'
import BlockFieldFeedback from 'components/block-field-feedback'
import styles from 'components/piece-field/styles.css'

const DatePicker = (props) => {
  const {
    className,
    wrapperClassName,
    label,
    id,
    name,
    placeholder,
    onChange,
    isValidDate,
    errorMessage = [],
    disabled,
    dateFormat,
    ...extra
  } = props

  const inputId = id || name
  const value = parseDatetime(props.value)

  const inputProps = {
    placeholder,
    name,
    id: inputId,
    disabled,
    className: styles.field,
    autoComplete: 'off',
  }

  const onDateChange = (date) => {
    const formattedDate = parseDatetime(date)
    onChange(formattedDate, inputId)
  }

  return (
    <div className={classnames(wrapperClassName, styles.wrapper)}>
      {label && <Label id={inputId} label={label} />}
      <ReactDatepicker
        value={value}
        isValidDate={isValidDate}
        inputProps={inputProps}
        onChange={onDateChange}
        closeOnSelect
        className={className}
        dateFormat={dateFormat}
        {...extra}
      />

      <BlockFieldFeedback errorMessage={errorMessage} />
    </div>
  )
}

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  isValidDate: PropTypes.func,
  errorMessage: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  dateFormat: PropTypes.string,
}

DatePicker.defaultProps = {
  label: undefined,
  id: undefined,
  placeholder: undefined,
  value: undefined,
  isValidDate: undefined,
  errorMessage: undefined,
  disabled: false,
  className: undefined,
  wrapperClassName: undefined,
  dateFormat: undefined,
}

export default DatePicker
