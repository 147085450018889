import { Map } from 'immutable'

import createReducer from '../../utils/create-reducer'

import { ESTIMATE_TARGET_CREATORS } from './actions'

export const INITIAL_STATE = new Map()

export const estimator = createReducer(INITIAL_STATE, {
  [ESTIMATE_TARGET_CREATORS.FULFILLED]: (state, { payload, meta }) =>
    state.mergeDeep({ [meta]: payload }),
})
