import PropTypes from 'prop-types'

const If = ({ condition, children }) => (condition ? children : null)

If.propTypes = {
  condition: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  children: PropTypes.node.isRequired,
}

If.defaultProps = {
  condition: false,
}

export default If
