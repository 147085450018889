/* eslint-disable react/button-has-type   */
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'
import classnames from 'classnames'

import Spinner from '../piece-spinner'

import styles from './styles.css'

const BUTTON_KIND = {
  primary: 'primary',
  secondary: 'secondary',
  disabled: 'disabled',
  disabledQuiet: 'disabledQuiet',
  quiet: 'quiet',
  fileInput: 'fileInput',
  floating: 'floating',
}

// @TODO: Ideally we should use <button type="button" onClick ... > for buttons
// and <a className="btn" href ... > for links that look like a button.
// Also we could use <Link> from react-router to internal links.
const Button = (props) => {
  const { onClick, kind, id, type, external, href, children, loading, disabled } = props

  const handleOnClick = kind === 'disabled' || loading ? null : onClick

  const renderLink = () => {
    if (external) {
      return (
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          id={id}
          className={styles[BUTTON_KIND[kind]]}
        >
          {children}
        </a>
      )
    }

    return (
      <Link to={href} id={id} className={styles[BUTTON_KIND[kind]]}>
        {children}
      </Link>
    )
  }

  const renderButton = () => (
    <button
      onClick={handleOnClick}
      id={id}
      type={type}
      className={classnames(styles.base, styles[BUTTON_KIND[kind]])}
      disabled={disabled}
    >
      {loading ? (
        <span className={styles.spinner}>
          <Spinner />
        </span>
      ) : null}
      {children}
    </button>
  )

  return href ? renderLink() : renderButton()
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  external: PropTypes.bool,
  kind: PropTypes.oneOf(Object.keys(BUTTON_KIND)),
  href: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  loading: PropTypes.bool,
}

Button.defaultProps = {
  type: 'button',
  href: null,
  loading: false,
}

export default Button
