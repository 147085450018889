import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Link } from 'react-router'

import ModalConfirm from '../modal-confirm'
import EmbargoDate from '../embargo-date'
import DeliverBy from '../deliver-by'
import Legend from '../legend'
import If from '../if'
import linkify from '../../utils/linkify'

import styles from './styles.css'

const DELIVER_TOOLTIP =
  '"Delivery" in Woovit means you\'ve made video<br>on Twitch or YouTube covering the game.<br>Please put the name of the game in the video title<br>to ensure our search engine picks it up, thanks!'

class OfferMeta extends PureComponent {
  static propTypes = {
    timezone: PropTypes.string,
    embargoEnds: PropTypes.string,
    deliverBy: PropTypes.string,
    terms: PropTypes.string,
  }

  static defaultProps = {
    timezone: undefined,
    embargoEnds: undefined,
    deliverBy: undefined,
    terms: undefined,
  }

  state = {
    modalTerms: false,
  }

  toggleModalTerms = () => {
    this.setState(({ modalTerms }) => ({ modalTerms: !modalTerms }))
  }

  render() {
    const { timezone, embargoEnds, deliverBy, terms } = this.props
    return (
      <div className={styles.container}>
        <If condition={terms}>
          <div className={styles.infoBlock}>
            <Legend>Terms & Conditions</Legend>
            <Link onClick={this.toggleModalTerms}>View offer Terms & Conditions</Link>
          </div>
        </If>

        <If condition={embargoEnds}>
          <div className={styles.infoBlock}>
            <Legend>Embargo Ends</Legend>
            <EmbargoDate embargoEnds={embargoEnds} />
          </div>
        </If>

        <If condition={timezone}>
          <div className={styles.infoBlock}>
            <Legend>Timezone</Legend>
            {timezone}
          </div>
        </If>

        <If condition={deliverBy}>
          <div className={styles.infoBlock}>
            <Legend tooltip={DELIVER_TOOLTIP}>Deliver By</Legend>
            <DeliverBy deliverBy={deliverBy} />
          </div>
        </If>

        {/*  we keep this at last because we have a .infoBlock + .infoBlocK css margin */}
        <If condition={terms}>
          <ModalConfirm
            hideCancel
            open={this.state.modalTerms}
            onCancel={Function.prototype}
            onConfirm={this.toggleModalTerms}
            contentClassName={styles.termsModal}
            confirmText="Ok"
          >
            <div className={styles.terms} dangerouslySetInnerHTML={{ __html: linkify(terms) }} />
          </ModalConfirm>
        </If>
      </div>
    )
  }
}
export default OfferMeta
