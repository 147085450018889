import moment from 'moment'

import { DATETIME_FORMAT, DATE_FORMAT, HUMAN_FORMAT, EMBARGO_FORMAT } from '../config/time-settings'

const MONTH_AND_DAY = 'MMMM DD'
const SIX_DAYS = 6
const wrapMoment = (date) => {
  if (moment.isMoment(date)) {
    return date
  }
  return moment(date)
}

export const parseDatetime = (datetime) => moment(datetime, DATETIME_FORMAT)
export const parseDate = (datetime) => moment(datetime, DATE_FORMAT)

export const formatHuman = (datetime = null) => {
  const momentDateTime = moment.isMoment(datetime) ? datetime : moment(datetime)
  if (!momentDateTime.isValid()) {
    return null
  }
  return momentDateTime.format(HUMAN_FORMAT)
}

export const format = (datetime, dateFormat) => {
  if (!datetime) {
    return null
  }
  return wrapMoment(datetime).format(dateFormat)
}

export const fromNow = (
  datetime = null,
  { thresholdInDays = SIX_DAYS, dateFormat = MONTH_AND_DAY, hasTreshold = true } = {}
) => {
  const now = moment()
  const date = moment(datetime)
  if (!date.isValid()) {
    return null
  }
  const difference = now.diff(date, 'days')

  if (!hasTreshold || difference <= thresholdInDays) {
    return date.fromNow()
  }

  return date.format(dateFormat)
}

export const isAfterNow = (date) => wrapMoment(date).isAfter(moment.now())
export const isBeforeNow = (date) => wrapMoment(date).isBefore(moment.now())

export const formatEmbargo = (embargoDate, timezone, checkPastDate = false) => {
  if (!embargoDate) {
    return null
  }
  const date = wrapMoment(embargoDate)
  if (checkPastDate && !isAfterNow(date, timezone)) {
    return null
  }
  const tzDescription = timezone || 'local time'
  return `${date.format(EMBARGO_FORMAT)} (${tzDescription})`
}

export const unixNow = () => moment().unix()

export const progressedTime = (initial, due) => {
  const today = moment()
  const initialDate = wrapMoment(initial)
  const dueDate = wrapMoment(due)

  if (today.isAfter(due)) {
    return 100
  }
  if (initialDate.isAfter(today)) {
    return 0
  }

  return Math.round(((today - initialDate) / (dueDate - initialDate)) * 100)
}

export const yesterday = () => moment().subtract(1, 'day')

const PADDING = 2
export const normalizeDuration = (duration) => {
  const dur = moment.duration(duration)
  if (dur.isValid()) {
    return `${String(dur.hours()).padStart(PADDING, 0)}:${String(dur.minutes()).padStart(
      PADDING,
      0
    )}:${String(dur.seconds()).padStart(PADDING, 0)}`
  }
  return null
}
