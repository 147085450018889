import { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

const ONE_SEC = 1000

class DatetimeCountDown extends Component {
  static propTypes = {
    datetime: PropTypes.string,
    moment: PropTypes.shape({}),
  }

  static defaultProps = {
    datetime: undefined,
    moment: undefined,
  }

  state = { timeLeft: null }

  constructor(props) {
    super(props)
    this.datetime = props.moment || moment(props.datetime)
    this.setTimer = this.setTimer.bind(this)
    this.countDown = this.countDown.bind(this)
  }

  componentDidMount() {
    this.countDown()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  setTimer() {
    if (this.datetime.diff(moment.now(), 'days') > 0) {
      return // no need to keep updating
    }
    this.timer = setTimeout(this.countDown, ONE_SEC)
  }

  countDown() {
    clearTimeout(this.timer)
    this.setState({
      timeLeft: moment.duration(moment(this.datetime) - moment.now()).humanize(),
    })
    this.setTimer()
  }

  render() {
    return this.state.timeLeft
  }
}

export default DatetimeCountDown
