import React, { Component } from 'react'
import classnames from 'classnames'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map } from 'immutable'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import shallowequal from 'shallowequal'
import { Link, browserHistory } from 'react-router'
import { IntercomAPI } from 'react-intercom'
import ReactTooltip from 'react-tooltip'

import hasRole from '../../utils/has-role'
import { newHandleQuery } from '../../utils/handle-query'
import {
  searchCreators,
  SEARCH_CREATORS,
  selectCreator,
  clearSelectedCreators,
} from '../../modules/creator/actions'
import { selectPage, selectPageResults } from '../../modules/pagination/selectors'
import { buildResultsFromSearch } from '../../modules/creator/selectors'
import Breadcrumb from '../../components/breadcrumb'
import Image from '../../components/block-image'
import Followers from '../../components/followers-buttons'
import NothingHere from '../../components/piece-nothing-here'
import Loading from '../../components/piece-loading'
import Pagination from '../../components/pagination'
import Select from '../../components/piece-field-select'
import CreatorVideo from '../../components/creator-video'
import * as creatorService from '../../services/creator'
import {
  creator as creatorReducer,
  creatorSearch as creatorSearchReducer,
} from '../../modules/creator/reducers'
import { pagination as paginationReducer } from '../../modules/pagination/reducers'
import { video as videoReducer } from '../../modules/video/reducers'
import searchTermMatchesCreator from '../../utils/search-term-matches-creator'
import Checkbox from '../../components/checkbox'
import Button from '../../components/button'
import SendOfferModal from '../public-creator-page/send-offer-modal'
import AvatarPlaceholder from '../../img/avatar_placeholder.svg'
import Svg from '../../components/svg'
import { signout as signoutAction } from '../../modules/user/actions'
import localStorage from '../../utils/local-storage'
import sessionStorage from '../../utils/session-storage'
import { reset } from '../../services/tracker'

import styles from './styles.css'

const MAX_VIDEOS_PER_RESULT = 2
const ORDER_OPTIONS = [
  { value: 'relevance', label: 'Relevance' },
  { value: 'youtube_followers', label: 'YouTube followers' },
  { value: 'youtube_views', label: 'YouTube viewership' },
  { value: 'twitch_followers', label: 'Twitch followers' },
  { value: 'twitch_views', label: 'Twitch viewership' },
  { value: 'newest', label: 'Recently added' },
]
const DEFAULT_ORDERING = 'relevance'

const mapStateToProps = (state, props) => {
  const { user, selectedCreators, loading, creatorSearch, newCampaign } = state
  const page = selectPage(state, SEARCH_CREATORS.ACTION, {
    search: props.params.search,
    ...props.location.query,
  })
  const resultsPage = selectPageResults(page, creatorSearch)
  const results = buildResultsFromSearch(resultsPage, state)
  return {
    user,
    pagination: page,
    selectedCreators,
    results,
    isLoading: !!loading.get(SEARCH_CREATORS.ACTION),
    newCampaign,
  }
}
const mapDispatchToProps = {
  searchCreators,
  selectCreator,
  clearSelectedCreators,
  signout: signoutAction,
}

class SearchResultsPage extends Component {
  static propTypes = {
    location: PropTypes.shape({
      query: PropTypes.shape({
        offset: PropTypes.string,
        ordering: PropTypes.string,
      }),
    }).isRequired,
    params: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
    searchCreators: PropTypes.func.isRequired,
    selectCreator: PropTypes.func.isRequired,
    selectedCreators: ImmutablePropTypes.list.isRequired,
    user: ImmutablePropTypes.map.isRequired,
    clearSelectedCreators: PropTypes.func.isRequired,
    results: ImmutablePropTypes.list,
    pagination: ImmutablePropTypes.map,
    isLoading: PropTypes.bool.isRequired,
    children: PropTypes.node,
    signout: PropTypes.func,
    newCampaign: ImmutablePropTypes.map,
  }

  static defaultProps = {
    pagination: new Map(),
    results: new List(),
    children: null,
    signout: () => {},
    newCampaign: null,
  }

  static getData({ search }, { query }) {
    return creatorService
      .search()(search, query)
      .then((payload) => ({
        creator: creatorReducer(undefined, {
          type: SEARCH_CREATORS.FULFILLED,
          payload,
        }),
        video: videoReducer(undefined, {
          type: SEARCH_CREATORS.FULFILLED,
          payload,
        }),
        creatorSearch: creatorSearchReducer(undefined, {
          type: SEARCH_CREATORS.FULFILLED,
          payload,
        }),
        pagination: paginationReducer(undefined, {
          payload,
          type: SEARCH_CREATORS.FULFILLED,
          meta: {
            page: {
              search,
              ...query,
            },
          },
        }),
      }))
  }

  state = {
    isModalOpen: false,
  }

  componentDidMount() {
    const {
      location: { query },
      params: { search },
    } = this.props
    if (search) {
      this.props.searchCreators(search, query)
    }
  }

  componentDidUpdate(oldProps) {
    const {
      location: { query },
      params: { search },
    } = this.props
    if (!shallowequal(oldProps.location.query, query) || oldProps.params.search !== search) {
      this.props.searchCreators(search, query)
    }
  }

  handleSearch = (search) => {
    browserHistory.push(`/search/${search}`)
  }

  handlePagination = ({ value: offset }) => {
    newHandleQuery({
      offset: offset || undefined, // we don't need offset=0, it's implicit
    })
  }

  handleOrdering = ({ value }) => {
    newHandleQuery({
      ordering: value,
    })
  }

  handleCreatorSelection = (checked, creatorId) => {
    this.props.selectCreator(creatorId)
  }

  toggleModal = () => {
    this.setState(({ isModalOpen }) => ({
      isModalOpen: !isModalOpen,
    }))
  }

  renderVideo = (video) => (
    <div key={video.hashCode()} className={styles.video}>
      <CreatorVideo video={video} hasContent={!!video.get('video_url')} />
    </div>
  )

  signOutTo = (to) => () => {
    const { signout } = this.props
    localStorage.setItem('filters/available', null)
    sessionStorage.removeItem('admin_login')
    signout()
    IntercomAPI('shutdown')
    reset()
    browserHistory.replace(to)
  }

  renderResult = (item, index) => {
    const { selectedCreators, user, params, location } = this.props
    const search = params.search
    const offset = (location.query.offset && Number(location.query.offset)) || 0
    const username = item.getIn(['creator', 'name'])
    const avatar = item.getIn(['creator', 'avatar_url'])
    const profileLink = `/creator/${item.getIn(['creator', 'slug'])}`
    const profileLinkQuery = searchTermMatchesCreator(search, username) ? {} : { search }
    const creatorId = item.getIn(['creator', 'id'])
    const isSelected = selectedCreators.includes(creatorId)
    const isBlocklisted = !!item.getIn(['creator', 'blocklisted'])

    const twitch = {
      followers: item.getIn(['creator', 'twitch', 'follower_count']),
      url: item.getIn(['creator', 'twitch', 'account_url']),
    }

    const youtube = {
      followers: item.getIn(['creator', 'youtube', 'follower_count']),
      url: item.getIn(['creator', 'youtube', 'account_url']),
    }

    return (
      <div
        key={item.hashCode()}
        className={classnames(styles.result, { [styles.selected]: isSelected })}
      >
        {isBlocklisted && (
          <ReactTooltip
            id={`${item.hashCode()}`}
            multiline
            place="bottom"
            effect="solid"
            className={styles.embargoTooltip}
          />
        )}
        {hasRole(user, 'offer_invites') ? (
          <div
            className={styles.resultCheck}
            data-tip="This creator is blocklisted."
            data-for={`${item.hashCode()}`}
          >
            <Checkbox
              className={styles.multiCheckbox}
              checked={isSelected}
              onChange={this.handleCreatorSelection}
              onChangeWith={creatorId}
              disabled={isBlocklisted}
            />
          </div>
        ) : null}
        <div className={styles.avatarWrapper}>
          {avatar ? (
            <Link
              to={{
                pathname: profileLink,
                query: profileLinkQuery,
                state: { fromSearch: search },
              }}
            >
              <Image url={avatar} alt={username} />
            </Link>
          ) : (
            <Svg className={styles.avatarPlaceholder} svg={AvatarPlaceholder} />
          )}
        </div>
        <div className={styles.creatorData}>
          <h2 className={classnames(styles.resultName, { [styles.anonymous]: !username })}>
            {username ? (
              <Link
                to={{
                  pathname: profileLink,
                  query: profileLinkQuery,
                  state: { fromSearch: search },
                }}
              >
                {username}
              </Link>
            ) : (
              `Creator #${index + offset + 1}`
            )}
          </h2>
          <Followers twitch={twitch} youtube={youtube} />
        </div>
        <div className={styles.videos}>
          {item
            .get('videos', new List())
            .slice(0, MAX_VIDEOS_PER_RESULT)
            .map(this.renderVideo)}
        </div>
      </div>
    )
  }

  getSendOfferButtonTitle = (selectedCreatorsCount) => {
    if (!selectedCreatorsCount) {
      return 'Send offer'
    }
    if (selectedCreatorsCount === 1) {
      return 'Send 1 offer'
    }
    return `Send ${selectedCreatorsCount} offers`
  }

  handleOffersSent = (campaignId) => {
    this.setState({
      isModalOpen: false,
    })
    const currLocation = browserHistory.getCurrentLocation()
    browserHistory.replace({
      ...currLocation,
      pathname: `${currLocation.pathname}/offers-sent/${campaignId}`,
    })
  }

  render() {
    const {
      children,
      results,
      params,
      location,
      pagination,
      isLoading,
      selectedCreators,
      user,
    } = this.props
    const { isModalOpen } = this.state
    const offset = location.query.offset && Number(location.query.offset)
    return (
      <div className={styles.container}>
        <Breadcrumb paths={[['/search', 'Search']]}>{params.search}</Breadcrumb>
        <div className={styles.filters}>
          <div className={styles.selectWrapper}>
            <Select
              searchable={false}
              options={ORDER_OPTIONS}
              dark
              name="ordering"
              value={location.query.ordering || DEFAULT_ORDERING}
              onChange={this.handleOrdering}
            />
          </div>
          {hasRole(user, 'offer_invites') ? (
            <div className={styles.actionButtonsWrapper}>
              <Button
                onClick={this.toggleModal}
                disabled={!selectedCreators.size}
                kind={!selectedCreators.size ? 'grayed' : undefined}
              >
                {this.getSendOfferButtonTitle(selectedCreators.size)}
              </Button>
              {selectedCreators.size ? (
                <Button
                  className={styles.clearButton}
                  onClick={this.props.clearSelectedCreators}
                  kind="secondaryGray"
                >
                  Clear selection
                </Button>
              ) : null}
            </div>
          ) : null}
          <div className={styles.matches}>
            {pagination.get('count', '?').toLocaleString()} creators matching{' '}
            <strong>{params.search}</strong>
          </div>
        </div>

        <div className={styles.results}>
          {isLoading && !results.size ? (
            <div className={styles.loadingWrapper}>
              <Loading isLoading />
            </div>
          ) : null}
          <NothingHere visible={!isLoading && !results.size}>No results!</NothingHere>

          {results.map(this.renderResult)}
        </div>

        {pagination.get('results') ? (
          <div className={styles.paginationWrap}>
            <Pagination
              count={pagination.get('count')}
              prev={pagination.get('prev')}
              next={pagination.get('next')}
              onClick={this.handlePagination}
              current={offset}
            />
          </div>
        ) : null}

        {isModalOpen ? (
          <SendOfferModal
            onInvite={this.handleOffersSent}
            onCancel={this.toggleModal}
            params={{}}
            newCampaign={this.props.newCampaign}
          />
        ) : null}
        {children}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResultsPage)
