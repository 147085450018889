export const ON_FORM_FIELD_CHANGE = 'ON_FORM_FIELD_CHANGE'
export const CLEAR_UI_FORM_STORE = 'CLEAR_UI_FORM_STORE'

export const onFieldChange = (data) => ({
  type: ON_FORM_FIELD_CHANGE,
  payload: data,
})

export const clearUiFormStore = () => ({
  type: CLEAR_UI_FORM_STORE,
})
