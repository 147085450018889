import defineAction from '../../utils/define-action'
import * as statsService from '../../services/stats'

export const GET_USERS_STATS = defineAction('GET_USERS_STATS')
export const GET_CREATORS_STATS = defineAction('GET_CREATORS_STATS')

export const getUsersStats = () => (dispatch, getState) =>
  dispatch({
    type: GET_USERS_STATS.ACTION,
    payload: statsService.getUsersStats(getState().user.get('auth_token'))(),
  })

export const getCreatorStats = () => (dispatch, getState) =>
  dispatch({
    type: GET_CREATORS_STATS.ACTION,
    payload: statsService.getCreatorStats(getState().user.get('auth_token'))(),
  })
