import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'

import styles from './styles.css'

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(21, 29, 39, 0.5)',
    overflow: 'auto',
    padding: '80px 0',
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'wrap',
  },
}

const Modal = ({
  children,
  className,
  shouldCloseOnEsc,
  shouldCloseOnOverlayClick,
  style,
  ...props
}) => (
  <ReactModal
    onAfterOpen={console.info}
    shouldCloseOnEsc={shouldCloseOnEsc}
    style={{ ...modalStyles, ...style }}
    className={classnames(styles.modalContent, className)}
    shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    {...props}
  >
    {children}
  </ReactModal>
)

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.shape({
    overlay: PropTypes.object,
    content: PropTypes.object,
  }),
  shouldCloseOnEsc: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
}
Modal.defaultProps = {
  className: undefined,
  shouldCloseOnEsc: true,
  shouldCloseOnOverlayClick: true,
  style: {},
}

export default Modal
