import React from 'react'
import PropTypes from 'prop-types'

import MultiCheckboxSelect from 'components/piece-field-multi-select-checkbox'
import DatePicker from 'components/piece-field-datepicker'
import Button from 'components/button'
import Svg from 'components/svg'
import { format, isBeforeNow } from 'utils/datetime'
import icCalendar from 'img/ic_calendar.svg'

import CreatorsSelect from './creator-select'
import OrgMembersSelect from './org-member-select'
import CampaignsSelect from './campaign-select'
import styles from './styles.css'

const allOption = {
  label: 'All',
  value: '*',
}

const DATE_FORMAT = 'MM/DD/YYYY'

const disableFutureDate = (current) => isBeforeNow(current)
const FeedFilter = ({
  className,
  onResetFilter,
  filters,
  setFilters,
  hasCampaignFilter,
  activitiesFilterOptions,
}) => {
  const {
    publisher,
    creator,
    date_after: startDate,
    date_before: endDate,
    event_group: eventGroups,
    campaign,
  } = filters

  const onSelectChange = (event, name) => {
    setFilters({
      ...filters,
      [name]: event?.value || undefined,
    })
  }

  const onMultiSelectChange = (event, name) => {
    const values = event.map((option) => option.value)
    setFilters({
      ...filters,
      [name]: values,
    })
  }

  const onDateChange = (date, name) => {
    setFilters({
      ...filters,
      [name]: date,
    })
  }

  const hasFilters = () =>
    publisher ||
    creator ||
    campaign ||
    creator ||
    (eventGroups && eventGroups?.length <= activitiesFilterOptions?.length)

  return (
    <div className={className}>
      <h4>FILTERS</h4>
      <MultiCheckboxSelect
        className={styles.select}
        options={activitiesFilterOptions}
        name="event_group"
        onChange={onMultiSelectChange}
        value={
          eventGroups || [allOption.value, ...activitiesFilterOptions.map((option) => option.value)]
        }
        allValueDisplay="Activities: All"
        placeholder="Activities: All"
        allOption={allOption}
      />
      <OrgMembersSelect value={publisher} onSelectChange={onSelectChange} />
      {hasCampaignFilter && <CampaignsSelect value={campaign} onSelectChange={onSelectChange} />}
      <CreatorsSelect value={creator} onSelectChange={onSelectChange} />
      <h4>RANGE</h4>
      <div className={styles.dates}>
        <Svg className={styles.calendarIcon} alt="calendar-icon" svg={icCalendar} />
        <DatePicker
          name="date_after"
          placeholder="Start"
          className={styles.date}
          wrapperClassName={styles.dateWrapper}
          value={startDate && format(startDate, DATE_FORMAT)}
          onChange={onDateChange}
          timeFormat={false}
          isValidDate={disableFutureDate}
        />
      </div>
      <div className={styles.dates}>
        <Svg className={styles.calendarIcon} alt="calendar-icon" svg={icCalendar} />
        <DatePicker
          name="date_before"
          placeholder="End"
          className={styles.date}
          wrapperClassName={styles.dateWrapper}
          value={endDate && format(endDate, DATE_FORMAT)}
          onChange={onDateChange}
          timeFormat={false}
          isValidDate={disableFutureDate}
        />
      </div>
      <div className={styles.filterAction}>
        {hasFilters() && (
          <Button kind="secondary" className={styles.resetFilter} onClick={onResetFilter}>
            Reset filters
          </Button>
        )}
      </div>
    </div>
  )
}

FeedFilter.propTypes = {
  className: PropTypes.string,
  onResetFilter: PropTypes.func,
  filters: PropTypes.instanceOf(Object),
  setFilters: PropTypes.func,
  hasCampaignFilter: PropTypes.bool,
  activitiesFilterOptions: PropTypes.instanceOf(Array),
}

FeedFilter.defaultProps = {
  className: undefined,
  onResetFilter: () => {},
  filters: {
    publisher: null,
    creator: null,
    date_after: null,
    date_before: null,
    event_group: null,
    campaign: null,
  },
  setFilters: () => {},
  hasCampaignFilter: false,
  activitiesFilterOptions: [],
}

export default FeedFilter
