import immutable from 'immutable'

import createReducer from '../../utils/create-reducer'

import { ON_FORM_FIELD_CHANGE, CLEAR_UI_FORM_STORE } from './actions'

export const INITIAL_STATE = new immutable.Map()

export const uiForm = createReducer(INITIAL_STATE, {
  [ON_FORM_FIELD_CHANGE]: (state, action) => {
    const { payload } = action
    return state.merge(immutable.fromJS(payload))
  },

  [CLEAR_UI_FORM_STORE]: () => INITIAL_STATE,
})
