const env = (envName) => {
  if (typeof window === 'undefined' || process.env.NODE_ENV === 'test') {
    return process.env[envName]
  }
  return window[envName]
}

const getEnv = (envName, fallback) => {
  const value = env(envName)
  // we gotta check if env value is string 'undefined' because serverless returns it like this :/
  if ((typeof value === 'undefined' || value === 'undefined') && typeof fallback === 'undefined') {
    throw new Error(`Missing required env '${envName}'`)
  }
  if (typeof value !== 'undefined' && value !== 'undefined') {
    return value
  }
  return fallback
}

export default getEnv
