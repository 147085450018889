import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import ImmutableProptypes from 'react-immutable-proptypes'
import { Helmet } from 'react-helmet'
import { Map } from 'immutable'
import { faMapMarker } from '@fortawesome/free-solid-svg-icons'

import { selectPage, selectPageResults } from '../../modules/pagination/selectors'
import { getPublisher, GET_PUBLISHER } from '../../modules/publisher/actions'
import { getPublicCampaigns, GET_FEATURED_CAMPAIGNS } from '../../modules/campaign/actions'
import { publisher as publisherReducer } from '../../modules/publisher/reducers'
import { featuredCampaigns as featuredCampaignsReducer } from '../../modules/campaign/reducers'
import { pagination as paginationReducer } from '../../modules/pagination/reducers'
import { getPublisherBySlug } from '../../modules/publisher/selectors'
import * as publisherService from '../../services/publisher'
import * as campaignService from '../../services/campaigns'
import Loading from '../../components/piece-loading'
import Image from '../../components/block-image'
import OfferCard from '../../components/offer-card'
import Button from '../../components/button'
import Container from '../../components/container'
import Icon from '../../components/piece-icon'
import stripHTTP from '../../utils/strip-http'
import isNotFound from '../../utils/is-not-found'
import NotFoundPage from '../not-found'
import lowercaseParams from '../../hocs/lowercase-params'

import styles from './styles.css'

const PUBLIC_CAMPAIGNS_LIMIT = 90

const mapStateToProps = (state, { params: { slug } }) => {
  const page = selectPage(state, GET_FEATURED_CAMPAIGNS.ACTION, {
    company: slug,
    limit: PUBLIC_CAMPAIGNS_LIMIT,
  })
  return {
    user: state.user,
    publisher: getPublisherBySlug(state, slug),
    isLoading: !!state.loading.get(GET_PUBLISHER.ACTION),
    error: state.error.get(GET_PUBLISHER.ACTION),
    campaigns: selectPageResults(page, state.featuredCampaigns),
  }
}

const mapDispatchToProps = {
  getPublisher,
  getPublicCampaigns,
}

class PublicPublisherPage extends Component {
  static propTypes = {
    user: ImmutableProptypes.map,
    publisher: ImmutableProptypes.map,
    getPublisher: PropTypes.func.isRequired,
    getPublicCampaigns: PropTypes.func.isRequired,
    campaigns: ImmutableProptypes.list.isRequired,
    isLoading: PropTypes.bool.isRequired,
    params: PropTypes.shape({
      slug: PropTypes.string,
    }).isRequired,
    error: ImmutableProptypes.map,
  }

  static defaultProps = {
    user: new Map(),
    error: new Map(),
    publisher: new Map(),
  }

  static getData({ slug }) {
    return [
      publisherService
        .getPublisher()(slug)
        .then((payload) => ({
          publisher: publisherReducer(undefined, {
            type: GET_PUBLISHER.FULFILLED,
            payload,
          }),
        })),
      campaignService
        .getPublicCampaigns()({
          company: slug,
          limit: PUBLIC_CAMPAIGNS_LIMIT,
        })
        .then((payload) => ({
          featuredCampaigns: featuredCampaignsReducer(undefined, {
            type: GET_FEATURED_CAMPAIGNS.FULFILLED,
            payload,
          }),
          pagination: paginationReducer(undefined, {
            payload,
            type: GET_FEATURED_CAMPAIGNS.FULFILLED,
            meta: {
              page: {
                company: slug,
                limit: PUBLIC_CAMPAIGNS_LIMIT,
              },
            },
          }),
        })),
    ]
  }

  componentDidMount() {
    this.props.getPublisher(this.props.params.slug)
    this.props.getPublicCampaigns({
      company: this.props.params.slug,
      limit: PUBLIC_CAMPAIGNS_LIMIT,
    })
  }

  componentDidUpdate(oldProps) {
    if (oldProps.params.slug !== this.props.params.slug) {
      this.componentDidMount()
    }
  }

  renderOffer = (item) => {
    const href = `/offer/${item.get('slug')}`
    return (
      <div key={item.hashCode()} className={styles.offer}>
        <OfferCard
          title={item.get('title') || item.get('steam_game_name')}
          isPaid={item.get('paid')}
          image={item.get('cover_url')}
          publisher={item.getIn(['organization', 'name']) || item.get('steam_game_publisher')}
          platforms={item.get('platforms').toArray()}
          href={href}
          isReleased={item.get('is_released')}
          releaseAt={item.get('release_at')}
        >
          <Button className={styles.offerButton} href={href}>
            I want this offer!
          </Button>
        </OfferCard>
      </div>
    )
  }

  render() {
    const { campaigns, publisher, isLoading, error, user } = this.props

    if (isNotFound(error)) {
      return <NotFoundPage />
    }

    if (isLoading && !publisher.size) {
      return <Loading isLoading />
    }
    return (
      <div>
        <Helmet>
          <title>{`${publisher.get('name')} — Woovit`}</title>
        </Helmet>

        <Container>
          <div className={styles.title}>
            {publisher.get('logo_url') ? (
              <div className={styles.imageWrapper}>
                <Image alt={`${publisher.get('name')}}'s logo`} url={publisher.get('logo_url')} />
              </div>
            ) : null}

            <div>
              <h1 className={styles.publisherName}>{publisher.get('name')}</h1>
              {publisher.get('city') || publisher.get('email') || publisher.get('website_url') ? (
                <Fragment>
                  <span className={styles.label}>Contact</span>
                  {publisher.get('city') ? (
                    <span className={styles.meta}>
                      <Icon className={styles.marker} icon={faMapMarker} />
                      {publisher.get('city')}
                    </span>
                  ) : null}
                  <a
                    className={styles.meta}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`mailto:${publisher.get('email')}`}
                  >
                    {stripHTTP(publisher.get('email'))}
                  </a>
                  <a
                    className={styles.meta}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={publisher.get('website_url')}
                  >
                    {stripHTTP(publisher.get('website_url'))}
                  </a>
                </Fragment>
              ) : null}
            </div>
            {user.getIn(['organization', 'id']) &&
            user.getIn(['organization', 'id']) === publisher.get('id') ? (
              <div className={styles.editButton}>
                <Button href="/publisher/organization">Edit</Button>
              </div>
            ) : null}
          </div>
          <div className={styles.about}>{publisher.get('about')}</div>
        </Container>

        <div className={styles.blockList}>{campaigns.map(this.renderOffer)}</div>
      </div>
    )
  }
}

export default lowercaseParams(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PublicPublisherPage)
)
