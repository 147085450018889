export const DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ'
export const DATE_FORMAT = 'YYYY-MM-DD'

export const HUMAN_FORMAT = 'MMMM DD, YYYY [at] h:mm a'
export const HUMAN_FORMAT_NOTIME = 'MMMM DD, YYYY'
export const SHORT_HUMAN_FORMAT = 'MMM DD, YYYY [at] h:mm a'

export const REDIRECT_DELAY = 1000

export const EMBARGO_FORMAT = 'LLL'
