import defineAction from '../../utils/define-action'
import * as campaignService from '../../services/campaigns'

export const CREATE_NEW_CAMPAIGN = defineAction('CREATE_NEW_CAMPAIGN')
export const DELETE_CAMPAIGN_CODES = defineAction('DELETE_CAMPAIGN_CODES')
export const UPDATE_CAMPAIGN = defineAction('UPDATE_CAMPAIGN')
export const UPLOAD_IMAGE = defineAction('UPLOAD_IMAGE')
export const CHANGING_CAMPAIGN_FIELD = 'CHANGING_CAMPAIGN_FIELD'
export const CLEAR_NEW_CAMPAIGN_STORE = 'CLEAR_NEW_CAMPAIGN_STORE'
export const ADD_CODES = 'ADD_CODES'
export const DELETE_CODES = 'DELETE_CODES'
export const REMOVE_MEDIA = 'REMOVE_MEDIA'
export const ADD_MEDIA = 'ADD_MEDIA'
export const REORDER_MEDIA = 'REORDER_MEDIA'
export const CHECK_SLUG_UNIQUENESS = defineAction('CHECK_SLUG_UNIQUENESS')

export const updateCampaign = (slug, payload) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_CAMPAIGN.ACTION,
    payload: campaignService.update(getState().user.get('auth_token'))(slug, payload),
  })

export const uploadImage = (payload, cover) => (dispatch) =>
  dispatch({
    type: UPLOAD_IMAGE.ACTION,
    payload: campaignService.uploadImage(payload),
    meta: { cover },
  })

export const changeCampaignField = (payload) => ({
  type: CHANGING_CAMPAIGN_FIELD,
  payload,
})

export const clearNewCampaignStore = () => ({
  type: CLEAR_NEW_CAMPAIGN_STORE,
})

export const deleteCampaignCodes = (slug) => (dispatch, getState) =>
  dispatch({
    type: DELETE_CAMPAIGN_CODES.ACTION,
    payload: campaignService.deleteCodes(getState().user.get('auth_token'))(slug),
  })

export const createCampaign = (payload) => (dispatch, getState) =>
  dispatch({
    type: CREATE_NEW_CAMPAIGN.ACTION,
    payload: campaignService.create(getState().user.get('auth_token'))(payload),
  })

export const addCodes = (payload) => ({
  type: ADD_CODES,
  payload,
})

export const deleteCodes = (platform) => ({
  type: DELETE_CODES,
  payload: platform,
})

export const removeMedia = (mediaIndex) => ({
  type: REMOVE_MEDIA,
  payload: mediaIndex,
})

export const addMedia = (payload) => ({
  type: UPLOAD_IMAGE.FULFILLED,
  payload,
})

export const reorderMedia = (previousIndex, newIndex) => ({
  type: REORDER_MEDIA,
  payload: { previousIndex, newIndex },
})

export const checkSlugUniqueness = (slug) => (dispatch, getState) => {
  const cache = getState().slugUniqueness.get(slug)
  dispatch({
    type: CHECK_SLUG_UNIQUENESS,
    payload:
      typeof cache !== 'undefined'
        ? cache
        : campaignService.checkSlug(getState().user.get('auth_token'))({ slug }),
    meta: slug,
  })
}
