import { Map } from 'immutable'
import { normalize, schema } from 'normalizr'

import createReducer from '../../utils/create-reducer'

import {
  GET_PUBLISHER,
  GET_ORGANIZATION_MEMBERS,
  DEMOTE_MEMBER_FROM_ORGANIZATION,
  REMOVE_MEMBER_FROM_ORGANIZATION,
  REINSTATE_MEMBER_FROM_ORGANIZATION,
  PROMOTE_MEMBER_FROM_ORGANIZATION,
  INVITE_USERS,
  GET_INVITES,
  GET_INVITE,
  RESEND_INVITE,
  WITHDRAW_INVITE,
} from './actions'

export const publisherSchema = new schema.Entity('publisher')

export const publisher = createReducer(new Map(), {
  [GET_PUBLISHER.FULFILLED]: (state, { payload }) =>
    state.mergeDeep(normalize(payload, publisherSchema).entities.publisher),
})

const organizationMemberSchema = new schema.Entity('member')
export const organizationMembers = createReducer(new Map(), {
  [GET_ORGANIZATION_MEMBERS.FULFILLED]: (state, { payload }) =>
    state.mergeDeep(normalize(payload, [organizationMemberSchema]).entities.member),
  [DEMOTE_MEMBER_FROM_ORGANIZATION.FULFILLED]: (state, { payload }) =>
    state.mergeDeep({ [String(payload.id)]: payload }),
  [REMOVE_MEMBER_FROM_ORGANIZATION.FULFILLED]: (state, { payload }) =>
    state.mergeDeep({ [String(payload.id)]: payload }),
  [REMOVE_MEMBER_FROM_ORGANIZATION.FULFILLED]: (state, { payload }) =>
    state.mergeDeep({ [String(payload.id)]: payload }),
  [REINSTATE_MEMBER_FROM_ORGANIZATION.FULFILLED]: (state, { payload }) =>
    state.mergeDeep({ [String(payload.id)]: payload }),
  [PROMOTE_MEMBER_FROM_ORGANIZATION.FULFILLED]: (state, { payload }) =>
    state.mergeDeep({ [String(payload.id)]: payload }),
})

const organizationInviteSchema = new schema.Entity('invitation', undefined, {
  idAttribute: 'token',
})
export const organizationInvites = createReducer(new Map(), {
  [GET_INVITES.FULFILLED]: (state, { payload }) =>
    state.mergeDeep(normalize(payload, [organizationInviteSchema]).entities.invitation),
  [GET_INVITE.FULFILLED]: (state, { payload }) =>
    state.mergeDeep({ [String(payload.token)]: payload }),
  [INVITE_USERS.FULFILLED]: (state, { payload }) =>
    state.mergeDeep({ [String(payload.token)]: payload }),
  [RESEND_INVITE.FULFILLED]: (state, { payload }) =>
    state.mergeDeep({ [String(payload.token)]: payload }),
  [WITHDRAW_INVITE.FULFILLED]: (state, { meta }) => state.remove(meta),
})
