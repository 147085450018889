import React from 'react'
import PropTypes from 'prop-types'
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'

import Actionable from '../actionable'
import Icon from '../piece-icon'

import styles from './styles.css'

const Last = ({ onClick, onClickWith }) => (
  <li className={styles.item}>
    <Actionable className={styles.button} onClick={onClick} onClickWith={onClickWith}>
      <Icon icon={faAngleDoubleRight} />
    </Actionable>
  </li>
)

Last.propTypes = {
  onClick: PropTypes.func.isRequired,
  onClickWith: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
}

export default Last
