import { Map, fromJS } from 'immutable'
import { normalize, schema } from 'normalizr'

import createReducer from '../../utils/create-reducer'

import {
  FETCH_MANUAL_REVIEW_BY_CAMPAIGN,
  FETCH_CREATOR_MANUAL_REVIEWS,
  GET_MANUAL_REVIEW_DETAILS,
  DELETE_MANUAL_REVIEW,
  CREATE_NEW_MANUAL_REVIEW,
  CLEAR_NEW_REQUEST,
  STAGE_STATUS,
} from './actions'

export const INITIAL_STATE = new Map()
export const manualReviewSchema = new schema.Entity('request')

const normalizeAndMergeDeep = (state, { payload }) => {
  const results = normalize(payload.results, [manualReviewSchema]).entities.request
  return state.mergeDeep(results)
}

const creatorRequestsNormalizeAndMergeDeep = (state, { payload }) => {
  const results = normalize(payload.results, [manualReviewSchema]).entities.request
  return state.mergeDeep(results)
}

const creatorRequestNormalizeAndMergeDeep = (state, { payload }) => {
  const results = normalize(payload, manualReviewSchema).entities.request
  return state.mergeDeep(results)
}
export const manualReview = createReducer(INITIAL_STATE, {
  [FETCH_MANUAL_REVIEW_BY_CAMPAIGN.FULFILLED]: normalizeAndMergeDeep,
  [FETCH_CREATOR_MANUAL_REVIEWS.FULFILLED]: creatorRequestsNormalizeAndMergeDeep,
  [GET_MANUAL_REVIEW_DETAILS.FULFILLED]: creatorRequestNormalizeAndMergeDeep,
  [DELETE_MANUAL_REVIEW.FULFILLED]: (state, { payload }) => state.delete(payload),
  [CREATE_NEW_MANUAL_REVIEW.FULFILLED]: (state, { payload }) => {
    const newRequest = normalize(payload, manualReviewSchema).entities.request
    return state.mergeDeep(newRequest).set('newRequest', fromJS(payload))
  },
  [CLEAR_NEW_REQUEST]: (state) => state.delete('newRequest'),
  [STAGE_STATUS.PENDING]: (state, { meta }) =>
    state.mergeDeep(Object.fromEntries(meta.map((s) => [String(s[0]), { staged_status: s[1] }]))),
})
