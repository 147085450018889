import React from 'react'
import PropTypes from 'prop-types'
import { Route, IndexRedirect } from 'react-router'
import Loadable from 'react-loadable'

import Loading from '../../components/piece-loading-async-route'
import { CAMPAIGN_STATES } from '../../config/settings'

const loading = () => <Loading />

const AddNoteModal = Loadable({
  loader: () => import(/* webpackChunkName: "campaigndetail" */ '../../views/add-notes-modal'),
  loading,
})

const AddToWhitelistModal = Loadable({
  loader: () =>
    import(/* webpackChunkName: "campaigndetail" */ '../../views/view-campaign/add-to-whitelist'),
  loading,
})

const ViewCampaignWidget = Loadable({
  loader: () => import(/* webpackChunkName: "campaigndetail" */ '../../views/view-campaign/widget'),
  loading,
})

const ViewCampaignCodesEditModal = Loadable({
  loader: () =>
    import(/* webpackChunkName: "campaigndetail" */ '../../views/view-campaign/codes-edit-modal'),
  loading,
})

const ViewCampaignReports = Loadable({
  loader: () => import(/* webpackChunkName: "campaigndetail" */ '../../views/view-campaign-report'),
  loading,
})

const ViewCampaignVideoReports = Loadable({
  loader: () => import(/* webpackChunkName: "campaigndetail" */ '../../views/view-campaign/videos'),
  loading,
})

const ViewCampaignDiscovery = Loadable({
  loader: () =>
    import(/* webpackChunkName: "campaigndetail" */ '../../views/view-campaign/discovery'),
  loading,
})

const ViewCampaignCreators = Loadable({
  loader: () =>
    import(/* webpackChunkName: "campaigndetail" */ '../../views/view-campaign/creators'),
  loading,
})

const ViewCampaignFeed = Loadable({
  loader: () => import(/* webpackChunkName: "campaigndetail" */ '../../views/view-campaign/feed'),
  loading,
})

const ViewCampaignDescription = Loadable({
  loader: () =>
    import(/* webpackChunkName: "campaigndescription" */ '../../views/view-campaign/description'),
  loading,
})

const ViewCampaignMatchingVideos = Loadable({
  loader: () =>
    import(/* webpackChunkName: "campaigndescription" */ '../../views/view-campaign/matching-videos'),
  loading,
})

const ViewCampaignCodes = Loadable({
  loader: () => import(/* webpackChunkName: "campaigndetail" */ '../../views/view-campaign/codes'),
  loading,
})

const ViewCampaign = Loadable({
  loader: () => import(/* webpackChunkName: "campaigndetail" */ '../../views/view-campaign'),
  loading,
})

const ViewCampaignRequests = Loadable({
  loader: () =>
    import(/* webpackChunkName: "campaigndetail" */ '../../views/view-campaign/requests'),
  loading,
})

const SendOfferFeatureModal = Loadable({
  loader: () =>
    import(/* webpackChunkName: "sendoffer" */ 'views/public-creator-page/send-offer-feature-modal'),
  loading,
})

const ViewCampaignRoutes = ({ state, redirect }) => (
  <Route path={`${state}/:slug`} component={ViewCampaign}>
    <IndexRedirect to={redirect} />
    <Route path="description" component={ViewCampaignDescription} />
    <Route path="codes" component={ViewCampaignCodes} />
    {state !== CAMPAIGN_STATES.drafting && (
      <>
        <Route skipScrollUpOnOffset path="activity" component={ViewCampaignFeed} />
        {state !== CAMPAIGN_STATES.scheduled && (
          <>
            <Route path="requests" component={ViewCampaignRequests} />
            <Route path="videos" component={ViewCampaignVideoReports}>
              <Route path="add-note/:creatorId/:videoId" component={AddNoteModal} />
              <Route path="add-to-whitelist/:creatorId" component={AddToWhitelistModal} />
            </Route>
            <Route path="reports" component={ViewCampaignReports} />
            <Route path="creators" component={ViewCampaignCreators}>
              <Route
                skipScrollUp
                path=":offerId/codes/:platform"
                component={ViewCampaignCodesEditModal}
              />
            </Route>
            <Route path="discovery" component={ViewCampaignDiscovery}>
              <Route skipScrollUp path="send-offer-premium" component={SendOfferFeatureModal} />
            </Route>
            <Route path="widget" component={ViewCampaignWidget} />
            <Route path="matching-videos" component={ViewCampaignMatchingVideos} />
          </>
        )}
      </>
    )}
  </Route>
)

ViewCampaignRoutes.propTypes = {
  state: PropTypes.oneOf(Object.keys(CAMPAIGN_STATES)).isRequired,
  redirect: PropTypes.string.isRequired,
}

export default ViewCampaignRoutes
