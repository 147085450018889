import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import classnames from 'classnames'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import Button from 'components/button'
import FormActions from 'components/piece-form-actions'
import Modal from 'components/modal'
import Loading from 'components/piece-loading'
import Icon from 'components/piece-icon'

import styles from './styles.css'

export default class ModalConfirm extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func,
    onConfirmWith: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    children: PropTypes.node.isRequired,
    cancelText: PropTypes.string,
    confirmText: PropTypes.string,
    contentLabel: PropTypes.string,
    hideCancel: PropTypes.bool,
    disableConfirm: PropTypes.bool,
    className: PropTypes.string,
    footerClassName: PropTypes.string,
    buttonsAreaClassName: PropTypes.string,
    isLoading: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    isDestructive: PropTypes.bool,
    hideConfirm: PropTypes.bool,
    isSecondaryBlue: PropTypes.bool,
    showClose: PropTypes.bool,
    onConfirmHref: PropTypes.string,
  }

  static defaultProps = {
    cancelText: 'Cancel',
    onConfirmWith: undefined,
    confirmText: 'Confirm',
    onConfirm: () => {},
    contentLabel: '',
    hideCancel: false,
    className: '',
    onConfirmHref: undefined,
    disableConfirm: false,
    buttonsAreaClassName: '',
    footerClassName: undefined,
    isLoading: false,
    title: undefined,
    isDestructive: false,
    hideConfirm: false,
    isSecondaryBlue: false,
    showClose: true,
  }

  onCancel = (event) => {
    const { onCancel } = this.props
    if (onCancel) onCancel(event)
  }

  onConfirm = (event) => {
    const { onConfirm } = this.props
    if (onConfirm) onConfirm(event, this.props.onConfirmWith)
  }

  render() {
    const {
      children,
      open,
      cancelText,
      confirmText,
      contentLabel,
      hideCancel,
      buttonsAreaClassName,
      footerClassName,
      className,
      disableConfirm,
      title,
      isDestructive,
      isLoading,
      hideConfirm,
      isSecondaryBlue,
      showClose,
      onConfirmHref,
      ...otherProps
    } = this.props

    return (
      <Modal
        isOpen={open}
        contentLabel={contentLabel}
        className={classnames(styles.content, className)}
        onRequestClose={this.onCancel}
        {...otherProps}
      >
        {title && (
          <>
            <div className={styles.header}>
              {title}
              {showClose && hideCancel && (
                <Icon className={styles.closeIcon} onClick={this.onCancel} icon={faTimes} />
              )}
            </div>
          </>
        )}
        {children}
        <div className={classnames(footerClassName, styles.footer)}>
          <FormActions direction="right">
            <div className={buttonsAreaClassName}>
              {isLoading ? (
                <div className={styles.loadingWrapper}>
                  <Loading isLoading />
                </div>
              ) : (
                <Fragment>
                  {!hideCancel ? (
                    <Button
                      kind={isSecondaryBlue ? 'secondaryBlue' : 'secondaryGray'}
                      onClick={this.onCancel}
                    >
                      {cancelText}
                    </Button>
                  ) : null}
                  {!hideConfirm ? (
                    <Button
                      href={onConfirmHref}
                      disabled={disableConfirm}
                      onClick={this.onConfirm}
                      kind={isDestructive ? 'destructive' : 'primary'}
                    >
                      {confirmText}
                    </Button>
                  ) : null}
                </Fragment>
              )}
            </div>
          </FormActions>
        </div>
      </Modal>
    )
  }
}
