import { put, post, get } from './request'

export const claim = (key) => (offerId, platform) =>
  put(['offer-invites', offerId, 'claim'], { key }, { platform })
export const reject = (key) => (offerId) => put(['offer-invites', offerId, 'reject'], { key })

export const invite = (key) => (
  profiles,
  campaign,
  platforms,
  expirationDays,
  inviteToTry,
  inviteLetter,
  inviteSubject
) =>
  post(
    ['offer-invites'],
    { key },
    {
      profiles,
      campaign,
      platforms,
      invite_duration: expirationDays,
      invite_to_try_if_no_codes: inviteToTry,
      invite_letter: inviteLetter,
      invite_subject: inviteSubject,
    }
  )

export const getOffers = (key) => (params) => get(['offers', 'invited'], { key, params })
