import { defineAction as reduxDefine } from 'redux-define'

export const PENDING = 'PENDING'
export const FULFILLED = 'FULFILLED'
export const REJECTED = 'REJECTED'
export const OFFLINE = 'OFFLINE'
export const CLEAR = 'CLEAR'

const REQUEST = [PENDING, FULFILLED, REJECTED, OFFLINE, CLEAR]

const defineAction = (type, subactions) => reduxDefine(type, subactions || REQUEST)

export default defineAction
