import defineAction from '../../utils/define-action'
import * as offerService from '../../services/offers'

export const ACCEPT_OFFER = defineAction('ACCEPT_OFFER')
export const DELIVER_OFFER = defineAction('DELIVER_OFFER')
export const ARCHIVE_OFFER = defineAction('ARCHIVE_OFFER')
export const UNARCHIVE_OFFER = defineAction('UNARCHIVE_OFFER')
export const GET_OFFER_DETAILS = defineAction('GET_OFFER_DETAILS')
export const CLEAR_NEW_OFFER = 'CLEAR_NEW_OFFER'
export const SUBMIT_VIDEO = defineAction('SUBMIT_VIDEO')
export const GET_OFFER_VIDEO_REQUESTS = defineAction('GET_OFFER_VIDEO_REQUESTS')
export const BOOKMARK_OFFER = defineAction('BOOKMARK_OFFER')
export const UNBOOKMARK_OFFER = defineAction('UNBOOKMARK_OFFER')
export const GET_ACCEPTED_OFFERS = defineAction('GET_ACCEPTED_OFFERS')
export const GET_ARCHIVED_OFFERS = defineAction('GET_ARCHIVED_OFFERS')
export const GET_DELIVERED_OFFERS = defineAction('GET_DELIVERED_OFFERS')

export const getOfferDetails = (slug, params) => (dispatch, getState) =>
  dispatch({
    type: GET_OFFER_DETAILS.ACTION,
    payload: offerService.getOfferDetails(getState().user.get('auth_token'))(slug, params),
  })

export const acceptOffer = (payload) => (dispatch, getState) =>
  dispatch({
    type: ACCEPT_OFFER.ACTION,
    payload: offerService.acceptOffer(getState().user.get('auth_token'))(payload),
    meta: payload.campaign.toString(),
  })

export const deliverOffer = (offerId) => (dispatch, getState) =>
  dispatch({
    type: DELIVER_OFFER.ACTION,
    payload: offerService.deliverOffer(getState().user.get('auth_token'))(offerId),
  })

export const archiveOffer = (offerId) => (dispatch, getState) =>
  dispatch({
    type: ARCHIVE_OFFER.ACTION,
    payload: offerService.archiveOffer(getState().user.get('auth_token'))(offerId),
  })

export const unarchiveOffer = (offerId) => (dispatch, getState) =>
  dispatch({
    type: UNARCHIVE_OFFER.ACTION,
    payload: offerService.unarchiveOffer(getState().user.get('auth_token'))(offerId),
  })

export const getAcceptedOffers = (params = {}) => (dispatch, getState) =>
  dispatch({
    type: GET_ACCEPTED_OFFERS,
    payload: offerService.getAcceptedOffers(getState().user.get('auth_token'))(params),
    meta: {
      page: params,
    },
  })

export const getArchivedOffers = (params = {}) => (dispatch, getState) =>
  dispatch({
    type: GET_ARCHIVED_OFFERS,
    payload: offerService.getArchivedOffers(getState().user.get('auth_token'))(params),
    meta: {
      page: params,
    },
  })

export const getDeliveredOffers = (params = {}) => (dispatch, getState) =>
  dispatch({
    type: GET_DELIVERED_OFFERS,
    payload: offerService.getDeliveredOffers(getState().user.get('auth_token'))(params),
    meta: {
      page: params,
    },
  })

export const clearNewOffer = () => (dispatch) =>
  dispatch({
    type: CLEAR_NEW_OFFER,
  })

export const submitVideo = (offerId, videoId) => (dispatch, getState) =>
  dispatch({
    type: SUBMIT_VIDEO.ACTION,
    payload: offerService.submitVideo(getState().user.get('auth_token'))(offerId, videoId),
  })

export const getVideoRequests = (offerId, params) => (dispatch, getState) =>
  dispatch({
    type: GET_OFFER_VIDEO_REQUESTS,
    payload: offerService.getVideoRequests(getState().user.get('auth_token'))(offerId, params),
    meta: { offerId },
  })

export const bookmarkOffer = (campaignId) => (dispatch, getState) =>
  dispatch({
    type: BOOKMARK_OFFER,
    payload: offerService.bookmark(getState().user.get('auth_token'))(campaignId),
    meta: { campaignId: String(campaignId) },
  })

export const unbookmarkOffer = (bookmarkId, campaignId) => (dispatch, getState) =>
  dispatch({
    type: UNBOOKMARK_OFFER,
    payload: offerService.unbookmark(getState().user.get('auth_token'))(bookmarkId),
    meta: { campaignId: String(campaignId) },
  })
