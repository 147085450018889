const stripHTTP = (url = '', stripWWW) => {
  if (!url || typeof url !== 'string') {
    return ''
  }

  const withoutHTTP = url.replace(/https?:\/\//, '')
  if (!stripWWW) {
    return withoutHTTP
  }
  return withoutHTTP.replace('www.', '')
}

export default stripHTTP
