import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getUserByToken, FETCH_USER_DATA } from '../modules/user/actions'

const mapStateToProps = (state) => ({
  isLoadingUser: !!state.loading.get(FETCH_USER_DATA.ACTION),
})

const mapDispatchToProps = {
  getUserByToken,
}

const UserLoader = (WrappedComponent) => {
  class UserLoaderComponent extends Component {
    static propTypes = {
      getUserByToken: PropTypes.func.isRequired,
      isLoadingUser: PropTypes.bool.isRequired,
    }

    componentDidMount() {
      if (!this.props.isLoadingUser) {
        this.props.getUserByToken()
      }
    }

    render() {
      const { isLoadingUser } = this.props
      return <WrappedComponent {...this.props} isLoadingUser={isLoadingUser} />
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserLoaderComponent)
}

export default UserLoader
