import React from 'react'
import { Link, withRouter } from 'react-router'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Actionable from '../actionable'

import styles from './styles.css'

const Page = (props) => {
  const { onClick, location, onClickWith, isActive, children, finalOffset } = props

  // we generate a functional <a> link because of SEO
  // but prevent default nagivation and fire onClick
  // in order to have better control on how pagination works
  const handleClick = (e) => {
    e.preventDefault()
    onClick(onClickWith)
  }
  return (
    <li className={styles.item}>
      {!isActive ? (
        <Link
          className={styles.button}
          onClick={handleClick}
          to={{ pathname: location.pathname, query: { ...location.query, offset: finalOffset } }}
        >
          {children}
        </Link>
      ) : (
        // disabled self-page is active
        <Actionable
          onClick={Function.prototype}
          className={classnames(styles.button, styles.active)}
        >
          {children}
        </Actionable>
      )}
    </li>
  )
}

Page.propTypes = {
  onClick: PropTypes.func.isRequired,
  onClickWith: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  isActive: PropTypes.bool,
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    query: PropTypes.shape({}),
  }),
  finalOffset: PropTypes.number.isRequired,
}

Page.defaultProps = {
  isActive: false,
  location: {
    pathname: '',
    query: {},
  },
}

export default withRouter(Page)
