import defineAction from '../../utils/define-action'
import * as service from '../../services/blocklist'

export const FETCH_BLOCKLISTED_CREATORS = defineAction('FETCH_BLOCKLISTED_CREATORS')
export const ADD_CREATOR_TO_BLOCKLIST = defineAction('ADD_CREATOR_TO_BLOCKLIST')
export const REMOVE_CREATOR_FROM_BLOCKLIST = defineAction('REMOVE_CREATOR_FROM_BLOCKLIST')

export const fetchCreators = (params = {}) => (dispatch, getState) =>
  dispatch({
    type: FETCH_BLOCKLISTED_CREATORS.ACTION,
    payload: service.getCreators(getState().user.get('auth_token'))(params),
    meta: { page: params },
  })

export const addCreator = (payload, meta = {}) => (dispatch, getState) =>
  dispatch({
    type: ADD_CREATOR_TO_BLOCKLIST.ACTION,
    payload: service.addCreator(getState().user.get('auth_token'))(payload),
    meta,
  })

export const removeCreator = (id, meta = {}) => (dispatch, getState) =>
  dispatch({
    type: REMOVE_CREATOR_FROM_BLOCKLIST.ACTION,
    payload: service.deleteCreator(getState().user.get('auth_token'))(id),
    meta,
  })
