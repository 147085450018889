import React from 'react'
import PropTypes from 'prop-types'

import visa from '../../img/cards/img-visa-color.svg'
import master from '../../img/cards/img-mastercard-color.svg'
import discover from '../../img/cards/img-discover-color.svg'
import jcb from '../../img/cards/img-jcb-color.svg'
import amex from '../../img/cards/img-americanexpress-color.svg'
import diners from '../../img/cards/img-dinersclub-color.svg'
import Svg from '../svg'

const CARD_BRANDS = {
  Visa: visa,
  'American Express': amex,
  MasterCard: master,
  Discover: discover,
  JCB: jcb,
  'Diners Club': diners,
}

const CardFlag = ({ flag, width, height }) => (
  <Svg width={width} height={height} svg={CARD_BRANDS[flag]} />
)

CardFlag.propTypes = {
  flag: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
}

CardFlag.defaultProps = {
  width: '40',
  height: '40',
}

export default CardFlag
