import defineAction from '../../utils/define-action'
import * as campaignService from '../../services/campaigns'

export const ESTIMATE_TARGET_CREATORS = defineAction('ESTIMATE_TARGET_CREATORS')

export const estimateTargetCreators = (filters) => (dispatch, getState) => {
  const state = getState()
  const hashCode = String(filters.hashCode())
  dispatch({
    type: ESTIMATE_TARGET_CREATORS.ACTION,
    payload: campaignService.qualifyingCreators(state.user.get('auth_token'))(filters.toJS()),
    meta: hashCode,
  })
}
