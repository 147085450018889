import { del, get, post } from './request'

export const availableOffers = (key) => get(['offers', 'available'], { key })

export const getOfferDetails = (key) => (slug, params) => {
  if (params && params.status === 'available') {
    return get(['offers', 'available', slug], { key })
  }
  return get(['offers', slug], { key })
}

export const acceptOffer = (key) => (payload) => post('offers', { key }, payload)

export const deliverOffer = (key) => (id) => post(['offers', id, 'deliver'], { key })

export const archiveOffer = (key) => (id) => post(['offers', id, 'archive'], { key })

export const unarchiveOffer = (key) => (id) => post(['offers', id, 'unarchive'], { key })

export const hideVideoFromOffer = (key) => (id, video) =>
  post(['offers', id, 'hide-video'], { key }, { video })

export const showVideoFromOffer = (key) => (id, video) =>
  post(['offers', id, 'unhide-video'], { key }, { video })

export const submitVideo = (key) => (id, videoId) =>
  post(['offers', id, 'offer-video-requests'], { key }, { video_id: videoId })

export const getVideoRequests = (key) => (offerId, params) =>
  get(['offers', offerId, 'offer-video-requests'], { key, params })

export const bookmark = (key) => (campaignId) =>
  post('bookmarks', { key }, { campaign: campaignId })
export const unbookmark = (key) => (bookmarkId) => del(['bookmarks', bookmarkId], { key })

export const getAcceptedOffers = (key) => (params) =>
  get('offers', {
    key,
    params: {
      is_archived: false,
      is_delivered: false,
      ...params,
    },
  })

export const getArchivedOffers = (key) => (params) =>
  get('offers', {
    key,
    params: {
      is_archived: true,
      ...params,
    },
  })

export const getDeliveredOffers = (key) => (params) =>
  get('offers', {
    key,
    params: {
      is_delivered: true,
      ...params,
    },
  })
