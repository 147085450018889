import defineAction from '../../utils/define-action'
import * as manualReviewService from '../../services/manual-review'
import arrayWrap from '../../utils/array-wrap'

export const FETCH_MANUAL_REVIEW_BY_CAMPAIGN = defineAction('FETCH_MANUAL_REVIEW_BY_CAMPAIGN')
export const ACCEPT_MANUAL_REVIEW = defineAction('ACCEPT_MANUAL_REVIEW')
export const REJECT_MANUAL_REVIEW = defineAction('REJECT_MANUAL_REVIEW')
export const FETCH_CREATOR_MANUAL_REVIEWS = defineAction('FETCH_CREATOR_MANUAL_REVIEWS')
export const GET_MANUAL_REVIEW_DETAILS = defineAction('GET_MANUAL_REVIEW_DETAILS')
export const DELETE_MANUAL_REVIEW = defineAction('DELETE_MANUAL_REVIEW')
export const CREATE_NEW_MANUAL_REVIEW = defineAction('CREATE_NEW_MANUAL_REVIEW')
export const CLEAR_NEW_REQUEST = 'CLEAR_NEW_REQUEST'
export const STAGE_STATUS = defineAction('STAGE_STATUS')
export const COMMIT_STATUS = defineAction('COMMIT_STATUS')

const DEFAULT_PARAMS = {
  ordering: '-date',
}
export const fetchManualReviewByCampaign = (params = {}) => (dispatch, getState) =>
  dispatch({
    type: FETCH_MANUAL_REVIEW_BY_CAMPAIGN.ACTION,
    payload: manualReviewService.getByCampaign(getState().user.get('auth_token'))({
      ...DEFAULT_PARAMS,
      ...params,
    }),
    meta: {
      page: params,
    },
  })
export const acceptManualReview = (manualReviewIds) => (dispatch, getState) =>
  dispatch({
    type: ACCEPT_MANUAL_REVIEW.ACTION,
    payload: manualReviewService.accept(getState().user.get('auth_token'))(manualReviewIds),
  })

export const rejectManualReview = (manualReviewIds) => (dispatch, getState) =>
  dispatch({
    type: REJECT_MANUAL_REVIEW.ACTION,
    payload: manualReviewService.reject(getState().user.get('auth_token'))(manualReviewIds),
  })

export const createManualReview = (payload) => (dispatch, getState) =>
  dispatch({
    type: CREATE_NEW_MANUAL_REVIEW.ACTION,
    payload: manualReviewService.create(getState().user.get('auth_token'))(payload),
  })

export const clearNewRequest = () => (dispatch) =>
  dispatch({
    type: CLEAR_NEW_REQUEST,
  })

export const fetchCreatorManualReviews = (params = {}) => (dispatch, getState) =>
  dispatch({
    type: FETCH_CREATOR_MANUAL_REVIEWS.ACTION,
    payload: manualReviewService.getCreatorRequests(getState().user.get('auth_token'))(params),
    meta: {
      page: params,
    },
  })

export const getManualReviewDetails = (id, params) => (dispatch, getState) =>
  dispatch({
    type: GET_MANUAL_REVIEW_DETAILS.ACTION,
    payload: manualReviewService.getDetails(getState().user.get('auth_token'))(id, params),
  })

export const deleteManualReview = (id) => (dispatch, getState) =>
  dispatch({
    type: DELETE_MANUAL_REVIEW.ACTION,
    payload: manualReviewService
      .remove(getState().user.get('auth_token'))(id)
      .then(() => String(id)),
  })

export const stageStatus = (selections) => (dispatch, getState) =>
  dispatch({
    type: STAGE_STATUS.ACTION,
    payload: manualReviewService.stageStatus(getState().user.get('auth_token'))(
      Object.fromEntries(arrayWrap(selections))
    ),
    meta: arrayWrap(selections),
  })

export const commitStatus = (campaignId) => (dispatch, getState) =>
  dispatch({
    type: COMMIT_STATUS.ACTION,
    payload: manualReviewService.commitStatus(getState().user.get('auth_token'))(campaignId),
  })
