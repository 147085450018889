import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'

import Icon from '../piece-icon'

import styles from './styles.css'

import { BaseIcon } from '.'

const Youtube = ({ dark, light, small, ...props }) => (
  <BaseIcon {...props}>
    <div
      className={classnames(styles.providerIcon, {
        [styles.youtube]: !dark && !light,
        [styles.dark]: dark,
        [styles.light]: light,
        // [styles.small]: small,
      })}
    >
      <Icon icon={faYoutube} />
    </div>
  </BaseIcon>
)

Youtube.propTypes = {
  dark: PropTypes.bool,
  light: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  small: PropTypes.bool,
  withLabel: PropTypes.bool,
}

Youtube.defaultProps = {
  dark: false,
  light: false,
  small: false,
  withLabel: false,
  label: 'YOUTUBE',
}

export default Youtube
