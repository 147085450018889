import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ImmutableProptypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { browserHistory } from 'react-router'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import {
  addCreator,
  removeCreator,
  ADD_CREATOR_TO_BLOCKLIST,
  REMOVE_CREATOR_FROM_BLOCKLIST,
} from 'modules/blocklist/actions'
import { getCreatorBySlug } from 'modules/creator/selectors'
import { getCreatorDetails, GET_CREATOR_DETAILS } from 'modules/creator/actions'
import {
  getCreatorWhitelists as getCreatorWhitelistsAction,
  GET_CREATOR_WHITELISTS,
} from 'modules/whitelist/actions'
import getFollowersFromCreator from 'utils/get-follower-data'
import FollowersButtons from 'components/followers-buttons'
import Image from 'components/block-image'
import ModalConfirm from 'components/modal-confirm'
import Button from 'components/button'
import Loading from 'components/piece-loading'
import Icon from 'components/piece-icon'

import styles from './styles.css'

const mapStateToProps = (state, { slug }) => {
  const { loading, creatorWhitelists } = state
  const creator = getCreatorBySlug(state, slug) || new Map()
  return {
    creator,
    creatorWhitelists: creatorWhitelists.get(String(creator.get('id'))) || new List(),
    isLoadingCreator: !!loading.get(GET_CREATOR_DETAILS.ACTION),
    isLoadingWhitelist: !!loading.get(GET_CREATOR_WHITELISTS.ACTION),
    isLoadingBlocklistAction:
      !!loading.get(ADD_CREATOR_TO_BLOCKLIST.ACTION) ||
      !!loading.get(REMOVE_CREATOR_FROM_BLOCKLIST.ACTION),
  }
}

const mapDispatchToProps = {
  getCreator: getCreatorDetails,
  addCreatorToBlocklist: addCreator,
  removeCreatorFromBlocklist: removeCreator,
  getCreatorWhitelists: getCreatorWhitelistsAction,
}

const CreatorAvatar = ({
  slug,
  isLoadingBlocklistAction,
  isLoadingCreator,
  addCreatorToBlocklist,
  getCreator,
  removeCreatorFromBlocklist,
  getCreatorWhitelists,
  creator,
  onDismiss,
  creatorWhitelists,
  isLoadingWhitelist,
}) => {
  useEffect(() => {
    if (slug) {
      getCreator(slug)
    }
  }, [slug])

  const userId = creator.get('user_id')
  useEffect(() => {
    if (userId) {
      getCreatorWhitelists(userId, creator.get('id'))
    }
  }, [userId])
  const unblocklistCreator = ([blockListId, creatorId]) => {
    removeCreatorFromBlocklist(blockListId, {
      profileId: creatorId,
    })
  }

  const blocklistCreator = ([profileId, creatorId]) => {
    addCreatorToBlocklist(
      {
        user_id: profileId,
      },
      { profileId: creatorId }
    )
  }

  if (!slug) {
    return null
  }

  if (slug && !creator) {
    return (
      <ModalConfirm open hideConfirm hideCancel>
        <Loading isLoading />
      </ModalConfirm>
    )
  }
  const { twitch, youtube } = getFollowersFromCreator(creator)
  const name = creator.get('name') || creator.get('username')
  const email = creator.get('email')
  const avatar = creator.get('avatar_url') || creator.get('avatar')
  const isBlocklisted = !!creator.get('blocklisted')
  const isWhitelisted = !!creatorWhitelists.size
  return (
    <ModalConfirm
      open={!!creator}
      onRequestClose={onDismiss}
      onCancel={onDismiss}
      onConfirmHref={`/creator/${creator.get('slug')}`}
      confirmText="Visit profile"
      cancelText="Dismiss"
    >
      <div className={styles.creatorMain}>
        <div className={styles.creatorImage}>
          {avatar ? <Image url={avatar} alt={name} /> : null}
        </div>
        <div>
          <h3 className={styles.name}>{name}</h3>
          <div className={styles.email}>{email}</div>
          <FollowersButtons twitch={twitch} youtube={youtube} />
        </div>
      </div>

      <div className={styles.action}>
        {isBlocklisted ? (
          <Button
            onClick={unblocklistCreator}
            onClickWith={[creator.getIn(['blocklisted', 'id']), creator.get('id')]}
            size="small"
            kind="secondaryBlue"
            isLoading={isLoadingBlocklistAction || isLoadingCreator}
          >
            Remove blocklist
          </Button>
        ) : (
          <Button
            onClick={blocklistCreator}
            onClickWith={[creator.get('user_id'), creator.get('id')]}
            kind="secondaryRed"
            size="small"
            isLoading={isLoadingBlocklistAction || isLoadingCreator}
          >
            Blocklist user
          </Button>
        )}
      </div>
      <div className={styles.action}>
        <Button
          onClick={browserHistory.push}
          onClickWith={`/creator/${slug}/videos/add-to-whitelist/${creator.get('id')}`}
          kind="secondaryBlue"
          size="small"
          disabled={isWhitelisted}
          isLoading={isLoadingWhitelist}
        >
          {!isWhitelisted ? (
            <>Add to Whitelist</>
          ) : (
            <>
              <Icon icon={faCheck} /> Whitelisted
            </>
          )}
        </Button>
      </div>
      <div className={styles.action}>
        <Button
          onClick={browserHistory.push}
          onClickWith={`/creator/${slug}/videos/send-offer`}
          kind="secondaryBlue"
          size="small"
        >
          Send an Offer
        </Button>
      </div>
    </ModalConfirm>
  )
}

CreatorAvatar.propTypes = {
  getCreator: PropTypes.func.isRequired,
  onDismiss: PropTypes.func,
  isLoadingBlocklistAction: PropTypes.bool.isRequired,
  isLoadingCreator: PropTypes.bool.isRequired,
  isLoadingWhitelist: PropTypes.bool.isRequired,
  removeCreatorFromBlocklist: PropTypes.func.isRequired,
  getCreatorWhitelists: PropTypes.func.isRequired,
  addCreatorToBlocklist: PropTypes.func.isRequired,
  creator: ImmutableProptypes.map.isRequired,
  creatorWhitelists: ImmutableProptypes.list.isRequired,
  slug: PropTypes.string,
}

CreatorAvatar.defaultProps = {
  slug: undefined,
  onDismiss: Function.prototype,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatorAvatar)
