import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import classnames from 'classnames'

import Input from '../input'
import Icon from '../piece-icon'
import Actionable from '../actionable'

import styles from './styles.css'

const ENTER_REACT_KEY_CODE = 'Enter'
class Search extends Component {
  static propTypes = {
    params: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
    placeholder: PropTypes.string,
    onSearch: PropTypes.func,
    onChange: PropTypes.func,
    className: PropTypes.string,
    value: PropTypes.string,
  }

  static defaultProps = {
    placeholder: 'Discover creators',
    onSearch: () => {},
    onChange: () => {},
    className: undefined,
    value: '',
  }

  constructor(props) {
    super(props)
    this.state = {
      searchTerm: props.params.search || this.props.value,
    }
  }

  handleSearchChange = (searchTerm) => {
    this.setState({ searchTerm })
    this.props.onChange(searchTerm)
  }

  handleKeyPress = ({ key }) => {
    if (key === ENTER_REACT_KEY_CODE) {
      this.props.onSearch(this.state.searchTerm)
    }
  }

  handleSearch = () => {
    this.props.onSearch(this.state.searchTerm)
  }

  render() {
    const { placeholder, className } = this.props
    const { searchTerm } = this.state
    return (
      <div className={classnames(className, styles.searchWrap)}>
        <Actionable onClick={this.handleSearch} className={styles.iconSearchWrap}>
          <Icon icon={faSearch} />
        </Actionable>
        <Input
          onChange={this.handleSearchChange}
          className={styles.searchInput}
          onKeyPress={this.handleKeyPress}
          name="search"
          placeholder={placeholder}
          value={searchTerm}
        />
      </div>
    )
  }
}

export default withRouter(Search)
