import React from 'react'
import { Route, IndexRedirect } from 'react-router'
import Loadable from 'react-loadable'

import Loading from 'components/piece-loading-async-route'

const loading = () => <Loading />

const CreatorAccountSettings = Loadable({
  loader: () =>
    import(/* webpackChunkName: "creatoraccountsettings" */ 'views/creator-account-settings'),
  loading,
})

const CreatorAccountSettingsAccounts = Loadable({
  loader: () =>
    import(/* webpackChunkName: "creatoraccountsettings" */ 'views/creator-account-settings/accounts'),
  loading,
})

const CreatorSettingsDisconnectAccountModal = Loadable({
  loader: () =>
    import(/* webpackChunkName: "creatoraccountsettings" */ 'views/creator-account-settings/disconnect-modal'),
  loading,
})

const CreatorAccountSettingsProfile = Loadable({
  loader: () =>
    import(/* webpackChunkName: "creatoraccountsettings" */ 'views/creator-account-settings/profile'),
  loading,
})

const CreatorAccountSettingsPreferences = Loadable({
  loader: () =>
    import(/* webpackChunkName: "creatoraccountsettings" */ 'views/creator-account-settings/preferences'),
  loading,
})

const CreatorAccountSettingsDelete = Loadable({
  loader: () =>
    import(/* webpackChunkName: "creatoraccountsettings" */ 'views/creator-account-settings/delete'),
  loading,
})

const Settings = () => (
  <Route path="/settings" component={CreatorAccountSettings}>
    <IndexRedirect to="profile" />
    <Route path="profile" component={CreatorAccountSettingsProfile} />
    <Route path="preferences" component={CreatorAccountSettingsPreferences} />
    <Route path="delete" component={CreatorAccountSettingsDelete} />
    <Route path="accounts" component={CreatorAccountSettingsAccounts}>
      <Route
        skipScrollUp
        path="disconnect/:platformType"
        component={CreatorSettingsDisconnectAccountModal}
      />
    </Route>
  </Route>
)

export default Settings
