import { Map, fromJS } from 'immutable'

import createReducer from '../../utils/create-reducer'

import { GET_USERS_STATS, GET_CREATORS_STATS } from './actions'

export const INITIAL_STATE = new Map()

export const stats = createReducer(INITIAL_STATE, {
  [GET_USERS_STATS.FULFILLED]: (state, { payload }) => state.set('users', fromJS(payload)),
  [GET_CREATORS_STATS.FULFILLED]: (state, { payload }) => state.set('creators', fromJS(payload)),
})
