const lower = (s = '') => {
  if (s === null) {
    return ''
  }
  if (typeof s === 'object') {
    throw new Error('cant lower an object')
  }
  return ((s && String(s)) || '').toLowerCase()
}
export default lower
