import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { faBookmark } from '@fortawesome/free-solid-svg-icons'
import { faBookmark as faBookmarkRegular } from '@fortawesome/free-regular-svg-icons'

import Actionable from '../actionable'
import Icon from '../piece-icon'

import styles from './styles.css'

const Bookmark = ({ onBookmark, bookmark, campaignId }) => {
  if (!onBookmark || !campaignId) {
    return null
  }
  if (bookmark) {
    const tooltip = 'Unbookmark'
    return (
      <>
        <Actionable
          onClick={onBookmark}
          onClickWith={[campaignId, bookmark]}
          data-tip={tooltip}
          className={classnames(styles.bookmark, styles.bookmarked)}
        >
          <Icon icon={faBookmark} />
        </Actionable>
      </>
    )
  }

  return (
    <Actionable
      onClick={onBookmark}
      onClickWith={[campaignId]}
      data-tip="Bookmark"
      className={styles.bookmark}
    >
      <Icon icon={faBookmarkRegular} />
    </Actionable>
  )
}

Bookmark.propTypes = {
  onBookmark: PropTypes.func,
  bookmark: PropTypes.string,
  campaignId: PropTypes.string,
}

Bookmark.defaultProps = {
  onBookmark: undefined,
  campaignId: undefined,
  bookmark: false,
}

export default Bookmark
