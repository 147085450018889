import { Map } from 'immutable'
import { normalize, schema } from 'normalizr'

import createReducer from '../../utils/create-reducer'

import {
  FETCH_INVITATIONS_WHITELISTS,
  REMOVE_INVITATION_WHITELIST,
  CREATE_INVITATION_WHITELIST,
} from './actions'

export const INITIAL_STATE = new Map()
export const invitationSchema = new schema.Entity('invitations')

const normalizeAndMergeDeep = (state, { payload }) => {
  const results = normalize(payload.results, [invitationSchema]).entities.invitations
  return state.mergeDeep(results)
}

export const invitations = createReducer(INITIAL_STATE, {
  [FETCH_INVITATIONS_WHITELISTS.FULFILLED]: normalizeAndMergeDeep,
  [REMOVE_INVITATION_WHITELIST.FULFILLED]: (state) => state,
  [CREATE_INVITATION_WHITELIST.FULFILLED]: (state, { payload }) => {
    const results = normalize(payload, invitationSchema).entities.invitations
    return state.mergeDeep(results)
  },
})
