import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles.css'

const withMessage = (message) => (
  <div>
    <div className={styles.loading} />
    <div className={styles.message}>{message}</div>
  </div>
)

const Loading = ({ isLoading, message, small }) => {
  if (isLoading) {
    return message ? (
      withMessage(message)
    ) : (
      <div className={classnames(styles.loading, { [styles.small]: small })} />
    )
  }
  return null
}

Loading.propTypes = {
  isLoading: PropTypes.bool,
  small: PropTypes.bool,
  message: PropTypes.string,
}

Loading.defaultProps = {
  isLoading: false,
  small: false,
  message: null,
}

export default Loading
