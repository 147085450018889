import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import classnames from 'classnames'

import WoovitSocial from '../woovit-social'

import styles from './styles.css'

const Footer = ({ sticky }) => (
  <div className={classnames(styles.footer, { [styles.sticky]: sticky })}>
    <div className={styles.contentWrapper}>
      <ul className={styles.sitemap}>
        <li className={styles.sitemapItem}>
          <Link className={styles.link} to="/about">
            About
          </Link>
        </li>
        <li className={styles.sitemapItem}>
          <Link className={styles.link} to="/for-creators">
            For Creators
          </Link>
        </li>
        <li className={styles.sitemapItem}>
          <Link className={styles.link} to="/for-publishers">
            For Publishers
          </Link>
        </li>
      </ul>
      <ul className={styles.sitemap}>
        <li className={styles.sitemapItem}>
          <a className={styles.link} href="https://blog.woovit.com/">
            Blog
          </a>
        </li>
        <li className={styles.sitemapItem}>
          <Link className={styles.link} to="/terms">
            Terms
          </Link>
        </li>
        <li className={styles.sitemapItem}>
          <Link className={styles.link} to="/privacy-policy">
            Privacy
          </Link>
        </li>
        <li className={styles.sitemapItem}>
          <Link className={styles.link} to="/search">
            Search
          </Link>
        </li>
        <li className={styles.sitemapItem}>
          <a className={styles.link} href="https://intercom.help/woovit">
            Support
          </a>
        </li>
      </ul>
      <div className={styles.social}>
        <WoovitSocial color="#ccc" />
      </div>
      <div className={styles.copy}>Copyright &copy; {new Date().getFullYear()} Woovit Inc. </div>
    </div>
  </div>
)

Footer.propTypes = {
  sticky: PropTypes.bool,
}

Footer.defaultProps = {
  sticky: false,
}

export default Footer
