import { Component } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'

import { SENTRY_DSN } from '../../config/environment'
import { setup, identify } from '../../utils/exception'

const mapDispatchToProps = ({ user }) => ({ user })

class Raven extends Component {
  static propTypes = {
    user: ImmutablePropTypes.map.isRequired,
  }

  componentDidMount() {
    setup(SENTRY_DSN, window.WOOVIT_VERSION)
    identify(this.props.user.toJS())
  }

  shouldComponentUpdate({ user }) {
    return !this.props.user.equals(user)
  }

  componentDidUpdate() {
    identify(this.props.user.toJS())
  }

  render() {
    return null
  }
}

const RavenVoid = () => null

export default (SENTRY_DSN ? connect(mapDispatchToProps)(Raven) : RavenVoid)
