import React from 'react'
import { Route, IndexRedirect, IndexRoute } from 'react-router'
import Loadable from 'react-loadable'

import Loading from '../../components/piece-loading-async-route'

const loading = () => <Loading />

const Whitelist = Loadable({
  loader: () => import(/* webpackChunkName: "publisherwhitelist" */ '../../views/whitelist'),
  loading,
})

const WhitelistItem = Loadable({
  loader: () => import(/* webpackChunkName: "publisherwhitelist" */ '../../views/whitelist-detail'),
  loading,
})

const WhitelistCreator = Loadable({
  loader: () =>
    import(/* webpackChunkName: "publisherwhitelist" */ '../../views/whitelist-creators'),
  loading,
})

const WhitelistInvitations = Loadable({
  loader: () =>
    import(/* webpackChunkName: "publisherwhitelist" */ '../../views/whitelist-invitations'),
  loading,
})

const WhitelistRoutes = () => (
  <Route path="/whitelists">
    <IndexRoute component={Whitelist} />
    <Route path=":whitelistid" component={WhitelistItem}>
      <IndexRedirect to="creator" />
      <Route path="creator" component={WhitelistCreator} />
      <Route path="invitations" component={WhitelistInvitations} />
    </Route>
  </Route>
)

export default WhitelistRoutes
