import React from 'react'
import './wdyr'
import { hot } from 'react-hot-loader/root'
import ReactDOM from 'react-dom'
import { Router, browserHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'
import { Provider } from 'react-redux'
import { fromJS } from 'immutable'

import { INITIAL_STATE } from './config/settings'
import configureStore from './store/configure-store'
import routes from './routes'
import './utils/check-version'

const store = configureStore(fromJS(window[INITIAL_STATE]).toObject())
const history = syncHistoryWithStore(browserHistory, store)

const Root = () => (
  <Provider store={store}>
    <Router history={history}>{routes(store)}</Router>
  </Provider>
)

const HotRoot = hot(Root)

ReactDOM.render(<HotRoot store={store} history={history} />, document.getElementById('root'))
