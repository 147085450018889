import React from 'react'
import { Route, IndexRedirect, Redirect } from 'react-router'
import Loadable from 'react-loadable'

import Loading from '../../components/piece-loading-async-route'

const loading = () => <Loading />

const AccountSettings = Loadable({
  loader: () =>
    import(/* webpackChunkName: "publisheraccountsettings" */ '../../views/publisher-account-settings'),
  loading,
})

const AccountSettingsProfile = Loadable({
  loader: () =>
    import(/* webpackChunkName: "publisheraccountsettings" */ '../../views/publisher-account-settings/profile'),
  loading,
})

const AccountSettingsEmail = Loadable({
  loader: () =>
    import(/* webpackChunkName: "publisheraccountsettings" */ '../../views/publisher-account-settings/email'),
  loading,
})

const AccountSettingsEmailRequested = Loadable({
  loader: () =>
    import(/* webpackChunkName: "publisheraccountsettings" */ '../../views/publisher-account-settings/email-requested'),
  loading,
})

const AccountSettingsEmailChanged = Loadable({
  loader: () =>
    import(/* webpackChunkName: "publisheraccountsettings" */ '../../views/publisher-account-settings/email-changed'),
  loading,
})

const AccountSettingsPassword = Loadable({
  loader: () =>
    import(/* webpackChunkName: "publisheraccountsettings" */ '../../views/publisher-account-settings/password'),
  loading,
})

const Settings = () => (
  <Route path="/publisher/account" component={AccountSettings}>
    <IndexRedirect to="profile" />
    <Redirect from="billing" to="/publisher/organization/billing" />
    <Route path="profile" component={AccountSettingsProfile} />
    <Route path="email" component={AccountSettingsEmail} />
    <Route path="email/requested" component={AccountSettingsEmailRequested} />
    <Route path="email/changed" component={AccountSettingsEmailChanged} />
    <Route path="password" component={AccountSettingsPassword} />
  </Route>
)

export default Settings
