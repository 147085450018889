import React from 'react'
import PropTypes from 'prop-types'
import { faXTwitter, faDiscord, faTwitch } from '@fortawesome/free-brands-svg-icons'

import Icon from '../piece-icon'

import styles from './styles.css'

const WoovitSocial = ({ color }) => (
  <div className={styles.container}>
    <a
      data-tip="Woovit on Discord"
      className={styles.socialLink}
      style={{
        color,
      }}
      href="http://discord.gg/pJQqMxQ"
      target="_blank"
      rel="noreferrer noopener"
    >
      <Icon className={styles.socialIcon} icon={faDiscord} />
    </a>
    <a
      data-tip="Woovit on X"
      className={styles.socialLink}
      href="https://twitter.com/woovitinfo"
      target="_blank"
      rel="noreferrer noopener"
      style={{
        color,
      }}
    >
      <Icon className={styles.socialIcon} icon={faXTwitter} />
    </a>

    <a
      data-tip="Woovit on Twitch"
      className={styles.socialLink}
      href="https://twitch.tv/woovit"
      target="_blank"
      rel="noreferrer noopener"
      style={{
        color,
      }}
    >
      <Icon className={styles.socialIcon} icon={faTwitch} />
    </a>
  </div>
)

WoovitSocial.propTypes = {
  color: PropTypes.string,
}

WoovitSocial.defaultProps = {
  color: '#f5a623',
}

export default WoovitSocial
