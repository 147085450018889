import PropTypes from 'prop-types'
import classnames from 'classnames'
import React from 'react'

import Icon from '../piece-icon'
import Label from '../piece-label'
import BlockFieldFeedback from '../block-field-feedback'
import Loading from '../piece-loading'

import styles from './styles.css'

const Field = ({
  label,
  type,
  id,
  disabled,
  isLoading,
  name,
  icon,
  customIcon,
  placeholder,
  value,
  min,
  max,
  rendered,
  note,
  maxLength,
  autoComplete,
  onChange,
  step,
  errorMessage,
  className,
  isImg, // eslint-disable-line no-unused-vars
  ...props
}) => {
  const inputId = id || name
  const parsedValue = value === null ? '' : value
  const inputWrapperClass = icon || customIcon ? 'inputWithIcon' : ''

  const handleOnChange = (event) => {
    if (onChange) onChange(event)
  }

  const inputProps = {
    label,
    type,
    id,
    min,
    max,
    name,
    placeholder,
    maxLength,
    value,
    disabled,
    onChange,
    autoComplete,
    step,
    ...props,
  }

  return (
    <div className={classnames(className, styles.wrapper)}>
      {label && <Label id={inputId} label={label} />}
      {rendered ? (
        <span>{parsedValue}</span>
      ) : (
        <div className={classnames(styles[inputWrapperClass], styles.relative)}>
          {icon && (
            <div className={styles.icon}>
              <Icon icon={icon} />
            </div>
          )}
          {customIcon && <div className={styles.customIcon}>{customIcon}</div>}
          <input
            {...inputProps}
            className={styles.field}
            type={type}
            name={name}
            id={inputId}
            value={parsedValue}
            placeholder={placeholder}
            onChange={handleOnChange}
          />
          {isLoading ? (
            <span className={styles.loading}>
              <Loading small isLoading />
            </span>
          ) : null}
        </div>
      )}

      {note && <div className={styles.note}>{note}</div>}

      {errorMessage && <BlockFieldFeedback errorMessage={errorMessage} />}
    </div>
  )
}

Field.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  maxLength: PropTypes.string,
  step: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  rendered: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  icon: PropTypes.instanceOf(Object),
  customIcon: PropTypes.node,
  placeholder: PropTypes.string,
  note: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func,
  errorMessage: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.node]),
  autoComplete: PropTypes.string,
  isImg: PropTypes.bool,
  className: PropTypes.string,
}

Field.defaultProps = {
  rendered: false,
  id: undefined,
  disabled: false,
  isLoading: false,
  maxLength: undefined,
  min: undefined,
  max: undefined,
  label: undefined,
  step: undefined,
  value: undefined,
  icon: undefined,
  placeholder: undefined,
  note: undefined,
  onChange: undefined,
  errorMessage: undefined,
  isImg: false,
  customIcon: null,
  autoComplete: undefined,
  className: undefined,
}

export default Field
