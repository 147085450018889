import React from 'react'
import PropTypes from 'prop-types'

const SIZABLE_CODE_COUNT = 25

const UnclaimedCodes = ({ codeCount }) => {
  if (codeCount > SIZABLE_CODE_COUNT) {
    return <>Over 25 codes left</>
  }
  if (codeCount === 1) {
    return <>Only 1 code left, get yours now!</>
  }
  return (
    <>
      Only <var>{codeCount}</var> codes left, get yours now!
    </>
  )
}
UnclaimedCodes.propTypes = {
  codeCount: PropTypes.number.isRequired,
}

export default UnclaimedCodes
