import { Map, fromJS } from 'immutable'
import { schema, normalize } from 'normalizr'

import createReducer from '../../utils/create-reducer'

import { SUBSCRIBE, GET_CARD, GET_BILLING, REMOVE_CARD } from './actions'

export const INITIAL_STATE = new Map()

export const subscriptionSchema = new schema.Entity('subscription')
export const billingSchema = new schema.Entity('billing')

export const plans = createReducer(INITIAL_STATE, {
  [SUBSCRIBE.FULFILLED]: (state, { payload }) => state.mergeDeep(payload),
})

export const cards = createReducer(INITIAL_STATE, {
  [GET_CARD.FULFILLED]: (state, { payload }) => state.mergeDeep(payload),
  [REMOVE_CARD.FULFILLED]: () => INITIAL_STATE,
})

export const billing = createReducer(INITIAL_STATE, {
  [GET_BILLING.FULFILLED]: (state, { payload }) =>
    state.mergeDeep(fromJS(normalize(payload.results, [billingSchema]).entities.billing)),
})
