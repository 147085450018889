import { Map, fromJS } from 'immutable'
import { normalize, schema } from 'normalizr'

import createReducer from '../../utils/create-reducer'
import { CLAIM_OFFER } from '../proactive/actions'

import {
  ACCEPT_OFFER,
  DELIVER_OFFER,
  ARCHIVE_OFFER,
  UNARCHIVE_OFFER,
  GET_OFFER_DETAILS,
  GET_ACCEPTED_OFFERS,
  GET_DELIVERED_OFFERS,
  GET_ARCHIVED_OFFERS,
  CLEAR_NEW_OFFER,
  GET_OFFER_VIDEO_REQUESTS,
} from './actions'

export const INITIAL_STATE = new Map()

export const offerSchema = new schema.Entity('offer')

const mergeOffer = (state, { payload }) =>
  state.mergeDeep(normalize(payload, offerSchema).entities.offer)

const mergeOffers = (state, { payload }) =>
  state.mergeDeep(normalize(payload.results, [offerSchema]).entities.offer)

export const offer = createReducer(INITIAL_STATE, {
  [GET_OFFER_VIDEO_REQUESTS.FULFILLED]: (state, { payload, meta }) =>
    state.setIn([String(meta.offerId), 'pendingVideoRequestCount'], payload.count),

  [GET_OFFER_DETAILS.FULFILLED]: mergeOffer,

  [ACCEPT_OFFER.FULFILLED]: (state, { payload }) => {
    const newOffer = normalize(payload, offerSchema).entities.offer
    return state.mergeDeep(newOffer).set('newOffer', fromJS(payload))
  },
  [CLAIM_OFFER.FULFILLED]: (state, { payload }) => {
    const newOffer = normalize(payload, offerSchema).entities.offer
    return state.mergeDeep(newOffer).set('newOffer', fromJS(payload))
  },

  [DELIVER_OFFER.FULFILLED]: mergeOffer,

  [ARCHIVE_OFFER.FULFILLED]: mergeOffer,

  [UNARCHIVE_OFFER.FULFILLED]: mergeOffer,

  [GET_ACCEPTED_OFFERS.FULFILLED]: mergeOffers,
  [GET_DELIVERED_OFFERS.FULFILLED]: mergeOffers,
  [GET_ARCHIVED_OFFERS.FULFILLED]: mergeOffers,

  [CLEAR_NEW_OFFER]: (state) => state.delete('newOffer'),
})
