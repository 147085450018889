import React from 'react'
import PropTypes from 'prop-types'

import { PLATFORM_YOUTUBE, PLATFORM_TWITCH, PLATFORM_STEAM } from '../../config/settings'

import { Youtube, Twitch, Steam } from '.'

const PLATFORM_TO_COMPONENT = {
  [PLATFORM_YOUTUBE]: Youtube,
  [PLATFORM_TWITCH]: Twitch,
  [PLATFORM_STEAM]: Steam,
}

const Provider = ({ platform, ...otherProps }) => {
  const Component = PLATFORM_TO_COMPONENT[platform]
  return <Component {...otherProps} />
}

Provider.propTypes = {
  platform: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default Provider
