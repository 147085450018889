import React from 'react'
import { PropTypes } from 'prop-types'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import Icon from '../piece-icon'

import styles from './styles.css'

const Legend = ({ children, tooltip }) => (
  <span className={styles.container}>
    {children}
    {tooltip ? (
      <span className={styles.iconWrapper} data-tip={tooltip}>
        <Icon icon={faInfoCircle} />
      </span>
    ) : null}
  </span>
)

Legend.propTypes = {
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.string,
}

Legend.defaultProps = {
  tooltip: undefined,
}

export default Legend
