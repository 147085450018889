import { combineReducers } from 'redux'
import { routerReducer as routing } from 'react-router-redux'

import { INITIAL_STATE } from '../config/settings'

import { USER_SIGNOUT } from './user/actions'
import {
  user,
  cookieConsentAccepted,
  organizations,
  organizationInvitations,
} from './user/reducers'
import { offer } from './offer/reducers'
import {
  campaign,
  featuredCampaigns,
  campaignOffer,
  campaignOfferPagination,
  campaignOffers,
  discoverCreators,
  campaignOfferCache,
  campaignMatchingVideos,
} from './campaign/reducers'
import { activityFeedEvents, activityFeedEventsPagination } from './activity-feed/reducers'
import { newCampaign, slugUniqueness } from './new-campaign/reducers'
import { uiAlert } from './ui-alert/reducers'
import { uiForm } from './ui-form/reducers'
import {
  creator,
  creatorSearch,
  creatorNotes,
  selectedCreators,
  creatorPlayedGames,
  creatorSteamPlayedGames,
  creatorDeliveryStats,
  creatorWhitelists,
  similarCreators,
} from './creator/reducers'
import { game, gogGame, twitchGame } from './game/reducers'
import { estimator } from './estimator/reducers'
import { uiCampaignWizard } from './ui-campaign-wizard/reducers'
import { stats } from './stats/reducers'
import { plans, cards, billing } from './plan/reducers'
import { manualReview } from './manual-review/reducers'
import { pagination } from './pagination/reducers'
import { whitelists, whitelist } from './whitelist/reducers'
import { streamers, streamersSearch } from './streamers/reducers'
import { invitations } from './invitations/reducers'
import { featuredVideos, video } from './video/reducers'
import { error } from './error/reducers'
import { loading } from './loading/reducers'
import { offline } from './offline/reducers'
import { publisher, organizationMembers, organizationInvites } from './publisher/reducers'
import { campaignVideo, campaignVideoReport, campaignReports } from './report/reducers'
import { blocklistedCreators } from './blocklist/reducers'

const appReducer = combineReducers({
  billing,
  campaign,
  cards,
  creator,
  creatorSearch,
  creatorNotes,
  selectedCreators,
  error,
  estimator,
  featuredCampaigns,
  campaignOffer,
  campaignOfferPagination,
  campaignOffers,
  discoverCreators,
  campaignVideo,
  campaignVideoReport,
  campaignReports,
  campaignOfferCache,
  cookieConsentAccepted,
  activityFeedEvents,
  activityFeedEventsPagination,
  game,
  gogGame,
  twitchGame,
  invitations,
  manualReview,
  newCampaign,
  offer,
  video,
  pagination,
  plans,
  routing,
  stats,
  streamers,
  streamersSearch,
  slugUniqueness,
  uiAlert,
  uiCampaignWizard,
  uiForm,
  user,
  whitelists,
  whitelist,
  featuredVideos,
  offline,
  publisher,
  organizationInvites,
  organizationMembers,
  blocklistedCreators,
  loading,
  organizations,
  organizationInvitations,
  creatorPlayedGames,
  creatorSteamPlayedGames,
  creatorDeliveryStats,
  creatorWhitelists,
  similarCreators,
  campaignMatchingVideos,
})

const rootReducer = (state, action) => {
  if (action.type === USER_SIGNOUT) {
    if (typeof window !== 'undefined') {
      window[INITIAL_STATE] = null
    }
    return appReducer({ cookieConsentAccepted: state.cookieConsentAccepted }, action)
  }

  return appReducer(state, action)
}

export default rootReducer
