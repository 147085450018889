import React from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import { faHome, faCaretRight } from '@fortawesome/free-solid-svg-icons'

import Icon from '../piece-icon'

import styles from './styles.css'

const Breadcrumb = ({ children, paths }) => (
  <div className={styles.container}>
    <Link className={styles.breadcrumb} to="/">
      <Icon className={styles.icon} icon={faHome} /> Home
    </Link>
    {paths.map(([href, title]) => (
      <Link key={href} className={styles.breadcrumb} to={href}>
        <Icon className={styles.icon} icon={faCaretRight} />
        {title}
      </Link>
    ))}
    <span className={styles.current}>
      <Icon className={styles.icon} icon={faCaretRight} />
      {children}
    </span>
  </div>
)

Breadcrumb.propTypes = {
  children: PropTypes.node,
  paths: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string, PropTypes.string)),
}

Breadcrumb.defaultProps = {
  paths: [],
  children: undefined,
}

export default Breadcrumb
