import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles.css'

const BlockFieldFeedback = ({ errorMessage }) => (
  <div className={styles.blockFieldFeedback}>
    {Array.isArray(errorMessage) ? (
      <ul className={styles.feedback}>
        {errorMessage.map((error) => (
          <li key={`error-${error}`} className={styles.feedbackItem}>
            {error}
          </li>
        ))}
      </ul>
    ) : (
      <div className={styles.feedbackItem}>{errorMessage}</div>
    )}
  </div>
)

BlockFieldFeedback.propTypes = {
  errorMessage: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.node]),
}

BlockFieldFeedback.defaultProps = {
  errorMessage: [],
}

export default BlockFieldFeedback
