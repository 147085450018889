import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles.css'

const SelectableButton = ({ value, text, handleSelect, isSelected }) => (
  <button
    type="button"
    onClick={handleSelect}
    className={classnames(styles.selectableButton, {
      [styles.selected]: isSelected,
    })}
    value={value}
  >
    {text}
  </button>
)

SelectableButton.propTypes = {
  value: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  handleSelect: PropTypes.func.isRequired,
}

export default SelectableButton
