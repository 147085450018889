import PropTypes from 'prop-types'
import shallowequal from 'shallowequal'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ImmutableProptypes from 'react-immutable-proptypes'
import { Helmet } from 'react-helmet'

import { getPublicCampaigns, GET_FEATURED_CAMPAIGNS } from '../../modules/campaign/actions'
import Button from '../../components/button'
import OfferCard from '../../components/offer-card'
import Loading from '../../components/piece-loading'
import Pagination from '../../components/pagination'
import { selectPage, selectPageResults } from '../../modules/pagination/selectors'
import { newHandleQuery } from '../../utils/handle-query'

import styles from './styles.css'

const DEFAULT_CAMPAIGN_LIMIT = 20
const DEFAULT_CAMPAIGN_PARAMS = { limit: DEFAULT_CAMPAIGN_LIMIT, offset: 0 }

const mapStateToProps = (state, { location }) => {
  const offers = selectPage(state, GET_FEATURED_CAMPAIGNS.ACTION, {
    ...DEFAULT_CAMPAIGN_PARAMS,
    ...location.query,
  })
  return {
    featured: selectPageResults(offers, state.featuredCampaigns),
    isLoading: !!state.loading.get(GET_FEATURED_CAMPAIGNS.ACTION),
    pagination: offers,
  }
}

const mapDispatchToProps = {
  getPublicCampaigns,
}

class PublicOffers extends Component {
  static propTypes = {
    featured: ImmutableProptypes.list.isRequired,
    getPublicCampaigns: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    location: PropTypes.shape({
      query: PropTypes.shape({
        offset: PropTypes.string,
      }),
    }).isRequired,
    pagination: ImmutableProptypes.map.isRequired,
  }

  componentDidMount() {
    this.props.getPublicCampaigns({ ...DEFAULT_CAMPAIGN_PARAMS, ...this.props.location.query })
  }

  componentDidUpdate(oldProps) {
    const { location } = this.props
    if (!shallowequal(oldProps.location.query, location.query)) {
      this.props.getPublicCampaigns({ ...DEFAULT_CAMPAIGN_PARAMS, ...location.query })
    }
  }

  renderOffer = (item) => {
    const href = `/offer/${item.get('slug')}`
    return (
      <div key={item.hashCode()} className={styles.offer}>
        <OfferCard
          title={item.get('title') || item.get('steam_game_name')}
          isPaid={item.get('paid')}
          image={item.get('cover_url')}
          publisher={item.getIn(['organization', 'name']) || item.get('steam_game_publisher')}
          platforms={item.get('platforms').toArray()}
          href={href}
        >
          <Button className={styles.offerButton} href={href}>
            More Info
          </Button>
        </OfferCard>
      </div>
    )
  }

  handlePagination = ({ value: offset }) => {
    newHandleQuery({
      offset,
    })
  }

  render() {
    const { featured, isLoading, location, pagination } = this.props
    const offset = location.query.offset && Number(location.query.offset)
    return (
      <div className={styles.blockList}>
        <Helmet>
          <title>All Offers and Games — Woovit</title>
        </Helmet>

        <Loading isLoading={isLoading && !featured.size} />

        <ul className={styles.blockList}>{featured.map(this.renderOffer)}</ul>

        {pagination.get('results') ? (
          <div className={styles.paginationWrap}>
            <Pagination
              count={pagination.get('count')}
              prev={pagination.get('prev')}
              next={pagination.get('next')}
              onClick={this.handlePagination}
              current={offset}
              pageSize={DEFAULT_CAMPAIGN_LIMIT}
            />
          </div>
        ) : null}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicOffers)
