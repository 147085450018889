import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { browserHistory, Link } from 'react-router'

import logo from 'img/logo-woovit.svg'
import Header from 'components/header'
import Svg from 'components/svg'
import Search from 'components/search-input'
import { getUserByToken } from 'modules/user/actions'
import AuthBar from 'components/auth-bar'

import styles from './styles.css'

const mapStateToProps = ({ user }) => ({ user })
const mapDispatchToProps = {
  getUserByToken,
}

class HeaderPublic extends Component {
  static propTypes = {
    user: ImmutablePropTypes.map.isRequired,
    getUserByToken: PropTypes.func.isRequired,
  }

  componentDidMount() {
    if (this.props.user.get('auth_token')) {
      this.props.getUserByToken()
    }
  }

  onSearch = (searchTerm) => {
    browserHistory.push(`/search/${encodeURIComponent(searchTerm)}`)
  }

  render() {
    const { user } = this.props
    if (user.get('auth_token')) {
      return (
        <div className={styles.userHeader}>
          <Header />
        </div>
      )
    }
    return (
      <header className={styles.header}>
        <div className={styles.container}>
          <div className={styles.logo}>
            <Link to="/">
              <Svg
                width={null}
                height={null}
                svg={logo}
                alt="Woovit Logo"
                className={styles.logoSvg}
              />
            </Link>
          </div>

          <div className={styles.actionsWrapper}>
            <Search placeholder="Type a creator or game name" onSearch={this.onSearch} />
            <AuthBar />
          </div>
        </div>
      </header>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderPublic)
