import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import ActivityFeed from 'components/activity-feed'
import ActivityFeedFilter from 'components/activity-feed-filter'
import useActivityFeedFilter from 'hooks/use-activity-feed-filter'
import { ORGANIZATION_ACTIVITIES_FILTER_OPTIONS } from 'config/activity-feed'
import sortByProperty from 'utils/sort-by-property'

import styles from './styles.css'

const FILTER_NAMESPACE = 'organization/feed'

const sortedActivitiesFilter = sortByProperty(ORGANIZATION_ACTIVITIES_FILTER_OPTIONS, 'label')

const mapStateToProps = ({ user }) => {
  const organizationId = user.getIn(['organization', 'id'])
  return {
    organizationId,
  }
}

const ViewActivityFeed = ({ organizationId, location: { query } }) => {
  const { offset, ...queryFilters } = query
  const [onResetFilter, filters, setFilters] = useActivityFeedFilter({
    initialFilters: queryFilters,
    filterPath: FILTER_NAMESPACE,
  })

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.feedWrapper}>
        <ActivityFeedFilter
          className={styles.feedFilter}
          onResetFilter={onResetFilter}
          filters={filters}
          setFilters={setFilters}
          hasCampaignFilter
          activitiesFilterOptions={sortedActivitiesFilter}
        />
        <ActivityFeed
          className={styles.feedContainer}
          onResetFilter={onResetFilter}
          query={query}
          params={organizationId && { organization: organizationId }}
          expanded
        />
      </div>
    </div>
  )
}

ViewActivityFeed.propTypes = {
  organizationId: PropTypes.number,
  location: PropTypes.shape({
    query: PropTypes.shape({
      offset: PropTypes.string,
    }),
  }).isRequired,
}

ViewActivityFeed.defaultProps = {
  organizationId: undefined,
}

export default connect(mapStateToProps)(ViewActivityFeed)
