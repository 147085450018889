import React from 'react'
import ContentLoader from 'react-content-loader'

import styles from './styles.css'

const Loader = () => (
  <div className={styles.container}>
    <ContentLoader
      speed={2}
      viewBox="0 0 1120 150"
      backgroundColor="#dadada"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="0" ry="0" width="280" height="150" />
      <rect x="320" y="10" rx="0" ry="0" width="250" height="16" />
      <rect x="320" y="35" rx="0" ry="0" width="150" height="10" />
      <rect x="320" y="97" rx="0" ry="0" width="80" height="12" />
      <rect x="320" y="125" rx="0" ry="0" width="23" height="23" />
      <rect x="347" y="125" rx="0" ry="0" width="23" height="23" />
      <rect x="376" y="125" rx="0" ry="0" width="23" height="23" />
      <rect x="405" y="125" rx="0" ry="0" width="23" height="23" />
      <rect x="910" y="0" rx="2" ry="2" width="190" height="48" />
      <rect x="745" y="115" rx="2" ry="2" width="305" height="35" />
      <rect x="1065" y="120" rx="2" ry="2" width="23" height="23" />
    </ContentLoader>
  </div>
)

export default Loader
