export const defaultStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 10,
  }),
  control: (provided) => ({ ...provided, cursor: 'pointer' }),
  option: (provided) => {
    return {
      ...provided,
      color: '#000',
      cursor: 'pointer',
    }
  },
  indicatorSeparator: (provided) => ({
    ...provided,
    background: 'none',
  }),
}

export const darkStyles = {
  menu: (provided) => ({
    ...provided,
    background: '#344d68',
    border: 0,
    color: '#fff',
    zIndex: 10,
  }),
  option: (provided, { isFocused, isSelected }) => {
    return {
      ...provided,
      // eslint-disable-next-line no-nested-ternary
      backgroundColor: isSelected ? '#46678c' : isFocused ? '#1a314c' : '#344d68',
      color: '#fff',
      cursor: 'pointer',
    }
  },
  indicatorSeparator: (provided) => ({
    ...provided,
    background: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#fff',
    '&:hover': {
      color: '#fff',
    },
  }),
  input: (provided) => ({
    ...provided,
    color: '#fff',
    lineHeight: '28px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    color: '#fff',
  }),
  control: (provided) => ({
    ...provided,
    background: '#344d68',
    border: 0,
    cursor: 'pointer',
  }),
  placeholder: (provided, { isFocused }) => ({
    ...provided,
    color: isFocused ? 'rgba(255, 255, 255, 0.5)' : '#fff',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#fff',
  }),
}
