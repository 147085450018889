import React from 'react'
import { components } from 'react-select'
import makeAnimated from 'react-select/animated'
import PropTypes from 'prop-types'

import Label from '../piece-label'
import Select from '../piece-field-select'

const animatedComponents = makeAnimated()

const Option = ({ isSelected, label, ...props }) => (
  <div>
    <components.Option {...props}>
      <input type="checkbox" checked={isSelected} onChange={() => null} />
      <span style={{ marginLeft: '10px' }}>{label}</span>
    </components.Option>
  </div>
)

Option.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
}

const ValueContainer = ({ children, hasValue, ...props }) => {
  if (!hasValue) return <components.ValueContainer {...props}>{children}</components.ValueContainer>

  return (
    <components.ValueContainer {...props}>
      {[[children[0][0]], children[1]]}
    </components.ValueContainer>
  )
}

ValueContainer.propTypes = {
  children: PropTypes.node.isRequired,
  hasValue: PropTypes.bool.isRequired,
}

const MultiCheckboxSelect = ({
  allOption,
  allValueDisplay,
  options,
  onChange,
  label,
  ...props
}) => {
  const allOptions = [allOption, ...options]

  const MultiValue = ({ getValue, options: multiValueOptions, data }) => {
    const values = getValue()
    let labelToBeDisplayed = `${values.length} of ${multiValueOptions.length} selected`
    if (data.value === allOption.value) {
      labelToBeDisplayed = allValueDisplay
    }

    return <div>{labelToBeDisplayed}</div>
  }

  MultiValue.propTypes = {
    getValue: PropTypes.func.isRequired,
    options: PropTypes.instanceOf(Array).isRequired,
    data: PropTypes.shape({
      value: PropTypes.string,
    }).isRequired,
  }

  const getValue = (value) => {
    if (!value) {
      return []
    }
    return allOptions.filter((o) => value.includes(o.value))
  }

  return (
    <>
      {label && <Label label={label} />}

      <Select
        closeMenuOnSelect={false}
        isSearchable={false}
        isMulti
        isClearable={false}
        hideSelectedOptions={false}
        options={allOptions}
        getValue={getValue}
        onChange={(selected, name, { action }) => {
          if (selected !== null && selected.length > 0) {
            if (selected[selected.length - 1].value === allOption.value) {
              return onChange(allOptions, name)
            }
            let result = []
            if (selected.length === options.length) {
              if (selected.includes(allOption)) {
                result = selected.filter((option) => option.value !== allOption.value)
              } else if (action === 'select-option') {
                result = allOptions
              }
              return onChange(result, name)
            }
          }

          return onChange(selected, name)
        }}
        components={{
          Option,
          MultiValue,
          ValueContainer,
          animatedComponents,
        }}
        {...props}
      />
    </>
  )
}

MultiCheckboxSelect.propTypes = {
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  allValueDisplay: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  onChange: PropTypes.func,
  label: PropTypes.string,
}

MultiCheckboxSelect.defaultProps = {
  allOption: {
    label: 'All',
    value: '*',
  },
  allValueDisplay: 'All',
  options: [],
  onChange: () => {},
  label: '',
}

export default MultiCheckboxSelect
