import React from 'react'
import PropTypes from 'prop-types'
import { faCookieBite } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router'
import { connect } from 'react-redux'

import { acceptCookieConsent as acceptCookieConsentAction } from '../../modules/user/actions'
import Button from '../piece-button'
import Icon from '../piece-icon'

import styles from './styles.css'

const MESSAGE =
  'This website uses cookies to improve your experience. By using Woovit, you agree with our'

const mapStateToProps = ({ cookieConsentAccepted }) => ({ cookieConsentAccepted })

const mapDispatchToProps = {
  acceptCookieConsent: acceptCookieConsentAction,
}

export const CookieConsent = ({ cookieConsentAccepted, acceptCookieConsent }) => {
  const cssClass = !cookieConsentAccepted ? styles.containerVisible : styles.container

  return (
    <div className={cssClass}>
      <p className={styles.containerMessage}>
        <span>
          <Icon className={styles.icon} icon={faCookieBite} />
        </span>
        {MESSAGE}{' '}
        <Link
          to="/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.creator}
        >
          Cookies Policy
        </Link>
      </p>
      <Button onClick={acceptCookieConsent}>Understood</Button>
    </div>
  )
}

CookieConsent.propTypes = {
  cookieConsentAccepted: PropTypes.bool.isRequired,
  acceptCookieConsent: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CookieConsent)
