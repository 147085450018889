import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import ImmutableProptypes from 'react-immutable-proptypes'
import { IntercomAPI } from 'react-intercom'
import { browserHistory } from 'react-router'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import Image from '../../components/block-image'
import Svg from '../../components/svg'
import Actionable from '../../components/actionable'
import Icon from '../../components/piece-icon'
import Button from '../../components/button'
import * as statsService from '../../services/stats'
import {
  PREMIUM_PRICE,
  FREE_FEATURES,
  PREMIUM_FEATURES,
  MAXIMUM_FREE_ACTIVE_CAMPAIGNS,
  AGENCY_FEATURES,
} from '../../config/settings'
import { stats as statsReducer } from '../../modules/stats/reducers'
import { getUsersStats, GET_USERS_STATS } from '../../modules/stats/actions'

import reports from './publisher/reports.svg'
import wizard from './publisher/wizard.svg'
import platforms from './publisher/platforms.png'
import styles from './styles.css'

const numberToLocale = (number = '-') => number.toLocaleString()

const mapStateToProps = ({ stats }) => ({
  stats: stats.get('users', new Map()),
})

const mapDispatchToProps = {
  getUsersStats,
}

class Publisher extends Component {
  static propTypes = {
    getUsersStats: PropTypes.func.isRequired,
    stats: ImmutableProptypes.map.isRequired,
  }

  static getData() {
    return [
      statsService
        .getUsersStats()()
        .then((payload) => ({
          stats: statsReducer(undefined, {
            type: GET_USERS_STATS.FULFILLED,
            payload,
          }),
        })),
    ]
  }

  componentDidMount() {
    this.props.getUsersStats()
  }

  handleSignup = () => {
    browserHistory.push('/signup/publisher')
  }

  render() {
    const { stats } = this.props
    return (
      <div className={styles.container}>
        <div className={styles.head}>
          <div className={styles.headBg}>
            <h1>Woovit for Publishers</h1>
            <div className={styles.mission}>Simplify and scale your influencer marketing.</div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.spacer} />
          <p>
            Key distribution is a formidable challenge for game studios and publishers of all sizes,
            and you have only so much time to spend on it.
          </p>
          <p>
            Woovit is a network of {numberToLocale(stats.get('creators'))}+ real creators (and
            growing daily!) with validated channels and up-to-date data. Publishers can offer keys
            to these creators — or automate invitations to new ones.
          </p>

          <h2>Authenticated Creators</h2>
          <div className={styles.flex}>
            <Image className={styles.platforms} url={platforms} alt="platforms" />
            <p className={styles.authedCreators}>
              Woovit is improving influencer management by requiring creators to sign up with their
              real YouTube and/or Twitch accounts. To qualify for offers, these channels must meet
              YouTube Partner qualifications and/or have Twitch Affiliate/Partner status (or be
              invited by a publisher via whitelists) and a following that is majority real users.
              Creators will have a quota of three keys, which are replenished with successful video
              deliveries.
            </p>
          </div>

          <hr />

          <h2>Set Qualifications</h2>

          <p>
            Stop crawling through tons of requests from creators with no audience or no good
            intentions. As a publisher, you set minimum qualifications that a creator must meet to
            automatically get a key — or use a whitelist so anyone you trust can get products from
            you instantly.
          </p>
          <div className={styles.qualification}>
            <div className={styles.wizardWrapper}>
              <Svg
                className={styles.wizard}
                svg={wizard}
                width="396"
                height="214"
                alt="campaign wizard"
              />
            </div>
            <div>
              <h3>Code distribution made easy</h3>
              <p>
                From describing your offer to setting notifications, manage every process of key
                distribution.
              </p>

              <div className={styles.checks}>
                <div className={styles.check}>
                  <span className={styles.icon}>
                    <Icon icon={faCheck} />
                  </span>
                  Campaign Type
                </div>
                <div className={styles.check}>
                  <span className={styles.icon}>
                    <Icon icon={faCheck} />
                  </span>
                  Code Distribution
                </div>
                <div className={styles.check}>
                  <span className={styles.icon}>
                    <Icon icon={faCheck} />
                  </span>
                  Describe Offer
                </div>
                <div className={styles.check}>
                  <span className={styles.icon}>
                    <Icon icon={faCheck} />
                  </span>
                  Whitelists & Blocklists
                </div>
                <div className={styles.check}>
                  <span className={styles.icon}>
                    <Icon icon={faCheck} />
                  </span>
                  Upload Codes
                </div>
                <div className={styles.check}>
                  <span className={styles.icon}>
                    <Icon icon={faCheck} />
                  </span>
                  Platform Requirements
                </div>
                <div className={styles.check}>
                  <span className={styles.icon}>
                    <Icon icon={faCheck} />
                  </span>
                  Distribution Timeframe
                </div>
                <div className={styles.check}>
                  <span className={styles.icon}>
                    <Icon icon={faCheck} />
                  </span>
                  Terms & Conditions
                </div>
                <div className={styles.check}>
                  <span className={styles.icon}>
                    <Icon icon={faCheck} />
                  </span>
                  Notifications
                </div>
                <div className={styles.check}>
                  <span className={styles.icon}>
                    <Icon icon={faCheck} />
                  </span>
                  Review & Submit
                </div>
              </div>
            </div>
          </div>

          <hr />
          <h2>You build relationships, we&apos;ll take care of the spreadsheets</h2>
          <p>
            Tens of millions of creators are making videos globally. Who are you missing? Spending
            too much time watching videos? Woovit automates the reporting of key deliveries and
            delivery of videos so you don&apos;t have to.
          </p>

          <div className={styles.portfolio}>
            <div>
              <h3>Reporting done seamlessly</h3>
              <p>
                In a click of a button, aggregate information on those participating in your Woovit
                campaign instantly.
              </p>

              <div className={styles.checks}>
                <div className={styles.check}>
                  <span className={styles.icon}>
                    <Icon icon={faCheck} />
                  </span>
                  Total Videos
                </div>
                <div className={styles.check}>
                  <span className={styles.icon}>
                    <Icon icon={faCheck} />
                  </span>
                  Total Views
                </div>
                <div className={styles.check}>
                  <span className={styles.icon}>
                    <Icon icon={faCheck} />
                  </span>
                  Concurrent Views
                </div>
                <div className={styles.check}>
                  <span className={styles.icon}>
                    <Icon icon={faCheck} />
                  </span>
                  Total Keys Distributed
                </div>
                <div className={styles.check}>
                  <span className={styles.icon}>
                    <Icon icon={faCheck} />
                  </span>
                  Subscriber/Follower count
                </div>
              </div>
            </div>
            <Svg width="523px" height="251px" svg={reports} alt="portfolio" />
          </div>
          <div className={styles.spacer} />
          <p>
            Both you and the creators benefit from the automation of distribution and reporting.
            Upgrade to a premium account to manually review key requests.
          </p>

          <hr />
          <h2>Start your campaign with Woovit</h2>
          <p>
            Sign up for free, or set your campaigns for success with our premium option for $199 a
            month.
          </p>

          <div className={styles.plans}>
            <div className={styles.plan}>
              <div className={styles.planHead}>
                <h3 className={styles.planType}>Free</h3>
                <p className={styles.activeCampaigns}>
                  <span>{MAXIMUM_FREE_ACTIVE_CAMPAIGNS}</span> active campaign
                </p>
                <p>
                  <span className={styles.price}>$0</span>/month
                </p>
                <Button onClick={this.handleSignup}>Sign Up for free</Button>
              </div>
              <div className={styles.planFeatures}>
                {FREE_FEATURES.map((feature, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index}>{feature}</div>
                ))}
              </div>
            </div>

            <div className={styles.plan}>
              <div className={styles.planHead}>
                <h3 className={styles.planType}>Premium</h3>
                <p className={styles.activeCampaigns}>
                  <span>Unlimited</span> active campaigns
                </p>
                <p>
                  <span className={styles.price}>${PREMIUM_PRICE}</span>/month
                </p>
                <Button onClick={this.handleSignup}>Sign Up Now</Button>
              </div>
              <div className={styles.planFeatures}>
                {PREMIUM_FEATURES.map((feature, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index}>{feature}</div>
                ))}
              </div>
            </div>

            <div className={styles.plan}>
              <div className={styles.planHead}>
                <h3 className={styles.planType}>Agency Support</h3>
                <p className={styles.activeCampaigns}>
                  <span>Bespoke</span>
                </p>
                <p>
                  <span className={styles.price}>$TBD</span>
                </p>
                <Button href="mailto:hello@woovit.com" external>
                  Contact Us
                </Button>
              </div>
              <div className={styles.planFeatures}>
                {AGENCY_FEATURES.map((feature, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index}>{feature}</div>
                ))}
              </div>
            </div>
          </div>

          <div className={styles.question}>
            <h2>Got a Question?</h2>
            <p>
              Use our{' '}
              <Actionable
                skipOwnClass
                className={styles.helpLink}
                onClick={IntercomAPI}
                onClickWith="show"
              >
                Help Center
              </Actionable>{' '}
              to find an answer quickly or start a conversation.
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Publisher)
