import axios from 'axios'

import { API_URL } from '../config/environment'
import { DEFAULT_AUTHORIZATION_KEYWORD, DEFAULT_AUTHORIZATION_HEADER } from '../utils/request'

import { NODE_REQUEST_TIMEOUT } from './request'

const instance = axios.create({
  baseURL: API_URL.replace('/api/v1', '/api/v2'),
  // set timeout just for node (SSR):
  timeout: typeof window === 'undefined' ? NODE_REQUEST_TIMEOUT : undefined,
  headers: { 'Content-type': 'application/json; charset=UTF-8' },
})

instance.interceptors.request.use(({ key, ...config }) => {
  if (key) {
    return {
      ...config,
      headers: {
        [DEFAULT_AUTHORIZATION_HEADER]: `${DEFAULT_AUTHORIZATION_KEYWORD}${key}`,
        ...config.headers,
      },
    }
  }
  return config
})

export default instance
