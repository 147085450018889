import { Map } from 'immutable'
import { normalize, schema } from 'normalizr'

import createReducer from '../../utils/create-reducer'
import { GET_CAMPAIGN_DETAILS } from '../campaign/actions'

import { SEARCH_GAME, SEARCH_GOG_GAME, SEARCH_TWITCH_GAME } from './actions'

export const INITIAL_STATE = new Map()

export const game = createReducer(INITIAL_STATE, {
  [SEARCH_GAME.FULFILLED]: (state, { payload, meta }) => state.mergeDeep({ [meta]: payload }),
})

export const gogGame = createReducer(INITIAL_STATE, {
  [SEARCH_GOG_GAME.FULFILLED]: (state, { payload, meta }) => state.mergeDeep({ [meta]: payload }),
})

export const twitchGameSchema = new schema.Entity('twitchGame', undefined, {
  idAttribute: 'game_id',
})
export const twitchGame = createReducer(new Map({ ___all: new Map() }), {
  [SEARCH_TWITCH_GAME.FULFILLED]: (state, { payload, meta }) =>
    state.mergeDeep({
      [meta]: payload,
      ___all: normalize(payload, [twitchGameSchema]).entities.twitchGame,
    }),
  [GET_CAMPAIGN_DETAILS.FULFILLED]: (state, { payload }) =>
    state.mergeDeep({
      ___all: normalize(payload.twitch_games_fields, [twitchGameSchema]).entities.twitchGame,
    }),
})
