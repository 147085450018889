import React from 'react'
import { Link } from 'react-router'

import publisher from 'img/ic-publisher.svg'
import creator from 'img/ic-creator.svg'
import Svg from 'components/svg'

import styles from './styles.css'

const AuthBar = () => (
  <div className={styles.authMenu}>
    <Link className={styles.signup} to="/signin/publisher">
      <Svg svg={publisher} alt=" Publisher access" />
      Publisher access
    </Link>
    <div className={styles.divider} />
    <Link className={styles.creatorSignup} to="/signin/creator">
      <Svg svg={creator} alt="Creator Access" />
      Creator Access
    </Link>
  </div>
)

export default AuthBar
