import React from 'react'
import { Route, IndexRedirect } from 'react-router'
import Loadable from 'react-loadable'

import Loading from '../../components/piece-loading-async-route'

const loading = () => <Loading />

const UpgradeAccount = Loadable({
  loader: () => import(/* webpackChunkName: "publisherchangeplan" */ '../../views/upgrade-account'),
  loading,
})

const UpgradeAccountSuccess = Loadable({
  loader: () =>
    import(/* webpackChunkName: "publisherchangeplan" */ '../../views/upgrade-account/success'),
  loading,
})

const UpgradeAccountPayment = Loadable({
  loader: () =>
    import(/* webpackChunkName: "publisherchangeplan" */ '../../views/upgrade-account/payment'),
  loading,
})

const UpgradeAccountCancellationConfirmation = Loadable({
  loader: () =>
    import(/* webpackChunkName: "publisherchangeplan" */ '../../views/upgrade-account/cancellation-confirmation'),
  loading,
})

const DowngradeAccount = Loadable({
  loader: () =>
    import(/* webpackChunkName: "publisherchangeplan" */ '../../views/upgrade-account/downgrade'),
  loading,
})

const StripeProvider = Loadable({
  loader: () =>
    import(/* webpackChunkName: "publisherchangeplan" */ '../../components/stripe-provider'),
  loading,
})

const Upgrade = () => (
  <Route path="/upgrade" component={StripeProvider}>
    <IndexRedirect to="monthly" />
    <Route path="downgrade/confirmation" component={UpgradeAccountCancellationConfirmation} />
    <Route path="downgrade" component={DowngradeAccount} />
    <Route path="success" component={UpgradeAccountSuccess} />
    <Route path=":recurrence" component={UpgradeAccount} />
    <Route path=":recurrence/:plan" component={UpgradeAccountPayment} />
  </Route>
)

export default Upgrade
