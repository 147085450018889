import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { IntercomAPI } from 'react-intercom'

import { reset } from '../../services/tracker'
import { signout } from '../../modules/user/actions'
import { showAlertLayer } from '../../modules/ui-alert/actions'
import localStorage from '../../utils/local-storage'
import sessionStorage from '../../utils/session-storage'

import styles from './styles.css'

const SIGNOUT_MESSAGE = "You're signed out. Come back soon!"

const mapDispatchToProps = {
  signout,
  showAlertLayer,
}

class Signout extends Component {
  static propTypes = {
    signout: PropTypes.func.isRequired,
    showAlertLayer: PropTypes.func.isRequired,
  }

  componentWillMount() {
    localStorage.setItem('filters/available', null)
    sessionStorage.removeItem('admin_login')
    this.props.signout()
  }

  componentDidMount() {
    IntercomAPI('shutdown')
    reset()
    browserHistory.push('/')
    this.props.showAlertLayer({ message: SIGNOUT_MESSAGE })
  }

  render() {
    return (
      <div className={styles.page}>
        <p>Signing out...</p>
      </div>
    )
  }
}

export default connect(
  null,
  mapDispatchToProps
)(Signout)
