const CLOUDINARY_SEPARTOR = '/upload'
const THUMBNAIL_WIDTH = 267
const THUMBNAIL_HEIGHT = 75
const COVER = 'fit'

export const getThumbnailAndOriginal = ({ data: image }) => {
  if (!image.secure_url) return null

  const split = image.secure_url.split(CLOUDINARY_SEPARTOR)
  const finalUrl = `${split[0]}/upload/w_${THUMBNAIL_WIDTH},h_${THUMBNAIL_HEIGHT},c_${COVER}${
    split[1]
  }`

  return { thumbnail: finalUrl, original: image.secure_url }
}

export const loadImage = (src) =>
  new Promise((resolve, reject) => {
    let img = new Image()
    img.onload = () => {
      resolve(img)
      img = null
    }
    img.onerror = () => {
      reject(img)
      img = null
    }
    img.src = src
  })

export const getImageAspectRatio = async (src) => {
  const image = await loadImage(src)
  const imageWidth = image.naturalWidth
  const imageHeight = image.naturalHeight
  return Math.round((imageWidth / imageHeight) * 100) / 100
}
