import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutableProptypes from 'react-immutable-proptypes'
import { browserHistory } from 'react-router'
import { faBan, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Map } from 'immutable'

import Loading from '../../components/piece-loading'
import ModalConfirm from '../../components/modal-confirm'
import hasRole from '../../utils/has-role'
import Container from '../../components/container'
import Icon from '../../components/piece-icon'
import Button from '../../components/button'
import { getUserNameFromUser } from '../../utils/get-user-name'
import { format } from '../../utils/datetime'
import {
  addCreator,
  removeCreator,
  ADD_CREATOR_TO_BLOCKLIST,
  REMOVE_CREATOR_FROM_BLOCKLIST,
} from '../../modules/blocklist/actions'
import { SUCCESS, ERROR } from '../../config/alert'
import usePrevious from '../../hooks/use-previous'
import { showAlertLayer as showAlertLayerAction } from '../../modules/ui-alert/actions'
import { KIND } from '../../config/user-settings'
import { fetchParams } from '../../services/whitelist'
import { pluralize } from '../../utils/pluralize'

import styles from './styles.css'

const mapStateToProps = ({ user, loading, error }) => ({
  user,
  isLoadingAddToBlocklist: !!loading.get(ADD_CREATOR_TO_BLOCKLIST.ACTION),
  isLoadingRemoveFromBlocklist: !!loading.get(REMOVE_CREATOR_FROM_BLOCKLIST.ACTION),
  fetchWhitelists: fetchParams(user.get('auth_token')),
  hasErrorAddToBlocklist: error.get(ADD_CREATOR_TO_BLOCKLIST.ACTION, new Map()).size > 0,
  hasErrorRemoveFromBlocklist: error.get(REMOVE_CREATOR_FROM_BLOCKLIST.ACTION, new Map()).size > 0,
})

const mapDispatchToProps = {
  addCreatorToBlocklist: addCreator,
  removeCreatorFromBlocklist: removeCreator,
  showAlertLayer: showAlertLayerAction,
}

const Sidebar = ({
  creator,
  user,
  addCreatorToBlocklist,
  removeCreatorFromBlocklist,
  fetchWhitelists,
  showAlertLayer,
  isLoadingAddToBlocklist,
  isLoadingRemoveFromBlocklist,
  hasErrorAddToBlocklist,
  hasErrorRemoveFromBlocklist,
}) => {
  const previousIsLoading = usePrevious({ isLoadingAddToBlocklist, isLoadingRemoveFromBlocklist })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [whitelistCount, setWhitelistCount] = useState(0)
  const [creatorWhitelisted, setCreatorWhitelisted] = useState([])
  const [whitelist, setWhitelist] = useState([])
  const [isLoading, setLoading] = useState(true)

  const isBlocklisted = !!creator.get('blocklisted')
  const blocklister = getUserNameFromUser(creator.getIn(['blocklisted', 'blocklister']))
  const name = creator.get('name')
  const isPublisher = user.getIn(['profile', 'kind']) === KIND.PUBLISHER
  const isCreator = user.getIn(['profile', 'kind']) === KIND.CREATOR

  useEffect(() => {
    if (!user.get('auth_token')) {
      return
    }
    if (!creator.get('user_id')) {
      return
    }
    fetchWhitelists({
      paginated: 0,
      user_id: creator.get('user_id'),
    })
      .then((list) => {
        const inWhitelists = list.filter((r) => r.creator_whitelisted)
        const creatorWhitelistCount = inWhitelists.length
        setCreatorWhitelisted(inWhitelists)
        setWhitelist(list)
        setLoading(false)
        if (creatorWhitelistCount) {
          setWhitelistCount(creatorWhitelistCount)
        }
      })
      // not sure why this was not built using regular actions so we gotta
      // catch exceptions here because unauthenticated users were firing
      // sentry exceptions because this was throwing
      .catch(console.info)
  }, [creator.get('user_id')])

  useEffect(() => {
    if (previousIsLoading.isLoadingAddToBlocklist && !isLoadingAddToBlocklist) {
      const alert = hasErrorAddToBlocklist
        ? { message: 'An error happened while trying to add the creator.', type: ERROR }
        : {
            message: `${name} was added to your publisher's blocklist and removed from all whitelists.`,
            type: SUCCESS,
          }
      showAlertLayer(alert)
    }
  }, [isLoadingAddToBlocklist])

  useEffect(() => {
    if (previousIsLoading.isLoadingRemoveFromBlocklist && !isLoadingRemoveFromBlocklist) {
      const alert = hasErrorRemoveFromBlocklist
        ? { message: 'An error happened while trying to remove the creator.', type: ERROR }
        : {
            message: `${name} was removed from the blocklist.`,
            type: SUCCESS,
          }
      showAlertLayer(alert)
    }
  }, [isLoadingRemoveFromBlocklist])

  const closeModal = () => setIsModalOpen(false)

  const handleSendOffer = () => {
    const { pathname } = browserHistory.getCurrentLocation()
    browserHistory.push({ pathname: `${pathname}/send-offer` })
  }

  const handleAnonymousSendOffer = () => {
    const { pathname } = browserHistory.getCurrentLocation()
    browserHistory.push({ pathname: `${pathname}/send-offer-premium` })
  }

  const handleAddToWhitelist = () => {
    const { pathname } = browserHistory.getCurrentLocation()
    if (whitelist.length === 0) {
      browserHistory.replace('/whitelists?addWhitelist=opened')
    } else {
      browserHistory.push({
        pathname: `${pathname}/add-to-whitelist/${creator.get('id')}`,
      })
    }
  }

  const handleAddToBlocklist = () => {
    const payload = {
      user_id: creator.get('user_id'),
    }

    addCreatorToBlocklist(payload, { profileId: creator.get('id') })
    setWhitelistCount(0)

    closeModal()
  }

  const onAddToBlocklistClick = () => {
    if (whitelistCount > 0) {
      setIsModalOpen(true)
      return
    }
    handleAddToBlocklist()
  }

  const CreatorWhitelisted = () => {
    return (
      <span>
        {creatorWhitelisted.map((wList) => (
          <div className={styles.sidebarWhitelistItem}>{wList.name}</div>
        ))}
      </span>
    )
  }

  const handleRemoveFromBlocklist = () => {
    removeCreatorFromBlocklist(creator.getIn(['blocklisted', 'id']), {
      profileId: creator.get('id'),
    })
  }

  const renderPublisherSection = () =>
    isBlocklisted ? (
      <Container className={styles.sidebarItem}>
        <div className={styles.blocklistedContainer}>
          <Icon icon={faBan} />
          <p className={styles.blocklistedText}>
            This creator has been blocklisted {blocklister && `by ${blocklister}`}
            <span>
              <strong>{name}</strong> cannot see any campaigns made by your publisher as of{' '}
              <strong>
                {format(creator.getIn(['blocklisted', 'created_at']), 'MMM DD, YYYY')}
              </strong>
            </span>
          </p>
        </div>
        <Button
          className={styles.blocklistedButton}
          kind="secondaryBlue"
          onClick={handleRemoveFromBlocklist}
        >
          Remove from blocklist
        </Button>
      </Container>
    ) : (
      <>
        <Container className={styles.sidebarItem}>
          {hasRole(user, 'offer_invites') && (
            <div className={styles.btnWrapper}>
              <Button className={styles.sendOfferButton} onClick={handleSendOffer}>
                Send an offer
              </Button>
            </div>
          )}

          <div className={styles.btnWrapper}>
            <Button
              className={styles.sendOfferButton}
              kind="secondaryRed"
              onClick={onAddToBlocklistClick}
            >
              Blocklist user
            </Button>
            <ModalConfirm
              open={isModalOpen}
              onCancel={closeModal}
              onConfirm={handleAddToBlocklist}
              isDestructive
              title="Are you sure?"
            >
              <p>
                Are you sure you wish to blocklist and remove <strong>{name}</strong> from{' '}
                <strong>{whitelistCount}</strong> {pluralize(whitelistCount, 'whitelist')}?
              </p>
            </ModalConfirm>
          </div>
        </Container>
        <Container className={styles.sidebarItem}>
          <div className={styles.sidebarWhitelist}>
            <div className={styles.sidebarWhitelistTitle}>
              Whitelists
              {!isLoading && (
                <Button
                  kind="primary"
                  size="small"
                  className={styles.sidebarWhitelistBtn}
                  onClick={handleAddToWhitelist}
                >
                  <Icon icon={faPlus} /> Add
                </Button>
              )}
            </div>
            <CreatorWhitelisted />
            <div className={styles.sidebarLoading}>
              <Loading isLoading={isLoading} />
            </div>
          </div>
        </Container>
      </>
    )

  return (
    <div className={styles.actionButtonsWrapper}>
      {isPublisher && renderPublisherSection()}
      {(!isCreator && !isPublisher) ||
      (isPublisher && !isBlocklisted && !hasRole(user, 'offer_invites')) ? (
        <Container className={styles.sidebarItem}>
          <Button className={styles.sendOfferButton} onClick={handleAnonymousSendOffer}>
            Send an offer
          </Button>
        </Container>
      ) : null}
    </div>
  )
}

Sidebar.propTypes = {
  creator: ImmutableProptypes.map,
  user: ImmutableProptypes.map,
  addCreatorToBlocklist: PropTypes.func.isRequired,
  removeCreatorFromBlocklist: PropTypes.func.isRequired,
  showAlertLayer: PropTypes.func.isRequired,
  fetchWhitelists: PropTypes.func.isRequired,
  isLoadingAddToBlocklist: PropTypes.bool.isRequired,
  isLoadingRemoveFromBlocklist: PropTypes.bool.isRequired,
  hasErrorAddToBlocklist: PropTypes.bool.isRequired,
  hasErrorRemoveFromBlocklist: PropTypes.bool.isRequired,
}
Sidebar.defaultProps = {
  creator: new Map(),
  user: new Map(),
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar)
