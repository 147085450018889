import React from 'react'
import { Route, IndexRedirect } from 'react-router'

import AppUser from 'views/app-user'
import { KIND } from 'config/user-settings'
import { userLoginRequired } from 'utils/routes'

import offerList from './offer-list'
import offerDetail from './offer-detail'
import settings from './settings'

// To keep correctly order CSS we add the following import
// to push its content the top of webpack's ExtractTextPlugin
import 'components/modal-confirm/styles.css'

const routes = (store) => (
  <Route component={AppUser} onEnter={userLoginRequired(store, KIND.CREATOR, 'creator')}>
    <Route path="/offers">
      <IndexRedirect to="/offers/available" />
      {offerList()}
      {offerDetail()}
    </Route>
    {settings()}
  </Route>
)

export default routes
