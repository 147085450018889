const NOT_FOUND = 'Not found'

const isNotFound = (error) => {
  if (!error) {
    return false
  }
  if (typeof error === 'string') {
    if (
      error.includes(NOT_FOUND) ||
      error.includes('The requested resource was not found on this server')
    ) {
      return true
    }
    return false
  }

  if (error.size && error.get('detail').includes(NOT_FOUND)) {
    return true
  }
  return false
}

export default isNotFound
