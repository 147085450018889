import { fromJS, Map, List } from 'immutable'
import Cookies from 'universal-cookie'

import createReducer from '../../utils/create-reducer'
import { getInitialState } from '../../utils/reducers'
import pick from '../../utils/pick'
import localStorage from '../../utils/local-storage'
import { KIND } from '../../config/user-settings'
import { captureException } from '../../utils/exception'
import { ACCEPT_OFFER } from '../offer/actions'
import { ACCEPT_INVITE } from '../publisher/actions'
import { SUBSCRIBE, CHANGE_PLAN } from '../plan/actions'

import {
  PUBLISHER_LOGIN,
  SIGNUP_AS_PUBLISHER,
  COMPLETE_PUBLISHER_SIGNUP,
  CREATE_PUBLISHER_PASSWORD,
  CONNECT_CREATOR_ACCOUNT,
  UPDATE_ACCOUNT,
  USER_SIGNOUT,
  FETCH_USER_DATA,
  DELETE_PROVIDER,
  UPDATE_ORGANIZATION,
  UPDATE_PREFERENCES,
  NEW_UPDATE_PREFERENCES,
  GET_PREFERENCES,
  ACCEPT_COOKIE_CONSENT,
  GET_ORGANIZATIONS,
  GET_ORGANIZATION_INVITATIONS,
  REMOVE_ORGANIZATION_INVITATION,
  UPDATE_MEMBERSHIP,
} from './actions'

export const INITIAL_STATE = getInitialState('user') || new Map()

const ONE_YEAR = 365 * 24 * 60 * 60 * 1000 // eslint-disable-line no-magic-numbers

const cookies = new Cookies()

const handleUserInfo = (state, { payload }) => {
  // we minimize the user payload in order to save it in a cookies (keep it small)
  const userCookie = {
    ...pick(payload, ['first_name', 'last_name', 'email', 'username', 'id']),
    profile: {
      ...pick(payload.profile, ['kind', 'terms', 'company', 'id']),
      preferences_field: JSON.parse((payload.profile && payload.profile.preferences_field) || null),
      creator: pick(payload.profile && payload.profile.creator, ['name']),
    },
  }
  if (payload.auth_token) {
    cookies.set('auth_token', payload.auth_token, { path: '/', sameSite: 'lax' })
  }
  cookies.set('user', userCookie, { path: '/', sameSite: 'lax' })
  localStorage.setItem('invitation', payload.invitation)
  const userData = state.mergeDeep(fromJS(payload))

  const plans = userData.getIn(['profile', 'subscription', 'plans'], new List()).sort()

  try {
    if (plans.size && userData.getIn(['profile', 'subscription'])) {
      return userData
        .setIn(
          ['profile', 'preferences_field'],
          fromJS(JSON.parse(payload.profile.preferences_field || null))
        )
        .setIn(['profile', 'subscription', 'plans'], plans)
    }
    return userData.setIn(
      ['profile', 'preferences_field'],
      fromJS(JSON.parse(payload.profile.preferences_field || null))
    )
  } catch (e) {
    captureException(e, payload)
    return userData
  }
}

const handleSubscription = (state, { payload }) => {
  const subs = fromJS({
    end_date: payload.end_date,
    has_trial: payload.status === 'trialing',
    is_canceling: payload.canceling,
    plans: payload.plans.map((p) => p.slug).sort(),
    status: payload.status,
  })
  return state.setIn(['profile', 'subscription'], subs)
}

export const user = createReducer(INITIAL_STATE, {
  [ACCEPT_INVITE.FULFILLED]: handleUserInfo,
  [FETCH_USER_DATA.FULFILLED]: handleUserInfo,
  [CONNECT_CREATOR_ACCOUNT.FULFILLED]: handleUserInfo,

  [ACCEPT_OFFER.FULFILLED]: (state) => {
    const currentQuota = state.getIn(['profile', 'offer_quota'])
    return state.setIn(['profile', 'offer_quota'], currentQuota - 1)
  },

  [PUBLISHER_LOGIN.FULFILLED]: (state, { payload }) => {
    cookies.set('auth_token', payload.auth_token, { path: '/', sameSite: 'lax' })
    return state.merge({ auth_token: payload.auth_token, profile: { kind: KIND.PUBLISHER } })
  },

  [DELETE_PROVIDER.FULFILLED]: (state, { meta: { provider } }) =>
    state.removeIn(['profile', provider]),

  [UPDATE_ORGANIZATION.FULFILLED]: (state, { payload }) =>
    state.set('organization', fromJS(payload)),

  [UPDATE_ACCOUNT.FULFILLED]: handleUserInfo,

  [COMPLETE_PUBLISHER_SIGNUP.FULFILLED]: (state, { payload }) => {
    cookies.set('auth_token', payload.auth_token, { path: '/', sameSite: 'lax' })
    return state.set('auth_token', payload.auth_token)
  },

  [USER_SIGNOUT]: () => {
    cookies.remove('user', { path: '/' })
    cookies.remove('auth_token', { path: '/' })
    return new Map()
  },

  [CREATE_PUBLISHER_PASSWORD.FULFILLED]: (state, { payload }) => state.mergeDeep(fromJS(payload)),

  [SIGNUP_AS_PUBLISHER.FULFILLED]: (state, { payload }) => state.mergeDeep(fromJS(payload)),

  [UPDATE_PREFERENCES.PENDING]: (state, { meta }) =>
    state.updateIn(['profile', 'preferences_field'], (data) => (data || new Map()).merge(meta)),
  [UPDATE_PREFERENCES.FULFILLED]: handleUserInfo,
  [GET_PREFERENCES.FULFILLED]: (state, { payload }) => state.set('preferences', fromJS(payload)),
  [NEW_UPDATE_PREFERENCES.PENDING]: (state, { meta: preference }) =>
    state.set('preferences', state.get('preferences', new Map()).merge(preference)),
  [NEW_UPDATE_PREFERENCES.FULFILLED]: (state, { payload }) =>
    state.set('preferences', fromJS(payload)),

  [SUBSCRIBE.FULFILLED]: handleSubscription,
  [CHANGE_PLAN.FULFILLED]: handleSubscription,
  [UPDATE_MEMBERSHIP.FULFILLED]: (state, { payload }) =>
    state.setIn(['organization_member', 'title'], payload.title),
})

const INITIAL_COOKIE_CONSENT = !!getInitialState('cookieConsentAccepted')
export const cookieConsentAccepted = createReducer(INITIAL_COOKIE_CONSENT, {
  [ACCEPT_COOKIE_CONSENT]: () => {
    cookies.set('cookies_accepted', true, {
      path: '/',
      expires: new Date(Date.now() + ONE_YEAR),
      sameSite: 'lax',
    })
    return true
  },
})

export const organizations = createReducer(new List(), {
  [GET_ORGANIZATIONS.FULFILLED]: (state, { payload }) => fromJS(payload),
})

export const organizationInvitations = createReducer(new List(), {
  [GET_ORGANIZATION_INVITATIONS.FULFILLED]: (state, { payload }) => fromJS(payload),
  [REMOVE_ORGANIZATION_INVITATION.FULFILLED]: (state, { meta }) =>
    state.filter((invite) => invite.get('id') !== meta),
})
