import defineAction from '../../utils/define-action'
import * as reportService from '../../services/report'

export const GET_CAMPAIGN_VIDEO_REPORTS = defineAction('GET_CAMPAIGN_VIDEO_REPORTS')
export const GET_CAMPAIGN_DELIVERIES = defineAction('GET_CAMPAIGN_DELIVERIES')
export const GET_CAMPAIGN_DAILY_DELIVERIES = defineAction('GET_CAMPAIGN_DAILY_DELIVERIES')
export const GET_OFFER_COMPLETION = defineAction('GET_OFFER_COMPLETION')

export const getCampaignVideoReports = (id) => (dispatch, getState) =>
  dispatch({
    type: GET_CAMPAIGN_VIDEO_REPORTS.ACTION,
    payload: reportService.getVideoReports(getState().user.get('auth_token'))(id),
  })

export const getCampaignDeliveries = (id, params = {}) => (dispatch, getState) =>
  dispatch({
    type: GET_CAMPAIGN_DELIVERIES.ACTION,
    payload: reportService.getDeliveries(getState().user.get('auth_token'))(id, params),
    meta: { id, params },
  })

export const getCampaignDailyDeliveries = (id, params = {}) => (dispatch, getState) =>
  dispatch({
    type: GET_CAMPAIGN_DAILY_DELIVERIES.ACTION,
    payload: reportService.getDailyDeliveries(getState().user.get('auth_token'))(id, params),
    meta: { id, params },
  })

export const getOffersCompletion = (id, params = {}) => (dispatch, getState) =>
  dispatch({
    type: GET_OFFER_COMPLETION.ACTION,
    payload: reportService.getOffersCompletion(getState().user.get('auth_token'))(id, params),
    meta: { id, params },
  })
