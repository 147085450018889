import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ClickOutside from 'react-click-outside'
import { connect } from 'react-redux'
import ImmutableProptypes from 'react-immutable-proptypes'
import { browserHistory, Link, withRouter } from 'react-router'
import classnames from 'classnames'
import { faBars, faChevronDown, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

import Avatar from '../avatar'
import Actionable from '../actionable'
import Svg from '../svg'
import Loading from '../piece-loading'
import getUserName from '../../utils/get-user-name'
import Icon from '../piece-icon'
import If from '../if'
import logo from '../../img/logo-woovit.svg'
import Navigation from '../navigation'
import { KIND } from '../../config/user-settings'
import { FREE_PLAN } from '../../config/settings'
import manualReviewCount from '../../hocs/manual-review-requests-count'
import {
  setPrimaryOrganization,
  SET_PRIMARY_ORGANIZATION,
  getOrganizations,
} from '../../modules/user/actions'

import styles from './styles.css'

const UPGRADE_PATH = '/upgrade'

const mapStateToProps = (state) => ({
  user: state.user,
  organizations: state.organizations.filter(
    (org) => org.get('id') !== state.user.getIn(['organization', 'id'])
  ),
  isPublisher: state.user.getIn(['profile', 'kind']) === KIND.PUBLISHER,
  isLoadingOrganizationChange: !!state.loading.get(SET_PRIMARY_ORGANIZATION.ACTION),
})
const mapDispatchToProps = { setPrimaryOrganization, getOrganizations }

const gotoUpgrade = () => browserHistory.push('/upgrade')

const getName = (isPublisher, user) => {
  if (isPublisher) {
    return getUserName(
      user.get('first_name'),
      user.get('last_name'),
      user.getIn(['profile', 'creator', 'name']) || user.get('username')
    )
  }
  return getUserName(
    user.getIn(['profile', 'creator', 'name']),
    null,
    user.get('first_name') || user.get('username')
  )
}

class Header extends Component {
  static propTypes = {
    user: ImmutableProptypes.map.isRequired,
    requestsCounter: PropTypes.number.isRequired,
    isPublisher: PropTypes.bool.isRequired,
    setPrimaryOrganization: PropTypes.func.isRequired,
    organizations: ImmutableProptypes.list.isRequired,
    isLoadingOrganizationChange: PropTypes.bool.isRequired,
    getOrganizations: PropTypes.func.isRequired,
    // eslint-disable-next-line react/require-default-props
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }

  state = {
    menuUser: false,
    menuNav: false,
    isSwitchingAccounts: false,
  }

  componentDidMount() {
    if (this.props.isPublisher) {
      this.props.getOrganizations()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.setState({ menuNav: false })
    }
    if (this.props.isLoadingOrganizationChange && !nextProps.isLoadingOrganizationChange) {
      window.location.reload()
    }
  }

  toggleMenuUser = () => {
    this.setState({ menuUser: !this.state.menuUser })
  }

  toggleMenuNav = () => {
    this.setState(({ menuNav }) => ({ menuNav: !menuNav }))
  }

  handleClickOutsideUserMenu = (e) => {
    if (document.querySelector(`.${styles.userMenu}`).contains(e.target)) {
      return
    }
    this.toggleMenuUser()
  }

  handleClickOutsideNavMenu = (e) => {
    if (document.querySelector(`.${styles.menuIcon}`).contains(e.target)) {
      return
    }
    this.toggleMenuNav()
  }

  goTo = (path) => {
    this.toggleMenuUser()
    browserHistory.push(path)
  }

  switchAccount = (orgId) => {
    this.props.setPrimaryOrganization(orgId)
    this.setState({ isSwitchingAccounts: true })
  }

  renderOrganizationMenuEntry = (org) => (
    <li key={org.hashCode()} className={classnames(styles.floatingMenuItem, styles.orgMenuEntry)}>
      <Actionable
        onClick={this.switchAccount}
        onClickWith={org.get('id')}
        className={styles.floatingMenuLink}
      >
        {org.get('name')}
      </Actionable>
    </li>
  )

  render() {
    const { user, requestsCounter, isPublisher, organizations } = this.props
    const { menuUser, menuNav, isSwitchingAccounts } = this.state

    const logoPath = isPublisher ? '/activity' : '/offers'
    const settingsPath = isPublisher ? '/publisher/account' : '/settings'
    const organizationPath = '/publisher/organization'
    const creatorSlug = user.getIn(['profile', 'creator', 'slug'])
    const displayName = getName(isPublisher, user)
    const avatar = isPublisher
      ? user.getIn(['organization', 'logo_url'])
      : user.getIn(['profile', 'logo'])
    const isOnFreePlan = user.getIn(['profile', 'subscription', 'plans'], []).includes(FREE_PLAN)
    const displayUpgradeOption = isPublisher && isOnFreePlan
    const organizationSlug = !user.getIn(['organization', 'is_private'])
      ? user.getIn(['organization', 'slug'])
      : null
    return (
      <Fragment>
        <header className={styles.topbar}>
          <div className={styles.container}>
            {isPublisher ? (
              <Actionable
                onClick={this.toggleMenuNav}
                className={classnames(styles.menuIcon, styles.block)}
              >
                <Icon className={styles.icon} icon={faBars} />
              </Actionable>
            ) : null}

            <div className={classnames(styles.logo, styles.block)}>
              <Link to={logoPath}>
                <Svg
                  width={null}
                  height={null}
                  alt="Woovit Logo"
                  svg={logo}
                  className={styles.logoSvg}
                />
              </Link>
              <If condition={displayUpgradeOption}>
                <Actionable onClick={gotoUpgrade} className={styles.upgradeBadge}>
                  <div className={styles.arrowLeft} />
                  Upgrade
                </Actionable>
              </If>
            </div>

            {isPublisher ? (
              <Navigation className={styles.menuNavDesktop} requestsCounter={requestsCounter} />
            ) : null}

            <div className={classnames(styles.account, styles.block)}>
              <Actionable onClick={this.toggleMenuUser} className={styles.userMenu}>
                <Avatar
                  img={avatar}
                  className={classnames(styles.avatar, { [styles.publisherAvatar]: isPublisher })}
                />
                <span className={styles.orgName}>
                  {isPublisher ? user.getIn(['organization', 'name']) : null}
                </span>
                <span
                  className={classnames(styles.menuIndicator, {
                    [styles.chevronSpacer]: isPublisher,
                  })}
                >
                  <Icon className={styles.icon} icon={faChevronDown} />
                </span>
              </Actionable>
              <If condition={menuUser}>
                <div className={styles.floatingMenu}>
                  <ClickOutside onClickOutside={this.handleClickOutsideUserMenu}>
                    <div className={styles.arrowTop} />
                    <div className={styles.menuHeader}>
                      <div className={styles.floatingMenuName}>{displayName}</div>
                      <If condition={user.get('email')}>
                        <div className={styles.floatingMenuEmail}>{user.get('email')}</div>
                      </If>
                    </div>
                    <div className={styles.separator} />
                    <ul className={styles.floatingMenuList}>
                      {isPublisher && organizationSlug ? (
                        <li className={styles.floatingMenuItem}>
                          <Actionable
                            onClick={this.goTo}
                            onClickWith={`/publisher/${organizationSlug}`}
                            className={styles.floatingMenuLink}
                          >
                            View Profile
                          </Actionable>
                        </li>
                      ) : null}

                      {!isPublisher ? (
                        <li className={styles.floatingMenuItem}>
                          <Actionable
                            onClick={this.goTo}
                            onClickWith={`/creator/${creatorSlug}`}
                            className={styles.floatingMenuLink}
                          >
                            Profile
                          </Actionable>
                        </li>
                      ) : null}

                      <li className={styles.floatingMenuItem}>
                        <Actionable
                          onClick={this.goTo}
                          onClickWith={settingsPath}
                          className={styles.floatingMenuLink}
                        >
                          Settings
                        </Actionable>
                      </li>

                      {isPublisher ? (
                        <li className={styles.floatingMenuItem}>
                          <Actionable
                            onClick={this.goTo}
                            onClickWith={organizationPath}
                            className={styles.floatingMenuLink}
                          >
                            Organization Settings
                          </Actionable>
                        </li>
                      ) : null}

                      {!isPublisher ? (
                        <li className={styles.floatingMenuItem}>
                          <Actionable
                            onClick={this.goTo}
                            onClickWith="/offers/archived"
                            className={styles.floatingMenuLink}
                          >
                            Archived Offers
                          </Actionable>
                        </li>
                      ) : null}

                      {isPublisher && organizations.size ? (
                        <>
                          <li
                            className={classnames(
                              styles.floatingMenuItem,
                              styles.floatingMenuLink,
                              styles.floatingMenuSwitch
                            )}
                          >
                            Switch Account:
                          </li>
                          {isSwitchingAccounts ? (
                            <Loading isLoading />
                          ) : (
                            organizations.map(this.renderOrganizationMenuEntry)
                          )}
                        </>
                      ) : null}

                      <If condition={displayUpgradeOption}>
                        <li className={styles.floatingMenuItem}>
                          <Actionable
                            onClick={this.goTo}
                            onClickWith={UPGRADE_PATH}
                            className={styles.floatingMenuLink}
                          >
                            Upgrade
                          </Actionable>
                        </li>
                      </If>
                      <li className={styles.floatingMenuItem}>
                        <Link className={styles.floatingMenuLink} to="/signout">
                          <Icon className={styles.icon} icon={faSignOutAlt} /> Sign out
                        </Link>
                      </li>
                    </ul>
                  </ClickOutside>
                </div>
              </If>
            </div>
          </div>
        </header>
        <div className={styles.spacer} />
        {isPublisher && menuNav ? (
          <ClickOutside onClickOutside={this.handleClickOutsideNavMenu}>
            <Navigation
              organizationSlug={
                !user.getIn(['organization', 'is_private'])
                  ? user.getIn(['organization', 'slug'])
                  : null
              }
              className={styles.menuNavMobile}
              requestsCounter={requestsCounter}
            />
          </ClickOutside>
        ) : null}
      </Fragment>
    )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(manualReviewCount(Header))
)
