import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Twitter extends Component {
  static propTypes = {
    handle: PropTypes.string.isRequired,
    chrome: PropTypes.string,
    limit: PropTypes.number,
  }

  static defaultProps = {
    limit: 4,
    chrome: 'transparent',
  }

  componentDidMount() {
    if (window.twttr) {
      window.twttr.widgets.load()
      return
    }
    if (!document.getElementById('twitter-js')) {
      const script = document.createElement('script')
      script.async = true
      script.id = 'twitter-js'
      script.src = 'https://platform.twitter.com/widgets.js'
      document.head.appendChild(script)
    }
  }

  render() {
    const { handle, limit, chrome } = this.props
    return (
      <a
        data-tweet-limit={limit}
        data-chrome={chrome}
        className="twitter-timeline"
        href={`https://twitter.com/${handle}?ref_src=twsrc%5Etfw`}
      >
        Tweets by {handle}
      </a>
    )
  }
}

export default Twitter
