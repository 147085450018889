import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { Youtube, Twitch } from '../provider-icon'
import abbreviateNumber from '../../utils/abbreviate-number'

import styles from './styles.css'

const FollowersButtons = ({ twitch, youtube, countPosition, isDisabled }) => {
  const hasTwitchChannel = twitch && twitch.followers > 0
  const hasYoutubeChannel = youtube && youtube.followers > 0

  if (!hasTwitchChannel && !hasYoutubeChannel) {
    return null
  }

  return (
    <span className={styles.container}>
      {hasTwitchChannel && (
        <a
          className={classnames(styles.link, { [styles.disabled]: isDisabled })}
          href={twitch.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Twitch
            className={classnames(styles.icon, { [styles.down]: countPosition === 'down' })}
            small
            withLabel
            labelPosition={countPosition}
            label={abbreviateNumber(twitch.followers)}
          />
        </a>
      )}
      {hasYoutubeChannel && (
        <a
          className={classnames(styles.link, { [styles.disabled]: isDisabled })}
          href={youtube.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Youtube
            className={classnames(styles.icon, { [styles.down]: countPosition === 'down' })}
            small
            withLabel
            labelPosition={countPosition}
            label={abbreviateNumber(youtube.followers)}
          />
        </a>
      )}
    </span>
  )
}

const objShape = PropTypes.shape({
  followers: PropTypes.number,
  url: PropTypes.string,
})

FollowersButtons.propTypes = {
  twitch: objShape,
  youtube: objShape,
  countPosition: PropTypes.string,
  isDisabled: PropTypes.bool,
}

FollowersButtons.defaultProps = {
  twitch: null,
  youtube: null,
  countPosition: 'down',
  isDisabled: false,
}

export default FollowersButtons
