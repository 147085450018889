import PropTypes from 'prop-types'
import React, { Component } from 'react'
import hoistStatics from 'hoist-non-react-statics'

import lower from '../utils/lower'

const lowerCaseParams = (params) => {
  const obj = {}
  Object.keys(params).forEach((k) => {
    const value = params[k]
    if (typeof value === 'string') {
      obj[k] = lower(value)
      return
    }
    obj[k] = value
  })
  return obj
}

const LowercaseParams = (WrappedComponent) => {
  class LowercaseParamsComponent extends Component {
    static propTypes = {
      params: PropTypes.shape({}).isRequired, // Components used on <Route> always have params
    }

    render() {
      const { params } = this.props
      const newParams = lowerCaseParams(params)
      return <WrappedComponent {...this.props} params={newParams} />
    }
  }

  LowercaseParamsComponent.WrappedComponent = WrappedComponent
  return hoistStatics(LowercaseParamsComponent, WrappedComponent)
}

export default LowercaseParams
