import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { HTTP_400_BAD_REQUEST } from '../../utils/get-status'

const UnknownError = ({ params: { error } }) => (
  <Fragment>
    <h1>Unknown error.</h1>
    <details>{error}</details>
  </Fragment>
)

UnknownError.propTypes = {
  params: PropTypes.shape({
    error: PropTypes.string,
  }).isRequired,
}

UnknownError.getStatusCode = () => HTTP_400_BAD_REQUEST

export default UnknownError
