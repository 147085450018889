import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Prev from './prev'
import Next from './next'
import First from './first'
import Last from './last'
import Page from './page'
import styles from './styles.css'

const ITEMSPERPAGE = 10
const PAGINATION_SIBLINGS = 3

class Pagination extends Component {
  static propTypes = {
    count: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    pageSize: PropTypes.number,
    current: PropTypes.number,
    maxPageNumbers: PropTypes.number,
    className: PropTypes.string,
  }

  static defaultProps = {
    pageSize: ITEMSPERPAGE,
    current: 0,
    maxPageNumbers: 6,
    className: undefined,
  }

  handlePageChange = (index) => {
    const page = { value: index * this.props.pageSize }
    this.props.onClick(page, 'offset')
  }

  getHeadIndex = (total, currentIndex) => {
    if (currentIndex < PAGINATION_SIBLINGS) {
      return 0
    }
    return currentIndex - 2 // eslint-disable-line no-magic-numbers
  }

  getTailIndex = (total, currentIndex) => {
    if (currentIndex > total - PAGINATION_SIBLINGS) {
      return total
    }
    return currentIndex + PAGINATION_SIBLINGS
  }

  getPageNumbers = () => {
    const { count, pageSize, current, maxPageNumbers } = this.props
    const pageCount = Math.ceil(count / pageSize)
    const currentIndex = Math.ceil(current / pageSize)

    const pages = [...Array(pageCount).keys()]

    // return all pages if there are not many
    if (pageCount < maxPageNumbers) {
      return pages
    }

    return [
      ...pages.slice(
        this.getHeadIndex(pageCount, currentIndex),
        this.getTailIndex(pageCount, currentIndex)
      ),
    ]
  }

  renderPageNumbers = () => {
    const { pageSize, current } = this.props
    const currentIndex = Math.ceil(current / pageSize)
    return this.getPageNumbers().map((page) => {
      if (page === '...') {
        return (
          <Page finalOffset={pageSize * page} key={page} onClick={Function.prototype}>
            ...
          </Page>
        )
      }
      return (
        <Page
          finalOffset={pageSize * page}
          key={page}
          onClick={this.handlePageChange}
          onClickWith={page}
          isActive={page === currentIndex}
        >
          {page + 1}
        </Page>
      )
    })
  }

  render() {
    const { count, pageSize, current, className } = this.props

    const pageCount = Math.ceil(count / pageSize)
    const currentIndex = current / pageSize

    return (
      <ul className={classnames(className, styles.pagination)}>
        {currentIndex > 0 && count > pageSize ? (
          <Fragment>
            <First onClick={this.handlePageChange} onClickWith={0} />
            <Prev onClick={this.handlePageChange} onClickWith={currentIndex - 1} />
          </Fragment>
        ) : null}
        {this.renderPageNumbers()}
        {pageCount !== currentIndex + 1 && count > pageSize ? (
          <Fragment>
            <Next onClick={this.handlePageChange} onClickWith={currentIndex + 1} />
            <Last onClick={this.handlePageChange} onClickWith={pageCount - 1} />
          </Fragment>
        ) : null}
      </ul>
    )
  }
}

export default Pagination
