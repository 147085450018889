import React, { Component } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link, browserHistory } from 'react-router'
import { CardElement, injectStripe } from 'react-stripe-elements'
import { Map } from 'immutable'
import { connect } from 'react-redux'
import { faLock } from '@fortawesome/free-solid-svg-icons'

import Field from '../../components/piece-field'
import Button from '../../components/button'
import Legend from '../../components/legend'
import BlockFieldFeedback from '../../components/block-field-feedback'
import Loading from '../../components/piece-loading'
import Icon from '../../components/piece-icon'
import { subscribe, getCard, changePlan, SUBSCRIBE, CHANGE_PLAN } from '../../modules/plan/actions'
import {
  PLAN_PRICING,
  PLAN_COMBINATION_SLUG_MAP,
  DISCOUNT_MAP,
  ANNUAL,
  MONTHLY,
  PLANS,
  RECURRENCES,
} from '../../config/settings'
import If from '../../components/if'
import CardFlag from '../../components/card-flag'

import baseStyles from './styles.css'
import styles from './payment.css'

export const style = {
  base: {
    color: '#32325d',
    fontFamily: 'Montserrat, Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '14px',
    '::placeholder': {
      color: '#aab7c4',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
  },
}

export const INVALID_CARD = 'E_INVALID_CARD'

const mapDispatchToProps = {
  getCard,
  subscribe,
  changePlan,
}

const mapStateToProps = ({ loading, error, user, cards }) => ({
  cards,
  user,
  isLoadingSubscribe: !!loading.get(SUBSCRIBE.ACTION),
  isLoadingChangePlan: !!loading.get(CHANGE_PLAN.ACTION),
  error: error.get(SUBSCRIBE.ACTION),
  planchangeError: error.get(CHANGE_PLAN.ACTION),
})

class UpgradeAccountPayment extends Component {
  static propTypes = {
    params: PropTypes.shape({
      plan: PropTypes.string,
      recurrence: PropTypes.string,
    }).isRequired,
    subscribe: PropTypes.func.isRequired,
    stripe: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    isLoadingSubscribe: PropTypes.bool.isRequired,
    isLoadingChangePlan: PropTypes.bool.isRequired,
    error: ImmutablePropTypes.map,
    planchangeError: ImmutablePropTypes.map,
    user: ImmutablePropTypes.map.isRequired,
    cards: ImmutablePropTypes.map.isRequired,
    getCard: PropTypes.func.isRequired,
    changePlan: PropTypes.func.isRequired,
  }

  static defaultProps = {
    stripe: {},
    error: new Map(),
    planchangeError: new Map(),
  }

  state = {
    name: '',
    errors: {},
    cardErrors: '',
  }

  componentDidMount() {
    this.props.getCard()
  }

  componentWillReceiveProps({ isLoadingSubscribe, isLoadingChangePlan, planchangeError, error }) {
    if (error.size && error.get('error') === INVALID_CARD) {
      this.setState({ cardErrors: 'Invalid card number!' })
      return
    }
    if (!isLoadingSubscribe && this.props.isLoadingSubscribe && !error.size) {
      browserHistory.push('/upgrade/success')
      return
    }
    if (!isLoadingChangePlan && this.props.isLoadingChangePlan && !planchangeError.size) {
      browserHistory.push('/upgrade/success')
    }
  }

  handleCardChange = (event) => {
    if (event.error) {
      this.setState({ cardErrors: event.error.message })
    }
  }

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value })
  }

  upgrade = () => {
    const { name } = this.state
    const {
      cards,
      params: { recurrence, plan },
    } = this.props
    if (cards.size) {
      this.props.changePlan(PLAN_COMBINATION_SLUG_MAP[recurrence][plan])
      return
    }

    const errors = {}
    if (!name) {
      errors.name = "Type the cardholder's name."
    }

    this.setState({ errors, cardErrors: '' })

    if (Object.keys(errors).length) {
      return
    }

    this.props.subscribe(
      this.props.stripe.createToken,
      { name },
      PLAN_COMBINATION_SLUG_MAP[recurrence][plan]
    )
  }

  render() {
    const {
      params: { plan = 'premium', recurrence = 'monthly' },
      isLoadingSubscribe,
      error,
      user,
      cards,
    } = this.props
    const { name, errors, cardErrors } = this.state
    const reverseRecurrence = recurrence === ANNUAL ? MONTHLY : ANNUAL
    const switchTo = `/upgrade/${reverseRecurrence}/${plan}`
    const price = PLAN_PRICING[recurrence][plan]
    const priceLabel = recurrence === 'monthly' ? 'per month' : 'per year'
    const hasTrial = user.getIn(['profile', 'subscription', 'has_trial'])

    return (
      <div className={baseStyles.container}>
        <h1>Upgrade your plan</h1>
        <p>
          You&apos;re upgrading to the <strong>{PLANS[plan]}</strong>.
          <If condition={hasTrial}>
            <span> No charge for your first 14 days.</span>
          </If>
        </p>
        <div className={styles.paymentBody}>
          {!cards.size ? (
            <div>
              <Field
                name="name"
                type="text"
                label="Cardholder Name"
                value={name}
                errorMessage={errors.name}
                onChange={this.handleChange}
              />

              <div className={styles.cardWrapper}>
                <Legend>Credit Card</Legend>
                <CardElement onChange={this.handleCardChange} style={style} />
                <BlockFieldFeedback errorMessage={cardErrors} />
              </div>

              <div className={styles.summary}>
                <div>
                  <h3 className={styles.planName}>
                    {PLANS[plan]} ({RECURRENCES[recurrence]})
                  </h3>
                  <p>
                    <Link to={switchTo}>Switch to</Link> {reverseRecurrence} billing
                  </p>
                </div>
                <div className={styles.summaryPrice}>
                  <div className={classnames(baseStyles.price, styles.price)}>
                    <span className={baseStyles.priceDollar}>$</span>
                    <span className={baseStyles.priceAmount}>{price}</span>{' '}
                    <span className={baseStyles.priceLabel}>{priceLabel}</span>
                  </div>
                  {recurrence === ANNUAL ? (
                    <div className={styles.saving}>You are saving {DISCOUNT_MAP[plan]}%</div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.currentCard}>
              <div>Using the card on file:</div>
              <div className={styles.cardInfo}>
                <CardFlag flag={cards.get('brand')} />
                <span className={styles.cardnumberPlaceholder}>●●●● ●●●●● ●●●●</span>
                {cards.get('last_4_digits')}
              </div>
              <p>
                <Link to="/publisher/organization/billing#cardchange">Change card</Link>
              </p>
            </div>
          )}
          <div className={styles.payAndUpgrade}>
            {isLoadingSubscribe ? (
              <Loading isLoading />
            ) : (
              <Button onClick={this.upgrade}>Upgrade</Button>
            )}
            {error.size ? <BlockFieldFeedback errorMessage={cardErrors} /> : null}
            <p className={styles.securePayment}>
              <Icon icon={faLock} />
              Secure credit card payment
            </p>
            <p className={styles.additionalInfo}>
              * To include additional information (i.e. Address, VAT number) on your invoice, please
              update your organization info before proceeding.
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectStripe(UpgradeAccountPayment))
