import React from 'react'
import PropTypes from 'prop-types'

let div
const Svg = ({ svg, width, height, className, alt, ...otherProps }) => {
  const match = svg.url.match(/#(.*)/)
  const id = match && match[0]

  if (!div && typeof window !== 'undefined' && typeof document !== 'undefined' && XMLHttpRequest) {
    const matchURL = svg.url.match(/(.*)#/)
    const spriteURL = matchURL && matchURL[1]
    const ajax = new XMLHttpRequest()
    div = document.createElement('div')
    div.style.display = 'none'
    ajax.open('GET', spriteURL, true)
    ajax.send()
    ajax.onload = () => {
      div.innerHTML = ajax.responseText
      document.body.insertBefore(div, document.body.childNodes[0])
    }
  }

  return (
    <svg
      alt={alt}
      data-url={svg}
      className={className}
      width={width}
      height={height}
      {...otherProps}
    >
      <use xlinkHref={id} />
    </svg>
  )
}

Svg.propTypes = {
  svg: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
}

Svg.defaultProps = {
  width: '22px',
  height: '22px',
  className: undefined,
  alt: undefined,
}

export default Svg
