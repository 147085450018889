import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router'
import classnames from 'classnames'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import ReactTooltip from 'react-tooltip'

import { format, isAfterNow } from '../../utils/datetime'
import { HUMAN_FORMAT_NOTIME } from '../../config/time-settings'
import Image from '../block-image'
import If from '../if'
import Icon from '../piece-icon'
import Platforms from '../piece-platform-icon/platforms'
import Paid from '../effect-paid'
import Actionable from '../actionable'
import Proactive from '../effect-proactive'
import Loading from '../piece-loading'
import DatetimeCountDown from '../datetime-count-down'
import Svg from '../svg'
import videoSlash from '../../img/ic-video-slash.svg'
import UnclaimedCodes from '../unclaimed-codes'
import InfoLabel from '../info-label'

import Bookmark from './bookmark'
import styles from './styles.css'

const WHITELISTED_TIP = "You're whitelisted"

const OfferCard = ({
  platforms,
  discloseAllPlatforms,
  title,
  isPaid,
  isWhitelisted,
  isProactive,
  embargoEnds,
  image,
  publisher,
  href,
  imageOpacity,
  children,
  headChildren,
  onRejectOffer,
  isFeatured,
  offerId,
  campaignId,
  isLoadingRejection,
  onBookmark,
  bookmark,
  invitedAt,
  inviteDuration,
  isAccepted,
  unclaimedCodeCount,
  isEligible,
  isReleased,
  releaseAt,
}) => (
  <li
    className={classnames(styles.blockItem, {
      [styles.featured]: isFeatured && !isProactive,
      [styles.proactive]: isProactive,
    })}
  >
    {isPaid && !isProactive ? <Paid paid={isPaid} /> : null}
    <Proactive proactive={isProactive} />
    {headChildren}
    <Bookmark onBookmark={onBookmark} bookmark={bookmark} campaignId={campaignId} />
    <div className={classnames(styles.blockImage, { [styles.hasEmbargo]: !!embargoEnds })}>
      <If condition={image}>
        <Link to={href}>
          {isFeatured ? <span className={styles.featuredHover}>Featured</span> : null}
          <Image url={image} alt={title} opacity={imageOpacity} />
          {!isReleased && !releaseAt && (
            <InfoLabel className={styles.releaseAt} text="Coming soon" />
          )}
          {!isReleased && isAfterNow(releaseAt) && (
            <InfoLabel
              className={styles.releaseAt}
              text={`Coming ${format(releaseAt, HUMAN_FORMAT_NOTIME)}`}
            />
          )}
          {!!embargoEnds && (
            <>
              <ReactTooltip
                id={`embargo-${offerId}`}
                multiline
                place="bottom"
                effect="solid"
                className={styles.embargoTooltip}
              >
                <span>This campaign is under an embargo.</span>
                <br />
                {isAccepted
                  ? 'As you agreed, please do not deliver a video or stream of the game until:'
                  : 'No video or stream on this game can be delivered before:'}
                <br />
                <strong>{embargoEnds}</strong>
              </ReactTooltip>
              <Svg
                svg={videoSlash}
                className={styles.embargoIcon}
                data-for={`embargo-${offerId}`}
                data-tip
              />
            </>
          )}
        </Link>
      </If>
    </div>
    <div className={styles.blockInner}>
      <Link to={href} className={styles.blockTitle}>
        {title}
      </Link>
      <div className={styles.platform}>
        <Platforms discloseAllPlatforms={discloseAllPlatforms} platforms={platforms} />
        <If condition={publisher}>
          <span className={styles.publisherWrap}>
            <span className={styles.platformSpacer} />
            <span className={styles.publisherText}>{publisher}</span>
          </span>
        </If>
      </div>
      {unclaimedCodeCount > 0 && (
        <div className={styles.unclaimedCodeWrapper}>
          {isEligible && <UnclaimedCodes codeCount={unclaimedCodeCount} />}
        </div>
      )}
      {isProactive ? (
        <div className={styles.timeAndDecline}>
          {invitedAt ? (
            <div>
              <span data-tip="Time left to Claim">
                <Icon icon={faClock} />
              </span>{' '}
              <DatetimeCountDown moment={moment(invitedAt).add(inviteDuration, 'd')} />
            </div>
          ) : null}
          {onRejectOffer && !isLoadingRejection ? (
            <Actionable className={styles.decline} onClick={onRejectOffer} onClickWith={offerId}>
              <span className={styles.iconWrap}>
                <Icon icon={faTimes} />
              </span>{' '}
              Decline
            </Actionable>
          ) : null}
          {onRejectOffer && isLoadingRejection ? (
            <div className={styles.declineLoading}>
              <Loading isLoading small />
            </div>
          ) : null}
        </div>
      ) : null}
      {children || isWhitelisted ? (
        <div className={styles.foot}>
          {isWhitelisted ? (
            <div data-tip={WHITELISTED_TIP} className={styles.whitelistBadge}>
              W
            </div>
          ) : null}
          {children}
        </div>
      ) : null}
    </div>
  </li>
)

OfferCard.propTypes = {
  title: PropTypes.string,
  isPaid: PropTypes.bool,
  isWhitelisted: PropTypes.bool,
  image: PropTypes.string,
  publisher: PropTypes.string,
  href: PropTypes.string,
  imageOpacity: PropTypes.number,
  children: PropTypes.node,
  headChildren: PropTypes.node,
  platforms: Platforms.propTypes.platforms,
  isFeatured: PropTypes.bool,
  discloseAllPlatforms: PropTypes.bool,
  isProactive: PropTypes.bool,
  invitedAt: PropTypes.string,
  inviteDuration: PropTypes.number,
  onRejectOffer: PropTypes.func,
  offerId: PropTypes.string,
  campaignId: PropTypes.string,
  isLoadingRejection: PropTypes.bool,
  bookmark: PropTypes.string,
  onBookmark: PropTypes.func,
  embargoEnds: PropTypes.string,
  isAccepted: PropTypes.bool,
  unclaimedCodeCount: PropTypes.number,
  isEligible: PropTypes.bool,
  isReleased: PropTypes.bool,
  releaseAt: PropTypes.string,
}

OfferCard.defaultProps = {
  title: undefined,
  isPaid: false,
  isWhitelisted: false,
  image: undefined,
  publisher: undefined,
  platforms: undefined,
  children: undefined,
  headChildren: undefined,
  imageOpacity: 1,
  href: undefined,
  isFeatured: false,
  discloseAllPlatforms: false,
  isProactive: false,
  onRejectOffer: undefined,
  offerId: undefined,
  campaignId: undefined,
  isLoadingRejection: false,
  bookmark: null,
  onBookmark: undefined,
  invitedAt: undefined,
  inviteDuration: undefined,
  embargoEnds: undefined,
  isAccepted: false,
  unclaimedCodeCount: undefined,
  isEligible: false,
  isReleased: true,
  releaseAt: undefined,
}

export default OfferCard
