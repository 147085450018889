import { Map, OrderedMap, fromJS } from 'immutable'

import getActionName from '../../utils/get-action-name'
import isNumber from '../../utils/is-number'

import { getHashCodeFromObject } from './selectors'

const generateId = (obj) => {
  if (obj.id) {
    return String(obj.id)
  }
  return undefined
}

export const pagination = (state = new Map(), { payload, type, meta = {} }) => {
  if (meta.page && payload && isNumber(payload.count)) {
    const { offset = 0, ...page } = meta.page
    const pageHash = getHashCodeFromObject(page)
    const typeName = (meta.type && getActionName(meta.type)) || getActionName(type)

    const map = fromJS({
      ...payload,
      results: payload.results.map(generateId).filter(Boolean),
    })

    return state.updateIn([typeName, pageHash], (pageData = new OrderedMap()) =>
      pageData.set(String(offset), map)
    )
  }

  return state
}
