import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ReactTooltip from 'react-tooltip'

import Svg from '../svg'
import Actionable from '../actionable'
import * as platf from '../../config/game-code-types'
import steam from '../../img/ic_steam.svg'
import oculus from '../../img/ic_oculus.svg'
import gog from '../../img/ic_gog.svg'
import xbox from '../../img/ic_xbox.svg'
import ios from '../../img/ic_ios.svg'
import ps4 from '../../img/ic_ps4.svg'
import android from '../../img/ic_android.svg'
import custom from '../../img/ic_custom.svg'
import nintendo from '../../img/ic_nintendo.svg'
import origin from '../../img/ic_origin.svg'
import discord from '../../img/ic_discord.svg'
import epic from '../../img/ic_epic.svg'
import battlenet from '../../img/ic_battlenet.svg'

import styles from './styles.css'

const platforms = {
  [platf.STEAM]: steam,
  [platf.STEAMVR]: steam,
  [platf.OCULUS]: oculus,
  [platf.RIFT]: oculus,
  [platf.GOOD_OLD_GAMES]: gog,
  [platf.XBOX]: xbox,
  [platf.XBOX_NA]: xbox,
  [platf.XBOX_EUROPE]: xbox,
  [platf.XBOX_JAPAN]: xbox,
  [platf.XBOX_SERIES_XS_EUROPE]: xbox,
  [platf.XBOX_SERIES_XS_GLOBAL]: xbox,
  [platf.XBOX_SERIES_XS_NA]: xbox,
  [platf.XBOX_SERIES_XS_JAPAN]: xbox,
  [platf.XBOX_ONE_AND_SERIES_XS]: xbox,
  [platf.XBOX_ONE_AND_SERIES_XS_AND_PC]: xbox,
  [platf.PS4]: ps4,
  [platf.PS4_AMERICAS]: ps4,
  [platf.PS4_EUROPE_AUSTRALIA]: ps4,
  [platf.PS4_EUROPE]: ps4,
  [platf.PS4_AUSTRALIA]: ps4,
  [platf.PS4_JAPAN]: ps4,
  [platf.PS4_KOREA]: ps4,
  [platf.PS4_ASIA]: ps4,
  [platf.PS5]: ps4,
  [platf.PS5_AMERICAS]: ps4,
  [platf.PS5_EUROPE]: ps4,
  [platf.PS5_AUSTRALIA]: ps4,
  [platf.PS5_JAPAN]: ps4,
  [platf.PS5_KOREA]: ps4,
  [platf.PS5_ASIA]: ps4,
  [platf.PS5_US]: ps4,
  [platf.PS5_LATAM]: ps4,
  [platf.PSVR]: ps4,
  [platf.PSVR_NA]: ps4,
  [platf.PSVR_EUROPE]: ps4,
  [platf.IOS]: ios,
  [platf.ANDROID]: android,
  [platf.CUSTOM]: custom,
  [platf.NINTENDO_SWITCH]: nintendo,
  [platf.NINTENDO_SWITCH_AMERICAS]: nintendo,
  [platf.NINTENDO_SWITCH_EUROPE]: nintendo,
  [platf.NINTENDO_SWITCH_AUNZ]: nintendo,
  [platf.NINTENDO_SWITCH_JAPAN]: nintendo,
  [platf.NINTENDO_SWITCH_US]: nintendo,
  [platf.NINTENDO_SWITCH_LATAM]: nintendo,
  [platf.ORIGIN]: origin,
  [platf.DISCORD]: discord,
  [platf.EPIC]: epic,
  [platf.BATTLENET]: battlenet,
}

const PlatformIcon = ({
  platform,
  withName,
  dark,
  width,
  height,
  outOfCodes,
  onClick,
  onClickWith,
}) => {
  const platformContent = (
    <Fragment>
      <ReactTooltip class={styles.toolTip} multiline place="top" effect="solid" />
      <Svg
        data-tip={`${platf.CODE_TYPES[platform]} ${!outOfCodes ? '' : ' - Out of codes'}`}
        className={classnames(styles.svg, { [styles.dark]: dark, [styles.outOfCodes]: outOfCodes })}
        alt={platf.CODE_TYPES[platform]}
        width={`${width}px`}
        height={`${height}px`}
        svg={platforms[platform]}
      />
      {withName ? (
        <span className={classnames(styles.platformName, { [styles.exhausted]: outOfCodes })}>
          {platf.CODE_TYPES[platform]}
        </span>
      ) : null}
    </Fragment>
  )
  if (onClick) {
    return (
      <Actionable
        skipOwnClass
        className={classnames(styles.container, styles.actionable)}
        onClick={onClick}
        onClickWith={[platform, onClickWith]}
      >
        {platformContent}
      </Actionable>
    )
  }

  return <span className={styles.container}>{platformContent}</span>
}

PlatformIcon.propTypes = {
  platform: PropTypes.string.isRequired,
  withName: PropTypes.bool,
  dark: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  outOfCodes: PropTypes.bool,
  onClick: PropTypes.func,
  onClickWith: PropTypes.any, // eslint-disable-line react/forbid-prop-types
}

PlatformIcon.defaultProps = {
  onClick: undefined,
  onClickWith: undefined,
  withName: false,
  outOfCodes: false,
  dark: false,
  width: 20,
  height: 20,
}

export default PlatformIcon
