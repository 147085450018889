import PropTypes from 'prop-types'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Icon = ({ className, icon, style, ...props }) => {
  return <FontAwesomeIcon className={className} icon={icon} style={style} {...props} />
}

Icon.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.instanceOf(Object),
  ]).isRequired,
  className: PropTypes.string,
  style: PropTypes.instanceOf(Object),
}

Icon.defaultProps = {
  className: undefined,
  style: undefined,
}

export default Icon
