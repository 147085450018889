import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'

import * as streamersService from 'services/streamers'
import Select from 'components/piece-field-select'

import styles from './styles.css'

const MIN_INPUT_SEARCH = 2
const DEBOUNCE_DELAY = 500

const mapStateToProps = ({ user }) => ({
  user,
})

const renderCreatorsValue = (value) => `Creator: ${value.label}`

const CreatorsSelect = ({ value, onSelectChange, user }) => {
  const [placeholder, setPlaceholder] = useState('Creators: All')
  const [options, setOptions] = useState([])

  const getCreatorsSelectOption = ({ streamer: { slug } }) => {
    return {
      value: slug,
      label: slug,
    }
  }

  const removeEmptySlugs = ({ streamer: { slug } }) => !!slug

  const searchCreator = (searchTerm, callback) =>
    streamersService
      .search(user.get('auth_token'))({ value: searchTerm })
      .then((response) => {
        const newOptions = response.filter(removeEmptySlugs).map(getCreatorsSelectOption)
        setOptions(newOptions)
        callback(newOptions)
      })

  useEffect(() => {
    if (value) {
      searchCreator(value, () => {})
    }
  }, [value])

  const debouncedFetch = debounce((searchTerm, callback) => {
    searchCreator(searchTerm, callback)
  }, DEBOUNCE_DELAY)

  const onSearchCreator = (inputValue = '', callback) => {
    if (inputValue.length < MIN_INPUT_SEARCH) {
      setOptions([])
      callback([])
      return
    }

    debouncedFetch(inputValue, callback)
  }

  const onFocus = () => setPlaceholder('Type to search...')
  const onBlur = () => setPlaceholder('Creators: All')

  const getValue = (newValue) => {
    if (!newValue) {
      return null
    }
    // eslint-disable-next-line eqeqeq
    return options.find((o) => o.value == newValue)
  }

  return (
    <Select
      className={styles.select}
      valueRenderer={renderCreatorsValue}
      name="creator"
      isClearable
      value={value}
      onChange={onSelectChange}
      placeholder={placeholder}
      onFocus={onFocus}
      onBlur={onBlur}
      isAsync
      loadOptions={onSearchCreator}
      getValue={getValue}
    />
  )
}

CreatorsSelect.propTypes = {
  value: PropTypes.string,
  onSelectChange: PropTypes.func,
  user: ImmutablePropTypes.map,
}

CreatorsSelect.defaultProps = {
  value: undefined,
  onSelectChange: () => {},
  user: new Map(),
}

export default connect(mapStateToProps)(CreatorsSelect)
