import React from 'react'
import { Route, IndexRedirect } from 'react-router'
import Loadable from 'react-loadable'

import Loading from '../../components/piece-loading-async-route'

const loading = () => <Loading />

const OrganizationSettings = Loadable({
  loader: () =>
    import(/* webpackChunkName: "organizationsettings" */ '../../views/organization-settings'),
  loading,
})

const OrganizationSettingsOrganization = Loadable({
  loader: () =>
    import(/* webpackChunkName: "organizationsettings" */ '../../views/organization-settings/organization'),
  loading,
})

const OrganizationSettingsAdmin = Loadable({
  loader: () =>
    import(/* webpackChunkName: "organizationsettings" */ '../../views/organization-settings/admin'),
  loading,
})

const OrganizationSettingsInviteUserModal = Loadable({
  loader: () =>
    import(/* webpackChunkName: "organizationsettings" */ '../../views/organization-settings/invite-user-modal'),
  loading,
})

const AccountSettingsBilling = Loadable({
  loader: () =>
    import(/* webpackChunkName: "organizationsettings" */ '../../views/organization-settings/billing'),
  loading,
})

const StripeProvider = Loadable({
  loader: () =>
    import(/* webpackChunkName: "organizationsettings" */ '../../components/stripe-provider'),
  loading,
})

const OrganizationSettingsOrgs = Loadable({
  loader: () =>
    import(/* webpackChunkName: "organizationsettings" */ '../../views/organization-settings/orgs'),
  loading,
})

const OrganizationSettingsMe = Loadable({
  loader: () =>
    import(/* webpackChunkName: "organizationsettings" */ '../../views/organization-settings/me'),
  loading,
})

const Organization = () => (
  <Route path="/publisher/organization" component={OrganizationSettings}>
    <IndexRedirect to="profile" />
    <Route path="profile" component={OrganizationSettingsOrganization} />
    <Route component={StripeProvider}>
      <Route path="billing" component={AccountSettingsBilling} />
    </Route>
    <Route path="team-members" component={OrganizationSettingsAdmin}>
      <Route path="invite" component={OrganizationSettingsInviteUserModal} />
    </Route>
    <Route path="orgs" component={OrganizationSettingsOrgs} />
    <Route path="me" component={OrganizationSettingsMe} />
  </Route>
)

export default Organization
