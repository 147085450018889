import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ImmutableProptypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router'
import classnames from 'classnames'
import throttle from 'lodash.throttle'

import Svg from 'components/svg'
import logo from 'img/logo-woovit.svg'
import gaming from 'img/landing-background.jpg'
import gaming2x from 'img/landing-background@2x.jpg'
import gaming3x from 'img/landing-background@3x.jpg'
import screens from 'img/screens.svg'
import benefit1 from 'img/benefit-1.svg'
import benefit2 from 'img/benefit-2.svg'
import benefit3 from 'img/benefit-3.svg'
import benefit4 from 'img/benefit-4.svg'
import benefit5 from 'img/benefit-5.svg'
import benefit6 from 'img/benefit-6.svg'
import benefit7 from 'img/benefit-7.svg'
import benefit8 from 'img/benefit-8.svg'
import benefit9 from 'img/benefit-9.svg'
import { getUsersStats as getUsersStatsAction } from 'modules/stats/actions'
import Footer from 'components/footer'
import AuthBar from 'components/auth-bar'
import logoSega from 'img/customers/sega.svg'
import logoCreativeAssembly from 'img/customers/creative-assembly.svg'
import logoGog from 'img/customers/gog.svg'
import logoAtlus from 'img/customers/atlus.svg'
import logoGungHo from 'img/customers/gungho.svg'
import logoParadox from 'img/customers/paradox.svg'
import logoTwitch from 'img/platforms/twitch-logo.svg'
import logoYoutube from 'img/platforms/youtube-logo.svg'
import logoQuanticDream from 'img/customers/quantic-dream.svg'
import logoBlizzard from 'img/customers/blizzard.svg'

import UpgradeAccount from '../upgrade-account'

import styles from './styles.css'

const WOOVIT_DESCRIPTION =
  'Woovit connects publishers and brands with thousands of authenticated content creators on Twitch and YouTube. Join for free!'

const numberToLocale = (number = '-') => number.toLocaleString()

const BENEFITS = [
  {
    id: 1,
    icon: benefit1,
    getDescription: () => (
      <p>Instantly verify and authenticate inbound requests from influencers</p>
    ),
  },
  {
    id: 2,
    icon: benefit2,
    getDescription: () => (
      <p>Track content deliveries for all those who engage with your campaign</p>
    ),
  },
  {
    id: 3,
    icon: benefit3,
    getDescription: (stats) => (
      <p>
        Search our database of{' '}
        <Link to="/stats">
          <var>{numberToLocale(stats.get('creators'))}</var> verified influencers
        </Link>{' '}
        for new partnerships
      </p>
    ),
  },
  {
    id: 4,
    icon: benefit4,
    getDescription: () => (
      <p>Create and share your custom campaign link on emails, social posts, etc.</p>
    ),
  },
  { id: 5, icon: benefit5, getDescription: () => <p>Manage existing and new relationships</p> },
  { id: 6, icon: benefit6, getDescription: () => <p>Get performance reports and graphs</p> },
  {
    id: 7,
    icon: benefit7,
    getDescription: () => <p>Collaborate with your team members and agency reps</p>,
  },
  {
    id: 8,
    icon: benefit8,
    getDescription: () => <p>Currently supporting Twitch and YouTube</p>,
  },
]

const mapStateToProps = (state) => {
  return {
    stats: state.stats.get('users', new Map()),
  }
}

const mapDispatchToProps = {
  getUsersStats: getUsersStatsAction,
}

const HEADER_TOP_OFFSET = 600

const Home = ({ getUsersStats, stats }) => {
  const [headerBackgroundOpacity, setHeaderBackgroundOpacity] = useState(
    typeof window !== 'undefined' && window.scrollY / HEADER_TOP_OFFSET
  )
  let onScroll = null

  const updateHeaderVisibility = () => {
    setHeaderBackgroundOpacity(window.scrollY / HEADER_TOP_OFFSET)
  }

  useEffect(() => {
    getUsersStats()
    onScroll = throttle(updateHeaderVisibility, 100)
    window.addEventListener('scroll', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <div className={styles.body}>
      <Helmet>
        <meta property="og:title" content="Woovit" />
        <meta property="description" content={WOOVIT_DESCRIPTION} />
        <meta property="og:description" content={WOOVIT_DESCRIPTION} />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dj4laqzi0/image/upload/v1536949843/campaign/esskemmtcyp5dhhhrme8.jpg"
        />
      </Helmet>

      <div className={styles.main}>
        <img
          sizes="(max-width: 1920px) 100vw, 1920px"
          srcSet={`${gaming} 720w, ${gaming2x} 1080w, ${gaming3x} 1920w`}
          src={gaming}
          alt="Main section background"
          className={styles.background}
        />
        <header
          className={classnames(styles.header, styles.container)}
          style={{ background: `rgba(0, 0, 0, ${headerBackgroundOpacity})` }}
        >
          <Svg svg={logo} alt="Woovit Logo" className={styles.logo} />
          <AuthBar />
        </header>
        <section className={classnames(styles.banner, styles.container)}>
          <h1>
            Video Game Influencer Marketing <br />
            Software and <span>Agency Services</span>
          </h1>

          <div className={styles.services}>
            <div className={styles.service}>
              <h2>Software</h2>
              <p>
                Level-up your workflow and relationship management with <span>authentication</span>,{' '}
                <span>distribution</span>, <span>tracking</span>, <span>reporting</span> and more.{' '}
                <Link to="/signup/publisher">Sign up now</Link>
              </p>
            </div>
            <div className={classnames(styles.service, styles.agencyService)}>
              <h2>Agency</h2>
              <p>
                Need more than software? Hire our influencer relations team to plan and execute your
                campaign. Connect with us at <a href="mailto:hello@woovit.com">hello@woovit.com</a>
              </p>
            </div>
          </div>

          <p className={styles.servicing}>
            <Link to="/stats">
              Engaging creators on <Svg className={styles.platformLogo} svg={logoTwitch} /> and{' '}
              <Svg height="20px" width="90px" className={styles.platformLogo} svg={logoYoutube} />
            </Link>
          </p>
        </section>
      </div>
      <section className={styles.signupContainer}>
        <Link to="/signup/creator" className={styles.creatorSignupSection}>
          <div>
            <h3>Are you a Content Creator?</h3>
            <p>
              Join{' '}
              <strong>
                <var>{numberToLocale(stats.get('creators'))}</var>
              </strong>{' '}
              other creators and get access to game offers right away.
            </p>
          </div>
          <div className={styles.signupBtn}>Sign up now</div>
        </Link>
      </section>
      <section className={classnames(styles.problems, styles.container)}>
        <div className={styles.screens}>
          <Svg svg={screens} alt="Screens" />
        </div>
        <div className={styles.problemsText}>
          <h2 className={styles.subtitle}>ARE YOU...</h2>
          <p>
            <span className={styles.strategicSupport}>
              Looking for <strong>strategic support</strong> to maximize your campaign?
            </span>
            <br />
            <strong>Spending time</strong> verifying all the inbound requests?
            <br />
            <strong>Watching hours</strong> of video/streams for relevance and reporting?
            <br />
            <strong>Needing reports</strong> on all the video content you’re generating?
            <br />
            <strong>Copy-pasting</strong> codes out of a spreadsheet?
            <br />
            Worried about <strong>fraud</strong>?
            <br />
            <strong>Mail-merging</strong> out codes that go unused?
            <br />
            Having trouble <strong>coordinating</strong> with colleagues?
          </p>
        </div>
      </section>
      <section className={classnames(styles.benefits, styles.container)}>
        <h2 className={styles.subtitle}>WITH WOOVIT YOU CAN</h2>
        <ul>
          {BENEFITS.map((benefit) => {
            const { id, icon, getDescription } = benefit
            return (
              <li key={id}>
                <Svg svg={icon} alt={`Benefit ${id}`} className={styles.benefitIcon} />
                {getDescription(stats)}
              </li>
            )
          })}
        </ul>
        <p className={styles.relationsTeam}>
          <Svg svg={benefit9} alt="team collab" className={styles.benefitIcon} />
          …or hire our influencer relations team to lead or support the execution of your campaign.
          Whether it be earned or sponsored content, tell us your objectives and we’ll help form a
          scope and budget. Contact us at <a href="mailto:hello@woovit.com">hello@woovit.com</a>
        </p>
      </section>
      <section className={classnames(styles.customersSection, styles.container)}>
        <h2 className={styles.subtitle}>SOME OF OUR CUSTOMERS</h2>
        <div className={styles.customers}>
          <Svg svg={logoSega} alt="SEGA Logo" width="160" height="180" />
          <Svg svg={logoParadox} alt="Paradox Logo" width="160" height="120" />
          <Svg svg={logoGog} alt="GOG Logo" width="140" height="100" />
          <Svg svg={logoAtlus} alt="Atlus Logo" width="180" height="200" />
          <Svg svg={logoCreativeAssembly} alt="Creative Assembly Logo" width="140" height="100" />
          <Svg svg={logoGungHo} alt="GungHo Logo" width="130" height="100" />
          <Svg svg={logoQuanticDream} alt="Quantic Dream Logo" width="200" height="120" />
          <Svg svg={logoBlizzard} alt="Blizzard Logo" width="130" height="100" />
        </div>
      </section>
      <section className={classnames(styles.publisherSignupSection, styles.container)}>
        <p>
          More than{' '}
          <strong>
            <var>{numberToLocale(stats.get('publishers'))}</var> companies
          </strong>{' '}
          have published campaigns on Woovit. <strong>Join them now!</strong>
        </p>
        <Link to="/signup/publisher">Sign up</Link>
      </section>
      <section>
        <UpgradeAccount className={classnames(styles.pricing, styles.container)} />
      </section>
      <Footer />
    </div>
  )
}

Home.propTypes = {
  getUsersStats: PropTypes.func.isRequired,
  stats: PropTypes.oneOfType([PropTypes.object, ImmutableProptypes.map]).isRequired,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home)
