import React from 'react'
import { Route } from 'react-router'
import Loadable from 'react-loadable'

import Loading from '../../components/piece-loading-async-route'

const loading = () => <Loading />

const Campaigns = Loadable({
  loader: () => import(/* webpackChunkName: "campaignlist" */ '../../views/campaigns'),
  loading,
})

const CampaignsDrafting = Loadable({
  loader: () => import(/* webpackChunkName: "campaignlist" */ '../../views/campaigns/drafting'),
  loading,
})

const CampaignsScheduled = Loadable({
  loader: () => import(/* webpackChunkName: "campaignlist" */ '../../views/campaigns/scheduled'),
  loading,
})

const CampaignsActive = Loadable({
  loader: () => import(/* webpackChunkName: "campaignlist" */ '../../views/campaigns/active'),
  loading,
})

const CampaignsStopped = Loadable({
  loader: () => import(/* webpackChunkName: "campaignlist" */ '../../views/campaigns/stopped'),
  loading,
})

const CampaignPremiumFeatureModal = Loadable({
  loader: () =>
    import(/* webpackChunkName: "campaignlist" */ '../../views/campaigns/premium-feature-modal'),
  loading,
})

const CampaignList = () => (
  <Route component={Campaigns}>
    <Route path="drafting" component={CampaignsDrafting} skipScrollUpOnOffset />
    <Route path="scheduled" component={CampaignsScheduled} skipScrollUpOnOffset />
    <Route path="active" component={CampaignsActive} skipScrollUpOnOffset>
      <Route path="premium-feature" component={CampaignPremiumFeatureModal} />
    </Route>
    <Route path="stopped" component={CampaignsStopped} skipScrollUpOnOffset />
  </Route>
)

export default CampaignList
