import React from 'react'
import { Route, Redirect } from 'react-router'
import Loadable from 'react-loadable'

import Loading from 'components/piece-loading-async-route'

const loading = () => <Loading />

const Offers = Loadable({
  loader: () => import(/* webpackChunkName: "offerlist" */ 'views/offers'),
  loading,
})

const OffersAvailable = Loadable({
  loader: () => import(/* webpackChunkName: "offerlist" */ 'views/offers/available'),
  loading,
})

const OffersPending = Loadable({
  loader: () => import(/* webpackChunkName: "offerlist" */ 'views/offers/pending'),
  loading,
})

const OffersProactive = Loadable({
  loader: () => import(/* webpackChunkName: "offerlist" */ 'views/offers/proactive'),
  loading,
})

const OffersAccepted = Loadable({
  loader: () => import(/* webpackChunkName: "offerlist" */ 'views/offers/accepted'),
  loading,
})

const OffersDelivered = Loadable({
  loader: () => import(/* webpackChunkName: "offerlist" */ 'views/offers/delivered'),
  loading,
})

const OffersArchived = Loadable({
  loader: () => import(/* webpackChunkName: "offerlist" */ 'views/offers/archived'),
  loading,
})

const OfferList = () => (
  <Route component={Offers}>
    <Redirect from="claimed" to="accepted" />
    <Route path="available" stickyFooter component={OffersAvailable} />
    <Route path="pending" component={OffersPending} />
    <Route path="offered" component={OffersProactive} />
    <Route path="accepted" component={OffersAccepted} />
    <Route path="delivered" component={OffersDelivered} />
    <Route path="archived" component={OffersArchived} />
    <Route skipScrollUp path="coming-soon" component={OffersAvailable} />
  </Route>
)

export default OfferList
