import * as request from './request'
import instance from './request-v2'

export const getBySlug = (key) => (slug) =>
  request.get(['public-profiles', 'find_by_slug'], { key, params: { slug } })

const STREAMER_SEARCH_DEFAULTS = { limit: 10, ordering: 'relevance' }

export const search = (key) => (searchTerm, params = {}) =>
  request
    .get('public-profiles', {
      key,
      params: { ...STREAMER_SEARCH_DEFAULTS, search: searchTerm, ...params },
    })
    .then((payload) => ({
      ...payload,
      results: payload.results.map((result) => ({
        ...result,
        id: `${searchTerm}+${result.profile.id}`,
      })),
    }))

export const list = (key) => (params = {}) =>
  instance
    .get('simple-public-profiles/', {
      params,
      key,
    })
    .then((response) => response.data)

export const getNotes = (key) => (creatorId) =>
  request.get('creator-notes', { key, params: { creator_id: creatorId, limit: 40 } })

export const deleteNote = (key) => (noteId) => request.del(['creator-notes', noteId], { key })

export const createNote = (key) => (creatorId, note, createFeedEvent = true) =>
  request.post(
    'creator-notes',
    { key },
    { creator: creatorId, note, create_feed_event: createFeedEvent }
  )

export const getPlayedGames = (key) => (creatorId) =>
  instance
    .get(`analytics/twitch-played-games/${creatorId}/`, { key })
    .then((response) => response.data)

export const getSteamPlayedGames = (key) => (creatorId) =>
  instance
    .get(`analytics/steam-played-games/${creatorId}/`, { params: { top_n_games: 40 }, key })
    .then((response) => response.data)

export const getDeliveryStats = (key) => (creatorId) =>
  instance.get(`analytics/codes-and-deliveries/${creatorId}/`, {
    params: { only_my_data: false },
    key,
  })

export const getDeliveryDelay = (key) => (creatorId) =>
  instance.get(`analytics/delivery-delay/${creatorId}/`, {
    params: { only_my_data: false },
    key,
  })

export const getSimilarCreators = (key) => (creatorId) =>
  instance.get(`recommendation/twitch-played-games/${creatorId}/`, {
    key,
  })
