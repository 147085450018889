import { Map, fromJS } from 'immutable'

import createReducer from '../../utils/create-reducer'

import { SHOW_ALERT_LAYER, CLOSE_ALERT_LAYER } from './actions'

export const INITIAL_STATE = new Map({ visible: false, message: null })

export const uiAlert = createReducer(INITIAL_STATE, {
  [SHOW_ALERT_LAYER]: (state, { payload }) =>
    fromJS({
      visible: true,
      message: payload.message,
      type: payload.type,
      delay: payload.delay,
    }),

  [CLOSE_ALERT_LAYER]: (state) =>
    fromJS({
      visible: false,
      type: state.get('type'),
      message: state.get('message'),
    }),
})
