import { Map, fromJS, List } from 'immutable'
import { normalize, schema } from 'normalizr'
import flatten from 'lodash.flatten'

import createReducer from '../../utils/create-reducer'
import { SEARCH_CREATORS } from '../creator/actions'

import { GET_PUBLIC_VIDEOS, GET_CREATOR_VIDEOS, SEARCH_VIDEOS } from './actions'

export const INITIAL_STATE = new List()

export const featuredVideos = createReducer(INITIAL_STATE, {
  [GET_PUBLIC_VIDEOS.FULFILLED]: (state, { payload }) => fromJS(payload),
})

export const videoSchema = new schema.Entity('video')

const defaultMerge = (state, { payload }) =>
  state.mergeDeep(normalize(payload.results, [videoSchema]).entities.video)

export const video = createReducer(new Map(), {
  [GET_CREATOR_VIDEOS.FULFILLED]: defaultMerge,
  [SEARCH_VIDEOS.FULFILLED]: defaultMerge,

  [SEARCH_CREATORS.FULFILLED]: (state, { payload }) => {
    const videos = payload.results.map((result) => result.videos)
    return state.mergeDeep(normalize(flatten(videos), [videoSchema]).entities.video)
  },
})
