import defineAction from '../../utils/define-action'
import * as proactiveService from '../../services/proactive'

export const CLAIM_OFFER = defineAction('CLAIM_OFFER')
export const REJECT_OFFER = defineAction('REJECT_OFFER')
export const INVITE_CREATOR = defineAction('INVITE_CREATOR')
export const GET_PROACTIVE_OFFERS = defineAction('GET_PROACTIVE_OFFERS')
export const INVITE_MULTIPLE_CREATORS = defineAction('INVITE_MULTIPLE_CREATORS')

export const claimOffer = (offerId, platform) => (dispatch, getState) =>
  dispatch({
    type: CLAIM_OFFER,
    payload: proactiveService.claim(getState().user.get('auth_token'))(offerId, platform),
  })

export const rejectOffer = (offerId) => (dispatch, getState) =>
  dispatch({
    type: REJECT_OFFER,
    payload: proactiveService.reject(getState().user.get('auth_token'))(offerId),
  })

export const inviteCreator = (
  creatorId,
  campaignId,
  platforms,
  expirationDays,
  inviteLetter,
  inviteSubject
) => (dispatch, getState) =>
  dispatch({
    type: INVITE_CREATOR,
    payload: proactiveService.invite(getState().user.get('auth_token'))(
      [creatorId],
      campaignId,
      platforms,
      expirationDays,
      undefined,
      inviteLetter,
      inviteSubject
    ),
  })

export const getProactiveOffers = (params) => (dispatch, getState) =>
  dispatch({
    type: GET_PROACTIVE_OFFERS,
    payload: proactiveService.getOffers(getState().user.get('auth_token'))(params),
  })

export const inviteCreators = ({
  creators,
  campaignId,
  platforms,
  expirationDays,
  inviteToTry = false,
  inviteLetter,
  inviteSubject,
}) => (dispatch, getState) =>
  dispatch({
    type: INVITE_MULTIPLE_CREATORS,
    payload: proactiveService.invite(getState().user.get('auth_token'))(
      creators,
      campaignId,
      platforms,
      expirationDays,
      inviteToTry,
      inviteLetter,
      inviteSubject
    ),
  })
