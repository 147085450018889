import React from 'react'
import moment from 'moment'

import { TWITCH_IFRAME_PARENT } from '../config/environment'
import { PLATFORM_YOUTUBE, PLATFORM_TWITCH } from '../config/settings'

const formatStartTime = (duration) =>
  moment.utc(moment.duration(duration).asMilliseconds()).format('HH[h]mm[m]ss[s]')

const getTwitchEmbed = (videoId, autoplay = 'false', time = undefined) => (
  <iframe
    src={`https://player.twitch.tv/?video=${videoId}&autoplay=${autoplay}&parent=${TWITCH_IFRAME_PARENT}&time=${formatStartTime(
      time
    )}`}
    width="100%"
    height="100%"
    frameBorder="0"
    scrolling="no"
    allowFullScreen
    title="vide embed"
  />
)

const getYoutubeEmbed = (videoId, autoPlay = '0') => (
  <iframe
    width="100%"
    height="100%"
    src={`https://www.youtube.com/embed/${videoId}?autoplay=${autoPlay}`}
    frameBorder="0"
    allowFullScreen
    title="video embed"
  />
)

const selectEmbed = (videoId, platformId, time) => {
  if (platformId === PLATFORM_YOUTUBE) {
    return getYoutubeEmbed(videoId, '1')
  }
  if (platformId === PLATFORM_TWITCH) {
    return getTwitchEmbed(videoId, 'true', time)
  }
  return null
}

const getEmbed = (videoId, platformId, time) => selectEmbed(videoId, parseInt(platformId, 10), time)

export default getEmbed
