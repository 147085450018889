import { get, del, post, patch } from './request'

export const fetch = (key) => ({ offset, paginated, ordering, hasCount }) =>
  get('whitelist', {
    key,
    params: { offset, paginated: paginated ? 0 : null, ordering, has_counts: hasCount },
  })

export const fetchParams = (key) => (params) => get('whitelist', { key, params })

export const fetchOne = (key) => (id, params) => get(['whitelist', id], { key, params })

export const search = (key) => (name) => get(['whitelist', 'search'], { key, params: { name } })

export const remove = (key) => (id) => del(['whitelist', id], { key })

export const create = (key) => (payload) => post('whitelist', { key }, payload)

export const update = (key) => (id, payload) => patch(['whitelist', id], { key }, payload)

export const fetchCreators = (key) => (id, params) =>
  get(['whitelist', id, 'creator'], { key, params })

export const removeCreator = (key) => (whitelistId, creatorId, removeFromAllWhitelists) =>
  del(['whitelist', whitelistId, 'creator', creatorId], {
    key,
    params: { remove_from_all_whitelists: removeFromAllWhitelists },
  })

export const getWhitelistInvitations = (key) => (id, params) =>
  get(['whitelist', id, 'invited'], { key, params })

export const removeWhitelistInvitation = (key) => (whitelistId, whitelistInvitationId) =>
  del(['whitelist', whitelistId, 'invited', whitelistInvitationId], { key })

export const createWhitelistCreator = (key) => (whitelistId, payload) =>
  post(['whitelist', whitelistId, 'creator'], { key }, payload)

export const createWhitelistInvitation = (key) => (whitelistId, payload) =>
  post(['whitelist', whitelistId, 'invited'], { key }, payload)

export const resendWhitelistInvitation = (key) => (whitelistId, whitelistInvitationId) =>
  get(['whitelist', whitelistId, 'invited', whitelistInvitationId, 'resend'], { key })

export const searchByEmail = (key, whitelistId) => (email) =>
  get(['whitelist', whitelistId, 'invited', 'status'], { key, params: { email } })

export const validateWhitelistInvitationEmails = (key) => (whitelistId, payload) =>
  post(['whitelist', whitelistId, 'invited', 'validate'], { key }, payload)

export const getCampaigns = (key) => (whitelistId) =>
  get(['whitelist', whitelistId, 'campaigns'], { key })

export const acceptInvitation = (key) => (payload) => post('whitelist-invitation', { key }, payload)
