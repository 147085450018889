import React from 'react'
import { Helmet } from 'react-helmet'

import styles from './styles.css'

/* eslint max-len: 0, react/no-unescaped-entities: 0 */
const Terms = () => (
  <div className={styles.wrap}>
    <Helmet>
      <title>Terms of Service — Woovit</title>
    </Helmet>
    <h1 className={styles.heading}>Woovit Terms of Service</h1>

    <p>
      Woovit, Inc. (“Woovit,” “we,” or “us”) provides a marketplace that enables intellectual
      property holders to distribute goods to influential consumers of their media (the “Woovit
      services”). We make every effort to protect your content so that it is only distributed to its
      intended recipients but can make no guarantee that will be always effective.
    </p>
    <p>
      We reserve the right to remove participants in the system who we believe are bad actors,
      including but not limited to making false representations of themselves, use of Woovit
      services with an intent to use intellectual property for personal gain, or if there is
      objectionable material or offers posted.
    </p>

    <h2>1. Terms</h2>

    <p>
      By signing up for or using a creator or publisher account for the Woovit services, you are
      agreeing to be bound by Woovit’s current terms of service (located at
      http://www.woovit.com/terms), all applicable laws and regulations, and agree that you are
      responsible for compliance with any applicable local laws. If you do not agree with these
      terms, you may cease use of your Woovit account and/or ask us to delete your account.
    </p>

    <h2>2. Use License</h2>

    <p>
      The materials contained in this website are protected by applicable copyright and trademark
      law. Permission is granted to temporarily download a copy of the materials (information or
      software) constituting the Woovit services for personal or internal business transitory
      viewing only. This is the grant of a license, not a transfer of title, and under this license
      you may not:
    </p>
    <ul>
      <li>modify or copy the materials;</li>
      <li>
        use the materials for any commercial purpose, or for any public display (commercial or
        non-commercial);
      </li>
      <li>attempt to decompile or reverse engineer any software contained on Woovit's website;</li>
      <li>remove any copyright or other proprietary notations from the materials; or</li>
      <li>
        transfer the materials to another person or "mirror" the materials on any other server.
      </li>
    </ul>
    <p>
      This license shall automatically terminate if you violate any of these restrictions and may be
      terminated by Woovit at any time without notice. Upon terminating your viewing of these
      materials or upon the termination of this license, you must destroy any Woovit materials in
      your possession whether in electronic or printed format.
    </p>

    <h2>3. Disclaimer</h2>

    <p>
      The materials on Woovit's website and the Woovit services are provided on an 'as is' basis.
      Woovit makes no warranties, expressed or implied, and hereby disclaims and negates all other
      warranties including, without limitation, implied warranties or conditions of merchantability,
      fitness for a particular purpose, or non-infringement of intellectual property or other
      violation of rights.
    </p>
    <p>
      Further, Woovit does not warrant or make any representations concerning the accuracy, likely
      results, or reliability of the use of the materials on its website or otherwise relating to
      such materials or on any sites linked to ths site. For instance, the materials appearing on
      Woovit's website could include technical, typographical, or photographic errors. Woovit may
      make changes to the materials contained on its website at any time without notice, but does
      not make any commitment to update such materials.
    </p>

    <h2>4. Limitations</h2>

    <p>
      IN NO EVENT SHALL WOOVIT OR ITS SUPPLIERS BE LIABLE FOR ANY DAMAGES (INCLUDING, WITHOUT
      LIMITATION, DAMAGES FOR LOSS OF DATA OR PROFIT, OR DUE TO BUSINESS INTERRUPTION) ARISING OUT
      OF THE USE OR INABILITY TO USE THE MATERIALS ON WOOVIT'S WEBSITE, EVEN IF WOOVIT OR A WOOVIT
      AUTHORIZED REPRESENTATIVE HAS BEEN NOTIFIED ORALLY OR IN WRITING OF THE POSSIBILITY OF SUCH
      DAMAGE. BECAUSE SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES, OR
      LIMITATIONS OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THESE LIMITATIONS MAY NOT
      APPLY TO YOU.
    </p>

    <h2>5. Links</h2>

    <p>
      Woovit has not reviewed all of the sites linked from its website and is not responsible for
      the contents of any such linked site. The inclusion of any link does not imply endorsement by
      Woovit of the site. Use of any such linked website is at the user's own risk.
    </p>

    <h2>6. Modifications to these Terms</h2>
    <p>
      Woovit may revise these terms of service for its website at any time, at its discretion,
      without notice. By using this website you are agreeing to be bound by the then current version
      of these terms of service, which may be found at http://www.woovit.com/terms. Changes to these
      terms of service will be effective as of the date noted on the terms of service. Your
      continued use of the Woovit website or Woovit services after changes to these terms of service
      take effect will constitute your acceptance of the changes. If you do not agree to a change,
      you must stop using the Woovit website and Woovit services, and may ask us to delete your
      account.
    </p>

    <h2>7. Governing Law</h2>

    <p>
      These terms and conditions are governed by and construed in accordance with the laws of
      California and you irrevocably submit to the exclusive jurisdiction of the courts in that
      State or location.
    </p>

    <h2>8. Contacting Us</h2>

    <p>
      If there are any questions regarding this privacy policy, you may contact us using the
      information below.
    </p>
    <p>
      Woovit, Inc.
      <br />
      595 Market St., Suite 1340
      <br />
      San Francisco, CA 94105
      <br />
      United States
      <br />
      hello@woovit.com
    </p>
    <p>Last Edited on 2021-04-05</p>
  </div>
)

export default Terms
