const SI_PREFIXES = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'k' },
  { value: 1e6, symbol: 'M' },
  { value: 1e9, symbol: 'G' },
  { value: 1e12, symbol: 'T' },
  { value: 1e15, symbol: 'P' },
  { value: 1e18, symbol: 'E' },
]

const THOUSAND = 1000
const TEN_THOUSAND = 10000

const getNumber = (number, tier) => {
  if (number < TEN_THOUSAND) {
    return number / tier.value
  }
  return Math.round(number / tier.value)
}
const abbreviateNumber = (number = 0) => {
  if (number === null) return null
  if (number < THOUSAND) return String(number)

  const tier = SI_PREFIXES.filter((n) => number >= n.value).pop()
  const numberFixed = getNumber(number, tier)
    .toFixed(1)
    .replace(/\.?0+$/, '')

  return `${numberFixed}${tier.symbol}`
}

export default abbreviateNumber
