import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { fetchCampaignsByUser, FETCH_CAMPAIGNS_BY_USER } from '../modules/campaign/actions'

const mapStateToProps = (state) => ({
  isLoadingCampaigns: !!state.loading.get(FETCH_CAMPAIGNS_BY_USER.ACTION),
})

const mapDispatchToProps = {
  fetchCampaignsByUser,
}

const CampaignLoader = (WrappedComponent) => {
  class CampaignLoaderComponent extends Component {
    static propTypes = {
      fetchCampaignsByUser: PropTypes.func.isRequired,
      isLoadingCampaigns: PropTypes.bool.isRequired,
    }

    componentDidMount() {
      if (!this.props.isLoadingCampaigns) {
        this.props.fetchCampaignsByUser()
      }
    }

    render() {
      const { isLoadingCampaigns } = this.props
      return <WrappedComponent {...this.props} isLoadingCampaigns={isLoadingCampaigns} />
    }
  }
  CampaignLoaderComponent.WrappedComponent = WrappedComponent

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(CampaignLoaderComponent)
}

export default CampaignLoader
