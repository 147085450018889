import downloadBlob from 'utils/download-blob'

import { HTTP_404_NOT_FOUND } from '../utils/get-status'

import instance from './request-v2'
import { post, get, del } from './request'

export const createCard = (key) => (cardToken) =>
  post('card-info', { key }, { card_token: cardToken })

export const createSubscription = (key) => (newPlans, coupon) =>
  post('subscription', { key }, { plans: newPlans, promotion_code: coupon })

export const subscribe = (key) => (stripePromise, plans, coupon) =>
  stripePromise.then((data) => {
    if (data.error) {
      return Promise.reject(data)
    }
    return createCard(key)(data.token.id).then(() => createSubscription(key)(plans, coupon))
  })

export const addCard = (key) => (stripePromise) =>
  stripePromise.then((data) => {
    if (data.error) {
      return Promise.reject(data)
    }
    return createCard(key)(data.token.id)
  })

export const getCard = (key) => get('card-info', { key })

export const getBillingHistory = (key) => (params) => get('billing-history', { key, params })

const FILENAME_RE = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
export const downloadInvoice = (key) => (invoiceId) =>
  instance
    .get(`billing-history/${invoiceId}/download`, { key, responseType: 'blob' })
    .then((response) => {
      const filename = response.headers['content-disposition']
        .match(FILENAME_RE)[1]
        .replace(/"/g, '')
      downloadBlob(response.data, filename, 'application/pdf')
      return 'OK'
    })
    .catch((er) => {
      if (er.response.status === HTTP_404_NOT_FOUND) {
        return Promise.reject(new Error('Invoice not found.'))
      }
      return er
    })

export const downgrade = (key) => del('subscription', { key })

export const changePlan = (key) => (newPlans) =>
  post(['subscription'], { key }, { plans: newPlans })

export const removeCard = (key) => () => del(['card-info'], { key })
