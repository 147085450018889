import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map } from 'immutable'

import Actionable from '../actionable'
import Image from '../block-image'
import cdnizeAsset from '../../utils/cdnize-asset'
import { IMAGE, VIDEO, getOrder, byOrder } from '../../utils/get-media'
import { buildEmbedYoutubeURL, getYoutubeID } from '../../utils/youtube'

import styles from './styles.css'

class OfferMedia extends Component {
  static propTypes = {
    media: ImmutablePropTypes.list,
  }

  static defaultProps = {
    media: new List(),
  }

  state = {}

  updateImage = (activeImage) => this.setState({ activeImage })

  renderMedia = (media) => {
    if (media.get('type') === IMAGE) {
      return <img src={cdnizeAsset(media.get('url'))} alt="campaign thumbnail" />
    }
    if (media.get('type') === VIDEO) {
      if (getYoutubeID(media.get('url'))) {
        return (
          <iframe
            title="media preview"
            width="100%"
            height="315"
            src={buildEmbedYoutubeURL(media.get('url'))}
            frameBorder="0"
            allowFullScreen
          />
        )
      }
      return (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
          width="100%"
          height="315"
          controls
          poster={cdnizeAsset(media.get('thumbnail_url'))}
          src={cdnizeAsset(media.get('url'))}
          type="video/webm"
        />
      )
    }
    return null
  }

  renderThumbnail = (t) => (
    <div key={t.hashCode()} className={styles.thumbnail}>
      <Actionable onClick={this.updateImage} onClickWith={t}>
        <div className={styles.thumbnailWrap}>
          <Image url={cdnizeAsset(t.get('thumbnail_url'))} alt="campaign thumbnail" />
        </div>
      </Actionable>
    </div>
  )

  getJSONLDData = (media, index) => `
    {
      "@type": "ListItem",
      "position": ${index + 1},
      "url": "${cdnizeAsset(media.get('thumbnail_url'), true)}"
    }`

  render() {
    const { media } = this.props

    const mediaSort = media.sortBy(getOrder, byOrder)
    const { activeImage } = this.state
    const mainMedia = activeImage || mediaSort.first() || new Map()

    return (
      <div className={styles.container}>
        <Helmet
          script={[
            {
              type: 'application/ld+json',
              innerHTML: `{
                "@context": "http://schema.org",
                "@type": "ItemList",
                "itemListElement": [${mediaSort.map(this.getJSONLDData).join(',\n')}]
              }`,
            },
          ]}
        />
        <div className={styles.mainImageWrapper}>{this.renderMedia(mainMedia)}</div>
        <div className={styles.thumbnails}>{mediaSort.map(this.renderThumbnail)}</div>
      </div>
    )
  }
}

export default OfferMedia
