import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles.css'

const Actionable = ({ onClick, onClickWith, children, className, skipOwnClass, ...otherProps }) => {
  const handleClick = () => {
    onClick(onClickWith)
  }
  return (
    <button
      type="button"
      onClick={handleClick}
      className={classnames({ [styles.actionable]: !skipOwnClass }, className)}
      {...otherProps}
    >
      {children}
    </button>
  )
}

Actionable.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  skipOwnClass: PropTypes.bool,
  onClickWith: PropTypes.any, // eslint-disable-line react/forbid-prop-types
}

Actionable.defaultProps = {
  className: '',
  onClickWith: undefined,
  skipOwnClass: false,
}

export default Actionable
