import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { ping } from '../../modules/offline/actions'
import Actionable from '../actionable'

import styles from './styles.css'

const PING_INTERVAL = 1700

const mapStateToProps = ({ offline: isOffline }) => ({ isOffline })
const mapDispatchToProps = {
  ping,
}

class Offline extends Component {
  static propTypes = {
    isOffline: PropTypes.bool.isRequired,
    ping: PropTypes.func.isRequired,
  }

  state = {
    dismiss: false,
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOffline !== this.props.isOffline) {
      // reset internal state after going back online
      this.setState({ dismiss: false })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.isOffline !== this.props.isOffline || nextState.dismiss !== this.state.dismiss
  }

  componentDidUpdate() {
    if (this.props.isOffline) {
      this.pingForNetwork()
    }
  }

  pingForNetwork = () => {
    clearTimeout(this.timeout)
    if (this.props.isOffline) {
      this.props.ping()
      this.timeout = setTimeout(this.pingForNetwork, PING_INTERVAL)
    }
  }

  dismiss = () => {
    this.setState({ dismiss: true })
  }

  render() {
    const { isOffline } = this.props
    const style = {}
    if (isOffline && !this.state.dismiss) {
      style.transform = 'translateY(0) translateX(-50%)'
    }
    if (!isOffline) {
      style.background = '#abe6ab'
    }

    return (
      <div style={style} className={styles.offline}>
        {isOffline ? (
          <Fragment>
            Your device lost its internet connection.{' '}
            <Actionable className={styles.dismiss} skipOwnClass onClick={this.dismiss}>
              Dismiss
            </Actionable>
          </Fragment>
        ) : (
          'Back online!'
        )}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Offline)
