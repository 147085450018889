import { useRef, useEffect } from 'react'

function useOuterClick(callback) {
  const innerRef = useRef()
  const callbackRef = useRef()
  useEffect(() => {
    callbackRef.current = callback
  })
  useEffect(() => {
    function handleClick(e) {
      if (innerRef.current && callbackRef.current && !innerRef.current.contains(e.target)) {
        callbackRef.current(e)
      }
    }
    document.addEventListener('click', handleClick)
    return () => document.removeEventListener('click', handleClick)
  }, [])
  return innerRef
}

export default useOuterClick
