import { get } from './request'

export const getVideoReports = (key) => (id) => get(['campaigns', id, 'video-report'], { key })

export const getDeliveries = (key) => (id, params) =>
  get(['campaigns', id, 'delivery-report'], { key, params })

export const getDailyDeliveries = (key) => (id, params) =>
  get(['campaigns', id, 'daily-deliveries-report'], { key, params })

export const getOffersCompletion = (key) => (id, params) =>
  get(['campaigns', id, 'offer-completion-report'], { key, params })
