import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import { closeAlertLayer as closeAlertLayerAction } from 'modules/ui-alert/actions'
import { SUCCESS, INFO, ERROR, WARNING } from 'config/alert'
import Icon from 'components/piece-icon'
import Button from 'components/piece-button'
import Svg from 'components/svg'
import NoticeIcon from 'img/ic-notice.svg'
import WarningIcon from 'img/ic-warning.svg'
import ErrorIcon from 'img/ic-exclamation-triangle.svg'
import SuccessIcon from 'img/ic-check.svg'

import styles from './styles.css'

const LAYER_DELAY = 4000

export const TYPES = {
  [SUCCESS]: { style: 'success', icon: SuccessIcon },
  [INFO]: { style: 'notice', icon: NoticeIcon },
  [ERROR]: { style: 'error', icon: ErrorIcon },
  [WARNING]: { style: 'warning', icon: WarningIcon },
}

const mapStateToProps = ({ uiAlert }) => ({
  isVisible: uiAlert.get('visible'),
  message: uiAlert.get('message'),
  delay: uiAlert.get('delay'),
  type: TYPES[uiAlert.get('type')] || TYPES[INFO],
})

const mapDispatchToProps = {
  closeAlertLayer: closeAlertLayerAction,
}

export const Alert = ({ message, isVisible, delay, type: { icon, style }, closeAlertLayer }) => {
  let timer = null

  const setTimer = () => {
    clearTimeout(timer)
    timer = setTimeout(closeAlertLayer, delay)
  }
  useEffect(() => {
    setTimer()
    return () => {
      clearTimeout(timer)
    }
  }, [message, isVisible])

  const cssClass = isVisible ? styles.containerVisible : styles.container

  return (
    <div className={classnames(cssClass, styles[style])}>
      <div className={styles.containerMessage}>
        <Svg width="15" height="15" svg={icon} />
        <p>{message}</p>
      </div>
      <Button onClick={closeAlertLayer}>
        <Icon className={styles.icon} icon={faTimes} />
      </Button>
    </div>
  )
}

Alert.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  message: PropTypes.string,
  delay: PropTypes.number,
  closeAlertLayer: PropTypes.func.isRequired,
  type: PropTypes.shape({ style: PropTypes.string, icon: PropTypes.instanceOf(Object) }).isRequired,
}

Alert.defaultProps = {
  delay: LAYER_DELAY,
  message: undefined,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Alert)
