import React, { Component, Fragment } from 'react'
import { Map, List } from 'immutable'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { browserHistory, Link } from 'react-router'
import moment from 'moment'

import Textarea from 'components/textarea'
import Input from 'components/input'
import Label from 'components/piece-label'

import { selectCampaignsForSelect } from '../../modules/campaign/selectors'
import ModalConfirm from '../../components/modal-confirm'
import {
  inviteCreator,
  inviteCreators,
  INVITE_MULTIPLE_CREATORS,
  INVITE_CREATOR,
} from '../../modules/proactive/actions'
import { acceptManualReview, ACCEPT_MANUAL_REVIEW } from '../../modules/manual-review/actions'
import { getCreatorBySlug, selectSelectedCreators } from '../../modules/creator/selectors'
import campaignLoader from '../../hocs/campaign-loader'
import Select from '../../components/piece-field-select'
import PlatformIcon from '../../components/piece-platform-icon'
import Checkbox from '../../components/piece-field-checkbox'
import sumUnclaimedCodes from '../../utils/sum-unclaimed-codes'
import getFollowerDataFromCreator from '../../utils/get-follower-data'
import FollowersButtons from '../../components/followers-buttons'
import Avatar from '../../components/avatar'
import { track } from '../../services/tracker'
import { OFFER_EXPIRATION_DAYS } from '../../config/settings'
import { SEND_OFFER_TO_CREATOR } from '../../config/common-messages'
import SelectableButton from '../../components/selectable-button'
import ManualRequestAction from '../../components/manual-request-action'
import { showAlertLayer as showAlertLayerAction } from '../../modules/ui-alert/actions'
import { SUCCESS } from '../../config/alert'

import styles from './styles.css'

const NO_CODES_AVAILABLE = 'E_NO_CODES_AVAILABLE'
const EXISTING_MANUAL_REVIEW_REQUEST = 'E_MANUAL_REVIEW_REQUEST_ALREADY_EXISTS'
const OFFER_ALREADY_EXISTS = 'E_OFFER_ALREADY_EXISTS'

const getCreatorId = (creator) => creator.get('id')
const expirationDate = (expirationInDays) =>
  moment()
    .add(expirationInDays, 'days')
    .format('MMM D, YYYY')

const mapStateToProps = (state, ownProps) => {
  const {
    params: { slug },
  } = ownProps
  const { loading, error, campaign } = state
  const creator = getCreatorBySlug(state, slug)
  const creatorId = creator && String(getCreatorId(creator))
  return {
    isLoadingInvite:
      !!loading.get(INVITE_CREATOR.ACTION) || !!loading.get(INVITE_MULTIPLE_CREATORS.ACTION),
    isLoadingManualReview: !!loading.get(ACCEPT_MANUAL_REVIEW.ACTION),
    creator: creator || new Map(),
    selectedCreators: ownProps.previouslySelectedCreators || selectSelectedCreators(state),
    organizationName: state.user.getIn(['organization', 'name']),
    campaigns: !ownProps.hideCampaigns ? selectCampaignsForSelect(state, 'active') : new List(),
    campaign,
    acceptManualReviewError: error.get(ACCEPT_MANUAL_REVIEW.ACTION, new Map()),
    error:
      error
        .get(INVITE_CREATOR.ACTION, new Map())
        .mergeDeep(error.get(INVITE_MULTIPLE_CREATORS.ACTION, new Map()))
        .get(creatorId) || new Map(),
  }
}

const OFFERS_SENT_SUCCESS_MESSAGE = 'Offer(s) successfully sent.'

const mapDispatchToProps = {
  inviteCreator,
  inviteCreators,
  acceptManualReview,
  showAlertLayer: showAlertLayerAction,
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
})

class SendOfferModal extends Component {
  static propTypes = {
    acceptManualReviewError: ImmutablePropTypes.map.isRequired,
    campaign: ImmutablePropTypes.map.isRequired,
    inviteCreator: PropTypes.func.isRequired,
    inviteCreators: PropTypes.func.isRequired,
    acceptManualReview: PropTypes.func.isRequired,
    creator: ImmutablePropTypes.map,
    selectedCreators: ImmutablePropTypes.list.isRequired,
    isLoadingInvite: PropTypes.bool.isRequired,
    isLoadingManualReview: PropTypes.bool.isRequired,
    params: PropTypes.shape({
      slug: PropTypes.string,
    }).isRequired,
    campaigns: ImmutablePropTypes.list.isRequired,
    error: ImmutablePropTypes.map.isRequired,
    onCancel: PropTypes.func,
    onInvite: PropTypes.func,
    newCampaign: ImmutablePropTypes.map,
    previouslySelectedCampaign: PropTypes.shape({}),
    previouslySelectedCreators: ImmutablePropTypes.list,
    hideCreators: PropTypes.bool,
    hideCampaigns: PropTypes.bool,
    showAlertLayer: PropTypes.func,
    onConfirm: PropTypes.func,
    organizationName: PropTypes.string,
    headerTitle: PropTypes.string,
  }

  static defaultProps = {
    creator: undefined,
    onCancel: undefined,
    onInvite: undefined,
    headerTitle: undefined,
    newCampaign: null,
    previouslySelectedCampaign: undefined,
    previouslySelectedCreators: undefined,
    hideCreators: false,
    hideCampaigns: false,
    organizationName: '',
    showAlertLayer: () => {},
    onConfirm: () => {},
  }

  state = {
    unselectedCreators: [],
    selectedPlatforms: {},
    selectedCampaign: null,
    selectedOfferExpirationDays: 15,
    campaignHasNoCodes: false,
    manualRequestDone: false,
    isModalOpen: true,
    inviteLetterValue: undefined,
    inviteSubjectValue: '',
  }

  componentDidMount() {
    const { previouslySelectedCampaign, selectedCreators } = this.props

    if (previouslySelectedCampaign) {
      const codes = previouslySelectedCampaign.platform_code_counts
      const selectedPlatforms = {}

      Object.keys(codes).forEach((platform) => {
        selectedPlatforms[platform] = codes[platform].unclaimed >= (selectedCreators.size || 1)
      })

      this.setState({ selectedCampaign: previouslySelectedCampaign, selectedPlatforms })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { error, isLoadingInvite } = nextProps
    if (
      !isLoadingInvite &&
      this.props.isLoadingInvite &&
      error.get('error') === EXISTING_MANUAL_REVIEW_REQUEST
    ) {
      this.setState({
        errorMessage: null,
        campaignHasNoCodes: false,
        manualRequest: error.get('manual_review_request_id'),
        manualRequestDone: false,
      })
      return
    }

    if (
      !nextProps.isLoadingManualReview &&
      this.props.isLoadingManualReview &&
      !nextProps.acceptManualReviewError.size
    ) {
      this.setState({ manualRequestDone: true, manualRequest: null })
    }
    // This should not happen anymore as we validate existing codes before submitting the form:
    // Can only happen in a "race condition" or if screen was stale
    if (
      !isLoadingInvite &&
      this.props.isLoadingInvite &&
      error.get('error') === NO_CODES_AVAILABLE
    ) {
      const campaignHref = `/campaigns/edit/${this.state.selectedCampaign.id}/codes`
      this.setState({
        manualRequestDone: false,
        manualRequest: null,
        errorMessage: (
          <div className={styles.error}>
            <p>The selected campaign is missing codes for the following platform(s):</p>
            <p>
              {error.get('platforms').map((p) => (
                <Fragment key={p}>
                  {p}
                  <br />
                </Fragment>
              ))}
            </p>
            <p>
              <Link to={campaignHref}>Please upload more codes here</Link>.
            </p>
          </div>
        ),
      })
      return
    }
    if (
      !isLoadingInvite &&
      this.props.isLoadingInvite &&
      error.get('error') === OFFER_ALREADY_EXISTS
    ) {
      this.setState({
        manualRequest: null,
        manualRequestDone: false,
        errorMessage: 'This creator already has an offer for the selected campaign.',
        campaignHasNoCodes: false,
      })
      return
    }
    this.setState({
      errorMessage: null,
      campaignHasNoCodes: false,
    })
  }

  componentDidUpdate(oldProps) {
    const {
      error,
      isLoadingInvite,
      onInvite,
      previouslySelectedCreators,
      showAlertLayer,
    } = this.props
    const { selectedCampaign } = this.state
    if (!error.get('error') && !isLoadingInvite && oldProps.isLoadingInvite) {
      if (onInvite) {
        onInvite(selectedCampaign.id)
        return
      }

      if (previouslySelectedCreators) {
        showAlertLayer({ message: OFFERS_SENT_SUCCESS_MESSAGE, type: SUCCESS })
        this.closeModal()
        this.props.onConfirm()
      } else {
        const { pathname } = browserHistory.getCurrentLocation()
        browserHistory.replace(
          pathname.replace('/send-offer', `/offer-sent/${selectedCampaign.id}`)
        )
      }
    }
  }

  handleCampaignSelect = (selectedCampaign) => {
    const { campaign } = this.props
    const codeCounts = campaign.getIn([String(selectedCampaign.id), 'platform_code_counts'])
    const codes = codeCounts.toJS()
    const selectedPlatforms = {}
    Object.keys(codes).forEach((platform) => {
      selectedPlatforms[platform] =
        codes[platform].unclaimed >= (this.props.selectedCreators.size || 1)
    })

    this.setState({
      selectedCampaign,
      selectedPlatforms,
      campaignHasNoCodes: sumUnclaimedCodes(codeCounts) < (this.props.selectedCreators.size || 1),
      errorMessage: null,
    })
  }

  closeModal = () => this.setState({ isModalOpen: false })

  inviteCreator = () => {
    const {
      selectedPlatforms,
      unselectedCreators,
      selectedOfferExpirationDays,
      inviteLetterValue,
      inviteSubjectValue,
    } = this.state
    const platforms = Object.keys(selectedPlatforms)
      .map((k) => selectedPlatforms[k] && k)
      .filter(Boolean)

    // Making sure we are not sending it as an empty string:
    const inviteSubject = inviteSubjectValue || undefined

    // Handling multi selection of creators
    if (this.props.selectedCreators.size) {
      this.props.inviteCreators({
        creators: this.props.selectedCreators
          .map(getCreatorId)
          .toSet()
          .subtract(new List(unselectedCreators.map(Number)))
          .toArray(),
        campaignId: this.state.selectedCampaign.id,
        platforms,
        expirationDays: selectedOfferExpirationDays,
        inviteLetter: inviteLetterValue,
        inviteSubject,
      })
      track('invite_creator', { count: this.props.selectedCreators.size, source: 'multi' })
      return
    }

    // Single creator selection
    this.props.inviteCreator(
      this.props.creator.get('id'),
      this.state.selectedCampaign.id,
      platforms,
      selectedOfferExpirationDays,
      inviteLetterValue,
      inviteSubject
    )
    track('invite_creator', { source: 'single' })
  }

  cancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel()
      return
    }
    const { pathname } = browserHistory.getCurrentLocation()
    browserHistory.push(pathname.replace('/send-offer', ''))
  }

  handlePlatformCheckbox = ({ target }) => {
    this.setState({
      selectedPlatforms: {
        ...this.state.selectedPlatforms,
        [target.name]: target.checked,
      },
    })
  }

  renderPlatforms = () => {
    const { selectedCampaign, selectedPlatforms } = this.state
    const { campaign, hideCampaigns } = this.props
    if (!selectedCampaign) {
      return null
    }

    const codes = campaign.getIn([String(selectedCampaign.id), 'platform_code_counts']).toJS()

    return (
      <div className={styles.platformsWrapper} style={{ marginTop: hideCampaigns ? '0' : '-20px' }}>
        <Label label="Select which platforms you'd like to send:" />
        {Object.keys(codes).map((platform) => {
          const outOfCodes = codes[platform].unclaimed < (this.props.selectedCreators.size || 1)
          return (
            <div key={platform}>
              <Checkbox
                disabled={outOfCodes}
                label={<PlatformIcon outOfCodes={outOfCodes} platform={platform} dark withName />}
                onChange={this.handlePlatformCheckbox}
                checked={selectedPlatforms[platform]}
                name={platform}
              />
            </div>
          )
        })}
      </div>
    )
  }

  renderExpirationTime = () => {
    const { selectedCampaign, selectedOfferExpirationDays } = this.state
    if (!selectedCampaign) {
      return null
    }

    return (
      <div className={styles.ExpirationTimeWrapper}>
        <Label label="Choose an expiration time for the offer" />

        {OFFER_EXPIRATION_DAYS.map((numberOfDays) => (
          <SelectableButton
            value={numberOfDays}
            text={`${numberOfDays} days`}
            handleSelect={this.handleExpirationSelect}
            isSelected={selectedOfferExpirationDays === numberOfDays}
            key={numberOfDays}
          />
        ))}
      </div>
    )
  }

  handleExpirationSelect = (event) => {
    this.setState({
      selectedOfferExpirationDays: Number(event.target.value),
    })
  }

  getHeader = () => {
    const { selectedCreators, creator, headerTitle } = this.props
    const { unselectedCreators } = this.state
    if (headerTitle) {
      return headerTitle
    }
    if (selectedCreators.size) {
      return selectedCreators.size > 1
        ? `Send offers to ${selectedCreators.size - unselectedCreators.length} creators`
        : `Send offer to one creator`
    }
    return `Send offer to ${creator.get('name')}`
  }

  handleUnselectCreator = ({ target }) => {
    const creatorId = target.name
    this.setState(({ unselectedCreators }) => {
      const index = unselectedCreators.indexOf(creatorId)
      if (index !== -1) {
        const newArr = [...unselectedCreators]
        newArr.splice(index, 1)
        return { unselectedCreators: newArr }
      }
      return {
        unselectedCreators: [...unselectedCreators, creatorId],
      }
    })
  }

  renderSelectedCreators = (creator) => {
    const { unselectedCreators } = this.state
    const { twitch, youtube } = getFollowerDataFromCreator(creator)
    return (
      <div key={creator.hashCode()} className={styles.selectedCreator}>
        <div className={styles.selectedCreatorHead}>
          <Checkbox
            onChange={this.handleUnselectCreator}
            label={<div />}
            name={String(creator.get('id'))}
            checked={unselectedCreators.indexOf(String(creator.get('id'))) === -1}
          />
          <Avatar
            slug={creator.get('slug')}
            img={creator.get('avatar_url')}
            name={creator.get('name')}
          />
        </div>
        <div className={styles.selectedCreatorFollowersWrap}>
          <FollowersButtons countPosition="right" twitch={twitch} youtube={youtube} />
        </div>
      </div>
    )
  }

  getSelectedCampaign = (selectedCampaign) => {
    const { newCampaign } = this.props
    return selectedCampaign || (newCampaign && newCampaign.get('id'))
  }

  handleInviteLetterChange = (inviteLetterValue) => {
    this.setState({
      inviteLetterValue,
    })
  }

  handleInviteSubjectChange = (inviteSubjectValue) => {
    this.setState({
      inviteSubjectValue,
    })
  }

  render() {
    const {
      isLoadingInvite,
      campaigns,
      isLoadingManualReview,
      selectedCreators,
      hideCreators,
      hideCampaigns,
      organizationName,
    } = this.props
    const {
      selectedCampaign,
      selectedOfferExpirationDays,
      errorMessage,
      manualRequest,
      manualRequestDone,
      campaignHasNoCodes,
      unselectedCreators,
      isModalOpen,
      inviteLetterValue,
      inviteSubjectValue,
      selectedPlatforms,
    } = this.state

    const platforms = Object.keys(selectedPlatforms)
      .map((k) => selectedPlatforms[k] && k)
      .filter(Boolean)
    return (
      <ModalConfirm
        disableConfirm={
          platforms.length === 0 ||
          campaignHasNoCodes ||
          !selectedCampaign ||
          !!(selectedCreators.size && unselectedCreators.length >= selectedCreators.size)
        }
        confirmText={selectedCreators.size > 1 ? 'Send Offers' : 'Send Offer'}
        isLoading={isLoadingInvite}
        open={isModalOpen}
        onCancel={this.cancel}
        onConfirm={this.inviteCreator}
        title={this.getHeader()}
      >
        <p className={styles.aboutProactive}>{SEND_OFFER_TO_CREATOR}</p>
        {!hideCreators && selectedCreators.size ? (
          <div className={styles.selectedCreatorsWrapper}>
            {selectedCreators.map(this.renderSelectedCreators)}
          </div>
        ) : null}

        {!hideCreators &&
        selectedCreators.size &&
        unselectedCreators.length >= selectedCreators.size ? (
          <div className={styles.noCodesWrapper}>
            <h3>You must select at least one creator!</h3>
          </div>
        ) : null}

        {!hideCampaigns && (
          <Select
            placeholder="Pick any active campaign..."
            label="Select a campaign"
            name="campaign"
            options={campaigns.toArray()}
            onChange={this.handleCampaignSelect}
            getOptionLabel={(option) => option.title}
            getOptionValue={(option) => option.id}
            value={this.getSelectedCampaign(selectedCampaign)}
            errorMessage={errorMessage}
          />
        )}

        {!campaignHasNoCodes ? (
          <div>
            {this.renderPlatforms()}
            {this.renderExpirationTime()}

            {hideCampaigns && errorMessage && <p>{errorMessage}</p>}
          </div>
        ) : (
          <div className={styles.noCodesWrapper}>
            <h3>This campaign has no available codes.</h3>
          </div>
        )}

        <ManualRequestAction
          manualRequest={manualRequest}
          isLoadingManualReview={isLoadingManualReview}
          manualRequestDone={manualRequestDone}
          acceptManualReview={this.props.acceptManualReview}
        />

        {!campaignHasNoCodes && selectedCampaign ? (
          <div className={styles.sendOfferConfigBlock}>
            <p>
              <Label label="Custom Message Subject (optional)" />
              <Input
                className={styles.inputMessageSubject}
                onChange={this.handleInviteSubjectChange}
                value={inviteSubjectValue}
              />
            </p>
            <p className={styles.messageBody}>
              <Label label="Message body (optional)" />
              <Textarea onChange={this.handleInviteLetterChange} value={inviteLetterValue} />
              <small className={styles.messageReplacements}>
                <mark>{'{creator_name}'}</mark> will be replaced with the actual creator name
              </small>
            </p>

            <Label label="Preview" />
            {selectedCampaign && selectedOfferExpirationDays && platforms.length ? (
              <div className={styles.emailPreview}>
                <p className={styles.emailSubject}>
                  Subject:{' '}
                  {inviteSubjectValue || `You have been invited to try: ${selectedCampaign.title}`}
                </p>
                <p className={styles.emailPreviewGreeting}>
                  Hi {'{creator_name}'}!<br />
                  {organizationName} is offering you a {selectedCampaign.title} key!
                </p>
                {!!inviteLetterValue && (
                  <p className={styles.emailPreviewGreeting}>{inviteLetterValue}</p>
                )}
                <p className={styles.claimOffer}>
                  <span className={styles.claimOfferFakeLink}>CLAIM OFFER</span>
                </p>
                <p>This offer will expire {expirationDate(selectedOfferExpirationDays)}.</p>
              </div>
            ) : (
              <p>Preview will appear here when ready</p>
            )}
          </div>
        ) : null}
      </ModalConfirm>
    )
  }
}

export default campaignLoader(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(SendOfferModal)
)
