const REPLACE = '{VID}'
const YOUTUBE_THUMBNAIL_URL = `https://img.youtube.com/vi/${REPLACE}/0.jpg`
const YOUTUBE_BASE_WATCH_URL = `https://www.youtube.com/watch?v=${REPLACE}`
const YOUTUBE_EMBED_URL = `https://www.youtube.com/embed/${REPLACE}`

const stripParameters = (str) => {
  // Split parameters or split folder separator
  if (str.indexOf('?') > -1) {
    return str.split('?')[0]
  }
  if (str.indexOf('/') > -1) {
    return str.split('/')[0]
  }
  return str
}

export const getYoutubeID = (str) => {
  // shortcode
  const shortcode = /youtube:\/\/|https?:\/\/youtu\.be\//g

  if (shortcode.test(str)) {
    const shortcodeid = str.split(shortcode)[1]
    return stripParameters(shortcodeid)
  }

  // /v/ or /vi/
  const inlinev = /\/v\/|\/vi\//g

  if (inlinev.test(str)) {
    const inlineid = str.split(inlinev)[1]
    return stripParameters(inlineid)
  }

  // v= or vi=
  const parameterv = /v=|vi=/g

  if (parameterv.test(str)) {
    const arr = str.split(parameterv)
    return arr[1].split('&')[0]
  }

  // v= or vi=
  const parameterwebp = /\/an_webp\//g

  if (parameterwebp.test(str)) {
    const webp = str.split(parameterwebp)[1]
    return stripParameters(webp)
  }

  // embed
  const embedreg = /\/embed\//g

  if (embedreg.test(str)) {
    const embedid = str.split(embedreg)[1]
    return stripParameters(embedid)
  }

  // user
  const userreg = /\/user\//g

  if (userreg.test(str)) {
    const elements = str.split('/')
    return stripParameters(elements.pop())
  }

  // attribution_link
  const attrreg = /\/attribution_link\?.*v%3D([^%&]*)(%26|&|$)/

  if (attrreg.test(str)) {
    return str.match(attrreg)[1]
  }

  return null
}

export const getYoutubeThumbnail = (videoURL = '') => {
  if (typeof videoURL !== 'string') {
    return null
  }
  return YOUTUBE_THUMBNAIL_URL.replace(REPLACE, getYoutubeID(videoURL))
}

export const buildCleanYoutubeURL = (videoURL) =>
  YOUTUBE_BASE_WATCH_URL.replace(REPLACE, getYoutubeID(videoURL))

export const buildEmbedYoutubeURL = (videoURL) =>
  YOUTUBE_EMBED_URL.replace(REPLACE, getYoutubeID(videoURL))
