const getVideoViewUnit = (video) => {
  if (typeof video.get('avg_viewers') === 'number') {
    return 'avg live viewers'
  }
  return video.get('views_count') > 1 ? 'views' : 'views'
}

const getPeakViewers = (video) => {
  if (typeof video.get('peak_viewers') === 'number') {
    return `(${video.get('peak_viewers').toLocaleString()} peak)`
  }
  return ''
}

const getViewCount = (video) => {
  if (video.get('peak_viewers') === 0) {
    return 'No views'
  }
  const unit = getVideoViewUnit(video)
  const views =
    typeof video.get('avg_viewers') === 'number'
      ? video.get('avg_viewers')
      : video.get('views_count')
  return `${(views || '').toLocaleString()} ${unit} ${getPeakViewers(video)}`
}

export default getViewCount
