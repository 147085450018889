import { List } from 'immutable'

export const IMAGE = 'image'
export const VIDEO = 'video'

export const FULL = 'url'
export const THUMBNAIL = 'thumbnail_url'

export const getOrder = (o) => o.get('order')
export const byOrder = (a, b) => (a > b ? 1 : -1)

const getMedia = (media = new List(), size = FULL, type = IMAGE) =>
  media
    .filter((o) => o.get('type') === type)
    .sortBy(getOrder, byOrder)
    .getIn([0, size])

export default getMedia
