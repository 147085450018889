import { Map, List } from 'immutable'
import moment from 'moment'
import { createSelector } from 'reselect'

const getCreators = (state) => state.creator
const getSlug = (state, slug) => slug

export const getCreatorBySlug = createSelector(
  getCreators,
  getSlug,
  (creators = new Map(), slug) => creators.find((p) => p.get('slug') === slug)
)

const getResults = (results) => results
const getVideos = (results, state) => state.video
const getCreatorsWithResults = (results, state) => state.creator
export const buildResultsFromSearch = createSelector(
  getResults,
  getCreatorsWithResults,
  getVideos,
  (results = new List(), creators = new Map(), videos = new Map()) =>
    results.map((result) =>
      result
        .set('creator', creators.get(result.get('creator')))
        .set('videos', result.get('videos', new List()).map((v) => videos.get(v)))
    )
)

const getNotes = (state) => state.creatorNotes
const getCreatorId = (state, creatorId) => creatorId && String(creatorId)

const getDate = (note) => moment(note.get('created_at'))
const mostRecent = (a, b) => (b.isBefore(a) ? -1 : 1)

export const selectNotesFromCreator = createSelector(
  getNotes,
  getCreatorId,
  (notes = new Map(), creatorId) => {
    if (!creatorId) {
      return new List()
    }
    return notes
      .filter((n) => String(n.get('creator')) === creatorId)
      .toList()
      .sortBy(getDate, mostRecent)
  }
)

const getSelectedCreators = (state) => state.selectedCreators

export const selectSelectedCreators = createSelector(
  getCreators,
  getSelectedCreators,
  (creators = new Map(), selectedCreatorsList) =>
    selectedCreatorsList.map((creatorId) => creators.get(String(creatorId)))
)

const getSimilarCreators = (state) => state.similarCreators
export const selectSimilarCreators = createSelector(
  getCreators,
  getSimilarCreators,
  getCreatorId,
  (creators = new Map(), similarCreators = new List(), creatorId) => {
    return similarCreators
      .get(creatorId, new List())
      .map((s) => s.set('profile', creators.get(s.get('profileId'))).remove('profileId'))
  }
)
