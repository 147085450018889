export const SET_UI = 'SET_UI'
export const CLEAR_UI = 'CLEAR_UI'

export const setUI = (payload) => ({
  type: SET_UI,
  payload,
})

export const clearUI = () => ({
  type: CLEAR_UI,
})
