import defineAction from '../../utils/define-action'
import * as accountService from '../../services/account'
import { YOUTUBE, TWITCH, STEAM } from '../../config/settings'

export const PUBLISHER_LOGIN = defineAction('PUBLISHER_LOGIN')
export const SIGNUP_AS_PUBLISHER = defineAction('SIGNUP_AS_PUBLISHER')
export const COMPLETE_PUBLISHER_SIGNUP = defineAction('COMPLETE_PUBLISHER_SIGNUP')
export const CREATE_PUBLISHER_PASSWORD = defineAction('CREATE_PUBLISHER_PASSWORD')
export const CONNECT_CREATOR_ACCOUNT = defineAction('CONNECT_CREATOR_ACCOUNT')
export const UPDATE_PASSWORD = defineAction('UPDATE_PASSWORD')
export const UPDATE_ACCOUNT = defineAction('UPDATE_ACCOUNT')
export const RESET_PASSWORD_CONFIRM = defineAction('RESET_PASSWORD_CONFIRM')
export const RESET_PASSWORD = defineAction('RESET_PASSWORD')
export const FETCH_USER_DATA = defineAction('FETCH_USER_DATA')
export const ACCEPT_TERMS = defineAction('ACCEPT_TERMS')
export const CHANGE_EMAIL_FIRST_CONFIRMATION = defineAction('CHANGE_EMAIL_FIRST_CONFIRMATION')
export const CHANGE_EMAIL_CONFIRMATION = defineAction('CHANGE_EMAIL_CONFIRMATION')
export const UPDATE_EMAIL = defineAction('UPDATE_EMAIL')
export const USER_SIGNOUT = 'USER_SIGNOUT'
export const REQUEST_STEAM_CRAWL = defineAction('REQUEST_STEAM_CRAWL')
export const REQUEST_TWITCH_CRAWL = defineAction('REQUEST_TWITCH_CRAWL')
export const REQUEST_YOUTUBE_CRAWL = defineAction('REQUEST_YOUTUBE_CRAWL')
export const DELETE_PROVIDER = defineAction('DELETE_PROVIDER')
export const UPDATE_ORGANIZATION = defineAction('UPDATE_ORGANIZATION')
export const UPDATE_PREFERENCES = defineAction('UPDATE_PREFERENCES')
export const NEW_UPDATE_PREFERENCES = defineAction('NEW_UPDATE_PREFERENCES')
export const GET_PREFERENCES = defineAction('GET_PREFERENCES')
export const ACCEPT_COOKIE_CONSENT = defineAction('ACCEPT_COOKIE_CONSENT')
export const DELETE_ACCOUNT = defineAction('DELETE_ACCOUNT')
export const GET_ORGANIZATIONS = defineAction('GET_ORGANIZATIONS')
export const SET_PRIMARY_ORGANIZATION = defineAction('SET_PRIMARY_ORGANIZATION')
export const GET_ORGANIZATION_INVITATIONS = defineAction('GET_ORGANIZATION_INVITATIONS')
export const ACCEPT_ORGANIZATION_INVITATION = defineAction('ACCEPT_ORGANIZATION_INVITATION')
export const REMOVE_ORGANIZATION_INVITATION = defineAction('REMOVE_ORGANIZATION_INVITATION')
export const CREATE_ORGANIZATION = defineAction('CREATE_ORGANIZATION')
export const UPDATE_MEMBERSHIP = defineAction('UPDATE_MEMBERSHIP')

export const REQUEST_PROVIDER_CRAWL_ACTIONS = {
  [STEAM]: REQUEST_STEAM_CRAWL,
  [TWITCH]: REQUEST_TWITCH_CRAWL,
  [YOUTUBE]: REQUEST_YOUTUBE_CRAWL,
}

export const signupAsPublisher = (payload) => (dispatch) =>
  dispatch({
    type: SIGNUP_AS_PUBLISHER.ACTION,
    payload: accountService.create(payload),
  })

export const completePublisherSignup = (uid, payload) => (dispatch) =>
  dispatch({
    type: COMPLETE_PUBLISHER_SIGNUP.ACTION,
    payload: accountService.activate(uid, payload),
  })

export const createPublisherPassword = (uid, payload) => (dispatch, getState) =>
  dispatch({
    type: CREATE_PUBLISHER_PASSWORD.ACTION,
    payload: accountService.setPassword(getState().user.get('auth_token'))(uid, payload),
  })

export const publisherSignin = (username, password) => (dispatch) =>
  dispatch({
    type: PUBLISHER_LOGIN.ACTION,
    payload: accountService.signin(username, password),
  })

export const connectCreatorAccount = (data, platform) => (dispatch, getState) =>
  dispatch({
    type: CONNECT_CREATOR_ACCOUNT,
    payload: accountService.connectCreator(getState().user.get('auth_token'))(data, platform),
    meta: platform,
  })

export const updateUserPassword = (payload) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_PASSWORD.ACTION,
    payload: accountService.updatePassword(getState().user.get('auth_token'))(payload),
  })

export const updateUserAccount = (payload, newLocation) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_ACCOUNT.ACTION,
    payload: accountService.updateAccount(getState().user.get('auth_token'))(payload, newLocation),
  })

export const signout = () => ({ type: USER_SIGNOUT })

export const setNewPassword = (payload) => (dispatch) =>
  dispatch({
    type: RESET_PASSWORD_CONFIRM.ACTION,
    payload: accountService.resetPasswordConfirm(payload),
  })

export const resetPassword = (email) => (dispatch) =>
  dispatch({
    type: RESET_PASSWORD.ACTION,
    payload: accountService.resetPassword(email),
  })

export const getUserByToken = () => (dispatch, getState) =>
  dispatch({
    type: FETCH_USER_DATA,
    payload: accountService.fetchUserByToken(getState().user.get('auth_token')),
  })

export const acceptTerms = () => (dispatch, getState) =>
  dispatch({
    type: UPDATE_ACCOUNT.ACTION,
    payload: accountService.acceptTerms(getState().user.get('auth_token')),
  })

export const changeEmailFirstConfirmation = (uuid) => (dispatch, getState) =>
  dispatch({
    type: CHANGE_EMAIL_FIRST_CONFIRMATION.ACTION,
    payload: accountService.changeEmailFirstConfirmation(getState().user.get('auth_token'))(uuid),
  })

export const changeEmailConfirmation = (uuid) => (dispatch, getState) =>
  dispatch({
    type: CHANGE_EMAIL_CONFIRMATION.ACTION,
    payload: accountService.changeEmailConfirmation(getState().user.get('auth_token'))(uuid),
  })

export const updateUserEmail = (payload) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_EMAIL.ACTION,
    payload: accountService.updateUserEmail(getState().user.get('auth_token'))(payload),
  })

export const requestProviderCrawl = (provider) => (dispatch, getState) =>
  dispatch({
    type: REQUEST_PROVIDER_CRAWL_ACTIONS[provider].ACTION,
    payload: accountService.requestProviderCrawl(getState().user.get('auth_token'))(provider),
  })

export const deleteProvider = (provider, providerId) => (dispatch, getState) =>
  dispatch({
    type: DELETE_PROVIDER,
    payload: accountService.deleteProvider(getState().user.get('auth_token'))(provider, providerId),
    meta: {
      provider,
    },
  })

export const updateOrg = (orgId, data) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_ORGANIZATION,
    payload: accountService.updateOrganization(getState().user.get('auth_token'))(orgId, data),
  })

export const updatePreferences = (preferences) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_PREFERENCES,
    payload: accountService.updatePreferences(getState().user.get('auth_token'))(preferences),
    meta: preferences,
  })

export const updateNewPreferences = (preference) => (dispatch, getState) =>
  dispatch({
    type: NEW_UPDATE_PREFERENCES,
    payload: accountService.updateNewPreferences(getState().user.get('auth_token'))(preference),
    meta: preference,
  })

export const getPreferences = () => (dispatch, getState) =>
  dispatch({
    type: GET_PREFERENCES,
    payload: accountService.getPreferences(getState().user.get('auth_token'))(),
  })

export const acceptCookieConsent = () => (dispatch) =>
  dispatch({
    type: ACCEPT_COOKIE_CONSENT.ACTION,
  })

export const deleteAccount = (reason) => (dispatch, getState) =>
  dispatch({
    type: DELETE_ACCOUNT,
    payload: accountService.deleteAccount(getState().user.get('auth_token'))(reason || undefined),
  })

export const getOrganizations = () => (dispatch, getState) =>
  dispatch({
    type: GET_ORGANIZATIONS,
    payload: accountService.getOrganizations(getState().user.get('auth_token')),
  })

export const setPrimaryOrganization = (orgId) => (dispatch, getState) =>
  dispatch({
    type: SET_PRIMARY_ORGANIZATION,
    payload: accountService.activateOrganization(getState().user.get('auth_token'))(orgId),
  })

export const getOrganizationInvitations = () => (dispatch, getState) =>
  dispatch({
    type: GET_ORGANIZATION_INVITATIONS,
    payload: accountService.getOrganizationInvitations(getState().user.get('auth_token')),
  })

export const acceptOrganizationInvitation = (token) => (dispatch, getState) =>
  dispatch({
    type: ACCEPT_ORGANIZATION_INVITATION,
    payload: accountService.acceptOrganizationInvitation(getState().user.get('auth_token'))(token),
  })

export const removeOrganizationInvitation = (invitationId) => (dispatch, getState) =>
  dispatch({
    type: REMOVE_ORGANIZATION_INVITATION,
    payload: accountService.removeOrganizationInvitation(getState().user.get('auth_token'))(
      invitationId
    ),
    meta: invitationId,
  })

export const createOrganization = (data) => (dispatch, getState) =>
  dispatch({
    type: CREATE_ORGANIZATION,
    payload: accountService.createOrganization(getState().user.get('auth_token'))(data),
  })

export const updateMembership = (data) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_MEMBERSHIP,
    payload: accountService.updateMembership(getState().user.get('auth_token'))(data),
  })
