import PropTypes from 'prop-types'
import React from 'react'
import ReactSelect, { components } from 'react-select'
import AsyncSelect from 'react-select/async'

import Label from '../piece-label'
import BlockFieldFeedback from '../block-field-feedback'

import styles from './styles.css'
import { defaultStyles, darkStyles } from './select-styles'

/* DEPRECATED
  Component preserved in order to maintain backward compatibility with react-select v1
  Please use piece-field-select-v2
*/
const Select = ({
  label,
  id,
  name,
  value,
  options,
  className,
  clearable,
  onChange,
  errorMessage,
  onInputChange,
  placeholder,
  disabled,
  isLoading,
  valueRenderer,
  searchable,
  dark,
  autoComplete,
  isMulti,
  isAsync,
  getValue,
  getOptionValue,
  ...props
}) => {
  const selectId = id || name

  const handleOnChange = (event, action) => {
    if (onChange) onChange(event, name, action)
  }

  const SingleValue = (singleValueProps) => {
    return (
      <components.SingleValue {...singleValueProps}>
        {valueRenderer ? valueRenderer(singleValueProps.data) : singleValueProps.children}
      </components.SingleValue>
    )
  }

  const inputProps = {
    label,
    id,
    name,
    value,
    options,
    clearable,
    disabled,
    onChange,
    placeholder,
    isLoading,
    autoComplete,
  }

  // eslint-disable-next-line eqeqeq
  const defaultGetValue = () => options.find((option) => getOptionValue(option) == value)

  const mainClass = className || styles.wrapper
  return (
    <div className={mainClass}>
      {label && <Label id={selectId} label={label} />}
      {isAsync ? (
        <AsyncSelect
          {...inputProps}
          name={name}
          className={styles.selectWrapper}
          classNamePrefix="react-select"
          value={getValue ? getValue(value) : defaultGetValue()}
          isClearable={clearable}
          isSearchable={searchable}
          onInputChange={onInputChange}
          onChange={handleOnChange}
          styles={dark ? darkStyles : defaultStyles}
          isMulti={isMulti}
          components={{
            SingleValue,
          }}
          {...props}
        />
      ) : (
        <ReactSelect
          {...inputProps}
          name={name}
          className={styles.selectWrapper}
          classNamePrefix="react-select"
          value={getValue ? getValue(value) : defaultGetValue()}
          options={options}
          isClearable={clearable}
          isSearchable={searchable}
          onInputChange={onInputChange}
          onChange={handleOnChange}
          styles={dark ? darkStyles : defaultStyles}
          isMulti={isMulti}
          components={{
            SingleValue,
          }}
          {...props}
        />
      )}

      <BlockFieldFeedback errorMessage={errorMessage} />
    </div>
  )
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  onInputChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  clearable: PropTypes.bool,
  onChange: PropTypes.func,
  errorMessage: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.node]),
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  valueRenderer: PropTypes.func,
  searchable: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  dark: PropTypes.bool,
  isMulti: PropTypes.bool,
  isAsync: PropTypes.bool,
  getValue: PropTypes.func,
  getOptionValue: PropTypes.func,
}

Select.defaultProps = {
  isMulti: false,
  clearable: false,
  searchable: true,
  onInputChange: Function.prototype,
  disabled: false,
  valueRenderer: undefined,
  id: undefined,
  label: undefined,
  value: undefined,
  options: [],
  className: undefined,
  onChange: undefined,
  errorMessage: [],
  placeholder: undefined,
  autoComplete: undefined,
  isLoading: false,
  dark: false,
  isAsync: false,
  getValue: undefined,
  getOptionValue: (option) => option.value,
}

export default Select
