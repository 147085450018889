import { useEffect, useState } from 'react'
import { browserHistory } from 'react-router'

import { newHandleQuery } from 'utils/handle-query'
import { format } from 'utils/datetime'
import { DATE_FORMAT } from 'config/time-settings'
import sessionStorage from 'utils/session-storage'

const useActivityFeedFilter = ({ initialFilters, filterPath }) => {
  const [filters, setFilters] = useState(initialFilters)

  const persistFilters = (newFilters) => {
    if (!newFilters) {
      sessionStorage.setItem(filterPath, null)
      return
    }
    sessionStorage.setItem(filterPath, JSON.stringify(newFilters))
  }

  const onResetFilter = () => {
    setFilters({})
    persistFilters(null)
    const currentLocation = browserHistory.getCurrentLocation()
    browserHistory.replace({ pathname: currentLocation.pathname })
  }

  useEffect(() => {
    const sessionFilters = JSON.parse(sessionStorage.getItem(filterPath) || null)
    if (sessionFilters) {
      setFilters({ ...filters, ...sessionFilters })
      newHandleQuery(sessionFilters)
    }
  }, [])

  useEffect(() => {
    const { date_after: startDate, date_before: endDate } = filters
    persistFilters(
      newHandleQuery({
        offset: undefined,
        ...filters,
        date_after: startDate && format(startDate, DATE_FORMAT),
        date_before: endDate && format(endDate, DATE_FORMAT),
      })
    )
  }, [JSON.stringify(filters)])

  return [onResetFilter, filters, setFilters]
}

export default useActivityFeedFilter
