import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Link, browserHistory } from 'react-router'
import { CardElement, injectStripe } from 'react-stripe-elements'
import shallowequal from 'shallowequal'

import { captureException } from 'utils/exception'

import {
  PLANS,
  FREE_PLAN,
  STARTER_PLAN,
  STARTER_PLAN_ANNUAL,
  PREMIUM_PLAN,
  PREMIUM_PLAN_ANNUAL,
  STARTER_LEGACY_PLAN,
  STARTER_LEGACY_PLAN_ANNUAL,
  PREMIUM_21_PLAN,
  PREMIUM_21_PLAN_ANNUAL,
} from '../../config/settings'
import Button from '../../components/button'
import Pagination from '../../components/pagination'
import {
  getCard,
  getBilling,
  addCard,
  ADD_CARD,
  REMOVE_CARD,
  removeCard,
  downloadInvoice,
  DOWNLOAD_INVOICE,
  GET_BILLING,
} from '../../modules/plan/actions'
import { formatHuman } from '../../utils/datetime'
import If from '../../components/if'
import CardFlag from '../../components/card-flag'
import Field from '../../components/piece-field'
import Legend from '../../components/legend'
import Loading from '../../components/piece-loading'
import BlockFieldFeedback from '../../components/block-field-feedback'
import { style, INVALID_CARD } from '../upgrade-account/payment'
import userLoader from '../../hocs/user-loader'
import { selectPage, selectPageResults } from '../../modules/pagination/selectors'
import { newHandleQuery } from '../../utils/handle-query'

import styles from './styles.css'

const CARD_CHANGE_HASH = '#cardchange'
const CARD_REMOVE_HASH = '#cardremove'
const MONEY_DECIMAL_PLACES = 2

export const getPlanName = (plan = []) => {
  if (
    plan.includes(PREMIUM_PLAN) ||
    plan.includes(PREMIUM_21_PLAN) ||
    plan.includes(PREMIUM_21_PLAN_ANNUAL) ||
    plan.includes(PREMIUM_PLAN_ANNUAL)
  ) {
    return PLANS[PREMIUM_PLAN]
  }
  if (
    plan.includes(STARTER_PLAN) ||
    plan.includes(STARTER_PLAN_ANNUAL) ||
    plan.includes(STARTER_LEGACY_PLAN) ||
    plan.includes(STARTER_LEGACY_PLAN_ANNUAL)
  ) {
    return PLANS[STARTER_PLAN]
  }
  if (plan.includes(FREE_PLAN)) {
    return PLANS[FREE_PLAN]
  }
  return '?'
}

export const parseDate = (date) => {
  if (date) {
    return formatHuman(date)
  }
  return 'n/a'
}

const mapStateToProps = (state, { location: { hash, query } }) => {
  const { user, cards, billing, loading, error } = state
  const { offset } = query
  const page = selectPage(state, GET_BILLING.ACTION, { offset })

  return {
    user,
    cards,
    billing: selectPageResults(page, billing),
    isLoadingAddCard: !!loading.get(ADD_CARD.ACTION),
    isLoadingRemoveCard: !!loading.get(REMOVE_CARD.ACTION),
    isLoadingInvoiceHistory: !!loading.get(GET_BILLING.ACTION),
    loadingInvoiceDownloadDetail: loading.get(`${DOWNLOAD_INVOICE}_DETAIL`, new Map()),
    isChangingCard: hash === CARD_CHANGE_HASH,
    isRemovingCard: hash === CARD_REMOVE_HASH,
    downloadInvoiceError: error.get(DOWNLOAD_INVOICE.ACTION),
    addCardError: error.get(ADD_CARD.ACTION, new Map()),
    pagination: page,
  }
}

const mapDispatchToProps = {
  getCard,
  getBilling,
  downloadInvoice,
  addCard,
  removeCard,
}

class OrganizationSettingsBilling extends Component {
  static propTypes = {
    user: ImmutablePropTypes.map.isRequired,
    cards: ImmutablePropTypes.map.isRequired,
    billing: ImmutablePropTypes.list.isRequired,
    getCard: PropTypes.func.isRequired,
    getBilling: PropTypes.func.isRequired,
    loadingInvoiceDownloadDetail: ImmutablePropTypes.map.isRequired,
    downloadInvoice: PropTypes.func.isRequired,
    downloadInvoiceError: PropTypes.instanceOf(Error),
    isChangingCard: PropTypes.bool.isRequired,
    isRemovingCard: PropTypes.bool.isRequired,
    isLoadingInvoiceHistory: PropTypes.bool.isRequired,
    isLoadingRemoveCard: PropTypes.bool.isRequired,
    addCard: PropTypes.func.isRequired,
    removeCard: PropTypes.func.isRequired,
    stripe: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    isLoadingAddCard: PropTypes.bool.isRequired,
    addCardError: ImmutablePropTypes.map.isRequired,
    pagination: ImmutablePropTypes.map.isRequired,
    location: PropTypes.shape({
      query: PropTypes.shape({
        offset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    }).isRequired,
  }

  static defaultProps = {
    downloadInvoiceError: undefined,
    stripe: {},
  }

  state = {
    errors: {},
    name: '',
  }

  componentDidMount() {
    this.props.getCard()
    this.props.getBilling(this.props.location.query)
  }

  componentWillReceiveProps({ isLoadingAddCard, addCardError, downloadInvoiceError }) {
    if (addCardError.size && addCardError.get('error') === INVALID_CARD) {
      this.setState({ cardErrors: 'Invalid card number!' })
    }
    // since this should never error, we only capture an exception and
    // skip any UI feedbacks until there's a known case to handle:
    if (downloadInvoiceError) {
      console.error(downloadInvoiceError)
      captureException(new Error('Invoice download error'), { extra: downloadInvoiceError })
    }

    if (!isLoadingAddCard && this.props.isLoadingAddCard && !addCardError.size) {
      this.props.getCard()
      browserHistory.replace({ pathname: '/publisher/organization/billing', hash: '' })
    }
  }

  componentDidUpdate(oldProps) {
    const { location } = this.props
    if (!shallowequal(location.query, oldProps.location.query)) {
      this.props.getBilling(location.query)
    }
  }

  onChangeCard = () => {
    const { name } = this.state

    const errors = {}
    if (!name) {
      errors.name = "Type the cardholder's name."
    }

    this.setState({ errors, cardErrors: '' })

    if (Object.keys(errors).length) {
      return
    }

    this.props.addCard(this.props.stripe.createToken, { name })
  }

  onRemoveCard = () => {
    this.props.removeCard()
  }

  getCurrentCardInfo = () => {
    const { errors, cardErrors, name } = this.state
    const {
      user,
      cards,
      isChangingCard,
      isRemovingCard,
      isLoadingAddCard,
      isLoadingRemoveCard,
    } = this.props

    const isOnFreePlan = user.getIn(['profile', 'subscription', 'plans'], []).includes(FREE_PLAN)
    const isCanceling = user.getIn(['profile', 'subscription', 'is_canceling'])

    if (!cards.size) {
      return null
    }

    if (!isChangingCard) {
      return (
        <div>
          <p className={styles.cardInfo}>
            <span className={styles.cardFlagWrapper}>
              <CardFlag flag={cards.get('brand')} />
            </span>{' '}
            ●●●● ●●●●● ●●●●
            <span className={styles.last4}>{cards.get('last_4_digits')}</span>
          </p>
          <p className={styles.cardExpiration}>
            expires {cards.get('exp_month')}/{cards.get('exp_year')}
          </p>
          <p>
            <Button
              href={{ pathname: '/publisher/organization/billing', hash: CARD_CHANGE_HASH }}
              kind="secondaryGray"
            >
              Change card
            </Button>
            {isCanceling || isOnFreePlan ? (
              <Link
                to={{ pathname: '/publisher/organization/billing', hash: CARD_REMOVE_HASH }}
                className={styles.removeCardButton}
              >
                REMOVE CARD
              </Link>
            ) : null}
          </p>

          {isRemovingCard ? (
            <div className={styles.changeCardWrapper}>
              <h3>Are you sure you want to remove this card?</h3>
              <div className={styles.changeCardCTA}>
                <Link
                  to={{ pathname: '/publisher/organization/billing', hash: '' }}
                  className={styles.cancelCardChange}
                >
                  Cancel
                </Link>
                {!isLoadingRemoveCard ? (
                  <Button kind="destructive" onClick={this.onRemoveCard}>
                    Remove card
                  </Button>
                ) : (
                  <div className={styles.cardLoadingWrapper}>
                    <Loading isLoading />
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>
      )
    }
    if (isChangingCard) {
      return (
        <div className={styles.changeCardWrapper}>
          <Field
            name="name"
            type="text"
            label="Cardholder Name"
            value={name}
            errorMessage={errors.name}
            onChange={this.handleChange}
          />

          <Legend>Credit Card</Legend>
          <CardElement onChange={this.handleCardChange} style={style} />
          <BlockFieldFeedback errorMessage={cardErrors} />
          <div className={styles.changeCardCTA}>
            <Link
              to={{ pathname: '/publisher/organization/billing', hash: '' }}
              className={styles.cancelCardChange}
            >
              Cancel change
            </Link>
            {!isLoadingAddCard ? (
              <Button onClick={this.onChangeCard}>Change card</Button>
            ) : (
              <div className={styles.cardLoadingWrapper}>
                <Loading isLoading />
              </div>
            )}
          </div>
        </div>
      )
    }
    return null
  }

  handleCardChange = (event) => {
    if (event.error) {
      this.setState({ cardErrors: event.error.message })
    }
  }

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value })
  }

  renderInvoice = (bill) => (
    <tr key={bill.hashCode()} className={styles.invoiceRow}>
      <td className={styles.invoiceCell}>{parseDate(bill.get('date'))}</td>
      <td className={styles.invoiceCell}>
        ${(parseInt(bill.get('amount', 0), 10) / 100).toFixed(MONEY_DECIMAL_PLACES)}
      </td>
      <td className={styles.invoicePDFCell}>
        <Button
          size="small"
          kind="secondaryBlue"
          onClick={this.props.downloadInvoice}
          onClickWith={bill.get('id')}
          isLoading={!!this.props.loadingInvoiceDownloadDetail.get(bill.get('id'))}
        >
          Download PDF
        </Button>
      </td>
    </tr>
  )

  onPaginationClick = (page, index) => newHandleQuery({ [index]: page.value })

  render() {
    const {
      user,
      billing,
      cards,
      pagination,
      location: { query },
      isLoadingInvoiceHistory,
    } = this.props

    const isOnFreePlan = user.getIn(['profile', 'subscription', 'plans'], []).includes(FREE_PLAN)
    const isCanceling = user.getIn(['profile', 'subscription', 'is_canceling'])
    const isOnGracePeriod = !isOnFreePlan && isCanceling
    const currentPagination = Number(query.offset) || 0

    return (
      <div>
        <div className={styles.container}>
          <h2>Your plan</h2>
          <Legend>Type</Legend>
          <p>
            {getPlanName(user.getIn(['profile', 'subscription', 'plans']))}
            <If condition={!isOnFreePlan && !isCanceling}>
              <span>
                {' '}
                <Link to="/upgrade/downgrade">Stop Auto-Renew</Link>{' '}
                <Link to="/upgrade">Change plan</Link>
              </span>
            </If>
            <If condition={isOnGracePeriod}>
              <span> (grace period)</span> <Link to="/upgrade">Change</Link>
            </If>
          </p>
          <If condition={isOnFreePlan}>
            <p>
              <Button href="/upgrade">Upgrade</Button>
            </p>
          </If>
          <If condition={isOnGracePeriod}>
            <div>
              <Legend>Grace period ends in</Legend>
              <p>{parseDate(user.getIn(['profile', 'subscription', 'end_date']))}</p>
            </div>
          </If>
          <If condition={!isOnFreePlan}>
            <div>
              <Legend>Days left</Legend>
              <p>{user.getIn(['profile', 'subscription', 'days_left'])}</p>
              {!isCanceling && (
                <>
                  <Legend>Next billing date</Legend>
                  <p>{parseDate(user.getIn(['profile', 'subscription', 'end_date']))}</p>
                </>
              )}
            </div>
          </If>
        </div>
        <If condition={cards.size}>
          <div className={styles.container}>
            <h2>Payment method</h2>
            <If condition={isOnFreePlan && !cards.size}>
              <p>You are currently on a Free plan.</p>
            </If>
            {this.getCurrentCardInfo()}
          </div>
        </If>
        <div className={styles.container}>
          <h2>Invoices</h2>
          {billing.size ? (
            <>
              <table className={styles.invoiceTable}>
                <tbody>{billing.map(this.renderInvoice)}</tbody>
              </table>
              {pagination ? (
                <Pagination
                  pageSize={10}
                  count={pagination.get('count')}
                  prev={pagination.get('prev')}
                  next={pagination.get('next')}
                  onClick={this.onPaginationClick}
                  current={currentPagination}
                  className={styles.pagination}
                />
              ) : null}
            </>
          ) : null}

          {!billing.size && isLoadingInvoiceHistory ? <Loading isLoading /> : null}

          {!billing.size && !isLoadingInvoiceHistory ? (
            <p>You haven&apos;t made any purchases yet.</p>
          ) : null}
        </div>
      </div>
    )
  }
}

export default userLoader(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(injectStripe(OrganizationSettingsBilling))
)
