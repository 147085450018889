import React from 'react'
import { PropTypes } from 'prop-types'

import { formatHuman } from '../../utils/datetime'

const DeliverBy = ({ deliverBy }) => <span>{deliverBy ? formatHuman(deliverBy) : 'Anytime'}</span>

DeliverBy.propTypes = {
  deliverBy: PropTypes.string,
}

DeliverBy.defaultProps = {
  deliverBy: undefined,
}

export default DeliverBy
