import { Map } from 'immutable'

const getUserName = (first, last, fallback) => {
  if (first && last) {
    return `${first} ${last}`
  }
  if (first || last) {
    return String(first || last)
  }
  return (fallback && String(fallback)) || ''
}

export const getUserNameFromUser = (user = new Map(), fallback) =>
  getUserName(user.get('first_name'), user.get('last_name'), fallback)

export default getUserName
