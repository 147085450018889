import PropTypes from 'prop-types'
import React, { useState } from 'react'

import cdnizeAsset from '../../utils/cdnize-asset'

const Image = ({ url, alt, opacity, className, fallback, ...otherProps }) => {
  const [loadingError, setLoadingError] = useState(false)
  const handleLoadingError = () => {
    setLoadingError(true)
  }
  if (loadingError && fallback) {
    return fallback
  }
  return (
    <img
      className={className}
      src={cdnizeAsset(url)}
      alt={alt}
      style={{ opacity }}
      onError={handleLoadingError}
      {...otherProps}
    />
  )
}

Image.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  opacity: PropTypes.number,
  fallback: PropTypes.node,
}

Image.defaultProps = {
  opacity: 1,
  fallback: null,
  className: undefined,
}

export default Image
