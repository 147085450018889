import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Input extends Component {
  static propTypes = {
    type: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }

  static defaultProps = {
    type: 'text',
  }

  handleChange = (e) => {
    this.props.onChange(e.target.value, e.target.name, e)
  }

  render() {
    const { type, value, onChange, ...otherProps } = this.props
    return <input onChange={this.handleChange} value={value} type={type} {...otherProps} />
  }
}

export default Input
