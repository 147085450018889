import { get, del, post } from './request'
import instance from './request-v2'

export const MANUAL_REVIEW_PENDING = 'pending'
export const MANUAL_REVIEW_NO_CODES = 'no_codes'

export const getByCampaign = (key) => (params) => get('publisher-request-reviews', { key, params })

export const accept = (key) => (manualReviewRequestIds) =>
  post(['publisher-request-reviews', 'bulk_accept'], { key }, { ids: manualReviewRequestIds })

export const reject = (key) => (manualReviewRequestIds) =>
  post(['publisher-request-reviews', 'bulk_reject'], { key }, { ids: manualReviewRequestIds })

export const getCreatorRequests = (key) => (params) =>
  get('creator-request-reviews', { key, params })

export const getDetails = (key) => (id, params) =>
  get(['creator-request-reviews', id], { key, params })

export const remove = (key) => (id, params) => del(['creator-request-reviews', id], { key, params })

export const create = (key) => (payload) => post('creator-request-reviews', { key }, payload)

// the following is kinda messy but it debounces the actual API call.
// we accumulate the selection waiting for inactivity, then proceed
// with the actual API request and resolve all pending promises
const ONE_SEC = 2000
let selectionAccumulator = {}
let timeout = null
let promises = []
export const debouncedStageStatus = (key) => (selections) => {
  clearTimeout(timeout)
  return new Promise((resolve) => {
    promises.push(resolve)
    selectionAccumulator = { ...selectionAccumulator, ...selections }
    timeout = setTimeout(() => {
      const apiCall = instance
        .post('manual-review/stage-status/', { mrr_state_map: selectionAccumulator }, { key })
        .then((r) => r.data)
      selectionAccumulator = {}
      promises.forEach((r) => {
        r(apiCall)
      })
      promises = []
    }, ONE_SEC)
  })
}

export const stageStatus = (key) => (selections) =>
  instance
    .post('manual-review/stage-status/', { mrr_state_map: selections }, { key })
    .then((r) => r.data)

export const commitStatus = (key) => (campaignId) =>
  instance
    .post('manual-review/commit-status/', null, { key, params: { campaign_id: campaignId } })
    .then((r) => r.data)
