import { KIND } from '../config/user-settings'
import { acceptInvitation } from '../services/whitelist'

import localStorage from './local-storage'

export const isLoggedIn = (user) => user && !!user.get('auth_token')

const ifLoggedRedirectTo = (store, location, replace, route) => {
  if (isLoggedIn(store.getState().user)) {
    replace({
      pathname: route,
      state: { nextPathname: location.pathname },
    })
  }
}

const getRouteByUserKind = (user) => {
  const kind = user && user.getIn(['profile', 'kind'])
  return kind && kind === KIND.PUBLISHER ? '/activity' : '/offers'
}

export const userLoginRequired = (store, kind, to) => (nextState, replace) => {
  const user = store.getState().user
  const userKind = user.getIn(['profile', 'kind'])
  if (!isLoggedIn(user) || (userKind && userKind !== kind)) {
    localStorage.setItem('nextRoute', nextState.location.pathname)
    localStorage.setItem('kind', to)
    replace({
      pathname: `/signin/${to}`,
      state: { nextPathname: nextState.location.pathname },
    })
  }
}

export const redirectIfLogged = (store) => ({ location }, replace) => {
  const user = store.getState().user
  ifLoggedRedirectTo(store, location, replace, getRouteByUserKind(user))
}

export const redirectIfLoggedWithInvitation = (store) => ({ location }, replace) => {
  const user = store.getState().user
  if (user) {
    const { invitation_token: token } = location.query
    const kind = user.getIn(['profile', 'kind'])
    if (token && kind === KIND.CREATOR) {
      acceptInvitation(user.get('auth_token'))({ token })
    }
  }
  ifLoggedRedirectTo(store, location, replace, getRouteByUserKind(user))
}
