import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import styles from './styles.css'

const EffectPaid = ({ paid, children }) =>
  paid ? (
    <Fragment>
      <div className={styles.paidEffect}>
        <span className={styles.getPaid}>GET PAID</span>
      </div>
      <div className={styles.paidShadowWrap}>
        <div className={styles.paidShadow} />
      </div>
      <div className={styles.paidBackEffect} />
      <div className={styles.content}>{children}</div>
    </Fragment>
  ) : (
    children
  )

EffectPaid.propTypes = {
  children: PropTypes.node,
  paid: PropTypes.bool.isRequired,
}

EffectPaid.defaultProps = {
  children: null,
}

export default EffectPaid
