import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { faGift } from '@fortawesome/free-solid-svg-icons'

import Icon from '../piece-icon'

import styles from './styles.css'

const TOOLTIP = `You've been personally offered a code for this game by the publisher.<br />
Accepting this code will not affect your quota. If you don't want this offer,<br />
please "decline" it so the code can be freed for another creator.`
const EffectProactive = ({ proactive, children }) =>
  proactive ? (
    <Fragment>
      <div className={styles.proactiveEffect}>
        <div className={styles.proactiveIcon} data-tip={TOOLTIP}>
          <Icon icon={faGift} />
        </div>
      </div>
      <div className={styles.proactiveShadowWrap}>
        <div className={styles.proactiveShadow} />
      </div>
      <div className={styles.proactiveBorderTopFix} />
      <div className={styles.proactiveBorderBottomFix} />
      <div className={styles.proactiveBackEffect} />
      <div className={styles.content}>{children}</div>
    </Fragment>
  ) : (
    children
  )

EffectProactive.propTypes = {
  children: PropTypes.node,
  proactive: PropTypes.bool.isRequired,
}

EffectProactive.defaultProps = {
  children: null,
}

export default EffectProactive
