import React from 'react'
import PropTypes from 'prop-types'

import Actionable from '../actionable'
import Loading from '../piece-loading'

import styles from './styles.css'

const ManualRequestAction = ({
  manualRequest,
  isLoadingManualReview,
  manualRequestDone,
  acceptManualReview,
}) => (
  <>
    {manualRequest ? (
      <div className={styles.manualReview}>
        Good news! There is already a request from this creator to get this offer from you.{' '}
        <Actionable
          className={styles.link}
          skipOwnClass
          onClick={acceptManualReview}
          onClickWith={manualRequest}
        >
          Click here
        </Actionable>{' '}
        {isLoadingManualReview ? (
          <div className={styles.loadingWrap}>
            <Loading small isLoading />
          </div>
        ) : null}{' '}
        to approve their request instead of sending one this way.
      </div>
    ) : null}
    {manualRequestDone ? (
      <div className={styles.manualReview}>Request successfully approved!</div>
    ) : null}
  </>
)

ManualRequestAction.propTypes = {
  manualRequest: PropTypes.string,
  isLoadingManualReview: PropTypes.bool.isRequired,
  manualRequestDone: PropTypes.bool.isRequired,
  acceptManualReview: PropTypes.func.isRequired,
}

ManualRequestAction.defaultProps = {
  manualRequest: null,
}

export default ManualRequestAction
