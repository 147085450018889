import defineAction from '../../utils/define-action'
import * as publisherService from '../../services/publisher'

export const GET_PUBLISHER = defineAction('GET_PUBLISHER')
export const GET_ORGANIZATION_MEMBERS = defineAction('GET_ORGANIZATION_MEMBERS')
export const REMOVE_MEMBER_FROM_ORGANIZATION = defineAction('REMOVE_MEMBER_FROM_ORGANIZATION')
export const DEMOTE_MEMBER_FROM_ORGANIZATION = defineAction('DEMOTE_MEMBER_FROM_ORGANIZATION')
export const REINSTATE_MEMBER_FROM_ORGANIZATION = defineAction('REINSTATE_MEMBER_FROM_ORGANIZATION')
export const PROMOTE_MEMBER_FROM_ORGANIZATION = defineAction('PROMOTE_MEMBER_FROM_ORGANIZATION')
export const INVITE_USERS = defineAction('INVITE_USERS')
export const GET_INVITES = defineAction('GET_INVITES')
export const RESEND_INVITE = defineAction('RESEND_INVITE')
export const GET_INVITE = defineAction('GET_INVITE')
export const ACCEPT_INVITE = defineAction('ACCEPT_INVITE')
export const WITHDRAW_INVITE = defineAction('WITHDRAW_INVITE')

export const getPublisher = (slug) => (dispatch, getState) =>
  dispatch({
    type: GET_PUBLISHER.ACTION,
    payload: publisherService.getPublisher(getState().user.get('auth_token'))(slug),
  })

export const getMembers = () => (dispatch, getState) =>
  dispatch({
    type: GET_ORGANIZATION_MEMBERS,
    payload: publisherService.getMembers(getState().user.get('auth_token'))(),
  })

export const demoteMember = (memberId) => (dispatch, getState) =>
  dispatch({
    type: DEMOTE_MEMBER_FROM_ORGANIZATION,
    payload: publisherService.demoteMember(getState().user.get('auth_token'))(memberId),
  })

export const removeMember = (memberId) => (dispatch, getState) =>
  dispatch({
    type: REMOVE_MEMBER_FROM_ORGANIZATION,
    payload: publisherService.removeMember(getState().user.get('auth_token'))(memberId),
  })

export const reinstateMember = (memberId) => (dispatch, getState) =>
  dispatch({
    type: REINSTATE_MEMBER_FROM_ORGANIZATION,
    payload: publisherService.reinstateMember(getState().user.get('auth_token'))(memberId),
  })

export const promoteMember = (memberId) => (dispatch, getState) =>
  dispatch({
    type: PROMOTE_MEMBER_FROM_ORGANIZATION,
    payload: publisherService.promoteMember(getState().user.get('auth_token'))(memberId),
  })

export const inviteUsers = (users) => (dispatch, getState) =>
  dispatch({
    type: INVITE_USERS,
    payload: publisherService.inviteUsers(getState().user.get('auth_token'))(users),
  })

export const getInvites = () => (dispatch, getState) =>
  dispatch({
    type: GET_INVITES,
    payload: publisherService.getInvites(getState().user.get('auth_token'))(),
  })

export const resendInvite = (token) => (dispatch, getState) =>
  dispatch({
    type: RESEND_INVITE,
    payload: publisherService.resendInvite(getState().user.get('auth_token'))(token),
  })

export const getInvite = (token) => (dispatch) =>
  dispatch({
    type: GET_INVITE,
    payload: publisherService.getInvite(token),
  })

export const acceptInvite = (data) => (dispatch) =>
  dispatch({
    type: ACCEPT_INVITE,
    payload: publisherService.acceptInvite(data),
  })

export const withdrawInvite = (token) => (dispatch, getState) =>
  dispatch({
    type: WITHDRAW_INVITE,
    payload: publisherService.withdrawInvite(getState().user.get('auth_token'))(token),
    meta: token,
  })

export const clearInviteError = () => (dispatch) =>
  dispatch({
    type: INVITE_USERS,
  })
