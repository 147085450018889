import React from 'react'
import { Helmet } from 'react-helmet'

import styles from './styles.css'

/* eslint max-len: 0, react/no-unescaped-entities: 0 */
const PrivacyPolicy = () => (
  <div className={styles.wrap}>
    <Helmet>
      <title>Privacy Policy — Woovit</title>
    </Helmet>
    <h1 className={styles.heading}>Woovit Privacy Policy </h1>
    <p>
      Woovit, Inc. (“Woovit,” “we,” or “us”) collects various personal information from you from
      your use of the services provided by Woovit. Please read this privacy policy carefully to get
      a clear understanding of how Woovit collects, uses, protects, processes, and otherwise handles
      your personal information.
    </p>
    <p className={styles.question}>
      What personal information do we collect from you and when do we collect it?
    </p>
    <p>
      When registering on our site, we will collect personal information you enter during the
      registration process, such as your name, email address, social media identities and other
      details to help you with your experience. In certain instances, Woovit may collect your credit
      card or other financial information in order to obtain payment for Woovit services, which will
      be shared with our payment provider.
    </p>
    <p>
      We automatically collect personal information about your browsing habits and the device you
      use when you access and use the Woovit website and Woovit services. For example, we may
      collect information about the pages you view, referring and exit page addresses, the links you
      click, your IP address, browser type and language, your device identifier, device type, model
      and manufacturer. We use this information to present our website and services to you on your
      device and monitor and improve our website, services and business, and to help us develop new
      products and services. We may also use cookies to remember certain information so the Woovit
      website and Woovit services are customized for you each time you access them.
    </p>
    <p>
      We may obtain personal information about you from third parties and sources, such as
      advertisers. When you access the Woovit website or Woovit services through social media
      networks or when you connect our website or services to social media networks, Woovit may
      collect, store, and use information and content made available by the social media networks in
      accordance with this privacy policy. We may also obtain information from linked third-party
      services, such as Twitch or Steam, regarding your use of those services. If we combine or
      associate information from other sources with information that we collect through your use of
      the Woovit website or Woovit services, we will treat the combined information in accordance
      with this privacy policy.
    </p>
    <p>
      Woovit uses YouTube API Services. Users agree to be bound by the{' '}
      <a href="https://www.youtube.com/t/terms" target="_blank" rel="noopener noreferrer">
        YouTube Terms of service
      </a>{' '}
      and agree to{' '}
      <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
        Google Privacy Policy
      </a>
      .
    </p>
    <p>
      Once a day, Woovit collects channel statistics, including:
      <ul>
        <li>Channel names</li>
        <li>Email addresses</li>
        <li>Subscriber counts </li>
        <li>Total views </li>
      </ul>
      Uploaded video data is also collected daily, including:
      <ul>
        <li>Titles</li>
        <li>Descriptions</li>
        <li>Thumbnails</li>
        <li>View counts</li>
        <li>Duration</li>
      </ul>
    </p>
    <p>
      Collected channel and video data is viewable in-app and is used to calculate if channels meet
      Woovit’s minimum qualifications or specific criteria set by publishers for campaign offers.
      Woovit does not share this information with third parties.
    </p>
    <p>
      Users can verify and revoke Woovit's access to their Authorized Data via the{' '}
      <a
        href="https://security.google.com/settings/security/permissions"
        target="_blank"
        rel="noopener noreferrer"
      >
        Google security settings page
      </a>
      . Contact the Woovit team via email (hello [at] woovit.com) or support chat with inquiries
      about Woovit’s privacy practices.
    </p>
    <p className={styles.question}>How do we use your information?</p>
    <p>We may use the information we collect from you in the following ways:</p>
    <ul>
      <li>
        To personalize your experience and to allow us to deliver the type of content and product
        offerings in which you are most interested.
      </li>
      <li>To administer a contest, promotion, survey or other site feature.</li>
      <li>To quickly process your transactions.</li>
      <li>To send periodic emails regarding Woovit products, offerings, and services.</li>
    </ul>
    <p>
      Any information collected from you may be processed by us (Woovit, Inc.) in the United States
      of America for the above reasons or other legitimate interests of us. We will store your
      personal information for no longer than necessary for the purposes set forth in this privacy
      policy, and in accordance with our legal obligations and legitimate interests
    </p>
    <p className={styles.question}>How do we protect your information?</p>
    <p>We use regular Malware Scanning.</p>
    <p>
      Your personal information is contained behind secured networks and is only accessible by a
      limited number of persons who have special access rights to such systems, and are required to
      keep the information confidential. In addition, all sensitive information you supply is
      encrypted via Secure Socket Layer (SSL) technology.
    </p>
    <p>
      We implement a variety of security measures when a user enters, submits, or accesses their
      information to maintain the safety of your personal information.
    </p>
    <p className={styles.question}>Do we use 'cookies'?</p>
    <p>
      Yes. They are used to help us understand your preferences based on previous or current site
      activity, which enables us to provide you with improved services. We also use cookies to help
      us compile aggregate data about site traffic and site interaction so that we can offer better
      site experiences and tools in the future.
    </p>
    <p>We use cookies to:</p>
    <ul>
      <li>Understand and save user's preferences for future visits.</li>
      <li>
        Compile aggregate data about site traffic and site interactions in order to offer better
        site experiences and tools in the future. We may also use trusted third-party services that
        track this information on our behalf.
      </li>
    </ul>
    <p>
      You can choose to have your computer warn you each time a cookie is being sent, or you can
      choose to turn off all cookies. You do this through your browser settings. Since browser is a
      little different, look at your browser's Help Menu to learn the correct way to modify your
      cookies.
    </p>
    <p className={styles.question}>If users disable cookies in their browser:</p>
    <p>
      If you turn cookies off, Some of the features that make your site experience more efficient
      may not function properly. Some of the features that make your site experience more efficient
      and may not function properly.
    </p>
    <p className={styles.question}>Third-party disclosure</p>
    <p>
      We do not sell, trade, or otherwise transfer to third parties any information that can
      identify you personally unless we provide users with advance notice, with the exceptionof our
      website hosting partners and other parties who assist us in operating our website, conducting
      our business, or serving our users, so long as those parties agree to keep this information
      confidential. We may also release information when its release is appropriate to comply with
      the law, enforce our site policies, or protect ours or others' rights, property or safety.
    </p>
    <p>
      However, non-personally identifiable visitor information may be provided to other parties for
      marketing, advertising, or other uses.
    </p>
    <p className={styles.question}>Third-party links</p>
    <p>
      Occasionally, at our discretion, we may include or offer third-party products or services on
      our website. These third-party sites have separate and independent privacy policies. We
      therefore have no responsibility or liability for the content and activities of these linked
      sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback
      about these sites.
    </p>
    <p className={styles.question}>Account Information, Closure & Deletion</p>
    <p>
      If you would like to obtain a copy of the information we have associated with your account, or
      to close and delete your account from the Woovit website and Woovit services, please email us
      at hello@woovit.com, and provide your name, and Woovit account ID. Woovit may retain certain
      personal information, even if your account is deleted, if required by applicable law.
    </p>
    <p className={styles.question}>How does our site handle Do Not Track signals?</p>
    <p>
      We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a Do
      Not Track (DNT) browser mechanism is in place.
    </p>
    <p className={styles.question}>Personal Information of Persons Under the Age of 13.</p>
    <p>
      Woovit does not knowingly collect personal information from any person under the age of 13. If
      Woovit learns that personal information of a person under the age of 13 age has been collected
      by or through the Woovit website or services, Woovit will take appropriate steps to delete
      this information.
    </p>
    <h2>Updates to this Policy</h2>
    <p>
      Woovit reserves the right to change, modify, add, or remove portions of this privacy policy at
      any time. We may email you to notify you of updates to this policy, but please check{' '}
      <a href="/privacy-policy">this privacy policy link</a> periodically to view any changes. Any
      updated policy will be noted by an updated last edited date below, and an updated effective
      date above. Your continued use of the Woovit website or Woovit services after the posting of
      changes constitutes your acceptance of such changes.
    </p>
    <h2>Contacting Us</h2>
    <p>
      If there are any questions regarding this privacy policy, you may contact us using the
      information below.
    </p>
    <p>
      Woovit, Inc.
      <br />
      595 Market St., Suite 1341
      <br />
      San Francisco, CA 94105
      <br />
      United States
      <br />
      hello@woovit.com
    </p>
    <p>Last Edited on 2018-05-22</p>
  </div>
)

export default PrivacyPolicy
