import { fromJS } from 'immutable'

import { INITIAL_STATE } from '../config/settings'

export const getInitialState = (path) => {
  if (typeof window === 'undefined') {
    return null
  }
  return fromJS(window[INITIAL_STATE] || {}).get(path)
}
