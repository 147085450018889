import { Map, OrderedMap, List, fromJS } from 'immutable'
import { normalize, schema } from 'normalizr'

import createReducer from '../../utils/create-reducer'
import isNumber from '../../utils/is-number'
import { getHashCodeFromObject } from '../pagination/selectors'

import { GET_ACTIVITY_FEED, SEND_COMMENT } from './actions'

export const INITIAL_STATE = new Map()

export const campaignSchema = new schema.Entity('campaign')

const generateId = (obj) => {
  if (obj.id) {
    return String(obj.id)
  }
  return undefined
}

export const activityFeedEventsSchema = new schema.Entity('activityFeedEvents')
export const activityFeedEvents = createReducer(new Map(), {
  [GET_ACTIVITY_FEED.FULFILLED]: (state, { payload }) =>
    state.mergeDeep(
      normalize(payload.results, [activityFeedEventsSchema]).entities.activityFeedEvents
    ),
  [SEND_COMMENT.FULFILLED]: (state, { payload }) => {
    const commentsPath = [String(payload.action), 'comments']
    const newComments = state.getIn(commentsPath, new List()).push(fromJS(payload))

    return state.setIn(commentsPath, newComments)
  },
})

export const activityFeedEventsPagination = createReducer(new Map(), {
  [GET_ACTIVITY_FEED.FULFILLED]: (state, { payload, meta }) => {
    if (meta.page && payload && isNumber(payload.offset)) {
      const { offset, ...page } = meta.page
      const pageHash = getHashCodeFromObject(page)

      return state.updateIn([pageHash], (pageData = new OrderedMap()) =>
        pageData.set(
          String(offset),
          fromJS({
            results: payload.results.map(generateId).filter(Boolean),
            offset: payload.offset,
            count: payload.count,
            limit: payload.limit,
            next: payload.next,
          })
        )
      )
    }
    return state
  },
})
