import React from 'react'

import { getURL } from '../services/request'

export const YOUTUBE_CONNECT_URL = getURL(['creator', 'youtube', 'connect'])
export const TWITCH_CONNECT_URL = getURL(['creator', 'twitch', 'connect'])
export const STEAM_CONNECT_URL = getURL(['creator', 'steam', 'connect'])

export const CDN_PROXY_URL = 'https://cdnproxy.woovit.com/'

export const CHECK_FOR_UPDATES_AFTER_SECONDS = 900000 // 15 minutes
export const CHECK_FOR_UPDATES_INTERVAL = 60000 // 1 minute

export const SCROLLTOP_OFFSET = 120

export const CAMPAIGN_STATUS = {
  scheduled: 'Scheduled',
  drafting: 'Drafting',
  active: 'Active',
  paused: 'Stopped',
  expired: 'Stopped',
}

export const CAMPAIGN_STATES = {
  scheduled: 'scheduled',
  drafting: 'drafting',
  active: 'active',
  paused: 'stopped',
  expired: 'stopped',
}

export const OFFER_STATUS = {
  available: 'Available',
  accepted: 'Accepted',
  delivered: 'Delivered',
  archived: 'Archived',
  pending: 'Pending',
  rejected: 'Passed',
  claimed: 'Claimed',
  invited: 'Invited',
}

export const OFFER_STATE = {
  invited: 'Pending',
  claimed: 'Accepted',
  rejected_by_creator: 'Declined',
}

export const OFFER_TYPE = {
  offered: 'Offered',
  claimed: 'Claimed',
}

export const OFFER_EXPIRATION_DAYS = [7, 15, 30] // eslint-disable-line no-magic-numbers

export const INITIAL_STATE = '__INITIAL_STATE__' // eslint-disable-line no-underscore-dangle

export const YOUTUBE = 'youtube'
export const TWITCH = 'twitch'
export const STEAM = 'steam'

export const PLATFORM_YOUTUBE = 1
export const PLATFORM_TWITCH = 2
export const PLATFORM_STEAM = 5

export const VIDEO_PLATFORMS = {
  [PLATFORM_YOUTUBE]: 'YouTube',
  [PLATFORM_TWITCH]: 'Twitch',
  [PLATFORM_STEAM]: 'Steam',
}

export const PLATFORM_CONNECTION_URL = {
  [YOUTUBE]: YOUTUBE_CONNECT_URL,
  [TWITCH]: TWITCH_CONNECT_URL,
  [STEAM]: STEAM_CONNECT_URL,
}
export const PLATFORM_NAME_ID_MAP = {
  [YOUTUBE]: PLATFORM_YOUTUBE,
  [TWITCH]: PLATFORM_TWITCH,
  [STEAM]: PLATFORM_STEAM,
}
export const VIDEO_PLATFORM_NAME = {
  [PLATFORM_YOUTUBE]: YOUTUBE,
  [PLATFORM_TWITCH]: TWITCH,
  [PLATFORM_STEAM]: STEAM,
}
export const PLATFORM_NICE_NAME_MAP = {
  [YOUTUBE]: VIDEO_PLATFORMS[PLATFORM_YOUTUBE],
  [TWITCH]: VIDEO_PLATFORMS[PLATFORM_TWITCH],
  [STEAM]: VIDEO_PLATFORMS[PLATFORM_STEAM],
}

export const CHART_PLATFORM_COLORS = {
  [PLATFORM_YOUTUBE]: '#FF0000',
  [PLATFORM_TWITCH]: '#9146FF',
}

export const FREE_PLAN = 'free'
export const STARTER_PLAN = 'starter'
export const STARTER_PLAN_ANNUAL = 'starter-annual'
export const STARTER_LEGACY_PLAN = 'starter-legacy'
export const STARTER_LEGACY_PLAN_ANNUAL = 'starter-legacy-annual'
export const PREMIUM_PLAN = 'premium'
export const PREMIUM_PLAN_ANNUAL = 'premium-annual'
export const PREMIUM_21_PLAN_ANNUAL = 'premium-21-annual'
export const PREMIUM_21_PLAN = 'premium-21'
export const STARTER_PRICE = 59
export const STARTER_PRICE_ANNUAL = 499
export const PREMIUM_PRICE = 199
export const PREMIUM_PRICE_ANNUAL = 1999
export const ANNUAL_DISCOUNT_STARTER = 30 // percent
export const ANNUAL_DISCOUNT_PREMIUM = 16 // percent

export const DISCOUNT_MAP = {
  [STARTER_PLAN]: ANNUAL_DISCOUNT_STARTER,
  [PREMIUM_PLAN]: ANNUAL_DISCOUNT_PREMIUM,
}

export const ANNUAL = 'annual'
export const MONTHLY = 'monthly'

export const PLAN_PRICING = {
  [MONTHLY]: {
    [STARTER_PLAN]: STARTER_PRICE,
    [PREMIUM_PLAN]: PREMIUM_PRICE,
  },
  [ANNUAL]: {
    [STARTER_PLAN]: STARTER_PRICE_ANNUAL,
    [PREMIUM_PLAN]: PREMIUM_PRICE_ANNUAL,
  },
}

export const PLAN_COMBINATION_SLUG_MAP = {
  [MONTHLY]: {
    [FREE_PLAN]: [FREE_PLAN],
    [PREMIUM_PLAN]: [PREMIUM_21_PLAN],
  },
  [ANNUAL]: {
    [FREE_PLAN]: [FREE_PLAN],
    [PREMIUM_PLAN]: [PREMIUM_21_PLAN_ANNUAL],
  },
}

export const PLAN_COMBINATION_MAP = {
  [MONTHLY]: {
    [FREE_PLAN]: [FREE_PLAN],
    [PREMIUM_PLAN]: [PREMIUM_PLAN],
  },
  [ANNUAL]: {
    [FREE_PLAN]: [FREE_PLAN],
    [PREMIUM_PLAN]: [PREMIUM_PLAN_ANNUAL],
  },
}

export const PLANS = {
  [STARTER_PLAN_ANNUAL]: 'Starter Plan (annual)',
  [STARTER_PLAN]: 'Starter Plan',
  [PREMIUM_PLAN_ANNUAL]: 'Premium Plan (annual)',
  [PREMIUM_PLAN]: 'Premium Plan',
  [FREE_PLAN]: 'Free Plan',
}

export const RECURRENCES = {
  [MONTHLY]: 'Monthly',
  [ANNUAL]: 'Annual',
}

export const NOT_FOUND = 'Not found'
export const OFFER_REDIRECT = 'E_CAMPAIGN_HAS_OFFER'
export const MANUAL_REVIEW_REDIRECT = 'E_CAMPAIGN_HAS_MANUAL_REVIEW_REQUEST'
export const WHITELIST_NOT_ALLOWED = 'E_NO_RIGHTS_OVER_WHITELIST'

export const RANGE = 'range'
export const FLAT_FEE = 'flat'
export const NEGOTIABLE = 'negotiable'

export const MAX_SLUG_LENGTH = 40

export const CREATOR_VIDEOS_LIMIT = 10

export const MAXIMUM_FREE_ACTIVE_CAMPAIGNS = 1
export const MAXIMUM_STARTER_ACTIVE_CAMPAIGNS = 3

export const ENTER_REACT_KEY_CODE = 'Enter'

export const WHITELIST_ADD_FROM_WOOVIT_TAB = 'from_woovit'
export const WHITELIST_INVITE_BY_EMAIL_TAB = 'invite'
export const WHITELIST_BULK_UPLOAD_TAB = 'bulk'
export const WHITELIST_BULK_UPLOAD_RESULTS_TAB = 'bulk_results'

export const MAIN_TITLE = 'Woovit – Enterprise Solution for Video Game Influencer Marketing'

export const FREE_FEATURES = [
  <div>
    <strong>Manual or Automated</strong> code distribution
  </div>,
  <div>
    Access to our <strong>Search Database</strong>
  </div>,
  <div>
    <strong>Reports</strong> of delivered videos
  </div>,
  <div>
    <strong>Email Alerts</strong> for campaign activities
  </div>,
  <div>
    <strong>Custom Campaign Link</strong> to use for inbound requests and distribution
  </div>,
  <div>
    <strong>Whitelists</strong> of creators to direct communication and access to Campaigns
  </div>,
]

export const STARTER_FEATURES = []

export const PREMIUM_FEATURES = [
  <div>
    <strong>Everything on Free</strong> and...
  </div>,
  <div>
    <strong>Listed Campaigns</strong> on Woovit&apos;s library to drive Creator discovery
  </div>,
  <div>
    <strong>Send offers</strong> directly to Creators on Woovit
  </div>,
  <div>
    <strong>Multi-user Accounts</strong> for team collaboration and campaign management
  </div>,
  <div>
    <strong>Shared notes</strong> on creator profiles for relationship management
  </div>,
  <div>
    <strong>Coming Soon Campaigns</strong> to begin recruiting prior to your launch
  </div>,
]

export const FEATURED_CAMPAIGN = [
  <div>
    <strong>Boost your campaign</strong> with top-billing on every creator&apos;s dashboard
  </div>,
  <div>
    Increase <strong>campaign discovery</strong>
  </div>,
  <div>
    Secure one of four<strong> limited spots</strong>
  </div>,
  <div>
    <strong>Distribute more keys </strong> to qualified creators
  </div>,
]

export const AGENCY_FEATURES = [
  <div>
    Secure a <strong>Team</strong> including an account manager and influencer relations specialist
  </div>,
  <div>
    Develop a campaign <strong>Plan</strong> to align on strategies based on your objectives
  </div>,
  <div>
    Strategically engage influencers for <strong>earned content</strong> (unpaid) opportunities
  </div>,
  <div>
    <strong>Sponsor</strong> (pay) influencers for content
  </div>,
  <div>
    Get custom strategies & results based on <strong>performance goals</strong>
  </div>,
  <div>
    Build and manage a <strong>Partner program</strong>
  </div>,
]

export const PLATFORM_TWITCH_MINIMUM_SUBS = 50
export const PLATFORM_YOUTUBE_MINIMUM_SUBS = 1000
export const PLATFORM_YOUTUBE_MINIMUM_WATCHED_MINUTES = 240000
export const PLATFORM_YOUTUBE_MINIMUM_PUBLISHED_VIDEOS = 5
export const PLATFORM_YOUTUBE_VIEWS_PROPORTION_TO_SUBS = 5
export const PLATFORM_YOUTUBE_MINIMUM_PUBLISHED_VIDEOS_PERIOD_IN_DAYS = 90
export const PLATFORM_YOUTUBE_CHANNEL_AGE = '6 months old'
export const PLATFORM_YOUTUBE_MINIMUM_ACCOUNT_AGE_IN_MONTHS = 6
