import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import classnames from 'classnames'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import Button from '../button'
import Icon from '../piece-icon'

import styles from './styles.css'

const renderTab = ([label, href, beta, counter]) => (
  <Link key={href} className={styles.tab} to={href} activeClassName={styles.tabActive}>
    {beta ? (
      <p className={styles.betaLabel}>
        {label}
        <span>{typeof beta === 'string' ? beta : 'Beta'}</span>
      </p>
    ) : (
      label
    )}
    {counter > 0 && (
      <p className={styles.counter}>
        <span>{counter}</span>
      </p>
    )}
  </Link>
)

const TabBar = ({ className, tabs, withBorder, onAddClick }) => (
  <div className={classnames(className, styles.tabBar, { [styles.withBorder]: withBorder })}>
    <div>{tabs.map(renderTab)}</div>
    {onAddClick && (
      <div>
        <Button onClick={onAddClick} className={styles.addButton}>
          <Icon icon={faPlus} /> Add
        </Button>
      </div>
    )}
  </div>
)

TabBar.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)).isRequired,
  withBorder: PropTypes.bool,
  onAddClick: PropTypes.func,
  className: PropTypes.string,
}

TabBar.defaultProps = {
  withBorder: false,
  onAddClick: undefined,
  className: undefined,
}

export default TabBar
