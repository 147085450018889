import defineAction from '../../utils/define-action'
import * as planService from '../../services/plans'

export const SUBSCRIBE = defineAction('SUBSCRIBE')
export const GET_CARD = defineAction('GET_CARD')
export const GET_BILLING = defineAction('GET_BILLING')
export const ADD_CARD = defineAction('ADD_CARD')
export const CHANGE_PLAN = defineAction('CHANGE_PLAN')
export const DOWNGRADE = defineAction('DOWNGRADE')
export const REMOVE_CARD = defineAction('REMOVE_CARD')
export const DOWNLOAD_INVOICE = defineAction('DOWNLOAD_INVOICE')

export const subscribe = (stripeFunction, options, plans, coupon) => (dispatch, getState) =>
  dispatch({
    type: SUBSCRIBE,
    payload: planService.subscribe(getState().user.get('auth_token'))(
      stripeFunction(options),
      plans,
      coupon
    ),
  })

export const getCard = () => (dispatch, getState) =>
  dispatch({
    type: GET_CARD.ACTION,
    payload: planService.getCard(getState().user.get('auth_token')),
  })

export const getBilling = (params = {}) => (dispatch, getState) =>
  dispatch({
    type: GET_BILLING,
    payload: planService.getBillingHistory(getState().user.get('auth_token'))(params),
    meta: { page: params },
  })

export const addCard = (stripeFunction, options) => (dispatch, getState) =>
  dispatch({
    type: ADD_CARD.ACTION,
    payload: planService.addCard(getState().user.get('auth_token'))(stripeFunction(options)),
  })

export const downgrade = () => (dispatch, getState) =>
  dispatch({
    type: DOWNGRADE.ACTION,
    payload: planService.downgrade(getState().user.get('auth_token')),
  })

export const changePlan = (newPlan) => (dispatch, getState) =>
  dispatch({
    type: CHANGE_PLAN,
    payload: planService.changePlan(getState().user.get('auth_token'))(newPlan),
  })

export const removeCard = () => (dispatch, getState) =>
  dispatch({
    type: REMOVE_CARD,
    payload: planService.removeCard(getState().user.get('auth_token'))(),
  })

export const downloadInvoice = (invoiceId) => (dispatch, getState) =>
  dispatch({
    type: DOWNLOAD_INVOICE,
    meta: {
      loading: invoiceId,
    },
    payload: planService.downloadInvoice(getState().user.get('auth_token'))(invoiceId),
  })
