import React from 'react'
import ContentLoader from 'react-content-loader'

import styles from './styles.css'

const Loader = () => (
  <div className={styles.container}>
    <ContentLoader
      speed={2}
      viewBox="0 0 1050 530"
      backgroundColor="#dadada"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="0" ry="0" width="1050" height="315" />
      <rect x="0" y="320" rx="0" ry="0" width="135" height="75" />
      <rect x="139" y="320" rx="0" ry="0" width="135" height="75" />
      <rect x="278" y="320" rx="0" ry="0" width="135" height="75" />
      <rect x="417" y="320" rx="0" ry="0" width="135" height="75" />
      <rect x="556" y="320" rx="0" ry="0" width="135" height="75" />
      <rect x="0" y="430" rx="0" ry="0" width="1050" height="12" />
      <rect x="0" y="450" rx="0" ry="0" width="1050" height="12" />
      <rect x="0" y="470" rx="0" ry="0" width="575" height="12" />
      <rect x="0" y="495" rx="0" ry="0" width="1050" height="12" />
      <rect x="0" y="515" rx="0" ry="0" width="1050" height="12" />
    </ContentLoader>
  </div>
)

export default Loader
