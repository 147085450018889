import React, { Component } from 'react'
import axios from 'axios'

import rich from '../../img/team/rich.jpg'
import iacami from '../../img/team/iacami.jpg'
import blane from '../../img/team/blane.jpg'
import quinn from '../../img/team/quinn.jpg'
import { format } from '../../utils/datetime'
import { HUMAN_FORMAT_NOTIME } from '../../config/time-settings'
import addEllipsis from '../../utils/add-ellipsis'
import Image from '../../components/block-image'
import Loading from '../../components/piece-loading'
import Container from '../../components/container'

import blogImageFallback from './woovit-blog.jpg'
import styles from './styles.css'

const MAX_BLOG_ENTRIES = 3
const MAX_BLOG_CONTENT_LENGTH = 240
const TEAM = [
  {
    image: rich,
    name: 'Richard Kain',
    title: 'Founder',
    email: 'rich@woovit.com',
  },
  {
    image: quinn,
    name: 'Quinn Wageman',
    title: 'Founder',
    email: 'quinn@woovit.com',
  },
  {
    image: iacami,
    name: 'Iacami Gevaerd',
    title: 'Senior Software Engineer',
    email: 'iacami@woovit.com',
  },
  {
    image: blane,
    name: 'Blane Humphries',
    title: 'Relationship Manager',
    email: 'blane@woovit.com',
  },
]

class About extends Component {
  state = {
    blogEntries: [],
    isLoading: true,
    blogError: false,
  }

  componentDidMount() {
    axios
      .get('/blog/feed/json')
      .then((response) => {
        if (this.unmount) {
          return
        }
        this.setState({
          blogEntries: response.data.items.splice(0, MAX_BLOG_ENTRIES),
          isLoading: false,
        })
      })
      .catch(() => {
        this.setState({ blogError: true, isLoading: false })
      })
  }

  componentWillUnmount() {
    this.unmount = true
  }

  renderTeam = (entry) => (
    <div key={entry.email} className={styles.entry}>
      <div className={styles.face}>
        <img src={entry.image} className={styles.faceImage} alt={entry.name} />
      </div>
      <div className={styles.meta}>
        <h4 className={styles.name}>{entry.name}</h4>
        <small className={styles.title}>{entry.title}</small>
        <span className={styles.email}>{entry.email}</span>
      </div>
    </div>
  )

  renderBlogEntry = (entry) => {
    const div = document.createElement('div')
    // This is a little hack to convert escaped entities
    // (such as &#8217;) back to their original representation
    div.innerHTML = entry.content_text
    const postText = div.textContent
    return (
      <div key={entry.id} className={styles.blogEntry}>
        <div className={styles.blogImage}>
          <Image alt={entry.title} url={entry.image || blogImageFallback} />
        </div>
        <div>
          <h4 className={styles.blogTitle}>
            <a href={entry.url}>{entry.title}</a>
          </h4>
          <span className={styles.blogMeta}>
            by {entry.author.name} on {format(entry.date_published, HUMAN_FORMAT_NOTIME)}
          </span>
          <p>
            {addEllipsis(postText, MAX_BLOG_CONTENT_LENGTH)} <a href={entry.url}>read more</a>
          </p>
        </div>
      </div>
    )
  }

  render() {
    const { blogEntries, blogError, isLoading } = this.state
    return (
      <div className={styles.container}>
        <div className={styles.head}>
          <h1>About us</h1>
          <p className={styles.mission}>
            Woovit’s mission is to accelerate and democratize relationships between creators and
            publishers.
          </p>
        </div>
        <div className={styles.spacer} />
        <div className={styles.content}>
          <h2>Meet Our Team</h2>
          <div className={styles.people}>{TEAM.map(this.renderTeam)}</div>

          <h1>Latest News</h1>
          <Container>
            <Loading isLoading={isLoading} />
            {blogEntries.map(this.renderBlogEntry)}
            {blogError ? <h4>An error happened while loading latest news.</h4> : null}
            <div className={styles.visitTheBlog}>
              <a href="https://blog.woovit.com">Visit the Woovit Blog</a>
            </div>
          </Container>

          <div className={styles.location}>
            <h1>Location</h1>
            <p>
              595 Market St Suite 1340
              <br />
              San Francisco, CA 94105
            </p>
            <p>hello@woovit.com</p>
          </div>
        </div>
      </div>
    )
  }
}

export default About
