/* eslint-disable react/button-has-type   */
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'
import classnames from 'classnames'

import Loader from 'components/piece-loading'

import styles from './styles.css'

const BUTTON_KIND = {
  primary: styles.primary,
  secondary: styles.secondary,
  secondaryGray: styles.secondaryGray,
  secondaryInvertGray: styles.secondaryInvertGray,
  secondaryRed: styles.secondaryRed,
  secondaryBlue: styles.secondaryBlue,
  grayed: styles.grayed,
  destructive: styles.destructive,
}

const BUTTON_SIZE = {
  big: styles.big,
  normal: styles.normal,
  small: styles.small,
}

const Button = React.forwardRef((props, ref) => {
  const {
    onClick,
    onClickWith,
    kind,
    size,
    type,
    children,
    href,
    external,
    className,
    disabled,
    isLoading,
    ...otherProps
  } = props
  const handleClick = (event) => {
    if (disabled || isLoading) {
      return
    }
    onClick(onClickWith, event)
  }
  if (external && href) {
    return (
      <a
        ref={ref}
        className={classnames(className, BUTTON_KIND[kind], BUTTON_SIZE[size])}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        {...otherProps}
      >
        {children}
      </a>
    )
  }

  if (href) {
    return (
      <Link
        ref={ref}
        className={classnames(className, BUTTON_KIND[kind], BUTTON_SIZE[size])}
        to={href}
        {...otherProps}
      >
        {children}
      </Link>
    )
  }
  return (
    <button
      onClick={handleClick}
      ref={ref}
      type={type}
      className={classnames(className, BUTTON_KIND[kind], BUTTON_SIZE[size], {
        [styles.loading]: isLoading,
      })}
      disabled={disabled}
      {...otherProps}
    >
      {isLoading ? (
        <span className={styles.loader}>
          <Loader small={size === 'small'} isLoading />
        </span>
      ) : null}
      {children}
    </button>
  )
})

Button.propTypes = {
  children: PropTypes.node.isRequired,
  kind: PropTypes.oneOf(Object.keys(BUTTON_KIND)),
  size: PropTypes.oneOf(Object.keys(BUTTON_SIZE)),
  onClick: PropTypes.func,
  onClickWith: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  href: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      hash: PropTypes.string,
    }),
  ]),
  className: PropTypes.string,
  external: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
}

Button.defaultProps = {
  type: 'button',
  kind: 'primary',
  size: 'normal',
  onClickWith: undefined,
  onClick: Function.prototype,
  href: undefined,
  className: undefined,
  external: false,
  disabled: false,
  isLoading: false,
}

export default Button
