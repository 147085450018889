import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router'

import Svg from 'components/svg'
import Actionable from 'components/actionable'

import { AvatarContext } from '../../views/app-user'

import styles from './styles.css'
import fallback from './avatar.svg'

const Wrap = ({ slug, children, className }) =>
  slug.length ? (
    <Link className={classnames(styles.avatar, styles.link, className)} to={`/creator/${slug}`}>
      {children}
    </Link>
  ) : (
    <div className={classnames(styles.avatar, className)}>{children}</div>
  )

Wrap.propTypes = {
  slug: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
}

Wrap.defaultProps = {
  slug: '',
  children: null,
  className: '',
}

const Avatar = ({ name, email, slug, img, className, alt, hasTitle }) => {
  const creatorAvatar = useContext(AvatarContext)
  const handleClickAvatar = () => {
    creatorAvatar.setCreatorAvatar(slug)
  }
  const content = (
    <>
      {img ? (
        <img className={styles.img} src={img} alt={name || alt} />
      ) : (
        <Svg width="33" height="33" svg={fallback} />
      )}{' '}
      {email ? (
        <div title={hasTitle ? email : ''}>
          <span className={styles.name}>{name}</span>
          <span className={styles.email}>{email}</span>
        </div>
      ) : (
        name && (
          <span className={styles.name} title={hasTitle ? name : ''}>
            {name}
          </span>
        )
      )}
    </>
  )
  if (slug) {
    return (
      <Actionable
        skipOwnClass
        onClick={handleClickAvatar}
        className={classnames(styles.avatar, className)}
      >
        {content}
      </Actionable>
    )
  }
  return (
    <Wrap slug={slug} className={className}>
      {content}
    </Wrap>
  )
}

Avatar.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  slug: PropTypes.string,
  img: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
  hasTitle: PropTypes.bool,
}

Avatar.defaultProps = {
  slug: '',
  className: '',
  img: undefined,
  alt: '',
  hasTitle: false,
  email: undefined,
  name: undefined,
}

export default Avatar
