import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import {
  CAMPAIGN_PUBLISHED,
  CAMPAIGN_PAUSED,
  CAMPAIGN_RESUMED,
  CODE_ADDED,
  CODE_CLAIMED,
  CODE_OFFERED,
  CODELESS_OFFERED,
  CODE_REQUESTED,
  CODE_DEPLETED,
  NEW_DELIVERY,
  OFFER_INVITE_ACCEPTED,
  OFFER_INVITE_REJECTED,
  REQUEST_APPROVED,
  REQUEST_REJECTED,
  WHITELIST_CREATED,
  WHITELIST_CREATOR_INVITED,
  WHITELIST_INVITE_ACCEPTED,
  WHITELIST_CREATOR_ADDED,
  WHITELIST_CREATOR_REMOVED,
  FEED_ICON_SET,
  CREATOR_NOTE_ADDED,
  ORGANIZATION_MEMBER_INVITED,
  ORGANIZATION_MEMBER_PROMOTED,
  ORGANIZATION_MEMBER_DEMOTED,
  ORGANIZATION_MEMBER_REMOVED,
  ORGANIZATION_MEMBER_REINSTATED,
  ORGANIZATION_MEMBER_INVITE_ACCEPTED,
  BLOCKLIST_CREATOR_ADDED,
  BLOCKLIST_CREATOR_REMOVED,
} from 'config/activity-feed'
import { VIDEO_PLATFORMS, CAMPAIGN_STATES } from 'config/settings'
import Actionable from 'components/actionable'
import Event from 'components/activity-feed-event'
import { sendComment as sendCommentAction } from 'modules/activity-feed/actions'
import { createNote as createNoteAction } from 'modules/creator/actions'
import CreatorVideo from 'components/creator-video'
import { AvatarContext } from 'views/app-user'
import { format } from 'utils/datetime'

import styles from './styles.css'

const mapDispatchToProps = {
  sendComment: sendCommentAction,
  createNote: createNoteAction,
}

const onLinkClick = (e) => e.stopPropagation()

const getCreatorCard = (onClick, actionEntity) => {
  if (!actionEntity) {
    return null
  }
  const slug = actionEntity.getIn(['object_data', 'creator', 'slug'])
  const name = actionEntity.getIn(['object_data', 'creator', 'name'])
  return (
    <Actionable skipOwnClass className={styles.link} onClick={onClick} onClickWith={slug}>
      {name || slug || ''}
    </Actionable>
  )
}

const getVideoForEmbed = (event) => {
  const video = {
    platform: event.getIn(['action_object', 'object_data', 'platform']),
    platform_video_id: event.getIn(['action_object', 'object_data', 'platform_video_id']),
    video_url: event.getIn(['action_object', 'object_data', 'video_url']),
    duration: event.getIn(['action_object', 'object_data', 'duration']),
    thumbnail_url: event.getIn(['action_object', 'object_data', 'thumbnail_url']),
    screenshots: event.getIn(['action_object', 'object_data', 'screenshots']),
    title: event.getIn(['action_object', 'name']),
  }

  return Map(video)
}

const renderCampaignLink = (actionEntity) => {
  if (!actionEntity) {
    return null
  }
  return (
    <Link
      to={`/campaigns/${
        CAMPAIGN_STATES[actionEntity.getIn(['object_data', 'state'])]
      }/${actionEntity.get('id')}`}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.link}
      onClick={onLinkClick}
    >
      <var>{actionEntity.get('name')}</var>
    </Link>
  )
}

const renderWhitelistLink = (actionEntity) => {
  if (!actionEntity) {
    return null
  }
  return (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      to={`/whitelists/${actionEntity.get('id')}`}
      className={styles.link}
      onClick={onLinkClick}
    >
      <var>{actionEntity.get('name')}</var>
    </Link>
  )
}

const renderTime = (event) => {
  return (
    <div>
      <small className={styles.time}>
        <var>{format(event.get('created_at'), 'h:mm A')}</var>
      </small>
    </div>
  )
}

const FeedEvent = ({ className, event, expanded, sendComment, createNote }) => {
  const creatorAvatar = useContext(AvatarContext)
  const handleClickAvatar = (slug) => {
    creatorAvatar.setCreatorAvatar(slug)
  }

  const renderCreatorCard = getCreatorCard.bind(null, handleClickAvatar)

  const EVENT_CONTENT_BY_TYPE = {
    [CAMPAIGN_PUBLISHED]: {
      content: (
        <>
          <strong>
            <var>{event.getIn(['actor', 'name'])}</var>
          </strong>{' '}
          {event.get('verb')}{' '}
          {expanded ? (
            renderCampaignLink(event.get('action_object'))
          ) : (
            <strong>
              <var>{event.getIn(['action_object', 'name'])}</var>
            </strong>
          )}
          .{renderTime(event)}
        </>
      ),
    },
    [CAMPAIGN_PAUSED]: {
      content: (
        <>
          <strong>
            <var>{event.getIn(['actor', 'name'])}</var>
          </strong>{' '}
          {event.get('verb')}{' '}
          {expanded ? (
            renderCampaignLink(event.get('action_object'))
          ) : (
            <strong>
              <var>{event.getIn(['action_object', 'name'])}</var>
            </strong>
          )}
          .{renderTime(event)}
        </>
      ),
    },
    [CAMPAIGN_RESUMED]: {
      content: (
        <>
          <strong>
            <var>{event.getIn(['actor', 'name'])}</var>
          </strong>{' '}
          {event.get('verb')}{' '}
          {expanded ? (
            renderCampaignLink(event.get('action_object'))
          ) : (
            <strong>
              <var>{event.getIn(['action_object', 'name'])}</var>
            </strong>
          )}
          .{renderTime(event)}
        </>
      ),
    },
    [CODE_ADDED]: {
      content: (
        <>
          <strong>
            <var>{event.getIn(['actor', 'name'])}</var>
          </strong>{' '}
          {event.get('verb')}{' '}
          <strong>
            <var>
              {event.getIn(['data', 'count'])} {event.getIn(['action_object', 'name'])}
            </var>
          </strong>{' '}
          code{event.getIn(['data', 'count']) > 1 && 's'}
          {expanded && <> to {renderCampaignLink(event.get('target'))}</>}
          {renderTime(event)}
        </>
      ),
    },
    [CODE_CLAIMED]: {
      content: (
        <>
          {renderCreatorCard(event.get('actor'))} {event.get('verb')} a{' '}
          <strong>
            <var>{event.getIn(['data', 'platform'])}</var>
          </strong>{' '}
          code ({event.getIn(['action_object', 'name'])})
          {expanded && <> on {renderCampaignLink(event.get('target'))}</>}.{renderTime(event)}
        </>
      ),
      creatorId: event.getIn(['actor', 'id']),
    },
    [CODE_OFFERED]: {
      content: (
        <>
          <strong>
            <var>{event.getIn(['actor', 'name'])}</var>
          </strong>{' '}
          {event.get('verb')} a{' '}
          <strong>
            <var>{event.getIn(['data', 'platform'])}</var>
          </strong>{' '}
          code to{' '}
          <strong>
            <var>{renderCreatorCard(event.get('action_object'))}</var>
          </strong>
          {expanded && <> on {renderCampaignLink(event.get('target'))}</>}.{renderTime(event)}
        </>
      ),
      creatorId: event.getIn(['action_object', 'id']),
    },
    [CODELESS_OFFERED]: {
      content: (
        <>
          <strong>
            <var>{event.getIn(['actor', 'name'])}</var>
          </strong>{' '}
          {event.get('verb')}{' '}
          <strong>
            <var>{renderCreatorCard(event.get('action_object'))}</var>
          </strong>
          {expanded && <> to visit {renderCampaignLink(event.get('target'))}</>}.{renderTime(event)}
        </>
      ),
      creatorId: event.getIn(['action_object', 'id']),
    },
    [CODE_REQUESTED]: {
      content: (
        <>
          {renderCreatorCard(event.get('actor'))} {event.get('verb')} a{' '}
          <strong>
            <var>{event.getIn(['action_object', 'name'])}</var>
          </strong>{' '}
          code
          {expanded && <> on {renderCampaignLink(event.get('target'))}</>}.{renderTime(event)}
        </>
      ),
      creatorId: event.getIn(['actor', 'id']),
    },
    [CODE_DEPLETED]: {
      content: (
        <>
          All{' '}
          <strong>
            <var>{event.getIn(['actor', 'name'])}</var>
          </strong>{' '}
          codes {event.get('verb')}
          {expanded && <> on {renderCampaignLink(event.get('target'))}</>}.{renderTime(event)}
        </>
      ),
    },
    [NEW_DELIVERY]: {
      content: (
        <>
          {VIDEO_PLATFORMS[event.getIn(['action_object', 'object_data', 'platform'])] ===
          'YouTube' ? (
            <>
              {renderCreatorCard(event.get('actor'))} {event.get('verb').split('|')[0]} on{' '}
            </>
          ) : (
            <>
              {renderCreatorCard(event.get('actor'))} {event.get('verb').split('|')[1]} on{' '}
            </>
          )}
          <strong>
            <var>{VIDEO_PLATFORMS[event.getIn(['action_object', 'object_data', 'platform'])]}</var>
          </strong>
          {expanded && <> for {renderCampaignLink(event.get('target'))}</>}.{renderTime(event)}
          <div className={styles.videoContent}>
            <div className={styles.videoWrapper}>
              <CreatorVideo
                thumbWidth="180px"
                thumbHeight="102px"
                video={getVideoForEmbed(event)}
                hasContent={!!event.getIn(['action_object', 'object_data', 'video_url'])}
              />
            </div>
            <div className={styles.videoTitle}>
              {event.getIn(['action_object', 'name'])}
              <span className={styles.videoViewInfo}>
                <br />
                {VIDEO_PLATFORMS[event.getIn(['action_object', 'object_data', 'platform'])] ===
                'Twitch' ? (
                  <>
                    Average viewership:{' '}
                    <strong>
                      <var>
                        {(
                          event.getIn(['action_object', 'object_data', 'avg_viewers'], 0) || 0
                        ).toLocaleString()}
                      </var>
                    </strong>{' '}
                    | Peak:{' '}
                    <strong>
                      <var>
                        {(
                          event.getIn(['action_object', 'object_data', 'peak_viewers'], 0) || 0
                        ).toLocaleString()}
                      </var>
                    </strong>
                  </>
                ) : (
                  <>
                    Views:{' '}
                    <strong>
                      <var>
                        {(
                          event.getIn(['action_object', 'object_data', 'views_count'], 0) || 0
                        ).toLocaleString()}
                      </var>
                    </strong>
                  </>
                )}
              </span>
            </div>
          </div>
        </>
      ),
      creatorId: event.getIn(['actor', 'id']),
    },
    [OFFER_INVITE_ACCEPTED]: {
      content: (
        <>
          {renderCreatorCard(event.get('actor'))} {event.get('verb')}{' '}
          <strong>
            <var>{event.getIn(['action_object', 'name'])}</var>
          </strong>
          {"'s "}
          <strong>
            <var>{event.getIn(['data', 'platform'])}</var>
          </strong>{' '}
          code offer
          {expanded && <> on {renderCampaignLink(event.get('target'))}</>}.{renderTime(event)}
        </>
      ),
      creatorId: event.getIn(['actor', 'id']),
    },
    [OFFER_INVITE_REJECTED]: {
      content: (
        <>
          {renderCreatorCard(event.get('actor'))} {event.get('verb')}{' '}
          <strong>
            <var>{event.getIn(['action_object', 'name'])}</var>
          </strong>
          {"'s "}
          <strong>
            <var>{event.getIn(['data', 'platform'])}</var>
          </strong>{' '}
          code offer
          {expanded && <> on {renderCampaignLink(event.get('target'))}</>}.{renderTime(event)}
        </>
      ),
      creatorId: event.getIn(['actor', 'id']),
    },
    [REQUEST_APPROVED]: {
      content: (
        <>
          <strong>
            <var>{event.getIn(['actor', 'name'])}</var>
          </strong>{' '}
          {event.get('verb')} {renderCreatorCard(event.get('action_object'))}
          {"'s "}
          <strong>
            <var>{event.getIn(['data', 'platform'])}</var>
          </strong>{' '}
          code request
          {expanded && <> on {renderCampaignLink(event.get('target'))}</>}.{renderTime(event)}
        </>
      ),
      creatorId: event.getIn(['action_object', 'id']),
    },
    [REQUEST_REJECTED]: {
      content: (
        <>
          <strong>
            <var>{event.getIn(['actor', 'name'])}</var>
          </strong>{' '}
          {event.get('verb')} {renderCreatorCard(event.get('action_object'))}
          {"'s "}
          <strong>
            <var>{event.getIn(['data', 'platform'])}</var>
          </strong>{' '}
          code request
          {expanded && <> on {renderCampaignLink(event.get('target'))}</>}.{renderTime(event)}
        </>
      ),
      creatorId: event.getIn(['action_object', 'id']),
    },
    [WHITELIST_CREATED]: {
      content: (
        <>
          <strong>
            <var>{event.getIn(['actor', 'name'])}</var>
          </strong>{' '}
          {event.get('verb')} a whitelist called {renderWhitelistLink(event.get('action_object'))}.
          {renderTime(event)}
        </>
      ),
    },
    [WHITELIST_CREATOR_INVITED]: {
      content: (
        <>
          <strong>
            <var>{event.getIn(['actor', 'name'])}</var>
          </strong>{' '}
          {event.get('verb')}{' '}
          <strong>
            <var>{event.getIn(['action_object', 'name'])}</var>
          </strong>{' '}
          to join {renderWhitelistLink(event.get('target'))}.{renderTime(event)}
        </>
      ),
    },
    [WHITELIST_INVITE_ACCEPTED]: {
      content: (
        <>
          {renderCreatorCard(event.get('actor'))} {event.get('verb')}{' '}
          <strong>
            <var>{event.getIn(['action_object', 'name'])}</var>
          </strong>
          {"'s "}invitation and joined {renderWhitelistLink(event.get('target'))}.
          {renderTime(event)}
        </>
      ),
      creatorId: event.getIn(['actor', 'id']),
    },
    [WHITELIST_CREATOR_ADDED]: {
      content: (
        <>
          <strong>
            <var>{event.getIn(['actor', 'name'])}</var>
          </strong>{' '}
          {event.get('verb')} {renderCreatorCard(event.get('action_object'))} to{' '}
          {renderWhitelistLink(event.get('target'))}.{renderTime(event)}
        </>
      ),
      creatorId: event.getIn(['action_object', 'id']),
    },
    [WHITELIST_CREATOR_REMOVED]: {
      content: (
        <>
          <strong>
            <var>{event.getIn(['actor', 'name'])}</var>
          </strong>{' '}
          {event.get('verb')} {renderCreatorCard(event.get('action_object'))} from{' '}
          {renderWhitelistLink(event.get('target'))}.{renderTime(event)}
        </>
      ),
      creatorId: event.getIn(['action_object', 'id']),
    },

    [CREATOR_NOTE_ADDED]: {
      content: (
        <>
          <strong>{event.getIn(['actor', 'name'])}</strong> {event.get('verb')}{' '}
          {renderCreatorCard(event.get('action_object'))}
          {renderTime(event)}
        </>
      ),
    },
    [ORGANIZATION_MEMBER_INVITED]: {
      content: (
        <>
          <strong>
            <var>{event.getIn(['actor', 'name'])}</var>
          </strong>{' '}
          {event.get('verb')}{' '}
          <strong>
            <var>{event.getIn(['action_object', 'name'])}</var>
          </strong>{' '}
          to join{' '}
          <strong>
            <var>{event.getIn(['target', 'name'])}</var>
          </strong>
          .{renderTime(event)}
        </>
      ),
    },
    [ORGANIZATION_MEMBER_PROMOTED]: {
      content: (
        <>
          <strong>
            <var>{event.getIn(['actor', 'name'])}</var>
          </strong>{' '}
          {event.get('verb')}{' '}
          <strong>
            <var>{event.getIn(['action_object', 'name'])}</var>
          </strong>{' '}
          to Admin.
          {renderTime(event)}
        </>
      ),
    },
    [ORGANIZATION_MEMBER_DEMOTED]: {
      content: (
        <>
          <strong>
            <var>{event.getIn(['actor', 'name'])}</var>
          </strong>{' '}
          {event.get('verb')}{' '}
          <strong>
            <var>{event.getIn(['action_object', 'name'])}</var>
          </strong>{' '}
          to Member.
          {renderTime(event)}
        </>
      ),
    },
    [ORGANIZATION_MEMBER_REMOVED]: {
      content: (
        <>
          <strong>
            <var>{event.getIn(['actor', 'name'])}</var>
          </strong>{' '}
          {event.get('verb')}{' '}
          <strong>
            <var>{event.getIn(['action_object', 'name'])}</var>
          </strong>{' '}
          from{' '}
          <strong>
            <var>{event.getIn(['target', 'name'])}</var>
          </strong>
          .{renderTime(event)}
        </>
      ),
    },
    [ORGANIZATION_MEMBER_REINSTATED]: {
      content: (
        <>
          <strong>
            <var>{event.getIn(['actor', 'name'])}</var>
          </strong>{' '}
          {event.get('verb')}{' '}
          <strong>
            <var>{event.getIn(['action_object', 'name'])}</var>
          </strong>{' '}
          from{' '}
          <strong>
            <var>{event.getIn(['target', 'name'])}</var>
          </strong>
          .{renderTime(event)}
        </>
      ),
    },
    [ORGANIZATION_MEMBER_INVITE_ACCEPTED]: {
      content: (
        <>
          <strong>
            <var>{event.getIn(['actor', 'name'])}</var>
          </strong>{' '}
          {event.get('verb')}{' '}
          <strong>
            <var>{event.getIn(['action_object', 'name'])}</var>
          </strong>
          {"'s "} invitation and joined{' '}
          <strong>
            <var>{event.getIn(['target', 'name'])}</var>
          </strong>
          .{renderTime(event)}
        </>
      ),
    },
    [BLOCKLIST_CREATOR_ADDED]: {
      content: (
        <>
          <strong>
            <var>{event.getIn(['actor', 'name'])}</var>
          </strong>{' '}
          {event.get('verb')} {renderCreatorCard(event.get('action_object'))}.{renderTime(event)}
        </>
      ),
      creatorId: event.getIn(['action_object', 'id']),
    },
    [BLOCKLIST_CREATOR_REMOVED]: {
      content: (
        <>
          <strong>
            <var>{event.getIn(['actor', 'name'])}</var>
          </strong>{' '}
          {event.get('verb')} {renderCreatorCard(event.get('action_object'))} from the blocklist.
          {renderTime(event)}
        </>
      ),
      creatorId: event.getIn(['action_object', 'id']),
    },
  }

  const sendReply = ({ eventId, creatorId }) => ({ message, sendToNotes }) => {
    sendComment(eventId, { message })
    if (sendToNotes) {
      createNote(creatorId, message, false)
    }
  }

  const type = event.get('action_type')
  const { content: eventContent, creatorId: eventCreatorId } = EVENT_CONTENT_BY_TYPE[type]
  if (!eventContent) {
    return null
  }

  const { primary, secondary } = FEED_ICON_SET[type]
  return (
    <Event
      id={event.get('id')}
      className={className}
      primaryIcon={primary}
      secondaryIcon={
        type === NEW_DELIVERY
          ? secondary[event.getIn(['action_object', 'object_data', 'platform'])]
          : secondary
      }
      time={event.get('created_at')}
      comments={event.get('comments')}
      hasSendToNotes={!!eventCreatorId}
      onSendReply={sendReply({ eventId: event.get('id'), creatorId: eventCreatorId })}
    >
      {eventContent}
    </Event>
  )
}

FeedEvent.propTypes = {
  className: PropTypes.string,
  event: ImmutablePropTypes.map.isRequired,
  expanded: PropTypes.bool,
  sendComment: PropTypes.func.isRequired,
  createNote: PropTypes.func.isRequired,
}

FeedEvent.defaultProps = {
  className: undefined,
  expanded: false,
}

export default connect(
  null,
  mapDispatchToProps
)(FeedEvent)
