const sessionStorageMock = {
  setItem: Function.prototype,
  getItem: Function.prototype,
  removeItem: Function.prototype,
}

const sessionStorage =
  (typeof window !== 'undefined' && window.sessionStorage) || sessionStorageMock

export default sessionStorage
