import defineAction from '../../utils/define-action'
import * as service from '../../services/feed'

export const GET_ACTIVITY_FEED = defineAction('GET_ACTIVITY_FEED')
export const SEND_COMMENT = defineAction('SEND_COMMENT')

export const ACTIVITY_FEED_DEFAULT_PARAMS = { limit: 12, offset: 0 }
export const getActivityFeed = (params) => (dispatch, getState) => {
  const newParams = { ...ACTIVITY_FEED_DEFAULT_PARAMS, ...params }
  return dispatch({
    type: GET_ACTIVITY_FEED,
    payload: service.getActivityFeed(getState().user.get('auth_token'))(newParams),
    meta: {
      page: { ...newParams },
    },
  })
}

export const sendComment = (actionId, payload) => (dispatch, getState) => {
  return dispatch({
    type: SEND_COMMENT,
    payload: service.sendComment(getState().user.get('auth_token'))(actionId, payload),
  })
}
