import { normalize, schema } from 'normalizr'
import { Map } from 'immutable'

import createReducer from '../../utils/create-reducer'

import { FETCH_BLOCKLISTED_CREATORS } from './actions'

export const INITIAL_STATE = new Map()
const blocklistSchema = new schema.Entity('blocklistedCreators')

const normalizeAndMergeDeep = (state, { payload }) => {
  const payloadResults = payload.results ? payload.results : payload
  const results = normalize(payloadResults, [blocklistSchema]).entities.blocklistedCreators
  return state.mergeDeep(results)
}

export const blocklistedCreators = createReducer(INITIAL_STATE, {
  [FETCH_BLOCKLISTED_CREATORS.FULFILLED]: normalizeAndMergeDeep,
})
