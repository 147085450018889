import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'
import ImmutableProptypes from 'react-immutable-proptypes'
import { Helmet } from 'react-helmet'

import sanitizeHTML from '../../utils/sanitize-html'
import OfferHead from '../../components/offer-head'
import OfferBody from '../../components/offer-body'
import OfferMeta from '../../components/offer-meta'
import Button from '../../components/button'
import Twitter from '../../components/twitter'
import If from '../../components/if'
import {
  getPublicCampaign as getPublicCampaignAction,
  GET_CAMPAIGN_OFFER_DETAILS,
} from '../../modules/campaign/actions'
import NotFoundPage from '../not-found'
import { MAIN_TITLE } from '../../config/settings'
import cdnizeAsset from '../../utils/cdnize-asset'
import { campaignOffer as campaignOfferReducer } from '../../modules/campaign/reducers'
import * as campaignService from '../../services/campaigns'
import isNotFound from '../../utils/is-not-found'

import styles from './styles.css'

const mapStateToProps = ({ campaignOffer, error, loading }, { params: { slug } }) => {
  const offer = campaignOffer.find((c) => c.get('slug') === slug)
  return {
    offer,
    isLoading: !offer && !!loading.get(GET_CAMPAIGN_OFFER_DETAILS.ACTION),
    error: error.get(GET_CAMPAIGN_OFFER_DETAILS.ACTION),
  }
}

const mapDispatchToProps = {
  getPublicCampaign: getPublicCampaignAction,
}

const PublicOffer = ({ params: { slug }, getPublicCampaign, offer, error, isLoading }) => {
  const title = offer.get('title') || offer.get('steam_game_name')

  useEffect(() => {
    getPublicCampaign(slug)
  }, [slug])

  const getCTA = () => {
    if (offer.get('status') === 'out_of_codes') {
      return (
        <Button kind="grayed" onClick={Function.prototype}>
          OUT OF CODES
        </Button>
      )
    }

    if (offer.get('state') === 'expired') {
      return (
        <Button kind="grayed" onClick={Function.prototype}>
          FINISHED
        </Button>
      )
    }

    return (
      <Button kind="primary" href={`/offers/available/${offer.get('slug')}`}>
        I WANT THIS OFFER!
      </Button>
    )
  }

  if (offer.isEmpty() && isNotFound(error)) {
    return <NotFoundPage />
  }

  const platforms = offer.get('require_manual_review')
    ? offer.get('code_platforms', new List())
    : offer.get('platforms', new List())

  return (
    <div className={styles.container}>
      <Helmet>
        <title>{title ? `${title} — Woovit` : MAIN_TITLE}</title>
        <meta property="og:title" content={title || MAIN_TITLE} />
        {offer.get('noindex') ? <meta name="robots" content="noindex" /> : null}
        <meta
          property="og:description"
          content={(
            sanitizeHTML(
              (offer.get('description') || '')
                .replace(/<\/p>/, '</p><br />')
                .replace(/<\/li>/, '</li><br />'),
              {
                allowedTags: ['br'],
              }
            ) || ''
          ).replace(new RegExp('(<br />)+', 'g'), '\n')}
        />
        <meta property="og:image" content={cdnizeAsset(offer.get('cover_url'))} />
      </Helmet>
      <OfferHead
        title={title}
        image={offer.get('cover_url')}
        shortURL={offer.get('short_url')}
        platforms={platforms.toArray()}
        publisher={offer.getIn(['organization', 'name']) || offer.get('steam_game_publisher')}
        organizationName={offer.getIn(['organization', 'name'])}
        organizationSlug={offer.getIn(['organization', 'slug'])}
        isPaid={offer.get('paid')}
        isLoading={isLoading}
        isReleased={offer.get('is_released')}
        releaseAt={offer.get('release_at')}
      >
        <div className={styles.ctaWrapper}>{getCTA()}</div>
      </OfferHead>
      <div className={styles.contentWrapper}>
        <OfferBody
          media={offer.get('media')}
          terms={offer.get('terms')}
          description={offer.get('description')}
          productURL={offer.get('product_url')}
          steamGameId={offer.get('steam_game_id')}
          isLoading={isLoading}
        />
        <If
          condition={
            offer.get('twitter_handle') || offer.get('deliver_by') || offer.get('embargo_ends')
          }
        >
          <div className={styles.sidebarWrapper}>
            {offer.get('deliver_by') || offer.get('embargo_ends') ? (
              <OfferMeta
                timezone={offer.get('timezone')}
                deliverBy={offer.get('deliver_by')}
                embargoEnds={offer.get('embargo_ends')}
              />
            ) : null}
            {offer.get('twitter_handle') ? (
              <div className={styles.sidebarItem}>
                <div className={styles.twitterFeed}>
                  <Twitter handle={offer.get('twitter_handle')} />
                </div>
              </div>
            ) : null}
          </div>
        </If>
      </div>
    </div>
  )
}

PublicOffer.propTypes = {
  offer: ImmutableProptypes.map,
  params: PropTypes.shape({
    slug: PropTypes.string,
  }).isRequired,
  getPublicCampaign: PropTypes.func.isRequired,
  error: ImmutableProptypes.map,
  isLoading: PropTypes.bool.isRequired,
}

PublicOffer.defaultProps = {
  offer: new Map(),
  error: new Map(),
}

PublicOffer.getData = ({ slug }) => [
  campaignService
    .getPublicCampaign()(slug)
    .then((payload) => ({
      campaignOffer: campaignOfferReducer(undefined, {
        type: GET_CAMPAIGN_OFFER_DETAILS.FULFILLED,
        payload,
      }),
    })),
]

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicOffer)
