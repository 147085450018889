import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { Lazy } from 'react-lazy'
import ImmutableProptypes from 'react-immutable-proptypes'
import classnames from 'classnames'
import { faPlay } from '@fortawesome/free-solid-svg-icons'

import getEmbed from '../../utils/get-embed'
import { normalizeDuration } from '../../utils/datetime'
import Actionable from '../actionable'
import Icon from '../piece-icon'
import fallbackImage from '../../img/image-not-found.jpg'
import PlayIcon from '../../img/ic-play.svg'
import Svg from '../svg'
import { VIDEO_PLATFORM_NAME, PLATFORM_YOUTUBE } from '../../config/settings'
import { THUMBNAIL_URL } from '../../config/environment'
import { VideoPlayerContext } from '../../views/app'

import styles from './styles.css'

const getThumbnailURL = (platform, url) =>
  `${THUMBNAIL_URL}/${VIDEO_PLATFORM_NAME[Number(platform)]}/${url}`
const CreatorVideo = ({
  video,
  thumbWidth,
  thumbHeight,
  duration,
  canPlay,
  hasContent,
  isDisabled,
  className,
}) => {
  const [loadingError, setLoadingError] = useState(false)
  const currentThumb = 0
  const videoPlayer = useContext(VideoPlayerContext)

  const handleThumbnailError = () => {
    setLoadingError(true)
  }

  const playVideo = () => {
    if (isDisabled || !canPlay) {
      return
    }
    videoPlayer.setVideoPlayerNode(
      getEmbed(video.get('platform_video_id'), video.get('platform'), video.get('game_start'))
    )
  }

  const getThumb = () => {
    if (video.get('screenshots', new List()).size) {
      const thumbPath = video.getIn(['screenshots', currentThumb])
      return getThumbnailURL(video.get('platform'), thumbPath)
    }
    const thumbnail = video.get('thumbnail_url')
    if (!thumbnail && video.get('platform') === String(PLATFORM_YOUTUBE)) {
      return `https://i3.ytimg.com/vi/${video.get('platform_video_id')}/sddefault.jpg`
    }
    return thumbnail || fallbackImage
  }

  const getVisual = () => {
    if (!loadingError) {
      return (
        <Lazy>
          <Actionable
            onClick={playVideo}
            className={isDisabled || !canPlay ? styles.disabled : undefined}
          >
            {canPlay && hasContent ? (
              <div className={styles.playIndicator}>
                <Icon icon={faPlay} />
              </div>
            ) : null}
            {hasContent ? (
              <img
                width={thumbWidth}
                height={thumbHeight}
                onError={handleThumbnailError}
                src={getThumb()}
                alt={video.get('title')}
              />
            ) : (
              <Svg className={styles.noThumbnail} svg={PlayIcon} />
            )}
            {duration ? (
              <div className={styles.videoDuration}>{normalizeDuration(video.get('duration'))}</div>
            ) : null}
          </Actionable>
        </Lazy>
      )
    }
    return null
  }

  return (
    <div
      className={classnames(
        styles.videoEmbed,
        {
          [styles.noPreview]: loadingError,
        },
        className
      )}
      style={{ minHeight: thumbHeight }}
    >
      {getVisual()}
    </div>
  )
}

CreatorVideo.propTypes = {
  video: ImmutableProptypes.map.isRequired,
  thumbWidth: PropTypes.string,
  thumbHeight: PropTypes.string,
  duration: PropTypes.bool,
  canPlay: PropTypes.bool,
  hasContent: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
}

CreatorVideo.defaultProps = {
  thumbWidth: undefined,
  thumbHeight: undefined,
  duration: true,
  canPlay: true,
  hasContent: true,
  isDisabled: false,
  className: undefined,
}

export default CreatorVideo
