import React from 'react'
import { Route, IndexRedirect, Redirect } from 'react-router'
import Loadable from 'react-loadable'

import Loading from 'components/piece-loading-async-route'

const loading = () => <Loading />

const ViewOfferAvailable = Loadable({
  loader: () => import(/* webpackChunkName: "offerdetail" */ 'views/view-offer/available'),
  loading,
})

const ViewOffer = Loadable({
  loader: () => import(/* webpackChunkName: "offerdetail" */ 'views/view-offer'),
  loading,
})

const ViewOfferDescription = Loadable({
  loader: () => import(/* webpackChunkName: "offerdetail" */ 'views/view-offer/description'),
  loading,
})

const ViewOfferVideos = Loadable({
  loader: () => import(/* webpackChunkName: "offerdetail" */ 'views/view-offer/videos'),
  loading,
})

const ViewOfferDescriptionArchived = Loadable({
  loader: () => import(/* webpackChunkName: "offerdetail" */ 'views/view-offer/archived'),
  loading,
})

const ViewOfferCongrats = Loadable({
  loader: () => import(/* webpackChunkName: "offerdetail" */ 'views/view-offer/congrats'),
  loading,
})

const ViewOfferHowToDeliver = Loadable({
  loader: () => import(/* webpackChunkName: "offerdetail" */ 'views/view-offer/how-to-deliver'),
  loading,
})

const ViewSubmitVideoModal = Loadable({
  loader: () => import(/* webpackChunkName: "offerdetail" */ 'views/submit-video-modal'),
  loading,
})

const ViewPendingRequest = Loadable({
  loader: () => import(/* webpackChunkName: "offerdetail" */ 'views/view-offer/pending'),
  loading,
})

const WithdrawPendingRequest = Loadable({
  loader: () => import(/* webpackChunkName: "offerdetail" */ 'views/view-offer/withdraw'),
  loading,
})

const ViewPassedRequest = Loadable({
  loader: () => import(/* webpackChunkName: "offerdetail" */ 'views/view-offer/passed'),
  loading,
})

const OfferDetail = () => (
  <>
    <Route path="available/:slug" component={ViewOfferAvailable} />
    <Route path="pending/:id" component={ViewPendingRequest}>
      <Route path="withdraw" component={WithdrawPendingRequest} />
    </Route>
    <Redirect from="claimed/:slug" to="accepted/:slug" />
    {/* the redirect above does not seem to automatically handle the path below: */}
    <Redirect from="claimed/:slug/how-to-deliver" to="accepted/:slug/how-to-deliver" />
    <Route path="accepted/:slug" component={ViewOffer}>
      <IndexRedirect to="how-to-deliver" />
      <Route path="how-to-deliver" component={ViewOfferHowToDeliver} />
      <Route path="description" component={ViewOfferDescription} />
      <Route component={ViewOfferHowToDeliver}>
        <Route path="congrats" component={ViewOfferCongrats} />
        <Route path="submit-video" component={ViewSubmitVideoModal} />
      </Route>
    </Route>
    <Route path="delivered/:slug" component={ViewOffer}>
      <IndexRedirect to="videos" />
      <Route path="description" component={ViewOfferDescription} />
      <Route path="videos" component={ViewOfferVideos} />
      <Route path="how-to-deliver" component={ViewOfferHowToDeliver} />
      <Route path="submit-video" component={ViewSubmitVideoModal} />
    </Route>
    <Route path="passed/:id" component={ViewPassedRequest} />
    <Route path="archived/:slug" component={ViewOffer}>
      <IndexRedirect to="description" />
      <Route path="description" component={ViewOfferDescriptionArchived} />
      <Route path="videos" component={ViewOfferVideos} />
    </Route>
  </>
)

export default OfferDetail
