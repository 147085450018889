import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles.css'

const DIRECTIONS = {
  right: 'container-dir-right',
  left: 'container-dir-left',
}

const FormActions = ({ children, direction }) => (
  <div className={styles[DIRECTIONS[direction]]}>{children}</div>
)

FormActions.propTypes = {
  children: PropTypes.node.isRequired,
  direction: PropTypes.string,
}

FormActions.defaultProps = {
  direction: 'left',
}

export default FormActions
