import defineAction from '../../utils/define-action'
import arrayWrap from '../../utils/array-wrap'
import * as campaignService from '../../services/campaigns'
import { getOfferDetails, hideVideoFromOffer, showVideoFromOffer } from '../../services/offers'
import { getHashCodeFromObject } from '../pagination/selectors'

export const FETCH_CAMPAIGNS_BY_USER = defineAction('FETCH_CAMPAIGNS_BY_USER')
export const GET_CAMPAIGN_DETAILS = defineAction('GET_CAMPAIGN_DETAILS')
export const DELETE_CAMPAIGN = defineAction('DELETE_CAMPAIGN')
export const GET_FEATURED_CAMPAIGNS = defineAction('GET_FEATURED_CAMPAIGNS')
export const EXPORT_CAMPAIGN_CSV = defineAction('EXPORT_CAMPAIGN_CSV')
export const TOGGLE_VIDEO = defineAction('TOGGLE_VIDEO')
export const GET_CAMPAIGN_OFFERS = defineAction('GET_CAMPAIGN_OFFERS')
export const GET_CAMPAIGN_CREATORS = defineAction('GET_CAMPAIGN_CREATORS')
export const FETCH_AVAILABLE_CAMPAIGNS = defineAction('FETCH_AVAILABLE_CAMPAIGNS')
export const GET_CAMPAIGN_OFFER_DETAILS = defineAction('GET_CAMPAIGN_OFFER_DETAILS')
export const FEATURE_CAMPAIGN = defineAction('FEATURE_CAMPAIGN')
export const DEFEATURE_CAMPAIGN = defineAction('DEFEATURE_CAMPAIGN')
export const PAUSE_CAMPAIGN = defineAction('PAUSE_CAMPAIGN')
export const UNPAUSE_CAMPAIGN = defineAction('UNPAUSE_CAMPAIGN')
export const GET_CAMPAIGN_VIDEOS = defineAction('GET_CAMPAIGN_VIDEOS')
export const REMOVE_REMAINING_CAMPAIGN_CODES = defineAction('REMOVE_REMAINING_CAMPAIGN_CODES')
export const DOWNLOAD_REMAINING_CAMPAIGN_CODES = defineAction('DOWNLOAD_REMAINING_CAMPAIGN_CODES')
export const UPDATE_CAMPAIGN_CODE = defineAction('UPDATE_CAMPAIGN_CODE')
export const GET_CAMPAIGN_CODES = defineAction('GET_CAMPAIGN_CODES')
export const REMOVE_CAMPAIGN_CODE = defineAction('REMOVE_CAMPAIGN_CODE')
export const REPLACE_CAMPAIGN_CODE = defineAction('REPLACE_CAMPAIGN_CODE')
export const GET_MATCHING_VIDEOS = defineAction('GET_MATCHING_VIDEOS')
export const DELIVER_FUZZY_MATCH = defineAction('DELIVER_FUZZY_MATCH')
export const DISCOVER_CAMPAIGN_CREATORS = defineAction('DISCOVER_CAMPAIGN_CREATORS')
export const COMMIT_DISCOVER_SELECTIONS = defineAction('COMMIT_DISCOVER_SELECTIONS')
export const STAGE_DISCOVER_SELECTIONS = defineAction('STAGE_DISCOVER_SELECTIONS')

export const updateCode = (campaignId, codeId, code) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_CAMPAIGN_CODE,
    payload: campaignService.updateCode(getState().user.get('auth_token'))(
      campaignId,
      codeId,
      code
    ),
  })

export const downloadRemainingCodes = (campaignId, filename, platform) => (dispatch, getState) =>
  dispatch({
    type: DOWNLOAD_REMAINING_CAMPAIGN_CODES,
    payload: campaignService.downloadRemainingCodes(getState().user.get('auth_token'))(
      campaignId,
      filename,
      platform
    ),
  })
export const removeRemainingCodes = (campaignId, platform) => (dispatch, getState) =>
  dispatch({
    type: REMOVE_REMAINING_CAMPAIGN_CODES,
    payload: campaignService.removeUnclaimedCodes(getState().user.get('auth_token'))(
      campaignId,
      platform
    ),
  })

export const fetchCampaignsByUser = (params) => (dispatch, getState) =>
  dispatch({
    type: FETCH_CAMPAIGNS_BY_USER.ACTION,
    payload: campaignService.getAll(getState().user.get('auth_token'))(params),
    meta: {
      page: params,
    },
  })

export const getCampaignDetails = (slug, params) => (dispatch, getState) =>
  dispatch({
    type: GET_CAMPAIGN_DETAILS.ACTION,
    payload: campaignService.getDetails(getState().user.get('auth_token'))(slug, params),
  })

export const getOfferCampaignDetails = (slug) => (dispatch, getState) =>
  dispatch({
    type: GET_CAMPAIGN_OFFER_DETAILS.ACTION,
    payload: getOfferDetails(getState().user.get('auth_token'))(slug, { status: 'available' }),
  })

export const deleteCampaign = (id) => (dispatch, getState) =>
  dispatch({
    type: DELETE_CAMPAIGN.ACTION,
    payload: campaignService
      .remove(getState().user.get('auth_token'))(id)
      .then(() => String(id)),
  })

export const getPublicCampaigns = (params) => (dispatch, getState) =>
  dispatch({
    type: GET_FEATURED_CAMPAIGNS.ACTION,
    payload: campaignService.getPublicCampaigns(getState().user.get('auth_token'))(params),
    meta: {
      page: params,
    },
  })

export const getPublicCampaign = (slug) => (dispatch, getState) =>
  dispatch({
    type: GET_CAMPAIGN_OFFER_DETAILS.ACTION,
    payload: campaignService.getPublicCampaign(getState().user.get('auth_token'))(slug),
  })

export const AVAILABLE_CAMPAIGNS_DEFAULT_PARAMS = {
  limit: 16,
  offset: 0,
  ordering: 'popularity,latest',
  is_released: true,
}
export const availableCampaigns = (params) => (dispatch, getState) => {
  const newParams = { ...AVAILABLE_CAMPAIGNS_DEFAULT_PARAMS, ...params }
  const state = getState()

  const pageHash = getHashCodeFromObject(newParams)
  // This is a hack to prevent duplicate requests for the same filters
  if (state.campaignOfferCache.get(pageHash)) {
    return Function.prototype
  }

  return dispatch({
    type: FETCH_AVAILABLE_CAMPAIGNS,
    payload: campaignService.availableCampaigns(state.user.get('auth_token'))(newParams),
    meta: {
      page: newParams,
    },
  })
}

export const exportCampaignCSV = (campaignId, filename, params) => (dispatch, getState) =>
  dispatch({
    type: EXPORT_CAMPAIGN_CSV.ACTION,
    payload: campaignService.exportCampaignCSV(getState().user.get('auth_token'))(
      campaignId,
      filename,
      params
    ),
  })

export const exportCampaignShortCSV = (campaignId, filename, params) => (dispatch, getState) =>
  dispatch({
    type: EXPORT_CAMPAIGN_CSV.ACTION,
    payload: campaignService.exportCampaignShortCSV(getState().user.get('auth_token'))(
      campaignId,
      filename,
      params
    ),
  })

export const hideVideo = (offerId, videoId) => (dispatch, getState) =>
  dispatch({
    type: TOGGLE_VIDEO.ACTION,
    payload: hideVideoFromOffer(getState().user.get('auth_token'))(offerId, videoId),
    meta: videoId,
  })

export const showVideo = (offerId, videoId) => (dispatch, getState) =>
  dispatch({
    type: TOGGLE_VIDEO.ACTION,
    payload: showVideoFromOffer(getState().user.get('auth_token'))(offerId, videoId),
    meta: videoId,
  })

export const CAMPAIGN_OFFERS_DEFAULT_PARAMS = { limit: 20, offset: 0 }
export const getCampaignOffers = (id, params) => (dispatch, getState) => {
  const newParams = { ...CAMPAIGN_OFFERS_DEFAULT_PARAMS, ...params }
  return dispatch({
    type: GET_CAMPAIGN_OFFERS,
    payload: campaignService.getOffers(getState().user.get('auth_token'))(id, newParams),
    meta: {
      page: { campaignId: id, ...newParams },
    },
  })
}

export const getCampaignVideos = (id, params) => (dispatch, getState) =>
  dispatch({
    type: GET_CAMPAIGN_VIDEOS,
    payload: campaignService.getVideos(getState().user.get('auth_token'))(id, params),
    meta: {
      page: {
        campaignId: id,
        ...params,
      },
    },
  })

export const featureCampaign = (campaignId) => (dispatch, getState) =>
  dispatch({
    type: FEATURE_CAMPAIGN,
    payload: campaignService.featureCampaign(getState().user.get('auth_token'))(campaignId),
  })

export const pauseCampaign = (campaignId) => (dispatch, getState) =>
  dispatch({
    type: PAUSE_CAMPAIGN,
    meta: { campaignId },
    payload: campaignService.pause(getState().user.get('auth_token'))(campaignId),
  })

export const unpauseCampaign = (campaignId) => (dispatch, getState) =>
  dispatch({
    type: UNPAUSE_CAMPAIGN,
    meta: { campaignId },
    payload: campaignService.unpause(getState().user.get('auth_token'))(campaignId),
  })

export const clearUnpauseError = () => (dispatch) =>
  dispatch({
    type: UNPAUSE_CAMPAIGN.CLEAR,
  })

export const removeCampaignCode = (campaignId, platform, code) => (dispatch, getState) =>
  dispatch({
    type: REMOVE_CAMPAIGN_CODE,
    payload: campaignService.deleteCode(getState().user.get('auth_token'))(
      campaignId,
      platform,
      code
    ),
    meta: { campaignId, code, platform },
  })

export const replaceCampaignCode = (campaignId, codeId) => (dispatch, getState) =>
  dispatch({
    type: REPLACE_CAMPAIGN_CODE,
    payload: campaignService.replaceCode(getState().user.get('auth_token'))(campaignId, codeId),
    meta: { campaignId, codeId },
  })

export const CAMPAIGN_MATCHING_VIDEOS_DEFAULT_PARAMS = { limit: 20, offset: 0 }
export const getMatchingVideos = (campaignId, params) => (dispatch, getState) => {
  const newParams = { ...CAMPAIGN_MATCHING_VIDEOS_DEFAULT_PARAMS, ...params }
  return dispatch({
    type: GET_MATCHING_VIDEOS,
    payload: campaignService.getMatchigVideos(getState().user.get('auth_token'))(
      campaignId,
      newParams
    ),
    meta: {
      page: {
        campaignId,
        ...newParams,
      },
    },
  })
}

export const deliverFuzzyMatch = (campaignId, videoId) => (dispatch, getState) =>
  dispatch({
    type: DELIVER_FUZZY_MATCH,
    payload: campaignService.deliverFuzzyMatch(getState().user.get('auth_token'))(
      campaignId,
      videoId
    ),
    meta: { videoId },
  })

export const discoverCampaignCreators = (campaignId, offset = 0) => (dispatch, getState) =>
  dispatch({
    type: DISCOVER_CAMPAIGN_CREATORS,
    payload: campaignService.discoverCampaignCreators(getState().user.get('auth_token'))(
      campaignId,
      offset
    ),
    meta: {
      page: { campaignId, offset },
    },
  })

export const commitDiscoverSelections = (
  campaignId,
  platforms,
  expirationDays,
  inviteLetter,
  inviteSubject
) => (dispatch, getState) =>
  dispatch({
    type: COMMIT_DISCOVER_SELECTIONS,
    payload: campaignService.commitDiscoverSelections(getState().user.get('auth_token'))(
      campaignId,
      platforms,
      expirationDays,
      inviteLetter,
      inviteSubject
    ),
  })

export const stageDiscoverSelections = (campaignId, selections) => (dispatch, getState) =>
  dispatch({
    type: STAGE_DISCOVER_SELECTIONS,
    payload: campaignService.stageDiscoverSelections(getState().user.get('auth_token'))(
      campaignId,
      Object.fromEntries(arrayWrap(selections))
    ),
    meta: arrayWrap(selections),
  })
