import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Input from 'components/piece-field'
import Button from 'components/button'
import Checkbox from 'components/piece-field-checkbox'
import { ENTER_REACT_KEY_CODE } from 'config/settings'

import styles from './styles.css'

const Reply = ({ id, hasSendToNotes, onSendReply, onCancel }) => {
  const [sendToNotes, setSendToNotes] = useState(false)
  const [replyValue, setReplyValue] = useState(null)

  const handleSendReply = () => {
    if (replyValue) {
      onSendReply({ message: replyValue, sendToNotes })
      setReplyValue(null)
    }
  }

  const handleFieldChange = ({ target: { value } }) => {
    setReplyValue(value)
  }

  const handleKeyPress = ({ key }) => {
    if (key === ENTER_REACT_KEY_CODE) {
      handleSendReply()
    }
  }

  const handleCancel = () => {
    setReplyValue(null)
    onCancel()
  }

  const onSendToNotesChange = () => {
    setSendToNotes(!sendToNotes)
  }

  return (
    // eslint-disable-next-line
    <div className={styles.replyContainer} onClick={(e) => e.stopPropagation()}>
      <Input
        className={styles.replyInput}
        onChange={handleFieldChange}
        onClick={(e) => e.stopPropagation()}
        type="text"
        name="message"
        placeholder="Write a comment for your organization"
        value={replyValue}
        onKeyPress={handleKeyPress}
        autoFocus
      />
      <div
        className={classnames(styles.replyActions, {
          [styles.hasSendToNotes]: hasSendToNotes,
        })}
      >
        {hasSendToNotes && (
          <Checkbox
            name={`sendToNotes-${id}`}
            label="Add to creator page as a note"
            onChange={onSendToNotesChange}
          />
        )}
        <div className={styles.replyActionsButtons}>
          <Button kind="secondaryBlue" onClick={handleCancel} id="cancelCommentBtn">
            Cancel
          </Button>
          <Button kind="primary" onClick={handleSendReply} id="addCommentBtn">
            Comment
          </Button>
        </div>
      </div>
    </div>
  )
}

Reply.propTypes = {
  id: PropTypes.number,
  hasSendToNotes: PropTypes.bool,
  onSendReply: PropTypes.func,
  onCancel: PropTypes.func,
}

Reply.defaultProps = {
  id: null,
  hasSendToNotes: false,
  onSendReply: () => {},
  onCancel: () => {},
}

export default Reply
