import { del, get, post } from './request'

export const getPublisher = (key) => (slug) => get(['public-organizations', slug], { key })
export const getPublishers = (key) => (params) => get('public-organizations', { params, key })

export const getMembers = (key) => () => get('organization-members', { key })
export const removeMember = (key) => (memberId) =>
  post(['organization-members', memberId, 'revoke'], { key })
export const demoteMember = (key) => (memberId) =>
  post(['organization-members', memberId, 'demote'], { key })
export const reinstateMember = (key) => (memberId) =>
  post(['organization-members', memberId, 'reinstate'], { key })
export const promoteMember = (key) => (memberId) =>
  post(['organization-members', memberId, 'promote'], { key })

export const inviteUsers = (key) => (users) => post(['organization-invites'], { key }, users)
export const getInvites = (key) => () => get('organization-invites', { key })
export const resendInvite = (key) => (token) =>
  post(['organization-invites', token, 'resend'], { key })
export const getInvite = (token) => get(['public-organization-invites', token])

export const acceptInvite = (data) => post('publisher-members', {}, data)

export const withdrawInvite = (key) => (token) => del(['organization-invites', token], { key })
