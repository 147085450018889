import defineAction from '../../utils/define-action'
import * as whitelistService from '../../services/whitelist'
import * as streamersService from '../../services/streamers'

export const FETCH_WHITELIST_STREAMERS = defineAction('FETCH_WHITELIST_STREAMERS')
export const REMOVE_WHITELIST_STREAMER = defineAction('REMOVE_WHITELIST_STREAMER')
export const CREATE_WHITELIST_STREAMER = defineAction('CREATE_WHITELIST_STREAMER')

export const SEARCH_STREAMERS = defineAction('SEARCH_STREAMERS')
export const CLEAR_STREAMERS_SEARCH = 'CLEAR_STREAMERS_SEARCH'

export const WHITELIST_STREAMERS_DEFAULT_PARAMS = { limit: 10 }
export const fetchWhitelistStreamers = (whitelistid, params) => (dispatch, getState) => {
  const newParams = { ...WHITELIST_STREAMERS_DEFAULT_PARAMS, ...params }

  return dispatch({
    type: FETCH_WHITELIST_STREAMERS.ACTION,
    payload: whitelistService.fetchCreators(getState().user.get('auth_token'))(
      whitelistid,
      newParams
    ),
    meta: {
      page: newParams,
    },
  })
}

export const removeWhitelistStreamer = (whitelistId, creatorId, removeFromAllWhitelists) => (
  dispatch,
  getState
) =>
  dispatch({
    type: REMOVE_WHITELIST_STREAMER.ACTION,
    payload: whitelistService.removeCreator(getState().user.get('auth_token'))(
      whitelistId,
      creatorId,
      removeFromAllWhitelists
    ),
  })

export const createStreamerWhitelist = (whitelistId, payload) => (dispatch, getState) =>
  dispatch({
    type: CREATE_WHITELIST_STREAMER.ACTION,
    payload: whitelistService.createWhitelistCreator(getState().user.get('auth_token'))(
      whitelistId,
      payload
    ),
  })

export const searchStreamers = (params) => (dispatch, getState) =>
  dispatch({
    type: SEARCH_STREAMERS.ACTION,
    payload: streamersService.search(getState().user.get('auth_token'))(params),
  })

export const clearSearch = () => ({
  type: CLEAR_STREAMERS_SEARCH,
})
