import React, { Component } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons'

import Icon from '../piece-icon'

import styles from './styles.css'

const ENTER_REACT_KEY_CODE = 'Enter'

class InputSearch extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    initialValue: PropTypes.string,
    className: PropTypes.string,
  }

  static defaultProps = {
    className: undefined,
    onChange: undefined,
    initialValue: '',
  }

  static defaultProps = {
    placeholder: undefined,
  }

  state = {
    value: '',
  }

  componentWillMount() {
    if (this.props.initialValue) {
      this.setState({
        value: this.props.initialValue,
        submitted: true,
      })
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.initialValue && !this.props.initialValue) {
      this.handleResetSearch()
    }
  }

  handleChange = (event) => {
    this.setState({
      value: event.target.value,
    })
    if (this.props.onChange) {
      this.props.onChange(event.target.value, this.props.name)
    }
  }

  handleSubmit = () => {
    this.props.onSubmit(this.state.value, this.props.name)
    this.setState({ submitted: !!this.state.value })
  }

  handleKeyPress = ({ key }) => {
    if (key === ENTER_REACT_KEY_CODE) {
      this.handleSubmit()
    }
  }

  handleResetSearch = () => {
    this.props.onSubmit('', this.props.name)
    this.setState({ submitted: false, value: '' })
  }

  handleInputRef = (ref) => {
    this.inputRef = ref
  }

  focus = () => this.inputRef && this.inputRef.focus()

  render() {
    const {
      placeholder,
      name,
      onChange,
      onSubmit,
      initialValue,
      className,
      ...otherProps
    } = this.props
    return (
      <div className={styles.container}>
        <input
          {...otherProps}
          ref={this.handleInputRef}
          onKeyPress={this.handleKeyPress}
          className={classnames(styles.input, className)}
          value={this.state.value}
          onChange={this.handleChange}
          name={name}
          placeholder={placeholder}
        />
        {!this.state.submitted ? (
          <button type="button" onClick={this.handleSubmit} className={styles.searchIcon}>
            <Icon icon={faSearch} />
          </button>
        ) : (
          <button type="button" onClick={this.handleResetSearch} className={styles.searchIcon}>
            <Icon icon={faTimes} />
          </button>
        )}
      </div>
    )
  }
}

export default InputSearch
