const DEFAULT_ELLIPSIS = '...'

const addEllipsis = (item, maxLength, { ellipsis = DEFAULT_ELLIPSIS } = {}) => {
  if (!item) {
    return null
  }
  return item.length > maxLength
    ? item.slice(0, maxLength - ellipsis.length).concat(ellipsis)
    : item
}

export default addEllipsis
