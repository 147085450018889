import axios from 'axios'

import { CHECK_FOR_UPDATES_AFTER_SECONDS, CHECK_FOR_UPDATES_INTERVAL } from '../config/settings'

import compareSemver from './compare-semver'

const MILISECONDS = 1000
const SECONDS_IN_MINUTE = 60

const checkVersion = () => {
  const getVersion = () =>
    axios.get(`${window.location.origin}/version/`).then((response) => response.data)

  const refreshIfOutdated = (version = '') => {
    const difference = compareSemver(version, window.WOOVIT_VERSION)
    if (difference > 0) {
      // fe is outdated, reload:
      window.location.reload()
    }
  }

  const poolForVersion = () => {
    setTimeout(() => {
      getVersion()
        .then((version) => {
          refreshIfOutdated(version)
          poolForVersion()
        })
        .catch(poolForVersion) // silently continue if a request fails
    }, CHECK_FOR_UPDATES_INTERVAL)
  }

  setTimeout(poolForVersion, CHECK_FOR_UPDATES_AFTER_SECONDS)
  console.info(`Checking front-end version in ${CHECK_FOR_UPDATES_AFTER_SECONDS /
    MILISECONDS /
    SECONDS_IN_MINUTE} minute
After initial threshold, checks will happen every ${CHECK_FOR_UPDATES_INTERVAL /
    MILISECONDS /
    SECONDS_IN_MINUTE} minute`)
}

if (process.env.NODE_ENV === 'production') {
  checkVersion()
}

export default checkVersion
