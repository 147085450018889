import { Map, fromJS } from 'immutable'

import createReducer from '../../utils/create-reducer'
import { UPDATE_CAMPAIGN, CREATE_NEW_CAMPAIGN } from '../new-campaign/actions'

import { SET_UI, CLEAR_UI } from './actions'

export const INITIAL_STATE = new Map()

export const uiCampaignWizard = createReducer(INITIAL_STATE, {
  [SET_UI]: (state, { payload }) => state.mergeDeep(fromJS(payload)),
  [CLEAR_UI]: () => INITIAL_STATE,
  [CREATE_NEW_CAMPAIGN.FULFILLED]: (state) => state.set('promptDiscard', false),
  [UPDATE_CAMPAIGN.FULFILLED]: (state) => state.set('promptDiscard', false),
})
