import React, { Component } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.css'

class Container extends Component {
  render() {
    const { className, ...otherProps } = this.props
    return (
      <div className={classnames(styles.container, className)} {...otherProps}>
        {this.props.children}
      </div>
    )
  }
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

Container.defaultProps = {
  className: undefined,
}

export default Container
