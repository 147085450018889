import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import Icon from '../piece-icon'

import styles from './styles.css'

const NextArrow = (
  { currentSlide, slideCount, ...filteredProps } // eslint-disable-line react/prop-types, max-len
) => (
  <button {...filteredProps} className={styles.arrowNext} type="button">
    <Icon icon={faChevronRight} />
  </button>
)

const PrevArrow = (
  { currentSlide, slideCount, ...filteredProps } // eslint-disable-line react/prop-types, max-len
) => (
  <button {...filteredProps} className={styles.arrowPrev} type="button">
    <Icon icon={faChevronLeft} />
  </button>
)

const Carousel = ({ children, ...settings }) => <Slider {...settings}>{children}</Slider>

Carousel.propTypes = {
  dots: PropTypes.bool,
  children: PropTypes.node.isRequired,
  infinite: PropTypes.bool,
  speed: PropTypes.number,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number,
  className: PropTypes.string,
  nextArrow: PropTypes.node,
  prevArrow: PropTypes.node,
  responsive: PropTypes.arrayOf(
    PropTypes.shape({
      breakpoint: PropTypes.number,
      settings: PropTypes.object,
    })
  ),
  lazyLoad: PropTypes.bool,
}

Carousel.defaultProps = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  className: undefined,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: undefined,
  lazyLoad: false,
}

export default Carousel
