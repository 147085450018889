import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { Link } from 'react-router'

import Platforms from '../piece-platform-icon/platforms'
import ShortURL from '../short-url'
import Share from '../share'
import Paid from '../effect-paid'
import Proactive from '../effect-proactive'
import cdnizeAsset from '../../utils/cdnize-asset'
import { renderPaymentInformation } from '../campaign-meta'
import { RANGE } from '../../config/settings'
import UnclaimedCodes from '../unclaimed-codes'
import { format } from '../../utils/datetime'
import { HUMAN_FORMAT_NOTIME } from '../../config/time-settings'

import styles from './styles.css'
import Loader from './loader'

const OfferHead = ({
  image,
  title,
  publisher,
  platforms,
  shortURL,
  children,
  isPaid,
  isProactive,
  onGetTwitterShareURL,
  paymentType,
  paymentLow,
  paymentHigh,
  organizationName,
  organizationSlug,
  alert,
  isLoading,
  unclaimedCodeCount,
  isReleased,
  releaseAt,
}) => {
  if (isLoading) {
    return <Loader />
  }

  return (
    <div className={styles.container}>
      {alert}
      <div className={styles.contentWrapper}>
        <div className={styles.imageWrapper}>
          {isPaid && !isProactive ? (
            <Paid paid>
              <img className={styles.image} src={cdnizeAsset(image)} alt={title} />
            </Paid>
          ) : null}
          {isProactive ? (
            <Proactive proactive>
              <img className={styles.image} src={cdnizeAsset(image)} alt={title} />
            </Proactive>
          ) : null}
          {!isPaid && !isProactive ? (
            <img className={styles.image} src={cdnizeAsset(image)} alt={title} />
          ) : null}
        </div>
        <div className={styles.middleWrapper}>
          <h1 className={styles.title}>
            {title}
            {organizationName && organizationSlug ? (
              <Link to={`/publisher/${organizationSlug}`} className={styles.publisher}>
                {organizationName}
              </Link>
            ) : (
              <small className={styles.publisher}>{publisher}</small>
            )}
            {!isReleased && !releaseAt && <p className={styles.releaseAt}>Coming soon</p>}
            {!isReleased && releaseAt && (
              <p className={styles.releaseAt}>Coming {format(releaseAt, HUMAN_FORMAT_NOTIME)}</p>
            )}
            {unclaimedCodeCount > 0 && (
              <small className={styles.publisher}>
                <UnclaimedCodes codeCount={unclaimedCodeCount} />
              </small>
            )}
          </h1>
          <div className={styles.platforms}>
            {platforms.length ? (
              <Fragment>
                <h3 className={styles.platformsTitle}>Platforms</h3>
                <Platforms discloseAllPlatforms dark width={26} height={26} platforms={platforms} />
              </Fragment>
            ) : (
              <h3 className={styles.platformsTitle}>No codes available</h3>
            )}
          </div>
        </div>
        <div className={styles.rightWrapper}>
          {children}
          {paymentType ? (
            <div className={styles.paymentWrapper}>
              <div className={styles.paymentStyle}>
                {paymentType === RANGE ? 'Approximate payment:' : 'Payment:'}{' '}
                {renderPaymentInformation(paymentType, paymentLow, paymentHigh)}
              </div>
            </div>
          ) : null}
          <div className={styles.share}>
            <ShortURL shortURL={shortURL} />
            <div className={styles.shareWrapper}>
              <Share
                onGetTwitterShareURL={onGetTwitterShareURL}
                gameName={title}
                shortURL={shortURL}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

OfferHead.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  publisher: PropTypes.string,
  platforms: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  shortURL: PropTypes.string,
  children: PropTypes.node,
  isPaid: PropTypes.bool,
  onGetTwitterShareURL: PropTypes.func,
  paymentType: PropTypes.string,
  paymentLow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paymentHigh: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  organizationName: PropTypes.string,
  organizationSlug: PropTypes.string,
  isProactive: PropTypes.bool,
  alert: PropTypes.node,
  isLoading: PropTypes.bool,
  unclaimedCodeCount: PropTypes.number,
  isReleased: PropTypes.bool,
  releaseAt: PropTypes.string,
}

OfferHead.defaultProps = {
  image: undefined,
  title: undefined,
  publisher: undefined,
  platforms: [],
  shortURL: undefined,
  children: undefined,
  isPaid: false,
  isProactive: false,
  onGetTwitterShareURL: undefined,
  paymentType: undefined,
  paymentLow: undefined,
  paymentHigh: undefined,
  organizationName: undefined,
  organizationSlug: undefined,
  alert: undefined,
  isLoading: false,
  unclaimedCodeCount: undefined,
  isReleased: true,
  releaseAt: undefined,
}

export default OfferHead
