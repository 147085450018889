import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles.css'

const Label = (props) => {
  const { label, id } = props

  if (!id) {
    return <span className={styles.label}>{label}</span>
  }

  return (
    // eslint-disable-next-line jsx-a11y/label-has-for
    <label className={styles.label} htmlFor={id}>
      {label}
    </label>
  )
}

Label.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

Label.defaultProps = {
  id: null,
}

export default Label
