import getEnv from '../utils/get-env'

export const CLOUD_NAME = process.env.CLOUD_NAME || 'dj4laqzi0'

export const UPLOAD_PRESET = process.env.UPLOAD_PRESET || 'bjqfakiv'

export const CLOUDINARY_URL = `https://api.cloudinary.com/v1_1/${CLOUD_NAME}/image/upload/`

export const API_URL = getEnv('API_URL', 'https://core.woovit.com/api/v1')

export const NODE_ENV = process.env.NODE_ENV

export const STRIPE_API_KEY = getEnv('STRIPE_API_KEY', 'pk_test_8gvRPgTs94zGlwAg05Pr6fRc')

export const INTERCOM_APP_ID = getEnv('INTERCOM_APP_ID', null)

export const SENTRY_DSN = getEnv('SENTRY_DSN', null)

export const THUMBNAIL_URL = getEnv('THUMBNAIL_URL', 'https://thumbnail.woovit.com')

export const TWITCH_IFRAME_PARENT = getEnv('TWITCH_IFRAME_PARENT', 'woovit.com')

export const GOOGLE_PLACES_API_KEY = getEnv('GOOGLE_PLACES_API_KEY', null)

export const STATICS_URL = getEnv('STATICS_URL', 'https://static.woovit.com/')

export const API_AUTH_TOKEN = getEnv('API_AUTH_TOKEN', null)
