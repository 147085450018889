import { browserHistory } from 'react-router'

const isNullOrUndefined = (value) => value === null || typeof value === 'undefined'

// @TODO: Deprecate/remove this fn
export default (option = {}, name) => {
  const currLocation = browserHistory.getCurrentLocation()
  const value = isNullOrUndefined(option && option.value) ? undefined : option.value
  browserHistory.replace({
    pathname: currLocation.pathname,
    query: {
      ...currLocation.query,
      [name]: value,
    },
  })
}

export const newHandleQuery = (query = {}) => {
  const currLocation = browserHistory.getCurrentLocation()
  const newQuery = {
    ...currLocation.query,
    ...query,
  }
  browserHistory.replace({
    pathname: currLocation.pathname,
    query: newQuery,
  })

  return newQuery
}
