import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles.css'

const InfoLabel = ({ className, text }) => (
  <span className={classnames(className, styles.infoLabel)}>{text}</span>
)

InfoLabel.propTypes = {
  className: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

InfoLabel.defaultProps = {
  className: undefined,
}

export default InfoLabel
