import axios from 'axios'

import { captureException } from '../utils/exception'
import { API_URL } from '../config/environment'
import { parseURL, parseConfig } from '../utils/request'
import { HTTP_500_INTERNAL_SERVER_ERROR, HTTP_503_SERVICE_UNAVAILABLE } from '../utils/get-status'

export const NODE_REQUEST_TIMEOUT = 3000 // 3s

export class NetworkError extends Error {
  // Necessary due https://github.com/babel/babel/issues/4485
  constructor(message) {
    super()
    this.constructor = NetworkError
    this.__proto__ = NetworkError.prototype // eslint-disable-line no-proto
    this.message = message
  }
}
export class InternalServerError extends Error {
  // Necessary due https://github.com/babel/babel/issues/4485
  constructor(message) {
    super()
    this.constructor = InternalServerError
    this.__proto__ = InternalServerError.prototype // eslint-disable-line no-proto
    this.message = message
  }
}

export class ServiceUnavailable extends Error {
  // Necessary due https://github.com/babel/babel/issues/4485
  constructor(message) {
    super()
    this.constructor = ServiceUnavailable
    this.__proto__ = ServiceUnavailable.prototype // eslint-disable-line no-proto
    this.message = message
  }
}

const instance = axios.create({
  baseURL: API_URL,
  // set timeout just for node (SSR):
  timeout: typeof window === 'undefined' ? NODE_REQUEST_TIMEOUT : undefined,
  headers: { 'Content-type': 'application/json; charset=UTF-8' },
})

export const INTERNAL_SERVER_ERROR = 'Internal Server Error'

const returnData = (response) => response.data
export const handleResponseError = (error) => {
  if (!error.response) {
    throw new NetworkError(error.message)
  }
  if (error.response.status === HTTP_503_SERVICE_UNAVAILABLE) {
    captureException(error, error.response.config)
    throw new ServiceUnavailable('Service Unavailable')
  }
  if (error.response.status === HTTP_500_INTERNAL_SERVER_ERROR) {
    captureException(error, error.response.config)
    throw new InternalServerError('Internal Server Error')
  }
  // This is a really bad leaky abstraction
  // access to actual response gets buried here
  // because only the response body gets forwarded
  return Promise.reject(error.response.data)
}

const parseParams = (url, config, data) => (fn) => {
  if (fn === instance.get) {
    return fn(parseURL(url), parseConfig(config))
      .then(returnData)
      .catch(handleResponseError)
  }
  if (fn === instance.delete) {
    return instance({
      method: 'delete',
      url: parseURL(url),
      data,
      ...parseConfig(config),
    })
      .then(returnData)
      .catch(handleResponseError)
  }
  return fn(parseURL(url), data, parseConfig(config))
    .then(returnData)
    .catch(handleResponseError)
}

export const post = (...params) => parseParams(...params)(instance.post)
export const patch = (...params) => parseParams(...params)(instance.patch)
export const put = (...params) => parseParams(...params)(instance.put)
export const upload = (...params) => parseParams(...params)(instance.post)
export const del = (...params) => parseParams(...params)(instance.delete)
export const get = (...params) => parseParams(...params)(instance.get)

export const getURL = (url) => API_URL + parseURL(url)

export default instance
