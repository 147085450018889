const downloadBlob = (data, filename, type = 'text/csv;charset=utf-8;') => {
  const csvData = new Blob([data], { type })
  const csvURL = window.URL.createObjectURL(csvData)
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = csvURL
  a.setAttribute('download', filename)
  document.body.append(a)
  a.click()
  a.remove()
}

export default downloadBlob

export const download = (blob, filename) => {
  const csvURL = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = csvURL
  a.setAttribute('download', filename)
  document.body.append(a)
  a.click()
  a.remove()
}

export const downloadString = (data, filename) => {
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = data
  a.setAttribute('download', filename)
  document.body.append(a)
  a.click()
  a.remove()
}
