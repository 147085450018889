const analytics = () => (next) => (action) => {
  const { type, payload } = action
  try {
    if (type === '@@router/LOCATION_CHANGE') {
      window.gtag('set', 'page_path', window.location.pathname)
      if (payload.action === 'PUSH') {
        window.gtag('event', 'page_view')
      }
      if (payload.action === 'REPLACE') {
        window.gtag('event', 'replace', { value: payload.search })
      }
    }
  } catch (e) {
    console.info('window.gtag not available')
  }

  return next(action)
}

export default analytics
