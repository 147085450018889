import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import ImmutableProptypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { browserHistory, Link } from 'react-router'

import CountUp from '../../components/count-up'
import InputSearch from '../../components/piece-input-search'
import { getPublicVideos } from '../../modules/video/actions'
import { getUsersStats, GET_USERS_STATS } from '../../modules/stats/actions'
import { stats as statsReducer } from '../../modules/stats/reducers'
import { KIND } from '../../config/user-settings'
import * as statsService from '../../services/stats'

import styles from './styles.css'

const WOOVIT_BASE_CREATOR_COUNT = 8900

const testImage = (URL) => {
  const tester = new Image()
  return new Promise((resolve, reject) => {
    tester.onerror = reject.bind(null, URL)
    tester.onload = resolve.bind(null, URL)
    tester.src = URL
  })
}

const skipNotFound = (url) => (url || '').includes('404_processing')
const getThumbnail = (v) => v.get('thumbnail_url')
const MAX_THUMBNAILS_TO_DISPLAY = 32
const MAX_THUMBNAILS_TO_LOAD = 38
const mapStateToProps = ({ stats, featuredVideos, user }) => ({
  isPublisher: user.getIn(['profile', 'kind']) === KIND.PUBLISHER,
  stats: stats.get('users', new Map()),
  thumbnails: featuredVideos
    .map(getThumbnail)
    .filter(Boolean)
    .filterNot(skipNotFound)
    .slice(0, MAX_THUMBNAILS_TO_LOAD),
  isFreePlan: user.getIn(['profile', 'subscription', 'plans'], []).includes('free'),
})

const mapDispatchToProps = {
  getPublicVideos,
  getUsersStats,
}

class SearchPage extends Component {
  static propTypes = {
    stats: ImmutableProptypes.map.isRequired,
    getPublicVideos: PropTypes.func.isRequired,
    thumbnails: ImmutableProptypes.list.isRequired,
    getUsersStats: PropTypes.func.isRequired,
    isPublisher: PropTypes.bool.isRequired,
    isFreePlan: PropTypes.bool.isRequired,
  }

  static getData() {
    return [
      statsService
        .getUsersStats()()
        .then((payload) => ({
          stats: statsReducer(undefined, {
            type: GET_USERS_STATS.FULFILLED,
            payload,
          }),
        })),
    ]
  }

  state = {
    goodThumbnails: [],
  }

  componentDidMount() {
    this.props.getUsersStats()
    this.props.getPublicVideos()
    if (this.refInput) {
      this.refInput.focus()
    }
    const { thumbnails } = this.props
    if (thumbnails.size) {
      this.buildThumbs(thumbnails)
    }
  }

  componentDidUpdate(oldProps) {
    const { thumbnails } = this.props
    if (thumbnails.size !== oldProps.thumbnails.size) {
      this.buildThumbs(thumbnails)
    }
  }

  buildThumbs = (thumbnails) => {
    thumbnails.map(testImage).forEach((promise) => {
      promise.then(this.appendThumbnail).catch(Function.prototype)
    })
  }

  appendThumbnail = (thumbnailURL) => {
    if (this.state.goodThumbnails.length >= MAX_THUMBNAILS_TO_DISPLAY) {
      return // got enough good thumbs
    }
    this.setState(({ goodThumbnails }) => ({
      goodThumbnails: [...goodThumbnails, thumbnailURL],
    }))
  }

  handleSearch = (search) => {
    browserHistory.push(`/search/${encodeURIComponent(search)}`)
  }

  setInputRef = (ref) => {
    if (!this.refInput) {
      ref.focus()
    }
    this.refInput = ref
  }

  renderThumbnail = (thumbnailURL) => (
    <div
      key={thumbnailURL}
      className={styles.thumbnail}
      style={{ backgroundImage: `url(${thumbnailURL})` }}
    />
  )

  render() {
    const { stats, isFreePlan, isPublisher } = this.props
    const { goodThumbnails } = this.state
    return (
      <div className={styles.landingContainer}>
        <div className={styles.background}>
          {goodThumbnails.slice(0, MAX_THUMBNAILS_TO_DISPLAY).map(this.renderThumbnail)}
        </div>
        <div className={styles.content}>
          <h1 className={styles.searchH1}>
            Freely search the{' '}
            <CountUp to={Number(stats.get('creators', WOOVIT_BASE_CREATOR_COUNT))} />+ creators on
            Woovit
          </h1>
          <div className={styles.searchWrapper}>
            <InputSearch
              ref={this.setInputRef}
              onSubmit={this.handleSearch}
              name="search"
              placeholder="Enter a creator or a game name..."
            />
            {isPublisher && isFreePlan ? (
              <p className={styles.upgradeToSend}>
                Upgrade to our{' '}
                <Link to="/upgrade" className={styles.upgradeToSend}>
                  Starter or Premium plans
                </Link>{' '}
                to send offers to creators on Woovit.
              </p>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchPage)
