const isOffline = (actionType) => actionType.toString().includes('_OFFLINE')

const isResponse = (actionType) =>
  actionType.toString().includes('_FULFILLED') || actionType.toString().includes('_REJECTED')

export const offline = (state = false, action) => {
  if (isOffline(action.type)) {
    return true
  }

  if (isResponse(action.type)) {
    return false
  }
  return state
}
