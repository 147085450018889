import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Checkbox extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool,
    onChangeWith: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  }

  static defaultProps = {
    onChangeWith: undefined,
    checked: false,
  }

  handleChange = (event) => {
    const checked = event.target.checked
    this.props.onChange(checked, this.props.onChangeWith, event)
  }

  render() {
    const { onChange, checked, onChangeWith, ...otherProps } = this.props
    return <input {...otherProps} checked={checked} type="checkbox" onChange={this.handleChange} />
  }
}

export default Checkbox
