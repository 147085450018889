import { Map } from 'immutable'
import { createSelector } from 'reselect'

import getUserName from '../../utils/get-user-name'

const getPublishers = (state) => state.publisher
const getSlug = (state, slug) => slug
export const getPublisherBySlug = createSelector(
  getPublishers,
  getSlug,
  (publishers = new Map(), slug = '') =>
    publishers.find((p) => p.get('slug', '').toLowerCase() === (slug || '').toLowerCase())
)

const fullName = (publisher) => publisher.fullName
const asc = (a = '', b = '') => a.localeCompare(b)
const getFullNameAndId = (publisher) => ({
  id: publisher.getIn(['user', 'id']),
  fullName: getUserName(
    publisher.getIn(['user', 'first_name']),
    publisher.getIn(['user', 'last_name'])
  ),
})
const getPublishersData = (publishers) => publishers
export const selectPublisherByName = createSelector(
  getPublishersData,
  (publishers = new Map()) =>
    publishers
      .toList()
      .map(getFullNameAndId)
      .sortBy(fullName, asc)
)
