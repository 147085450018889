import { Component } from 'react'
import PropTypes from 'prop-types'

const STEP_INCREMENTS = 121

class CountUp extends Component {
  static propTypes = {
    to: PropTypes.number.isRequired,
  }

  state = {
    i: 0,
  }

  componentDidMount() {
    window.requestAnimationFrame(this.countUp)
  }

  componentWillUnmount() {
    this.dropRaf = true
  }

  step = 0

  countUpOneByOne = () => {
    if (this.state.i < this.props.to && !this.dropRaf) {
      this.setState(({ i }) => ({ i: i + 1 }))
      window.requestAnimationFrame(this.countUpOneByOne)
    }
  }

  countUp = () => {
    if (this.state.i < this.props.to && !this.dropRaf) {
      // decrease increments when approaching `to`
      if (this.state.i + 100 > this.props.to) {
        this.countUpOneByOne()
        return
      }
      this.setState(({ i }) => ({ i: i + this.step + STEP_INCREMENTS }))
      this.step = this.step + 1
      window.requestAnimationFrame(this.countUp)
    }
  }

  render() {
    return this.state.i.toLocaleString()
  }
}

export default CountUp
