import PropTypes from 'prop-types'
import classnames from 'classnames'
import React from 'react'

import Svg from '../svg'
import discountArrow from '../../img/discount-indicator.svg'

import styles from './styles.css'

const Plan = ({
  children,
  title,
  activeCampaignsAmount,
  hideActiveCampaignsLabel,
  features,
  price,
  priceLabel,
  className,
  discount,
}) => (
  <div className={classnames(className, styles.plan)}>
    {discount ? (
      <div className={styles.discount}>
        <div className={styles.save}>save {discount}%</div>
        <Svg className={styles.arrow} width="40" height="25" svg={discountArrow} />
      </div>
    ) : null}

    <div className={styles.planHead}>
      <div className={styles.planHeadBackground}>
        <h2 className={styles.planName}>{title}</h2>
      </div>
      <div className={styles.planHeadEffect} />
    </div>
    <div className={styles.planBody}>
      <div className={styles.planActiveCampaigns}>
        <p className={styles.planActiveCampaignsAmount}>{activeCampaignsAmount}</p>
        {!hideActiveCampaignsLabel ? (
          <p className={styles.planActiveCampaignsLabel}>
            active campaign{activeCampaignsAmount === '1' ? '' : 's'}
          </p>
        ) : null}
      </div>
      <div className={styles.planFeatures}>
        {features.map((feature, index) => (
          // Because features is static we don't care if the key is the array index
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className={styles.planFeature}>
            {feature}
          </div>
        ))}
      </div>
      <div className={styles.price}>
        <span className={styles.priceDollar}>$</span>
        <span className={styles.priceAmount}>{price}</span>
        <span className={styles.priceLabel}>{priceLabel}</span>
      </div>
      {children}
    </div>
  </div>
)

Plan.propTypes = {
  discount: PropTypes.number,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  priceLabel: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  activeCampaignsAmount: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.node).isRequired,
  className: PropTypes.string,
  hideActiveCampaignsLabel: PropTypes.bool,
}

Plan.defaultProps = {
  className: undefined,
  discount: undefined,
  hideActiveCampaignsLabel: false,
}

export default Plan
