import {
  faWrench,
  faTicketAlt,
  faLongArrowAltRight,
  faSyncAlt,
  faListUl,
  faVideo,
  faUserAlt,
  faStickyNote,
  faExclamation,
  faTimes,
  faCheck,
  faPause,
  faPlus,
  faPlay,
  faEnvelope,
  faAngleUp,
  faAngleDown,
  faRocket,
  faBan,
} from '@fortawesome/free-solid-svg-icons'
import { faTwitch, faYoutube } from '@fortawesome/free-brands-svg-icons'

import { PLATFORM_YOUTUBE, PLATFORM_TWITCH } from './settings'

export const CAMPAIGN_PUBLISHED = 0
export const CAMPAIGN_PAUSED = 1
export const CAMPAIGN_RESUMED = 2
export const CODE_ADDED = 3
export const CODE_CLAIMED = 4
export const CODE_OFFERED = 5
export const CODE_REQUESTED = 6
export const CODE_DEPLETED = 7
export const NEW_DELIVERY = 8
export const OFFER_INVITE_ACCEPTED = 9
export const OFFER_INVITE_REJECTED = 10
export const REQUEST_APPROVED = 11
export const REQUEST_REJECTED = 12
export const WHITELIST_CREATED = 13
export const WHITELIST_CREATOR_INVITED = 14
export const WHITELIST_INVITE_ACCEPTED = 15
export const WHITELIST_CREATOR_ADDED = 16
export const WHITELIST_CREATOR_REMOVED = 17
export const CREATOR_NOTE_ADDED = 18
export const ORGANIZATION_MEMBER_INVITED = 19
export const ORGANIZATION_MEMBER_PROMOTED = 20
export const ORGANIZATION_MEMBER_DEMOTED = 21
export const ORGANIZATION_MEMBER_REMOVED = 22
export const ORGANIZATION_MEMBER_REINSTATED = 23
export const ORGANIZATION_MEMBER_INVITE_ACCEPTED = 24
export const BLOCKLIST_CREATOR_ADDED = 25
export const BLOCKLIST_CREATOR_REMOVED = 26
export const CODELESS_OFFERED = 27

const iconStyles = {
  negativeFour: { backgroundColor: '#C52B2B', padding: '4px' },
  negativeFive: { backgroundColor: '#C52B2B', padding: '5px' },
  neutralFive: { backgroundColor: '#0074BB', padding: '5px' },
  neutralSix: { backgroundColor: '#0074BB', padding: '6px' },
  positiveFive: { backgroundColor: '#04DA2F', padding: '5px' },
}

export const FEED_ICON_SET = {
  [CAMPAIGN_PUBLISHED]: {
    primary: { icon: faWrench },
    secondary: { icon: faRocket, style: iconStyles.neutralFive },
  },
  [CAMPAIGN_PAUSED]: {
    primary: { icon: faWrench },
    secondary: { icon: faPause, style: iconStyles.neutralSix },
  },
  [CAMPAIGN_RESUMED]: {
    primary: { icon: faWrench },
    secondary: { icon: faPlay, style: iconStyles.neutralSix },
  },
  [CODE_ADDED]: {
    primary: { icon: faWrench },
    secondary: { icon: faPlus, style: iconStyles.positiveFive },
  },
  [CODE_CLAIMED]: { primary: { icon: faTicketAlt }, secondary: null },
  [CODE_OFFERED]: { primary: { icon: faLongArrowAltRight }, secondary: null },
  [CODELESS_OFFERED]: { primary: { icon: faLongArrowAltRight }, secondary: null },
  [CODE_REQUESTED]: { primary: { icon: faSyncAlt }, secondary: null },
  [CODE_DEPLETED]: {
    primary: { icon: faWrench },
    secondary: { icon: faExclamation, style: iconStyles.negativeFive },
  },
  [NEW_DELIVERY]: {
    primary: { icon: faVideo },
    secondary: {
      [PLATFORM_TWITCH]: {
        icon: faTwitch,
        style: { padding: '4px 5px', backgroundColor: '#9146FF' },
      },
      [PLATFORM_YOUTUBE]: { icon: faYoutube, style: { color: '#F00' } },
    },
  },
  [OFFER_INVITE_ACCEPTED]: {
    primary: { icon: faLongArrowAltRight },
    secondary: { icon: faCheck, style: iconStyles.positiveFive },
  },
  [OFFER_INVITE_REJECTED]: {
    primary: { icon: faLongArrowAltRight },
    secondary: { icon: faTimes, style: iconStyles.negativeFour },
  },
  [REQUEST_APPROVED]: {
    primary: { icon: faSyncAlt },
    secondary: { icon: faCheck, style: iconStyles.positiveFive },
  },
  [REQUEST_REJECTED]: {
    primary: { icon: faSyncAlt },
    secondary: { icon: faTimes, style: iconStyles.negativeFour },
  },
  [WHITELIST_CREATED]: { primary: { icon: faListUl }, secondary: null },
  [WHITELIST_CREATOR_INVITED]: {
    primary: { icon: faListUl },
    secondary: { icon: faEnvelope, style: iconStyles.neutralFive },
  },
  [WHITELIST_INVITE_ACCEPTED]: {
    primary: { icon: faListUl },
    secondary: { icon: faPlus, style: iconStyles.positiveFive },
  },
  [WHITELIST_CREATOR_ADDED]: {
    primary: { icon: faListUl },
    secondary: { icon: faPlus, style: iconStyles.positiveFive },
  },
  [WHITELIST_CREATOR_REMOVED]: {
    primary: { icon: faListUl },
    secondary: { icon: faTimes, style: iconStyles.negativeFour },
  },
  [CREATOR_NOTE_ADDED]: { primary: { icon: faStickyNote }, secondary: null },
  [ORGANIZATION_MEMBER_INVITED]: {
    primary: { icon: faUserAlt },
    secondary: { icon: faEnvelope, style: iconStyles.neutralFive },
  },
  [ORGANIZATION_MEMBER_PROMOTED]: {
    primary: { icon: faUserAlt },
    secondary: { icon: faAngleUp, style: iconStyles.neutralFive },
  },
  [ORGANIZATION_MEMBER_DEMOTED]: {
    primary: { icon: faUserAlt },
    secondary: { icon: faAngleDown, style: iconStyles.neutralFive },
  },
  [ORGANIZATION_MEMBER_REMOVED]: {
    primary: { icon: faUserAlt },
    secondary: { icon: faTimes, style: iconStyles.negativeFour },
  },
  [ORGANIZATION_MEMBER_REINSTATED]: { primary: { icon: faUserAlt }, secondary: null },
  [ORGANIZATION_MEMBER_INVITE_ACCEPTED]: {
    primary: { icon: faUserAlt },
    secondary: { icon: faPlus, style: iconStyles.positiveFive },
  },
  [BLOCKLIST_CREATOR_ADDED]: {
    primary: { icon: faBan },
    secondary: { icon: faPlus, style: iconStyles.positiveFive },
  },
  [BLOCKLIST_CREATOR_REMOVED]: {
    primary: { icon: faBan },
    secondary: { icon: faTimes, style: iconStyles.negativeFour },
  },
}

export const CAMPAIGN_SETTINGS_EVENTS_ID = '0'
export const CLAIMED_CODES_EVENTS_ID = '1'
export const CODE_REQUESTS_EVENTS_ID = '2'
export const PROACTIVE_OFFERS_EVENTS_ID = '3'
export const DELIVER_EVENTS_ID = '4'
export const WHITELIST_EVENTS_ID = '5'
export const ORGANIZATION_MANAGEMENT = '6'

export const CAMPAIGN_ACTIVITIES_FILTER_OPTIONS = [
  { value: CAMPAIGN_SETTINGS_EVENTS_ID, label: 'Campaign setup' },
  { value: CLAIMED_CODES_EVENTS_ID, label: 'Claimed codes' },
  { value: CODE_REQUESTS_EVENTS_ID, label: 'Code requests' },
  { value: PROACTIVE_OFFERS_EVENTS_ID, label: 'Proactive offers' },
  { value: DELIVER_EVENTS_ID, label: 'Video deliveries' },
]

export const ORGANIZATION_ACTIVITIES_FILTER_OPTIONS = [
  ...CAMPAIGN_ACTIVITIES_FILTER_OPTIONS,
  { value: ORGANIZATION_MANAGEMENT, label: 'Team management' },
  { value: WHITELIST_EVENTS_ID, label: 'Whitelists' },
]
