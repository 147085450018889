import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutableProptypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { connect } from 'react-redux'

import Select from 'components/piece-field-select'
import { selectPublisherByName } from 'modules/publisher/selectors'
import { getMembers as getMembersAction, GET_ORGANIZATION_MEMBERS } from 'modules/publisher/actions'

import styles from './styles.css'

const mapStateToProps = ({ organizationMembers, loading }) => ({
  organizationMembers,
  isLoadingOrgMembers: !!loading.get(GET_ORGANIZATION_MEMBERS.ACTION),
})

const mapDispatchToProps = {
  getMembers: getMembersAction,
}

const renderTeamMemberValue = (value) => `Team Member: ${value.label}`

const OrgMemberSelect = ({
  value,
  onSelectChange,
  getMembers,
  organizationMembers,
  isLoadingOrgMembers,
}) => {
  useEffect(() => {
    getMembers()
  }, [])

  const getMembersSelectOption = (members) => {
    return selectPublisherByName(members)
      .map((member) => ({
        value: member.id.toString(),
        label: member.fullName,
      }))
      .toArray()
  }

  return (
    <Select
      className={styles.select}
      searchable={false}
      options={[{ label: 'All' }, ...getMembersSelectOption(organizationMembers)]}
      valueRenderer={renderTeamMemberValue}
      name="publisher"
      value={value}
      onChange={onSelectChange}
      isLoading={isLoadingOrgMembers}
    />
  )
}

OrgMemberSelect.propTypes = {
  value: PropTypes.string,
  onSelectChange: PropTypes.func,
  getMembers: PropTypes.func.isRequired,
  organizationMembers: ImmutableProptypes.map,
  isLoadingOrgMembers: PropTypes.bool,
}

OrgMemberSelect.defaultProps = {
  value: undefined,
  onSelectChange: () => {},
  organizationMembers: new Map(),
  isLoadingOrgMembers: false,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgMemberSelect)
