import defineAction from '../../utils/define-action'
import * as creatorService from '../../services/creator'

export const GET_CREATOR_DETAILS = defineAction('GET_CREATOR_DETAILS')
export const SEARCH_CREATORS = defineAction('SEARCH_CREATORS')
export const GET_CREATORS = defineAction('GET_CREATORS')
export const GET_CREATOR_NOTES = defineAction('GET_CREATOR_NOTES')
export const DELETE_CREATOR_NOTE = defineAction('DELETE_CREATOR_NOTE')
export const CREATE_NOTE = defineAction('CREATE_NOTE')
export const SELECT_CREATOR = defineAction('SELECT_CREATOR')
export const CLEAR_SELECTED_CREATORS = defineAction('CLEAR_SELECTED_CREATORS')
export const GET_CREATOR_PLAYED_GAMES = defineAction('GET_CREATOR_PLAYED_GAMES')
export const GET_CREATOR_STEAM_PLAYED_GAMES = defineAction('GET_CREATOR_STEAM_PLAYED_GAMES')
export const GET_CREATOR_DELIVERY_STATS = defineAction('GET_CREATOR_DELIVERY_STATS')
export const GET_CREATOR_DELIVERY_DELAY = defineAction('GET_CREATOR_DELIVERY_DELAY')
export const GET_SIMILAR_CREATORS = defineAction('GET_SIMILAR_CREATORS')

export const getCreatorDeliveryDelay = (creatorId) => (dispatch, getState) =>
  dispatch({
    type: GET_CREATOR_DELIVERY_DELAY,
    payload: creatorService.getDeliveryDelay(getState().user.get('auth_token'))(creatorId),
    meta: { creatorId },
  })

export const getCreatorDeliveryStats = (creatorId) => (dispatch, getState) =>
  dispatch({
    type: GET_CREATOR_DELIVERY_STATS,
    payload: creatorService.getDeliveryStats(getState().user.get('auth_token'))(creatorId),
    meta: { creatorId },
  })

export const getCreatorPlayedGames = (creatorId) => (dispatch, getState) =>
  dispatch({
    type: GET_CREATOR_PLAYED_GAMES,
    payload: creatorService.getPlayedGames(getState().user.get('auth_token'))(creatorId),
    meta: { creatorId },
  })

export const getCreatorSteamPlayedGames = (creatorId) => (dispatch, getState) =>
  dispatch({
    type: GET_CREATOR_STEAM_PLAYED_GAMES,
    payload: creatorService.getSteamPlayedGames(getState().user.get('auth_token'))(creatorId),
    meta: { creatorId },
  })

export const getCreatorDetails = (creatorSlug) => (dispatch, getState) =>
  dispatch({
    type: GET_CREATOR_DETAILS,
    payload: creatorService.getBySlug(getState().user.get('auth_token'))(creatorSlug),
  })

export const searchCreators = (search, params) => (dispatch, getState) =>
  dispatch({
    type: SEARCH_CREATORS,
    payload: creatorService.search(getState().user.get('auth_token'))(search, params),
    meta: {
      page: { search, ...params },
    },
  })

export const LIST_DEFAULT_PARAMS = { limit: 100, offset: 0 }
export const getCreators = (params = {}) => (dispatch, getState) => {
  const newParams = { ...LIST_DEFAULT_PARAMS, ...params }
  return dispatch({
    type: GET_CREATORS,
    payload: creatorService.list(getState().user.get('auth_token'))(newParams),
    meta: {
      page: newParams,
    },
  })
}

export const getNotes = (creatorId) => (dispatch, getState) =>
  dispatch({
    type: GET_CREATOR_NOTES,
    payload: creatorService.getNotes(getState().user.get('auth_token'))(creatorId),
    meta: {
      page: { creatorId },
    },
  })

export const deleteNote = (noteId) => (dispatch, getState) =>
  dispatch({
    type: DELETE_CREATOR_NOTE,
    payload: creatorService.deleteNote(getState().user.get('auth_token'))(noteId),
    meta: { noteId },
  })

export const createNote = (creatorId, note, createFeedEvent) => (dispatch, getState) =>
  dispatch({
    type: CREATE_NOTE,
    payload: creatorService.createNote(getState().user.get('auth_token'))(
      creatorId,
      note,
      createFeedEvent
    ),
  })

export const selectCreator = (profileId) => (dispatch) =>
  dispatch({
    type: SELECT_CREATOR.ACTION,
    payload: profileId,
  })

export const clearSelectedCreators = () => (dispatch) =>
  dispatch({ type: CLEAR_SELECTED_CREATORS.ACTION })

export const getSimilarCreators = (creatorId) => (dispatch, getState) =>
  dispatch({
    type: GET_SIMILAR_CREATORS,
    payload: creatorService.getSimilarCreators(getState().user.get('auth_token'))(creatorId),
    meta: { creatorId: String(creatorId) },
  })
