import { fromJS, Set } from 'immutable'

import isImmutable from './is-immutable'

const keyIn = (...keys) => {
  const keySet = new Set(keys)
  return (v, k) => keySet.has(k)
}

const pickObj = (o, keys = [], toJS = true) => {
  const picked = o.filter(keyIn(...keys))
  if (toJS) {
    return picked.toJS()
  }
  return picked
}

const pick = (o, keys, toJS) => {
  if (o && typeof o === 'object' && !Array.isArray(o) && Array.isArray(keys)) {
    const obj = isImmutable(o) ? o : fromJS(o)
    return pickObj(obj, keys, toJS)
  }
  return {}
}

export default pick
