import isPromise from '../utils/is-promise'
import getActionName from '../utils/get-action-name'
import { SHOW_ALERT_LAYER } from '../modules/ui-alert/actions'
import { NetworkError, InternalServerError, ServiceUnavailable } from '../services/request'

const INTERNAL_SERVER_ERROR_MESSAGE =
  'It seems we are having trouble processing this request. Please try again. If the issue persists get in touch with us!'

const SERVICE_UNAVAILABLE_ERROR_MESSAGE =
  'It looks like our API is unreachable. Please try again in a few minutes.'

const errorMiddleware = () => (next) => (action) => {
  const payload = action.payload
  if (payload instanceof NetworkError) {
    return next({
      type: `${getActionName(action.type)}_OFFLINE`,
      payload,
    })
  }
  if (payload instanceof ServiceUnavailable) {
    return next({
      type: SHOW_ALERT_LAYER,
      payload: {
        message: SERVICE_UNAVAILABLE_ERROR_MESSAGE,
        delay: 22000,
      },
    })
  }
  if (payload instanceof InternalServerError) {
    return next({
      type: SHOW_ALERT_LAYER,
      payload: {
        message: INTERNAL_SERVER_ERROR_MESSAGE,
        delay: 12000,
      },
    })
  }

  const result = next(action)

  if (!isPromise(result)) {
    return action
  }

  return result.catch(() => {})
}

export default errorMiddleware
