import React from 'react'
import PropTypes from 'prop-types'

import copyToClipboard from '../../utils/copy-to-clipboard'
import stripHTTP from '../../utils/strip-http'

import styles from './styles.css'

const ShortURL = ({ shortURL }) => {
  if (!shortURL) {
    return null
  }

  const handleCopy = () => {
    copyToClipboard(shortURL)
  }

  return (
    <div className={styles.shortURL}>
      <a href={shortURL} className={styles.shortURLLink} target="_blank" rel="noopener noreferrer">
        {stripHTTP(shortURL)}
      </a>
      <button type="button" onClick={handleCopy} className={styles.shortURLCopy}>
        copy
      </button>
    </div>
  )
}

ShortURL.propTypes = {
  shortURL: PropTypes.string,
}

ShortURL.defaultProps = {
  shortURL: undefined,
}

export default ShortURL
