const isPositiveInteger = (x) => /^\d+$/.test(x)

const validateParts = (parts) => {
  for (let i = 0; i < parts.length; i += 1) {
    if (!isPositiveInteger(parts[i])) {
      return false
    }
  }
  return true
}

const splitVersion = (version) => version.split('.')

const compareVersionNumbers = (v1parts, v2parts) => {
  if (!validateParts(v1parts) || !validateParts(v2parts)) {
    return NaN
  }

  for (let i = 0; i < v1parts.length; i += 1) {
    if (v2parts.length === i) {
      return 1
    }

    if (v1parts[i] === v2parts[i]) {
      continue // eslint-disable-line no-continue
    }

    if (v1parts[i] > v2parts[i]) {
      return 1
    }

    return -1
  }

  if (v1parts.length !== v2parts.length) {
    return -1
  }

  return 0
}

const compare = (v1 = '', v2 = '') => compareVersionNumbers(splitVersion(v1), splitVersion(v2))

export default compare
