import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import ReactTooltip from 'react-tooltip'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import Legend from 'components/legend'
import If from 'components/if'
import Platforms from 'components/piece-platform-icon/platforms'
import { formatHuman } from 'utils/datetime'
import { FLAT_FEE, RANGE, NEGOTIABLE } from 'config/settings'
import Icon from 'components/piece-icon'

import styles from './styles.css'

const exactGameNamesTooltip = `The exact game name(s) are needed for
  tracking purposes. If you wish to track a specific DLC or expansion, its
  whole name must be added. You may want to consider adding translations of
  your game name, or if you are requiring a specific title.`

const renderAlternativeName = (n) => (
  <span key={n} className={styles.alternativeName}>
    {n}
  </span>
)

export const renderPaymentInformation = (paymentType, paymentLow, paymentHigh) => {
  if (paymentType === FLAT_FEE) {
    return <span>${paymentLow}</span>
  }
  if (paymentType === RANGE) {
    return (
      <span>
        ${paymentLow} - ${paymentHigh}
      </span>
    )
  }
  if (paymentType === NEGOTIABLE) {
    return <span>Negotiable</span>
  }
  return null
}

class CampaignMeta extends PureComponent {
  static propTypes = {
    alternativeNames: PropTypes.arrayOf(PropTypes.string),
    timezone: PropTypes.string,
    recruitmentStarts: PropTypes.string,
    recruitmentEnds: PropTypes.string,
    embargoEnds: PropTypes.string,
    paymentType: PropTypes.string,
    paymentLow: PropTypes.number,
    paymentHigh: PropTypes.number,
    platforms: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    isReleased: PropTypes.bool,
    contactName: PropTypes.string,
    contactEmail: PropTypes.string,
    contactTwitter: PropTypes.string,
    releaseAt: PropTypes.string,
  }

  static defaultProps = {
    alternativeNames: [],
    timezone: undefined,
    recruitmentStarts: undefined,
    recruitmentEnds: undefined,
    embargoEnds: undefined,
    paymentType: undefined,
    isReleased: undefined,
    paymentLow: undefined,
    paymentHigh: undefined,
    platforms: undefined,
    title: '',
    contactName: undefined,
    contactEmail: undefined,
    contactTwitter: undefined,
    releaseAt: undefined,
  }

  render() {
    const {
      alternativeNames,
      timezone,
      recruitmentStarts,
      recruitmentEnds,
      embargoEnds,
      paymentType,
      paymentLow,
      paymentHigh,
      platforms,
      title,
      contactName,
      contactEmail,
      contactTwitter,
      releaseAt,
      isReleased,
    } = this.props
    const paymentInfo = renderPaymentInformation(paymentType, paymentLow, paymentHigh)
    return (
      <div className={styles.container}>
        <If condition={title}>
          <h2 className={styles.title}>{title}</h2>
        </If>
        <If condition={platforms}>
          <div className={styles.infoBlock}>
            <Legend>Platforms</Legend>
            <Platforms dark platforms={platforms} />
          </div>
        </If>
        <If condition={alternativeNames.length}>
          <div className={styles.infoBlock}>
            <Legend>
              Exact Game Name(s){' '}
              <span data-for="exactGameNames" data-tip={exactGameNamesTooltip}>
                <Icon icon={faInfoCircle} />
              </span>
            </Legend>
            <div className={styles.alternativeNames}>
              {alternativeNames.map(renderAlternativeName)}
            </div>
          </div>
        </If>

        <ReactTooltip
          id="exactGameNames"
          place="top"
          effect="solid"
          className={styles.exactGameNamesTooltip}
        />

        <If condition={timezone}>
          <div className={styles.infoBlock}>
            <Legend>Timezone</Legend>
            {timezone}
          </div>
        </If>

        <If condition={typeof recruitmentStarts !== 'undefined'}>
          <div className={styles.infoBlock}>
            <Legend>Campaign Starts</Legend>
            {recruitmentStarts || 'Immediately'}
          </div>
        </If>

        <If condition={!isReleased && releaseAt === null}>
          <div className={styles.infoBlock}>
            <Legend>Game Releases</Legend>
            TBD
          </div>
        </If>
        <If condition={!isReleased && releaseAt}>
          <div className={styles.infoBlock}>
            <Legend>Game Releases</Legend>
            {releaseAt}
          </div>
        </If>

        <If condition={embargoEnds}>
          <div className={styles.infoBlock}>
            <Legend>Embargo Ends</Legend>
            {formatHuman(embargoEnds)}
          </div>
        </If>

        <If condition={recruitmentEnds}>
          <div className={styles.infoBlock}>
            <Legend>Recruitment Ends</Legend>
            {recruitmentEnds}
          </div>
        </If>

        <If condition={paymentInfo}>
          <div className={styles.infoBlock}>
            <Legend>Payment</Legend>
            {paymentInfo}
          </div>
        </If>

        <If condition={contactName || contactEmail || contactTwitter}>
          <div>
            <div className={styles.hr} />
            <If condition={contactName}>
              <div className={styles.infoBlock}>
                <Legend>Contact name</Legend>
                {contactName}
              </div>
            </If>
            <If condition={contactEmail}>
              <div className={styles.infoBlock}>
                <Legend>Contact email</Legend>
                {contactEmail}
              </div>
            </If>
            <If condition={contactTwitter}>
              <div className={styles.infoBlock}>
                <Legend>Twitter</Legend>
                {contactTwitter}
              </div>
            </If>
          </div>
        </If>
      </div>
    )
  }
}
export default CampaignMeta
