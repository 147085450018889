const match = (term, name) => {
  if (term === name) {
    return true
  }

  if (term === name.replace(' ', '')) {
    return true
  }

  if (name.split(' ').includes(term)) {
    return true
  }
  return false
}

const clean = (s = '') =>
  s
    .replace(/-/g, ' ') // strip dashes
    .replace(/_/g, ' ') // strip underscores
    .replace(/[0-9]/g, '') // strip numbers
    .replace(/\s\s/g, ' ') // strip double spaces
    .replace(/([a-z0-9])([A-Z])/g, '$1 $2') // adding space between CamelCase words
    .toLowerCase()

const searchTermMatchesCreator = (term = '', name = '') => match(term.toLowerCase(), clean(name))
export default searchTermMatchesCreator
