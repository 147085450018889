import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'

import * as campaignService from 'services/campaigns'
import Select from 'components/piece-field-select'

import styles from './styles.css'

const MIN_INPUT_SEARCH = 2
const DEBOUNCE_DELAY = 500

const mapStateToProps = ({ user }) => ({
  user,
})

const renderCampaignValue = (value) => `Campaign: ${value.label}`

const DEFAULT_PLACEHOLDER = 'Campaigns: All'
const CampaignSelect = ({ value, onSelectChange, user }) => {
  const [placeholder, setPlaceholder] = useState(DEFAULT_PLACEHOLDER)
  const [options, setOptions] = useState([])

  const getCampaignSelectOption = (campaign) => {
    return {
      value: campaign.id,
      label: campaign.title,
    }
  }

  const searchCampaign = (search, callback) =>
    campaignService
      .getAll(user.get('auth_token'))({ search })
      .then((response) => {
        const newOptions = response.map(getCampaignSelectOption)
        setOptions(newOptions)
        callback(newOptions)
      })

  useEffect(() => {
    if (value) {
      searchCampaign(value, () => {})
    }
  }, [value])

  const debouncedFetch = debounce((searchTerm, callback) => {
    searchCampaign(searchTerm, callback)
  }, DEBOUNCE_DELAY)

  const onSearchCampaign = (inputValue = '', callback) => {
    if (inputValue.length < MIN_INPUT_SEARCH) {
      setOptions([])
      callback([])
      return
    }

    debouncedFetch(inputValue, callback)
  }

  const onFocus = () => setPlaceholder('Type to search...')
  const onBlur = () => setPlaceholder(DEFAULT_PLACEHOLDER)

  const getValue = (newValue) => {
    if (!newValue) {
      return null
    }
    // eslint-disable-next-line eqeqeq
    return options.find((o) => o.value == newValue)
  }

  return (
    <Select
      className={styles.select}
      valueRenderer={renderCampaignValue}
      name="campaign"
      value={value}
      isClearable
      onChange={onSelectChange}
      placeholder={placeholder}
      onFocus={onFocus}
      onBlur={onBlur}
      isAsync
      loadOptions={onSearchCampaign}
      getValue={getValue}
    />
  )
}

CampaignSelect.propTypes = {
  value: PropTypes.string,
  onSelectChange: PropTypes.func,
  user: ImmutablePropTypes.map,
}

CampaignSelect.defaultProps = {
  value: undefined,
  onSelectChange: () => {},
  user: new Map(),
}

export default connect(mapStateToProps)(CampaignSelect)
