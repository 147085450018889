import { Map } from 'immutable'

import getActionName from '../../utils/get-action-name'

export const INITIAL_STATE = new Map()

const isPending = (actionType) => actionType.toString().includes('_PENDING')

const actionHasResponseType = (actionType) =>
  actionType.includes('_FULFILLED') ||
  actionType.includes('_REJECTED') ||
  actionType.includes('_OFFLINE')

const isResponse = (action) => actionHasResponseType(action.toString())

export const loading = (state = INITIAL_STATE, action) => {
  const actionName = getActionName(action.type)
  const actionCount = `${actionName}_COUNT`
  const actionDetail = `${actionName}_DETAIL`

  const count = state.get(actionCount) || 0
  let newState = state
  if (isPending(action.type)) {
    newState = newState.set(actionCount, count + 1).set(actionName, count + 1 > 0)
    if (action.meta?.loading) {
      const specificLoadingCount = newState.getIn([actionDetail, action.meta.loading], 0)
      newState = newState.setIn([actionDetail, action.meta.loading], specificLoadingCount + 1)
    }
  }

  if (isResponse(action.type)) {
    newState = newState.set(actionCount, count - 1).set(actionName, count - 1 > 0)
    if (action.meta?.loading) {
      const specificLoadingCount = newState.getIn([actionDetail, action.meta.loading], 0)
      newState = newState.setIn([actionDetail, action.meta.loading], specificLoadingCount - 1)
    }
  }
  return newState
}
