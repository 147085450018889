import React, { Component } from 'react'
import ImmutableProptypes from 'react-immutable-proptypes'
import shallowequal from 'shallowequal'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { connect } from 'react-redux'
import { List, Map } from 'immutable'
import { Helmet } from 'react-helmet'
import classnames from 'classnames'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import Button from '../../components/button'
import ModalConfirm from '../../components/modal-confirm'
import Icon from '../../components/piece-icon'
import BlockFieldFeedback from '../../components/block-field-feedback'
import Plan from '../../components/plan'
import {
  PLAN_PRICING,
  ANNUAL_DISCOUNT_PREMIUM,
  ANNUAL,
  PLANS,
  PREMIUM_PRICE,
  PREMIUM_PRICE_ANNUAL,
  PLAN_COMBINATION_SLUG_MAP,
  FREE_PLAN,
  PREMIUM_PLAN,
  PLAN_COMBINATION_MAP,
  FREE_FEATURES,
  PREMIUM_FEATURES,
  FEATURED_CAMPAIGN,
  AGENCY_FEATURES,
} from '../../config/settings'
import { getPlanName, parseDate } from '../organization-settings/billing'
import { getCard, changePlan, downgrade, CHANGE_PLAN, DOWNGRADE } from '../../modules/plan/actions'
import { KIND } from '../../config/user-settings'

import styles from './styles.css'

const STANDARD_PLANS = ['monthly', 'annual']
const INVALID_CARD = 'E_INVALID_CARD'

const getPlanButtonLabelAndKind = (user, plansCombinations, currentPlans) => {
  if (!user.get('auth_token')) {
    return { planLabel: 'START NOW', planButtonKind: 'primary' }
  }

  if (
    plansCombinations.some((combination) => shallowequal(combination.sort(), currentPlans.sort()))
  ) {
    return { planLabel: 'CURRENT PLAN', planButtonKind: 'grayed' }
  }
  return { planLabel: 'CHANGE PLAN', planButtonKind: 'primary' }
}

const mapStateToProps = ({ loading, cards, user, error }) => ({
  currentPlanName: getPlanName(user.getIn(['profile', 'subscription', 'plans'])),
  currentPlans: user.getIn(['profile', 'subscription', 'plans'], new List()).toArray(),
  isLoadingChangePlan: !!loading.get(CHANGE_PLAN.ACTION),
  isLoadingDowngrade: !!loading.get(DOWNGRADE.ACTION),
  downgradeError: error.get(DOWNGRADE.ACTION, new Map()),
  changePlanError: error.get(CHANGE_PLAN.ACTION, new Map()),
  cards,
  user,
})

const mapDispatchToProps = {
  getCard,
  changePlan,
  downgrade,
}

class UpgradeAccount extends Component {
  static propTypes = {
    currentPlans: PropTypes.arrayOf(PropTypes.string).isRequired,
    currentPlanName: PropTypes.string.isRequired,
    params: PropTypes.shape({
      recurrence: PropTypes.string,
    }),
    user: ImmutableProptypes.map.isRequired,
    changePlan: PropTypes.func.isRequired,
    getCard: PropTypes.func.isRequired,
    cards: ImmutableProptypes.map.isRequired,
    changePlanError: ImmutableProptypes.map.isRequired,
    isLoadingChangePlan: PropTypes.bool.isRequired,
    isLoadingDowngrade: PropTypes.bool.isRequired,
    downgrade: PropTypes.func.isRequired,
    downgradeError: ImmutableProptypes.map.isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    params: { recurrence: 'monthly' },
    className: undefined,
  }

  state = {
    newPlan: null,
    isCancelModalOpen: false,
    recurrence: this.props.params.recurrence || 'monthly',
  }

  componentDidMount() {
    const { user } = this.props
    if (user.get('auth_token') && user.getIn(['profile', 'kind']) === KIND.PUBLISHER) {
      this.props.getCard()
    }
  }

  componentWillReceiveProps({
    isLoadingChangePlan,
    isLoadingDowngrade,
    downgradeError,
    changePlanError,
  }) {
    if (!isLoadingChangePlan && this.props.isLoadingChangePlan && !changePlanError.size) {
      browserHistory.push('/upgrade/success')
    }

    if (!isLoadingDowngrade && this.props.isLoadingDowngrade && !downgradeError.size) {
      browserHistory.replace('/upgrade/downgrade/confirmation')
    }
  }

  handleChangePlan = (plan) => {
    const { currentPlans, cards, user } = this.props
    const { recurrence } = this.state
    const newPlan = PLAN_COMBINATION_MAP[recurrence][plan]

    if (!user.get('auth_token')) {
      browserHistory.push(`/signup/publisher`)
      return
    }

    if (shallowequal(newPlan, currentPlans)) {
      return
    }

    if (plan === FREE_PLAN) {
      this.setState({ isCancelModalOpen: !this.state.isCancelModalOpen })
      return
    }

    if (!cards.size) {
      browserHistory.push(`/upgrade/${recurrence}/${plan}`)
      return
    }
    this.showChangePlanModal(plan)
  }

  confirmChangePlan = () => {
    const { recurrence, newPlan } = this.state
    // the new plan here follows the slug pattern
    // but the returned plan does not
    const newPlans = PLAN_COMBINATION_SLUG_MAP[recurrence][newPlan]
    return this.props.changePlan(newPlans)
  }

  dismissCancelModal = () => {
    this.setState({ isCancelModalOpen: false })
  }

  showChangePlanModal = (plan) => {
    this.setState({
      newPlan: plan,
    })
  }

  dismissModal = () => {
    this.setState({
      newPlan: null,
    })
  }

  handleChangeToPremium = () => {
    this.handleChangePlan(PREMIUM_PLAN)
  }

  handleChangeToFree = () => {
    if (this.props.currentPlans.includes(FREE_PLAN)) {
      return
    }
    this.handleChangePlan(FREE_PLAN)
  }

  onConfirmCancellation = () => {
    this.props.downgrade()
  }

  toggleRecurrence = (to, previous) => () => {
    this.setState({ recurrence: to })
    browserHistory.replace(browserHistory.getCurrentLocation().pathname.replace(previous, to))
  }

  render() {
    const {
      currentPlans,
      currentPlanName,
      isLoadingChangePlan,
      changePlanError,
      user,
      isLoadingDowngrade,
      downgradeError,
      className,
    } = this.props
    const { recurrence } = this.state
    const premiumPrice = recurrence === 'monthly' ? PREMIUM_PRICE : PREMIUM_PRICE_ANNUAL
    const priceLabel = recurrence === 'monthly' ? 'per month' : 'per year'

    const {
      planLabel: freePlanLabel,
      planButtonKind: freePlanButtonKind,
    } = getPlanButtonLabelAndKind(user, [PLAN_COMBINATION_MAP[recurrence][FREE_PLAN]], currentPlans)
    const {
      planLabel: premiumPlanLabel,
      planButtonKind: premiumPlanButtonKind,
    } = getPlanButtonLabelAndKind(
      user,
      [PLAN_COMBINATION_MAP[recurrence][PREMIUM_PLAN]],
      currentPlans
    )
    return (
      <div className={classnames(styles.container, className)}>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css?family=Shadows+Into+Light"
            rel="stylesheet"
          />
        </Helmet>
        {user.get('auth_token') ? (
          <>
            <h1>Level Up Your Campaigns</h1>
            <p>
              You&apos;re currently on the <strong>{currentPlanName}</strong>. Choose from one of
              the following premium options (upgrade, downgrade or cancel at any time).
            </p>
          </>
        ) : (
          <>
            <h1>Pricing</h1>
          </>
        )}
        <div>
          <div className={styles.recurrence}>
            <button
              type="button"
              className={classnames(styles.recurrenceSelect, {
                [styles.recurrentSelectActive]: recurrence === 'monthly',
              })}
              onClick={this.toggleRecurrence('monthly', 'annual')}
            >
              Monthly Billing
            </button>
            <button
              type="button"
              className={classnames(styles.recurrenceSelect, {
                [styles.recurrentSelectActive]: recurrence === 'annual',
              })}
              onClick={this.toggleRecurrence('annual', 'monthly')}
            >
              Annual Billing
            </button>
            {
              // <Link
              //    className={styles.recurrenceSelect}
              //    activeClassName={styles.recurrentSelectActive}
              //    to="/upgrade/featured-campaign"
              //  >
              //    Featured Placement
              //  </Link>
            }
          </div>
          {STANDARD_PLANS.includes(recurrence) ? (
            <div className={styles.plans}>
              <Plan
                title="Free"
                activeCampaignsAmount="1"
                features={FREE_FEATURES}
                price="0"
                priceLabel={priceLabel}
              >
                <Button
                  onClick={this.handleChangeToFree}
                  className={styles.button}
                  kind={freePlanButtonKind}
                >
                  {freePlanLabel}
                </Button>
              </Plan>
              <Plan
                title="Premium"
                discount={recurrence === ANNUAL ? ANNUAL_DISCOUNT_PREMIUM : undefined}
                activeCampaignsAmount="Unlimited"
                features={PREMIUM_FEATURES}
                price={premiumPrice.toLocaleString()}
                priceLabel={priceLabel}
              >
                <Button
                  onClick={this.handleChangeToPremium}
                  kind={premiumPlanButtonKind}
                  className={styles.button}
                >
                  {premiumPlanLabel}
                </Button>
              </Plan>
              <Plan
                title="Agency Support"
                activeCampaignsAmount="Bespoke"
                hideActiveCampaignsLabel
                features={AGENCY_FEATURES}
                price="TBD"
                priceLabel=""
              >
                <Button
                  href="mailto:hello@woovit.com"
                  external
                  kind={premiumPlanButtonKind}
                  className={styles.button}
                >
                  CONTACT US
                </Button>
              </Plan>
            </div>
          ) : null}
          {!STANDARD_PLANS.includes(recurrence) ? (
            <div className={styles.plans}>
              <Plan
                title="Featured"
                className={styles.featured}
                activeCampaignsAmount="Unlimited"
                features={FEATURED_CAMPAIGN}
                price="199"
                priceLabel="subject to availability"
              />
            </div>
          ) : null}
        </div>
        <ModalConfirm
          open={!!this.state.newPlan}
          onCancel={this.dismissModal}
          isLoading={isLoadingChangePlan}
          onConfirm={this.confirmChangePlan}
          confirmText="Change plan"
        >
          <div className={styles.cancellationModal}>
            <h1>Changing Plan</h1>
            <p>
              You&apos;re going from <b>{currentPlanName}</b> to <b>{PLANS[this.state.newPlan]}</b>,
              for a new {recurrence} price of ${PLAN_PRICING[recurrence][this.state.newPlan]}.
            </p>
            <BlockFieldFeedback
              errorMessage={
                changePlanError.get('error') === INVALID_CARD
                  ? 'We could not process your payment, please check your credit card details and try again.'
                  : changePlanError.get('error')
              }
            />
          </div>
        </ModalConfirm>

        <ModalConfirm
          isLoading={isLoadingDowngrade}
          open={this.state.isCancelModalOpen}
          onCancel={this.dismissCancelModal}
          onConfirm={this.onConfirmCancellation}
          confirmText="Finish cancellation"
          title="Cancel Subscription"
        >
          <div className={styles.cancellationModal}>
            <p>
              <Icon icon={faCheck} />
              Cancellation will be effective at the end of your current billing period on{' '}
              {parseDate(user.getIn(['profile', 'subscription', 'end_date']))}.
            </p>
            <p>
              <Icon icon={faCheck} /> We will send an email confirmation of your cancellation.
            </p>
            <p>
              <Icon icon={faCheck} /> Restart your subscription anytime.
            </p>
            <BlockFieldFeedback errorMessage={downgradeError.get('detail')} />
          </div>
        </ModalConfirm>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpgradeAccount)
