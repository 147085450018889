import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import classnames from 'classnames'

import useOuterClick from 'hooks/use-outer-click'
import Icon from 'components/piece-icon'
import { ENTER_REACT_KEY_CODE } from 'config/settings'
import Svg from 'components/svg'
import commentsIcon from 'img/ic_comments.svg'
import { format } from 'utils/datetime'

import Reply from './reply'
import styles from './styles.css'

const Event = ({
  id,
  className,
  primaryIcon,
  secondaryIcon,
  children,
  style,
  onSendReply,
  hasSendToNotes,
  comments,
}) => {
  const [replyOpen, setReplyOpen] = useState(false)

  const innerRef = useOuterClick(() => setReplyOpen(false))

  const toggleReply = () => {
    setReplyOpen(!replyOpen)
  }

  const onAreaClick = () => {
    toggleReply()
  }

  const handleKeyPress = ({ key }) => {
    if (key === ENTER_REACT_KEY_CODE) {
      toggleReply()
    }
  }

  const handleSendReply = (value) => {
    onSendReply(value)
    toggleReply()
  }

  const renderComment = (comment) => (
    <li key={comment.get('id')} id={`comment_${comment.get('id')}`}>
      <span className={styles.commentsIconContainer}>
        <Svg className={styles.arrow} svg={commentsIcon} />
      </span>
      <div className={styles.commentName}>
        <strong>{comment.getIn(['author', 'name'])}</strong>
        <p>{comment.get('message')}</p>
      </div>
      <span className={styles.commentsTime}>{format(comment.get('created_at'), 'h:mm A')}</span>
    </li>
  )

  return (
    <div
      ref={innerRef}
      onClick={onAreaClick}
      role="presentation"
      onKeyPress={handleKeyPress}
      className={classnames(className, styles.container)}
      style={style}
    >
      <div className={styles.contentContainer}>
        <div className={styles.iconContainer}>
          {primaryIcon && <Icon className={styles.primaryIcon} {...primaryIcon} />}
          {secondaryIcon && <Icon className={styles.secondaryIcon} {...secondaryIcon} />}
        </div>
        <div className={styles.content}>
          <div className={styles.message} data-isolate>
            {children}
          </div>
          <div className={styles.rightPanel}>
            <Svg
              className={classnames(styles.replyIcon, { [styles.visible]: replyOpen })}
              svg={commentsIcon}
            />
          </div>
        </div>
        <ul className={styles.comments}>{comments.map(renderComment)}</ul>
        {replyOpen && (
          <Reply
            id={id}
            hasSendToNotes={hasSendToNotes}
            onSendReply={handleSendReply}
            onCancel={toggleReply}
          />
        )}
      </div>
    </div>
  )
}

Event.propTypes = {
  id: PropTypes.number,
  children: PropTypes.node,
  primaryIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondaryIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  onSendReply: PropTypes.func,
  hasSendToNotes: PropTypes.bool,
  comments: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
}

Event.defaultProps = {
  id: null,
  children: null,
  primaryIcon: null,
  secondaryIcon: null,
  className: undefined,
  style: undefined,
  onSendReply: () => {},
  hasSendToNotes: false,
  comments: [],
}

export default Event
