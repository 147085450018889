import { Map, List, fromJS } from 'immutable'
import { normalize, schema } from 'normalizr'

import createReducer from '../../utils/create-reducer'
import { GET_CAMPAIGN_OFFERS, GET_CAMPAIGN_VIDEOS } from '../campaign/actions'
import { ADD_CREATOR_TO_BLOCKLIST, REMOVE_CREATOR_FROM_BLOCKLIST } from '../blocklist/actions'
import { GET_CREATOR_WHITELISTS } from '../whitelist/actions'

import {
  GET_CREATOR_DETAILS,
  SEARCH_CREATORS,
  GET_CREATORS,
  GET_CREATOR_NOTES,
  DELETE_CREATOR_NOTE,
  CREATE_NOTE,
  SELECT_CREATOR,
  CLEAR_SELECTED_CREATORS,
  GET_CREATOR_PLAYED_GAMES,
  GET_CREATOR_STEAM_PLAYED_GAMES,
  GET_CREATOR_DELIVERY_STATS,
  GET_CREATOR_DELIVERY_DELAY,
  GET_SIMILAR_CREATORS,
} from './actions'

export const creatorSchema = new schema.Entity('creator')

const getProfile = (creator) => creator.profile

export const creator = createReducer(new Map(), {
  [GET_CREATOR_DETAILS.FULFILLED]: (state, { payload }) => {
    const { email, ...data } = payload
    if (email) {
      data.email = email
    }
    return state.mergeDeep(normalize(data, creatorSchema).entities.creator)
  },

  [SEARCH_CREATORS.FULFILLED]: (state, { payload }) => {
    const creators = payload.results.map((result) => result.profile)
    return state.mergeDeep(normalize(creators, [creatorSchema]).entities.creator)
  },
  [GET_CREATORS.FULFILLED]: (state, { payload }) =>
    state.mergeDeep(normalize(payload.results, [creatorSchema]).entities.creator),

  [GET_CAMPAIGN_VIDEOS.FULFILLED]: (state, { payload }) => {
    const creators = payload.results.map((v) => ({ ...v.creator, id: v.creator.profile_id }))
    return state.mergeDeep(normalize(creators, [creatorSchema]).entities.creator)
  },

  [GET_CAMPAIGN_OFFERS.FULFILLED]: (state, { payload }) =>
    state.mergeDeep(normalize(payload.results.map(getProfile), [creatorSchema]).entities.creator),

  [ADD_CREATOR_TO_BLOCKLIST.PENDING]: (state, { meta }) =>
    meta.creatorId
      ? state.setIn([String(meta.creatorId), 'blocklisted'], fromJS({ created_at: Date.now() }))
      : state,

  [ADD_CREATOR_TO_BLOCKLIST.FULFILLED]: (state, { payload, meta }) =>
    meta.profileId ? state.setIn([String(meta.profileId), 'blocklisted'], fromJS(payload)) : state,
  [REMOVE_CREATOR_FROM_BLOCKLIST.PENDING]: (state, { meta }) =>
    meta.profileId ? state.setIn([String(meta.profileId), 'blocklisted'], null) : state,

  [GET_SIMILAR_CREATORS.FULFILLED]: (state, { payload }) => {
    const creators = payload.data.results.map((entry) => entry.profile)
    return state.mergeDeep(normalize(creators, [creatorSchema]).entities.creator)
  },
})

export const creatorSearchSchema = new schema.Entity('result')
export const creatorSearch = createReducer(new Map(), {
  [SEARCH_CREATORS.FULFILLED]: (state, { payload }) => {
    const results = payload.results.map((result) => ({
      videos: result.videos.map((v) => String(v.id)),
      creator: String(result.profile.id),
      highlight: result.highlight,
      id: String(result.id),
    }))
    return state.mergeDeep(normalize(results, [creatorSearchSchema]).entities.result)
  },
})

export const notesSchema = new schema.Entity('note')
export const creatorNotes = createReducer(new Map(), {
  [GET_CREATOR_NOTES.FULFILLED]: (state, { payload }) =>
    state.mergeDeep(normalize(payload.results, [notesSchema]).entities.note),
  [CREATE_NOTE.FULFILLED]: (state, { payload }) =>
    state.mergeDeep(normalize(payload, notesSchema).entities.note),
  [DELETE_CREATOR_NOTE.PENDING]: (state, { meta }) => state.remove(String(meta.noteId)),
})

export const selectedCreators = createReducer(new List(), {
  [SELECT_CREATOR.ACTION]: (state, { payload }) => {
    const index = state.indexOf(payload)
    if (index === -1) {
      return state.push(payload)
    }
    return state.splice(index, 1)
  },
  [CLEAR_SELECTED_CREATORS.ACTION]: () => new List(),
})

export const creatorPlayedGames = createReducer(new Map(), {
  [GET_CREATOR_PLAYED_GAMES.FULFILLED]: (state, { payload, meta }) =>
    state.mergeDeep(fromJS({ [String(meta.creatorId)]: payload })),
})
// pehaps the following and other creator stats related
// reducers could be merged into a single reducer?
export const creatorSteamPlayedGames = createReducer(new Map(), {
  [GET_CREATOR_STEAM_PLAYED_GAMES.FULFILLED]: (state, { payload, meta }) =>
    state.mergeDeep(fromJS({ [String(meta.creatorId)]: payload })),
})

export const creatorDeliveryStats = createReducer(new Map(), {
  [GET_CREATOR_DELIVERY_STATS.FULFILLED]: (state, { payload, meta }) =>
    state.mergeDeep(fromJS({ [String(meta.creatorId)]: payload.data })),
  // This is subject to key conflict with the payload above:
  [GET_CREATOR_DELIVERY_DELAY.FULFILLED]: (state, { payload, meta }) =>
    state.mergeDeep(fromJS({ [String(meta.creatorId)]: payload.data })),
})

export const creatorWhitelists = createReducer(new Map(), {
  [GET_CREATOR_WHITELISTS.FULFILLED]: (state, { payload, meta }) =>
    state.mergeDeep(fromJS({ [String(meta.creatorId)]: payload })),
})

export const similarCreators = createReducer(new Map(), {
  [GET_SIMILAR_CREATORS.FULFILLED]: (state, { payload, meta }) => {
    const creators = payload.data.results.map((entry) => ({
      profileId: String(entry.profile.id),
      score: entry.score,
    }))
    return state.mergeDeep({ [meta.creatorId]: creators })
  },
})
