import { CDN_PROXY_URL } from '../config/settings'

const OLD_STEAM_CDN = 'http://cdn.akamai.steamstatic.com/'
const NEW_STEAM_CDN = 'https://steamcdn-a.akamaihd.net/'
const NEW_STEAM_CDN_WITHOUT_SSL = 'http://steamcdn-a.akamaihd.net/'
const cdnizeAsset = (url = '', forceProxy) => {
  if (!url) {
    return ''
  }

  if (typeof url !== 'string') {
    return ''
  }

  // our own cloudinary bucket, we load it via cdn proxy so cloudflare can
  // optimize and cache it so we don't spend cloudinary's quota
  if (url.includes('https://res.cloudinary.com/dj4laqzi0')) {
    return `${CDN_PROXY_URL}${url}`
  }

  // startsWith https
  if (url.indexOf('https://', 0) === 0 && !forceProxy) {
    return url
  }

  // startsWith /
  if (url.indexOf('/', 0) === 0) {
    return url
  }

  // this is the old steam cdn, can be replaced with the new https one
  if (url.indexOf(OLD_STEAM_CDN, 0) === 0 && !forceProxy) {
    return url.replace(OLD_STEAM_CDN, NEW_STEAM_CDN)
  }

  if (url.indexOf(NEW_STEAM_CDN_WITHOUT_SSL) !== -1 && !forceProxy) {
    return url.replace(NEW_STEAM_CDN_WITHOUT_SSL, NEW_STEAM_CDN)
  }

  if (url.includes(CDN_PROXY_URL)) {
    return url
  }

  return `${CDN_PROXY_URL}${url}`
}

export default cdnizeAsset
