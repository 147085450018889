import React from 'react'

import Loading from '../piece-loading'

import styles from './styles.css'

const AsyncLoading = () => (
  <div className={styles.loadingWrapper}>
    <Loading isLoading />
  </div>
)

export default AsyncLoading
