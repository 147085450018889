import { Map, List, fromJS } from 'immutable'
import { normalize, schema } from 'normalizr'

import createReducer from '../../utils/create-reducer'
import { FLAG_VIDEO, UNFLAG_VIDEO } from '../video/actions'
import { ADD_CREATOR_TO_BLOCKLIST, REMOVE_CREATOR_FROM_BLOCKLIST } from '../blocklist/actions'
import { GET_CAMPAIGN_VIDEOS, TOGGLE_VIDEO } from '../campaign/actions'

import { getReportKey } from './selectors'
import {
  GET_CAMPAIGN_DELIVERIES,
  GET_CAMPAIGN_VIDEO_REPORTS,
  GET_CAMPAIGN_DAILY_DELIVERIES,
  GET_OFFER_COMPLETION,
} from './actions'

export const INITIAL_STATE = new Map()
export const reportSchema = new schema.Entity('report')
export const videoSchema = new schema.Entity('video')

const updateCreatorBlocklist = (state, userId, value) => {
  if (userId) {
    return state.map((video) => {
      if (video.getIn(['creator', 'user_id']) === userId) {
        return video.setIn(['creator', 'blocklisted'], value)
      }

      return video
    })
  }
  return state
}

export const campaignVideoReport = createReducer(INITIAL_STATE, {
  [GET_CAMPAIGN_VIDEO_REPORTS.FULFILLED]: (state, { payload }) =>
    state.mergeDeep(normalize(payload, reportSchema).entities.report),
})

export const campaignVideo = createReducer(INITIAL_STATE, {
  [TOGGLE_VIDEO.FULFILLED]: (state, { meta }) =>
    state.update(String(meta), (video) => video.set('hidden', !video.get('hidden'))),
  [GET_CAMPAIGN_VIDEOS.FULFILLED]: (state, { payload }) =>
    state.mergeDeep(normalize(payload.results, [videoSchema]).entities.video),
  [FLAG_VIDEO.PENDING]: (state, { meta }) =>
    state.setIn([String(meta.video), 'flag_video'], '<loading>'),
  [FLAG_VIDEO.FULFILLED]: (state, { payload }) =>
    state.setIn([String(payload.video), 'flag_video'], payload.id),
  [UNFLAG_VIDEO.PENDING]: (state, { meta }) =>
    state.setIn([String(meta.video), 'flag_video'], null),
  [ADD_CREATOR_TO_BLOCKLIST.PENDING]: (state, { meta }) =>
    updateCreatorBlocklist(state, meta.creatorUserId, fromJS({ id: -1 })),
  [ADD_CREATOR_TO_BLOCKLIST.FULFILLED]: (state, { meta, payload }) =>
    updateCreatorBlocklist(state, meta.creatorUserId, fromJS(payload)),
  [REMOVE_CREATOR_FROM_BLOCKLIST.PENDING]: (state, { meta }) =>
    updateCreatorBlocklist(state, meta.creatorUserId, null),
})

export const campaignReports = createReducer(INITIAL_STATE, {
  [GET_CAMPAIGN_DELIVERIES.FULFILLED]: (state, { payload, type, meta: { id, params } }) =>
    state.setIn(getReportKey(type, id, params), new List(payload)),
  [GET_CAMPAIGN_DAILY_DELIVERIES.FULFILLED]: (state, { payload, type, meta: { id, params } }) =>
    state.setIn(getReportKey(type, id, params), new List(payload)),
  [GET_OFFER_COMPLETION.FULFILLED]: (state, { payload, type, meta: { id, params } }) =>
    state.setIn(getReportKey(type, id, params), fromJS(payload)),
})
