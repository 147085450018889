import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'

import Button from '../button'
import If from '../if'
import linkify from '../../utils/linkify'
import Label from '../piece-label'
import OfferMedia from '../offer-media'

import Loader from './loader'
import styles from './styles.css'

const OfferBody = ({ description, productURL, terms, media, steamGameId, isLoading }) => {
  if (isLoading) {
    return <Loader />
  }

  return (
    <div className={styles.container}>
      <OfferMedia media={media} />
      <div
        className={styles.descriptionContent}
        dangerouslySetInnerHTML={{ __html: linkify(description) }}
      />
      <If condition={productURL}>
        <div className={styles.productButton}>
          <Button href={productURL} kind="primary" external>
            VISIT PRODUCT SITE
          </Button>
        </div>
      </If>
      <If condition={!productURL && steamGameId}>
        <div className={styles.productButton}>
          <Button href={`http://store.steampowered.com/app/${steamGameId}`} kind="primary" external>
            VISIT PRODUCT SITE
          </Button>
        </div>
      </If>
      <If condition={terms}>
        <div className={styles.terms}>
          <Label label="Additional Terms and Conditions" />
          <div
            className={styles.descriptionContent}
            dangerouslySetInnerHTML={{ __html: linkify(terms) }}
          />
        </div>
      </If>
    </div>
  )
}

OfferBody.propTypes = {
  description: PropTypes.string,
  productURL: PropTypes.string,
  terms: PropTypes.string,
  steamGameId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  media: ImmutablePropTypes.list,
  isLoading: PropTypes.bool,
}

OfferBody.defaultProps = {
  description: undefined,
  productURL: undefined,
  steamGameId: undefined,
  terms: undefined,
  media: new List(),
  isLoading: false,
}

export default OfferBody
