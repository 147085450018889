import React from 'react'

import notFound from '../../img/404.svg'
import Svg from '../../components/svg'
import { HTTP_404_NOT_FOUND } from '../../utils/get-status'

import styles from './styles.css'

const NotFoundPage = () => (
  <div className={styles.container}>
    <Svg width={null} height={null} alt="404 not found" svg={notFound} />
    <h1>Something’s wrong here!</h1>
    <p>Sorry, the page you were looking for appears to be missing.</p>
  </div>
)
NotFoundPage.getStatusCode = () => HTTP_404_NOT_FOUND

export default NotFoundPage
