import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import If from '../if'

import styles from './styles.css'

export const BaseIcon = ({
  children,
  withLabel,
  label,
  className,
  labelClassName,
  labelPosition,
}) => (
  <div className={classnames(styles.container, className, styles[labelPosition])}>
    {children}
    <If condition={withLabel}>
      <div className={classnames(styles.label, labelClassName)}>{label}</div>
    </If>
  </div>
)

BaseIcon.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  withLabel: PropTypes.bool,
  labelPosition: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
}

BaseIcon.defaultProps = {
  withLabel: false,
  label: undefined,
  labelPosition: 'right',
  className: '',
  labelClassName: '',
}

export Twitch from './twitch'
export Youtube from './youtube'
export Steam from './steam'
export Provider from './provider'
