import React, { useState, useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import ImmutableProptypes from 'react-immutable-proptypes'
import { Helmet } from 'react-helmet'
import { Map } from 'immutable'
import { faFacebook, faInstagram, faSnapchat } from '@fortawesome/free-brands-svg-icons'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import { getCreatorBySlug, selectNotesFromCreator } from '../../modules/creator/selectors'
import {
  GET_CREATOR_DETAILS,
  getCreatorDetails as getCreatorDetailsAction,
  getNotes as getNotesAction,
} from '../../modules/creator/actions'
import { MAIN_TITLE, VIDEO_PLATFORM_NAME, PLATFORM_NAME_ID_MAP } from '../../config/settings'
import NotFoundPage from '../not-found'
import Loading from '../../components/piece-loading'
import ShortURL from '../../components/short-url'
import addEllipsis from '../../utils/add-ellipsis'
import Actionable from '../../components/actionable'
import Container from '../../components/container'
import Image from '../../components/block-image'
import abbreviateNumber from '../../utils/abbreviate-number'
import cdnizeAsset from '../../utils/cdnize-asset'
import Label from '../../components/piece-label'
import Icon from '../../components/piece-icon'
import Breadcrumb from '../../components/breadcrumb'
import Button from '../../components/button'
import { Youtube, Twitch } from '../../components/provider-icon'
import Provider from '../../components/provider-icon/provider'
import lowercaseParams from '../../hocs/lowercase-params'
import { KIND } from '../../config/user-settings'
import isNotFound from '../../utils/is-not-found'
import TabBar from '../../components/tab-bar'

import Sidebar from './sidebar'
import styles from './styles.css'

const AVG_TOOLTIP_YOUTUBE = `
We calculate a channel's average views<br />
per video based on videos they've published<br />
over the last six months.`
const AVG_TOOLTIP_TWITCH = `
We calculate a channel's average concurrent<br />
viewers based on streams they've done over<br />
the last six months.`
const SHORT_BIO_LENGTH = 400

const mapStateToProps = (state, { params: { slug } }) => {
  const creator = getCreatorBySlug(state, slug) || new Map()
  const creatorNotes = selectNotesFromCreator(state, creator.get('id'))
  return {
    creator,
    creatorNotes,
    user: state.user,
    isLoading: !creator.size && !!state.loading.get(GET_CREATOR_DETAILS.ACTION),
    error: state.error.get(GET_CREATOR_DETAILS.ACTION),
  }
}

const mapDispatchToProps = {
  getCreatorDetails: getCreatorDetailsAction,
  getNotes: getNotesAction,
}

const PublicCreatorPage = ({
  creator,
  location,
  params,
  creatorNotes,
  getCreatorDetails,
  getNotes,
  isLoading,
  error,
  user,
  children,
}) => {
  const [showFullBio, setShowFullBio] = useState(false)
  const name = creator.get('name')

  const isPublisher = user.getIn(['profile', 'kind']) === KIND.PUBLISHER
  const isCreator = user.getIn(['profile', 'kind']) === KIND.CREATOR

  useEffect(() => {
    if (creator.get('id') && isPublisher) {
      getNotes(creator.get('id'))
    }
  }, [creator.get('id')])

  useEffect(() => {
    if (params.slug) {
      getCreatorDetails(params.slug)
    }
  }, [params.slug])

  const toggleBio = () => {
    setShowFullBio((previousShowFullBio) => !previousShowFullBio)
  }

  const getBreadCrumbs = () => {
    const fromSearch = location.state && location.state.fromSearch
    if (fromSearch) {
      return [['/search', 'Search'], [`/search/${fromSearch}`, fromSearch]]
    }
    return []
  }

  const getManagerEmail = () => {
    if (creator.get('manager_contact') && !creator.get('manager')) {
      return creator.get('manager_contact')
    }
    if (creator.get('manager_contact') && creator.get('manager')) {
      return ` (${creator.get('manager_contact')})`
    }
    return ''
  }

  const avatar = creator.get('avatar_url')
  const creatorBio = creator.get('bio')
  const creatorURL = `https://woovit.com/creator/${creator.get('slug')}`
  const title = name ? `${name} — Woovit` : MAIN_TITLE

  const breadCrumb = getBreadCrumbs()

  const getPagePath = (path) => {
    return `/creator/${creator.get('slug')}/${path}`
  }

  if (isNotFound(error)) {
    return <NotFoundPage />
  }

  if (isLoading && !creator.size) {
    return <Loading isLoading />
  }

  const tabs = []
  if (isPublisher) {
    tabs.push(['Videos', getPagePath('videos')])
    tabs.push(['Activity', getPagePath('feed')])
    tabs.push(['Notes', getPagePath('notes'), undefined, creatorNotes.size])
    tabs.push(['Stats', getPagePath('stats')])
    tabs.push(['Similar Creators', getPagePath('similar-creators'), true])
  }

  const renderProvider = (p) => (
    <div className={styles.wrapProvider}>
      <Provider key={p} platform={p} />
    </div>
  )

  const disconnectedPlatforms = Object.values(VIDEO_PLATFORM_NAME)
    .filter((p) => !!creator.getIn([p, 'auth_invalid_at']))
    .map((p) => PLATFORM_NAME_ID_MAP[p])

  return (
    <div className={styles.container}>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        {creatorBio ? <meta property="og:description" content={creatorBio} /> : null}
        {avatar ? <meta property="og:image" content={cdnizeAsset(avatar)} /> : null}
      </Helmet>

      <div className={styles.topWrapper}>
        <Breadcrumb paths={breadCrumb}>{creator.get('name')}</Breadcrumb>
        {creator.get('id') === user.getIn(['profile', 'id']) ? (
          <Button href="/settings/profile">Edit</Button>
        ) : null}
      </div>

      {disconnectedPlatforms.length ? (
        <div className={styles.invalidAuth}>
          This creator needs to reauthorize {disconnectedPlatforms.map(renderProvider)} access for
          us to retrieve their latest information.
        </div>
      ) : null}

      <Container className={styles.noBottomBorder}>
        <div className={styles.head}>
          <div className={styles.creatorCard}>
            <div className={styles.creatorHead}>
              <div className={styles.creatorImage}>
                {avatar ? <Image url={avatar} alt={creator.get('name')} /> : null}
              </div>
              <div className={styles.creatorName}>
                <h1 className={styles.creatorHeading}>
                  <Link to={`/creator/${creator.get('slug')}`}>{creator.get('name')}</Link>
                </h1>
                {creator.get('location') ? (
                  <div className={styles.meta}>
                    <Label label="Location" />
                    <span className={styles.metaValue}>
                      {creator.getIn(['location', 'formatted_address'])}
                    </span>
                  </div>
                ) : null}

                {creator.get('email') ? (
                  <div className={styles.meta}>
                    <Label label="Contact/Rep" />
                    <span className={styles.metaValue}>{creator.get('email')}</span>
                  </div>
                ) : null}

                {creator.get('manager') || creator.get('manager_contact') ? (
                  <div className={styles.meta}>
                    <Label label="Manager" />
                    <span className={styles.metaValue}>
                      {creator.get('manager')}

                      {getManagerEmail()}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
            {creatorBio ? (
              <p className={styles.bio}>
                {showFullBio ? creatorBio : addEllipsis(creatorBio, SHORT_BIO_LENGTH)}{' '}
                {creatorBio.length > SHORT_BIO_LENGTH ? (
                  <Actionable className={styles.showMoreBio} onClick={toggleBio}>
                    {showFullBio ? 'less' : 'more'}
                  </Actionable>
                ) : null}
              </p>
            ) : null}
          </div>
          <div className={styles.platformMeta}>
            <ReactTooltip multiline place="top" effect="solid" />
            <div className={styles.platforms}>
              {creator.getIn(['youtube', 'account_url']) ? (
                <div className={styles.platform}>
                  <div className={styles.platformFirstLine}>
                    <a
                      className={styles.platformLink}
                      href={creator.getIn(['youtube', 'account_url'])}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Youtube small />
                      <span className={styles.platformUsername}>
                        {creator.getIn(['youtube', 'username'])}
                      </span>
                    </a>
                    • {abbreviateNumber(creator.getIn(['youtube', 'follower_count']))}
                  </div>
                  <div className={styles.platformAvg}>
                    Average Views • {abbreviateNumber(creator.getIn(['youtube', 'avg_view_count']))}{' '}
                    <span data-tip={AVG_TOOLTIP_YOUTUBE}>
                      <Icon icon={faInfoCircle} />
                    </span>
                  </div>
                </div>
              ) : null}
              {creator.getIn(['twitch', 'account_url']) ? (
                <div className={styles.platform}>
                  <div className={styles.platformFirstLine}>
                    <a
                      className={styles.platformLink}
                      href={creator.getIn(['twitch', 'account_url'])}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Twitch small />
                      <span className={styles.platformUsername}>
                        {creator.getIn(['twitch', 'username'])}
                      </span>
                    </a>
                    • {abbreviateNumber(creator.getIn(['twitch', 'follower_count']))}
                  </div>
                  <div className={styles.platformAvg}>
                    Average Concurrents •{' '}
                    {abbreviateNumber(creator.getIn(['twitch', 'avg_view_count']))}{' '}
                    <span data-tip={AVG_TOOLTIP_TWITCH}>
                      <Icon icon={faInfoCircle} />
                    </span>
                  </div>
                </div>
              ) : null}

              {creator.get('facebook_id') ? (
                <div className={styles.platform}>
                  <a
                    target="_blank"
                    href={`https://facebook.com/${creator.get('facebook_id')}`}
                    rel="noopener noreferrer"
                  >
                    <span className={styles.iconWrapper}>
                      <Icon icon={faFacebook} />
                    </span>
                    {creator.get('facebook_id').replace('https://www.facebook.com/', '')}
                  </a>
                </div>
              ) : null}

              {creator.get('instagram_username') ? (
                <div className={styles.platform}>
                  <a
                    target="_blank"
                    href={`https://instagram.com/${creator
                      .get('instagram_username')
                      .replace('https://www.instagram.com/', '')}`}
                    rel="noopener noreferrer"
                  >
                    <span className={styles.iconWrapper}>
                      <Icon icon={faInstagram} />
                    </span>
                    {creator.get('instagram_username')}
                  </a>
                </div>
              ) : null}

              {creator.get('snapchat_id') ? (
                <div className={styles.platform}>
                  <span className={styles.iconWrapper}>
                    <Icon icon={faSnapchat} />
                  </span>
                  {creator.get('snapchat_id')}
                </div>
              ) : null}
            </div>
            <div className={styles.share}>
              <ShortURL shortURL={creatorURL} />
            </div>
          </div>
        </div>
      </Container>
      {tabs.length ? <TabBar tabs={tabs} /> : null}
      <div className={styles.spacer} />
      <div className={styles.contentWrapper}>
        <div className={styles.mainContent}>{children}</div>
        {!isCreator ? (
          <div className={styles.sidebar}>{creator ? <Sidebar creator={creator} /> : null}</div>
        ) : null}
      </div>
    </div>
  )
}

PublicCreatorPage.propTypes = {
  creator: ImmutableProptypes.map,
  user: ImmutableProptypes.map,
  getCreatorDetails: PropTypes.func.isRequired,
  getNotes: PropTypes.func.isRequired,
  creatorNotes: ImmutableProptypes.list.isRequired,
  isLoading: PropTypes.bool.isRequired,
  params: PropTypes.shape({
    slug: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({
      ordering: PropTypes.string,
      limit: PropTypes.string,
    }),
    pathname: PropTypes.string,
    state: PropTypes.shape({
      fromSearch: PropTypes.string,
    }),
  }).isRequired,
  error: PropTypes.oneOfType([ImmutableProptypes.map, PropTypes.string]),
  children: PropTypes.node,
}

PublicCreatorPage.defaultProps = {
  error: new Map(),
  creator: new Map(),
  user: new Map(),
  children: undefined,
}

export default lowercaseParams(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PublicCreatorPage)
)
