import { List, OrderedMap, Map } from 'immutable'
import { createSelector } from 'reselect'

import getActionName from '../../utils/get-action-name'
import { PLATFORM_YOUTUBE, PLATFORM_TWITCH } from '../../config/settings'

const DEFAULT_VALUE_PER_PLATFORM = {
  [PLATFORM_YOUTUBE]: {},
  [PLATFORM_TWITCH]: {},
}

export const getHashCodeFromObject = (params = {}) =>
  String(new Map(params).filterNot((val) => val === undefined).hashCode())
export const getReportKey = (type, id, params) => [
  id,
  getActionName(type),
  getHashCodeFromObject(params),
]

const getReports = (state) => state.campaignReports
const getType = (state, type) => type
const getId = (state, type, id) => id
const getParams = (state, type, id, params = {}) => params

export const selectReport = createSelector(
  getReports,
  getType,
  getId,
  getParams,
  (reports = new Map(), type, id, params) => reports.getIn(getReportKey(type, id, params))
)

export const selectDailyReport = createSelector(
  getReports,
  getType,
  getId,
  getParams,
  (reports = new Map(), type, id, params) => {
    const payload = reports.getIn(getReportKey(type, id, params), new List())
    // due to a ChartJS limitation (https://github.com/chartjs/Chart.js/issues/5072#issuecomment-354119741),
    // we need to add a default value to each platform for each day
    return payload.reduce(
      (accumulator, { platform, day, ...values }) =>
        accumulator.update(day, DEFAULT_VALUE_PER_PLATFORM, (value) => ({
          ...value,
          [platform]: values,
        })),
      new OrderedMap()
    )
  }
)
