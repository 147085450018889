import defineAction from '../../utils/define-action'
import * as videosService from '../../services/videos'

export const GET_PUBLIC_VIDEOS = defineAction('GET_PUBLIC_VIDEOS')
export const GET_CREATOR_VIDEOS = defineAction('GET_CREATOR_VIDEOS')
export const SEARCH_VIDEOS = defineAction('SEARCH_VIDEOS')
export const FLAG_VIDEO = defineAction('FLAG_VIDEO')
export const UNFLAG_VIDEO = defineAction('UNFLAG_VIDEO')

export const getPublicVideos = () => (dispatch, getState) =>
  dispatch({
    type: GET_PUBLIC_VIDEOS.ACTION,
    payload: videosService.getFeatured(getState().user.get('auth_token'))(),
  })

export const getCreatorVideos = (creatorId, params) => (dispatch, getState) =>
  dispatch({
    type: GET_CREATOR_VIDEOS,
    payload: videosService.getCreatorVideos(getState().user.get('auth_token'))(creatorId, params),
    meta: {
      page: { streamer: creatorId, ...params },
    },
  })

export const searchVideos = (search, params) => (dispatch, getState) =>
  dispatch({
    type: SEARCH_VIDEOS,
    payload: videosService.getVideos(getState().user.get('auth_token'))({ search, ...params }),
    meta: {
      page: { search, ...params },
    },
  })

export const flagVideo = (videoId) => (dispatch, getState) =>
  dispatch({
    type: FLAG_VIDEO,
    payload: videosService.flag(getState().user.get('auth_token'))(videoId),
    meta: { video: videoId },
  })

export const unflagVideo = (videoId, flagId) => (dispatch, getState) =>
  dispatch({
    type: UNFLAG_VIDEO,
    payload: videosService.unflag(getState().user.get('auth_token'))(flagId),
    meta: { video: videoId },
  })
