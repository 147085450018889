import { fromJS } from 'immutable'
import flatten from 'lodash.flatten'

export const preloadServerStore = (serverCookies) => ({
  user: fromJS({
    auth_token: serverCookies.auth_token,
    ...JSON.parse(serverCookies.user || null),
  }),
  cookieConsentAccepted: serverCookies.cookies_accepted,
})

const callGetData = (params, location, component) =>
  component && component.getData && component.getData(params, location)

export const handleGetData = (props) =>
  new Promise((resolve, reject) => {
    if (!props || !props.components) {
      reject(new Error('route/component not found'))
    }
    const promises = props.components
      .map(callGetData.bind(null, props.params, props.location))
      .filter(Boolean)
    Promise.all(flatten(promises))
      .then((data = []) => {
        const preloadedState = data.reduce(
          (prev = {}, current = {}) => ({ ...prev, ...current }),
          {}
        )
        resolve(preloadedState)
      })
      .catch(reject)
  })

//               day   h    m    s    ms
const ONE_YEAR = 365 * 24 * 60 * 60 * 1000 // eslint-disable-line no-magic-numbers
export const REFERER_COOKIE = 'firstReferer'
export const setRefererCookie = () => (req, res, next) => {
  if (typeof req.cookies[REFERER_COOKIE] === 'undefined') {
    res.cookie(REFERER_COOKIE, req.headers.referer || '', {
      expires: new Date(Date.now() + ONE_YEAR),
    })
  }
  next()
}
