import axios from 'axios'

import { CLOUDINARY_URL, UPLOAD_PRESET } from '../config/environment'
import { getThumbnailAndOriginal } from '../utils/image-utils'
import downloadBlob from '../utils/download-blob'

import { post, patch, get, del } from './request'
import instance from './request-v2'

export const create = (key) => (payload) => post('campaigns', { key }, payload)

export const update = (key) => (slug, payload) => patch(['campaigns', slug], { key }, payload)

export const remove = (key) => (id) => del(['campaigns', id], { key })

export const getDetails = (key) => (slug, params) => get(['campaigns', slug], { key, params })

export const getAll = (key) => (params) => get('campaigns', { key, params })

export const uploadImage = (payload) => {
  const data = new FormData()
  data.append('file', payload.image)
  data.append('upload_preset', UPLOAD_PRESET)
  return axios.post(CLOUDINARY_URL, data).then(getThumbnailAndOriginal)
}

export const publish = (key) => (slug) => post(['campaigns', slug, 'publish'], { key })

export const deleteCodes = (key) => (slug, platform) =>
  del(['campaigns', slug, 'codes', 'delete_unclaimed'], { params: { platform }, key })

export const getPublicCampaigns = (key) => (params) => get('public-campaigns', { params, key })

export const getPublicCampaign = (key) => (slug) => get(['public-campaigns', slug], { key })

export const availableCampaigns = (key) => (params) => get(['offers', 'available'], { key, params })

export const qualifyingCreators = (key) => (params) =>
  instance
    .get('qualifying-creators/', {
      params,
      key,
    })
    .then((response) => response.data)

export const campaignCreators = (key) => (campaignId, params = {}) =>
  instance
    .get(`campaign-creators/${campaignId}`, {
      params,
      key,
    })
    .then((response) => response.data)

export const exportCampaignCSV = (key) => (id, filename, params) =>
  get(['campaigns', id, 'export_as_csv'], { key, params }).then((data) => {
    downloadBlob(data, filename)
  })

export const exportCampaignShortCSV = (key) => (id, filename, params) =>
  get(['campaigns', id, 'export_resumed_csv'], { key, params }).then((data) => {
    downloadBlob(data, filename)
  })

export const getOffers = (key) => (id, params) => get(['campaigns', id, 'offers'], { key, params })

export const getVideos = (key) => (id, params) => get(['campaigns', id, 'videos'], { key, params })

export const checkSlug = (key) => (params) => get(['campaigns', 'validate_slug'], { key, params })

export const featureCampaign = (key) => (campaignId) =>
  post(['campaigns', campaignId, 'featured-campaign', 'activate'], { key })

export const defeatureCampaign = (key) => (campaignId) =>
  post(['campaigns', campaignId, 'featured-campaign', 'deactivate'], { key })

export const pause = (key) => (campaignId) => post(['campaigns', campaignId, 'pause'], { key })
export const unpause = (key) => (campaignId) => post(['campaigns', campaignId, 'unpause'], { key })
export const removeUnclaimedCodes = (key) => (campaignId, platform) =>
  del(['campaigns', campaignId, 'codes', 'delete_unclaimed'], { key, params: { platform } })

const parseCodesIntoCSVFormat = (data) =>
  data
    .sort((a, b) => (a.platform < b.platform ? -1 : 1))
    .map((c) => [c.code, c.platform].join(', '))
    .join('\n')
export const downloadRemainingCodes = (key) => (campaignId, filename, platform) =>
  get(['campaigns', campaignId, 'codes'], { key, params: { state: 'unclaimed', platform } }).then(
    (data) => {
      const codesCsv = parseCodesIntoCSVFormat(data)
      downloadBlob(codesCsv, filename)
    }
  )

export const updateCode = (key) => (campaignId, codeId, code) =>
  patch(['campaigns', campaignId, 'codes', codeId], { key }, { code })

export const getCountByState = (key) => (params) => get(['campaigns', 'count'], { key, params })

export const deleteCode = (key) => (campaignId, platform, code) =>
  del(['delete_code'], { key }, { code, campaing_id: campaignId, platform, replace_reserved: true })

export const replaceCode = (key) => (campaignId, codeId) =>
  post(['campaigns', campaignId, 'codes', codeId, 'replace_code'], { key })

export const getMatchigVideos = (key) => (campaignId, params) =>
  instance
    .get(`matching-videos/${campaignId}/`, {
      params,
      key,
    })
    .then((response) => {
      const data = response.data
      const results = data.results.map((r) => ({
        id: r.video.id,
        ...r,
      }))
      return {
        ...data,
        results,
      }
    })

export const deliverFuzzyMatch = (key) => (campaignId, videoId) =>
  instance.post(`deliver-fuzzy-match/${campaignId}/${videoId}/`, null, {
    key,
  })

export const discoverCampaignCreators = (key) => (campaignId, offset) =>
  instance
    .get(`campaign/${campaignId}/discover-creators/`, { key, params: { limit: 20, offset } })
    .then((r) => r.data)

export const stageDiscoverSelections = (key) => (campaignId, selections) =>
  instance
    .post(
      `campaign/${campaignId}/discover-creators/stage-marks`,
      { streamer_marks: selections },
      { key }
    )
    .then((r) => r.data)

export const commitDiscoverSelections = (key) => (
  campaignId,
  platforms,
  expirationDays,
  inviteLetter,
  inviteSubject
) =>
  instance
    .post(
      `campaign/${campaignId}/discover-creators/commit-marks`,
      {
        platforms,
        invite_duration: expirationDays,
        invite_letter: inviteLetter,
        invite_subject: inviteSubject,
      },
      { key }
    )
    .then((r) => r.data)
