import sanitize from 'sanitize-html'

const domSanitizeHTML = (html) => {
  const tmp = document.createElement('div')
  tmp.innerHTML = html
  return tmp.innerText
}

const sanitizeHTML = typeof document !== 'undefined' ? domSanitizeHTML : sanitize

export default sanitizeHTML
