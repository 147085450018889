import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ImmutableProptypes from 'react-immutable-proptypes'
import { Link } from 'react-router'
import { IntercomAPI } from 'react-intercom'
import { faChevronRight, faSearchPlus, faCheck } from '@fortawesome/free-solid-svg-icons'

import Carousel from '../../components/carousel'
import Image from '../../components/block-image'
import Svg from '../../components/svg'
import Loading from '../../components/piece-loading'
import Actionable from '../../components/actionable'
import Icon from '../../components/piece-icon'
import { getPublicCampaigns, GET_FEATURED_CAMPAIGNS } from '../../modules/campaign/actions'
import { selectPage, selectPageResults } from '../../modules/pagination/selectors'

import automatedDeliveries from './creator/automated-deliveries.svg'
import portfolio from './creator/portfolio.svg'
import signin from './creator/signin.jpg'
import qualify from './creator/qualify.jpg'
import acceptOffer from './creator/accept-offer.jpg'
import styles from './styles.css'

const CAMPAIGNS_LIMIT = 12
const CAMPAIGN_PARAMS = { limit: CAMPAIGNS_LIMIT }
const CAROUSEL_RESPONSIVE_SETTINGS = [{ breakpoint: 680, settings: { slidesToShow: 3 } }]

const mapStateToProps = (state) => {
  const featuredPage = selectPage(state, GET_FEATURED_CAMPAIGNS.ACTION, CAMPAIGN_PARAMS)
  return {
    featured: selectPageResults(featuredPage, state.featuredCampaigns),
    offersCount: featuredPage.get('count', 0),
  }
}

const mapDispatchToProps = {
  getPublicCampaigns,
}

class Creator extends Component {
  static propTypes = {
    featured: ImmutableProptypes.list.isRequired,
    offersCount: PropTypes.number.isRequired,
    getPublicCampaigns: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.getPublicCampaigns(CAMPAIGN_PARAMS)
  }

  renderFeatured = (item) => {
    const href = `/offer/${item.get('slug')}`
    return (
      <Link to={href} key={item.hashCode()} className={styles.offer}>
        <Image
          className={styles.offerImage}
          url={item.get('cover_url')}
          alt={item.get('title') || item.get('steam_game_name')}
        />
      </Link>
    )
  }

  render() {
    const { offersCount, featured } = this.props
    return (
      <div className={styles.container}>
        <div className={styles.head}>
          <div className={styles.headBg}>
            <h1>Woovit for Creators</h1>
            <div className={styles.mission}>Real offers. Instant qualification. Hassle free.</div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.carousel}>
            {featured.size ? (
              <Carousel responsive={CAROUSEL_RESPONSIVE_SETTINGS} slidesToShow={4}>
                {featured.map(this.renderFeatured)}
              </Carousel>
            ) : (
              <Loading isLoading />
            )}
          </div>
          <h2>Woovit Campaigns are Real Offers</h2>
          <p>
            Your time should be spent making great content, not tracking down keys. Woovit connects
            you with {offersCount} active developers and publishers with live campaigns. Most offer
            instant delivery of a key with review requests processed in ten days or less.
          </p>
          <Link to="/offer/all">
            <span className={styles.icon}>
              <Icon icon={faSearchPlus} /> See all available offers to grow your channel{' '}
              <Icon icon={faChevronRight} />
            </span>
          </Link>

          <hr />
          <h2>Instant Verification and Qualification</h2>
          <p>
            Creating an account on Woovit is easy! Sign in with your YouTube or Twitch account and
            we will instantly verify your identity. Each campaign has minimum requirements set by
            the publisher who created the campaign
          </p>

          <div className={styles.steps}>
            <div className={styles.step}>
              <Image url={signin} alt="1. Sign in" />
            </div>
            <div className={styles.step}>
              <Image url={qualify} alt="2. Qualify" />
            </div>
            <div className={styles.step}>
              <Image url={acceptOffer} alt="3. Accept Offer" />
            </div>
          </div>

          <hr />
          <h2>Automated Deliveries</h2>
          <Svg svg={automatedDeliveries} width="828px" height="395px" alt="Automated Deliveries" />
          <div className={styles.automatedDeliveries}>
            <p>
              When you claim a key from Woovit, we automatically detect and share any content you
              create for a campaign with the offer’s name.{' '}
            </p>
            <p>
              This means you do not have to upload, email, or report any videos you create and you
              can have peace-of-mind that the publisher or developer will see your content.
            </p>
          </div>

          <hr />
          <h2>Show Publishers Your Portfolio</h2>
          <p>
            When you create a Woovit account you are also given a searchable creator profile to tell
            publishers and brands about you— fully customizable based on what information you want
            to share.
          </p>

          <div className={styles.portfolio}>
            <div>
              <h3>Display only what’s most important to you</h3>
              <p>
                Showcase and leverage your content to impress publishers and brands looking for
                partnered creators.
              </p>

              <div className={styles.checks}>
                <div className={styles.check}>
                  <span className={styles.icon}>
                    <Icon icon={faCheck} />
                  </span>
                  Biography
                </div>
                <div className={styles.check}>
                  <span className={styles.icon}>
                    <Icon icon={faCheck} />
                  </span>
                  Social Network(s)
                </div>
                <div className={styles.check}>
                  <span className={styles.icon}>
                    <Icon icon={faCheck} />
                  </span>
                  Videos
                </div>
                <div className={styles.check}>
                  <span className={styles.icon}>
                    <Icon icon={faCheck} />
                  </span>
                  Business Manager Contact
                </div>
              </div>
            </div>
            <Svg svg={portfolio} width="388px" height="190px" alt="portfolio" />
          </div>

          <hr />
          <h2>Secure & Private Data</h2>
          <p>
            We keep your data safe and your contact information is not for sale or susceptible to
            unsolicited spam. To keep your identity private, we only use your official Twitch and/or
            YouTube APIs to sign in and record your subscriber/ viewer statistics to measure
            qualifications and track videos.
          </p>

          <div className={styles.question}>
            <h2>Got a Question?</h2>
            <p>
              Use our{' '}
              <Actionable
                skipOwnClass
                className={styles.helpLink}
                onClick={IntercomAPI}
                onClickWith="show"
              >
                Help Center
              </Actionable>{' '}
              to find an answer quickly or start a conversation.
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Creator)
